import React, { useState } from "react";

const AddFeedbackIcon = (props) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <>    
        { (isHover || props.isClicked) && <div style={{ background: 'rgba(191, 191, 191, 0.25)', width: '22px', height: '22px', left: '-28px', position: 'absolute', borderRadius: '11px' }} />}
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-5 -5 310 312"
            fill={(isHover || props.isClicked) ? '#000000' : '#00000090'}
            className='emoji emoji-picker'
            onMouseEnter={(e) => {
                setIsHover(true);
            }}
            onMouseLeave={(e) => {
                setIsHover(false);
            }}
            {...props} 
        >
            <path d="M149.99,301.35C67.28,301.35,0,234.07,0,151.36S67.28,1.37,149.99,1.37c5.52,0,10,4.48,10,10s-4.48,10-10,10c-71.68,0-129.99,58.31-129.99,129.99s58.31,129.99,129.99,129.99,129.99-58.31,129.99-129.99c0-5.52,4.48-10,10-10s10,4.48,10,10c0,82.7-67.29,149.99-149.99,149.99Z" />
            <g>
                <circle cx="104.86" cy="135.52" r="19.69" />
                <circle cx="196.9" cy="135.52" r="19.69" />
            </g>
            <path d="M150.71,239.13h-.21c-26.02-.07-50.33-12.31-68.46-34.47-3.5-4.27-2.87-10.58,1.41-14.07,4.28-3.5,10.57-2.87,14.07,1.41,14.27,17.45,33.1,27.08,53.03,27.13h.16c20.06,0,39.07-9.64,53.55-27.17,3.52-4.26,9.82-4.85,14.08-1.34,4.26,3.52,4.86,9.82,1.34,14.08-18.36,22.21-42.84,34.43-68.97,34.43Z" />
            <path d="M284.88,66.87h-93.74c-5.52,0-10-4.48-10-10s4.48-10,10-10h93.74c5.52,0,10,4.48,10,10s-4.48,10-10,10Z" />
            <path d="M238.01,113.74c-5.52,0-10-4.48-10-10V10c0-5.52,4.48-10,10-10s10,4.48,10,10v93.74c0,5.52-4.48,10-10,10Z" />
        </svg>
    </>
  );
};

export default AddFeedbackIcon
