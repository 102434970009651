import React from "react";
import Cookies from "universal-cookie";
import LogoutApi from "../../ServiceApi/Apis/LogoutApi";
import UserInfoUtil from "UserInfoUtil";
import UserApi from "../../ServiceApi/Apis/UserApi";
const cookies = new Cookies();
export default class Logout extends React.Component {
  componentDidMount() {
    let logout = new LogoutApi();
    const user = UserInfoUtil.getUserInfo();
    const userEmail = user ? user.userEmail : null;
    let logOutResult = logout.getdata(userEmail);
    let isSSOLogout = sessionStorage.getItem('_sso');
    logOutResult.then((result) => {
      if (result.status == 200) {
        cookies.remove('_pUser', { path: '/' });
        cookies.remove('_pRemember', { path: '/' });
        cookies.remove('AI_SESSION_ID', { path: '/' });
        localStorage.removeItem("_pUserLocal");
        sessionStorage.removeItem("_pUser");
        sessionStorage.removeItem("_performanceWindow");
        sessionStorage.removeItem("_LMTrackDataList");
        sessionStorage.removeItem("_sso");
        //sessionStorage.removeItem("prodServerURL");
        // if(result.sloUrl){
        //   window.location.href = result.sloUrl;
        // }
        // else{
        if (isSSOLogout != null && isSSOLogout == 1){
          window.location = "#/SSO";
        } else {
          window.location = "/";
        }
        // }
      }        
    })
      .catch((err) => {
        if (err.status == 401) {
          cookies.remove('_pUser', { path: '/' });
          cookies.remove('_pRemember', { path: '/' });
          cookies.remove('AI_SESSION_ID', { path: '/' });
          localStorage.removeItem("_pUserLocal");
          sessionStorage.removeItem("_pUser");
          sessionStorage.removeItem("_performanceWindow");
          sessionStorage.removeItem("_LMTrackDataList");
          sessionStorage.removeItem("_sso");
          //sessionStorage.removeItem("prodServerURL");
         
          if (isSSOLogout != null && isSSOLogout == 1){
            window.location = "#/SSO";
          } else {
            window.location = "/";
          } 
        }
      });


  }
  
  render() {
    return (
      <span></span>
    );
  }
}
