import BaseServiceApi from "../../../../ServiceApi/BaseServiceApi";
import BlockType from "../../../../Constants/BlockType";
import ConsoleStore from "ConsoleStore";
import { DataGraphConstants } from "../../../../Constants/DataGraphConstants";
import DatagraphHelper from "../../../../Utils/DatagraphHelper";
import DefaultDataGraphSetting from "../../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/DefaultDataGraphSettings";
import { getCurrentListData } from "../../../../Actions/ExternalDataUploaderActions";
import GraphType from "GraphType";
import { IndicatorHeaderTranslateHelper } from "../../../../Utils/TranslateHelper";
import MiniListHelper from "MiniListHelper";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import SettingsStore from "SettingsStore";
import SmartViewType from "../../../../Constants/SmartViewType";
import SymbolType from "SymbolType";
import TimeTrackingWindow from "../../../../RayCustomControls/TimeTrackingWindow";
import { updateAvailableComponent } from "../../../../Actions/DatagraphActions";
import { updateIndicatorHeight } from "../../../../Actions/PricePanelActions";
import { updateIndicatorMenu } from "../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";
import UserInfoUtil from "../../../../Utils/UserInfoUtil";
import { blockMenuTypes, indicatorNameConst, IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import { fork, put, select, takeLatest } from "redux-saga/effects";
import { getDatagraphStates, getIndicatorStates, getTimeLine, indicatorMenuSelect, KpiModuleReducerState } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors";

const { ActionTypes } = IndicatorsConstants;
const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
const IndicatorSettingName = {
    [BlockType.AccDist]: "AccDistChartSettings",
    [BlockType.EPSR]: "EPSRChartSettings",
    [BlockType.ExternalData]: "ExternalDataSettings",
    [BlockType.KeyPerfomanceIndicator]: "KpiSettings",
    [BlockType.MACD]: "MacdChartSettings",
    [BlockType.PTOE]: "PTOEChartSettings",
    [BlockType.PTOS]: "PTOSChartSettings",
    [BlockType.RSI]: "RsiChartSettings",
    [BlockType.Extended]: "ExtendedChartSettings",
    [BlockType.Stochastics]: "StochasticsChartSettings",
    [BlockType.WonStochastics]: "WonStochasticsChartSettings",
    [BlockType.TechIndicator]: "TechChartSettings",
    [BlockType.YTD]: "YTDChartSettings",
    [BlockType.YTD1]: "YTD1ChartSettings",
    [BlockType.YTD3]: "YTD3ChartSettings",
    [BlockType.YTD5]: "YTD5ChartSettings",
    [BlockType.YTD10]: "YTD10ChartSettings",
}

function isEtf(info) {
    return (info &&
        info.Industry197Name &&
        info.Industry197Name.includes("ETF")
    );
}
function* getTechIndicatorMenu() {
    try {
        const { pricePanelData, SymbolInfo, majorPeriodicity, viewsSettings, indicatorsHeight} = yield select(getDatagraphStates);
        const international = DatagraphHelper.internationalStock(SymbolInfo);
        const etf =  isEtf(SymbolInfo);
        const commonStock = DatagraphHelper.commonStock(SymbolInfo);
        let blockMenu = [];
        //let tiMenuSettings = [];
        const tiMenuSettings = viewsSettings.TiMenuSettings[majorPeriodicity][0];
        const isIntraday = majorPeriodicity === GraphType.Intraday;
        const isPEPSEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.PEPS_Entitlement);
        const isWonStochEntitle = UserInfoUtil.IsUserEntitled(EntitlementType.WON_STOCHASTICS_Entitlement)
        const isExtendedEntitle = UserInfoUtil.IsUserEntitled(EntitlementType.WONDA_EXTENDED_Entitlement)
        Object.keys(blockMenuTypes).map((blockItem) => {
            const info = [];
            switch (blockItem) {
                case blockMenuTypes.FM:
                    if (viewsSettings.EPSRChartSettings && viewsSettings.EPSRChartSettings[majorPeriodicity] && commonStock && !etf && !international) {
                        const epsrSettings = viewsSettings.EPSRChartSettings[majorPeriodicity];
                        if (majorPeriodicity === GraphType.Quarterly || majorPeriodicity === GraphType.Annual) {
                            epsrSettings.IsAvailable = false;
                        }
                        if (epsrSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.EPSR], ischecked: epsrSettings.IsVisible, blockType: BlockType.EPSR, seqNumber: epsrSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    if (isPEPSEntitled) {
                        if (viewsSettings.PTOEChartSettings && viewsSettings.PTOEChartSettings[majorPeriodicity] && commonStock && !etf && !international) {
                            const ptoeSettings = viewsSettings.PTOEChartSettings[majorPeriodicity];
                            if (ptoeSettings.IsAvailable) {
                                info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.PTOE], ischecked: ptoeSettings.IsVisible, blockType: BlockType.PTOE, seqNumber: ptoeSettings.seqNumber, height: indicatorsHeight })
                            }
                        }
                        if (viewsSettings.PTOSChartSettings && viewsSettings.PTOSChartSettings[majorPeriodicity] && commonStock && !etf && !international) {
                            const ptosSettings = viewsSettings.PTOSChartSettings[majorPeriodicity];
                            if (ptosSettings.IsAvailable) {
                                info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.PTOS], ischecked: ptosSettings.IsVisible, blockType: BlockType.PTOS, seqNumber: ptosSettings.seqNumber, height: indicatorsHeight })
                            }
                        }
                    }
                    break;
                case blockMenuTypes.TI:
                    if ((!isIntraday) && viewsSettings.AccDistChartSettings && viewsSettings.AccDistChartSettings[majorPeriodicity]) {
                        const accdiskSettings = viewsSettings.AccDistChartSettings[majorPeriodicity];
                        if (accdiskSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.AccDist], ischecked: accdiskSettings.IsVisible, blockType: BlockType.AccDist, seqNumber: accdiskSettings.seqNumber, height: indicatorsHeight });
                        }
                      }
                    if (viewsSettings.MacdChartSettings && viewsSettings.MacdChartSettings[majorPeriodicity]) {
                        const macdSettings = viewsSettings.MacdChartSettings[majorPeriodicity];
                        if (macdSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.MACD], ischecked: macdSettings.IsVisible, blockType: BlockType.MACD, seqNumber: macdSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    if (viewsSettings.RsiChartSettings && viewsSettings.RsiChartSettings[majorPeriodicity]) {
                        const rsiSettings = viewsSettings.RsiChartSettings[majorPeriodicity];
                        if (rsiSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.RSI], ischecked: rsiSettings.IsVisible, blockType: BlockType.RSI, seqNumber: rsiSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    if (isExtendedEntitle && viewsSettings.ExtendedChartSettings && viewsSettings.ExtendedChartSettings[majorPeriodicity]) {
                       const extendedSettings = viewsSettings.ExtendedChartSettings[majorPeriodicity];
                       if (extendedSettings.IsAvailable) {
                           info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.Extended], ischecked: extendedSettings.IsVisible, blockType: BlockType.Extended, seqNumber: extendedSettings.seqNumber, height: indicatorsHeight })
                       }
                    }
                    if (viewsSettings.StochasticsChartSettings && viewsSettings.StochasticsChartSettings[majorPeriodicity]) {
                        const stochkSettings = viewsSettings.StochasticsChartSettings[majorPeriodicity];
                        if (stochkSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.Stochastics], ischecked: stochkSettings.IsVisible, blockType: BlockType.Stochastics, seqNumber: stochkSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    if (isWonStochEntitle  && viewsSettings.WonStochasticsChartSettings && viewsSettings.WonStochasticsChartSettings[majorPeriodicity]) {
                        const stochkSettings = viewsSettings.WonStochasticsChartSettings[majorPeriodicity];
                        if (stochkSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.WonStochastics], ischecked: stochkSettings.IsVisible, blockType: BlockType.WonStochastics, seqNumber: stochkSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    break;
                case blockMenuTypes.PM:
                    if (viewsSettings.YTDChartSettings) {
                        const ytdSettings = viewsSettings.YTDChartSettings[majorPeriodicity];
                        if (ytdSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.YTD], ischecked: ytdSettings.IsVisible, blockType: BlockType.YTD, seqNumber: ytdSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    if (viewsSettings.YTD1ChartSettings) {
                        const ytdSettings = viewsSettings.YTD1ChartSettings[majorPeriodicity];
                        if (ytdSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.YTD1], ischecked: ytdSettings.IsVisible, blockType: BlockType.YTD1, seqNumber: ytdSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    if (viewsSettings.YTD3ChartSettings) {
                        const ytdSettings = viewsSettings.YTD3ChartSettings[majorPeriodicity];
                        if (ytdSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.YTD3], ischecked: ytdSettings.IsVisible, blockType: BlockType.YTD3, seqNumber: ytdSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    if (viewsSettings.YTD5ChartSettings) {
                        const ytdSettings = viewsSettings.YTD5ChartSettings[majorPeriodicity];
                        if (ytdSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.YTD5], ischecked: ytdSettings.IsVisible, blockType: BlockType.YTD5, seqNumber: ytdSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    if (viewsSettings.YTD10ChartSettings) {
                        const ytdSettings = viewsSettings.YTD10ChartSettings[majorPeriodicity];
                        if (ytdSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.YTD10], ischecked: ytdSettings.IsVisible, blockType: BlockType.YTD10, seqNumber: ytdSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    break;
                case blockMenuTypes.OR:
                    if (viewsSettings.TechChartSettings && viewsSettings.TechChartSettings[majorPeriodicity] && (commonStock || etf) && !international) {
                        const techSettings = viewsSettings.TechChartSettings[majorPeriodicity];
                        if (techSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.TechIndicator], ischecked: techSettings.IsVisible, blockType: BlockType.TechIndicator, seqNumber: techSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    if (viewsSettings.TechChartSettings && viewsSettings.TechChartSettings[majorPeriodicity] && international && majorPeriodicity === GraphType.Daily) {
                        const techSettings = viewsSettings.TechChartSettings[majorPeriodicity];
                        if (techSettings.IsAvailable) {
                            info.push({ Header: IndicatorHeaderTranslateHelper[BlockType.TechIndicator], ischecked: techSettings.IsVisible, blockType: BlockType.TechIndicator, seqNumber: techSettings.seqNumber, height: indicatorsHeight })
                        }
                    }
                    break;
                default:
                    break;
            }
            blockMenu.push({ Name: indicatorNameConst[blockItem], info, IsVisible: tiMenuSettings[blockItem].isVisible, isDisabled: info.length === 0, seqNumber: tiMenuSettings[blockItem].seqNumber, blockType: blockItem });
            return null;
        });
        if(pricePanelData.SymbolInfo.SymTypeEnum === SymbolType.ECONOMICINDICATOR){
            blockMenu = blockMenu.filter((item)=> item.blockType === blockMenuTypes.ED)
        }
        blockMenu.sort((a, b) => (a.seqNumber < b.seqNumber) ? -1 : (a.seqNumber > b.seqNumber) ? 1 : 0);
        blockMenu.map((item) => item.info.sort((a, b) => (a.seqNumber < b.seqNumber) ? -1 : (a.seqNumber > b.seqNumber) ? 1 : 0));
        let availableComponents = 1;
        blockMenu.forEach((item)=>{
            if(item.IsVisible){
                item.info.forEach((subItem)=>{
                    if(subItem.ischecked){
                        availableComponents++;
                    }
                })
            }
        })
        yield put(updateAvailableComponent(availableComponents))
        yield put(updateIndicatorMenu(blockMenu.filter((item)=> item.info.length !== 0 || item.IsVisible), blockMenu))
        yield fork(getExternalIndicatorMenu);
        yield fork(getKpiIndicatorMenu)
        TimeTrackingWindow.setPerformanceTIPanelStatus(blockMenu, pricePanelData.SymbolInfo);
    }
    catch (error) {
        console.log(`Error occurs in IndicatorFlyoutMenuSaga.js, getTechIndicatorMenu ${error}`, error);
    }
}
function* getExternalIndicatorMenu() {
    try {
        const { blockMenu } = yield select(getIndicatorStates);
        const { majorPeriodicity, viewsSettings, isIntraday, SymbolInfo } = yield select(getDatagraphStates);
        const settings = SettingsStore.getConsoleSettings();
        const isExternalDataEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement);
        blockMenu.forEach((blockItem) => {
            if (blockItem.Name === indicatorNameConst[blockMenuTypes.ED] && !isIntraday && isExternalDataEntitled && viewsSettings.ExternalDataSettings && settings.isUploadedExternalData) {
                blockItem.info = [];
                // listType = 1 is empty list and hence they are restricted to list under any context menu
                // disabled list for current symbol is restricted to display under Ti menu
                Object.entries(viewsSettings.ExternalDataSettings).forEach(([key, setting]) => {
                    if (key !== 'default' && setting[majorPeriodicity].listType !== 1 && setting[majorPeriodicity].listType !== BaseServiceApi.rayData["ListType"].EVENTSERIES_List && !setting[majorPeriodicity].isDisabledForCurrentSymbol) {
                        blockItem.info.push({
                            Header: setting[majorPeriodicity].Header,
                            ischecked: setting[majorPeriodicity].IsVisibleInIndicatorMenu,
                            blockType: BlockType.ExternalData,
                            listId: key,
                            menu: setting[majorPeriodicity].indicator,
                            periodicity: majorPeriodicity,
                            listType: setting[majorPeriodicity].listType,
                            isDisabledForCurrentSymbol: setting[majorPeriodicity].isDisabledForCurrentSymbol
                        });
                    }
                });
                blockItem.info.sort((a, b) => (a.Header.toLowerCase() < b.Header.toLowerCase()) ? -1 : ((a.Header.toLowerCase() > b.Header.toLowerCase()) ? 1 : 0));
                blockItem.isDisabled = blockItem.info.length === 0;
                blockItem.IsVisible = blockItem.info.length !== 0;
            }
        });
        const indicatorMenu = blockMenu.filter((item)=> item.info.length !== 0 || item.IsVisible);
        let availableComponents = 1;
        blockMenu.forEach((item)=>{
            if(item.IsVisible){
                item.info.forEach((subItem)=>{
                    if(subItem.ischecked){
                        availableComponents++;
                    }
                })
            }
        })
        yield put(updateAvailableComponent(availableComponents))
        TimeTrackingWindow.setPerformanceTIPanelStatus(indicatorMenu, SymbolInfo);
        yield put(updateIndicatorMenu(indicatorMenu, blockMenu))
        
    }
    catch (error) {
        console.log(`Error occurs in KPIIndicatorSaga.js, getKpiMenu ${error}`, error);
    }
}

export function* getKpiIndicatorMenu() {
    try {
        const { KPIsDataPoints, availableMenus } = yield select(KpiModuleReducerState);
        if(availableMenus.length > 0){
            const { blockMenu } = yield select(getIndicatorStates);
            const { Symbol, majorPeriodicity, viewsSettings, SymbolInfo, isIntraday } = yield select(getDatagraphStates);
            const isMiniListPlay = false;
            if (MiniListHelper.ActiveSymbolCheck(Symbol, isMiniListPlay)) {
                if(!isIntraday){
                    blockMenu.forEach((blockItem) => {
                        if (blockItem.Name === indicatorNameConst[blockMenuTypes.KPI]) {
                            blockItem.info = [];
                            availableMenus.forEach((item, key) => {
                                if (KPIsDataPoints[item.KpiId] &&  KPIsDataPoints[item.KpiId].length > 0) {
                                    const infoKpi = ConsoleStore.kpiList.find((d) => d.KpiId === item.KpiId);
                                    if (!viewsSettings.KpiSettings[item.KpiId]) {
                                        viewsSettings.KpiSettings[item.KpiId] = DefaultDataGraphSetting.getKpiSettingDefault(infoKpi.IsChecked, key + 1);
                                    }
                                    const kpiMenuSettings = viewsSettings.KpiSettings[item.KpiId][majorPeriodicity];
                                    blockItem.info.push({
                                        Header: item.KpiName,
                                        ischecked: kpiMenuSettings.IsVisible,
                                        blockType: BlockType.KeyPerfomanceIndicator,
                                        seqNumber: kpiMenuSettings.seqNumber ? kpiMenuSettings.seqNumber : key + 1,
                                        KpiId: item.KpiId,
                                        displayName: [infoKpi.DisplayNameLine1, infoKpi.DisplayNameLine2].filter(Boolean).join("\n"),
                                        definition: infoKpi.Definition,
                                    })
                                }
                            });
                            blockItem.isDisabled = blockItem.info.length === 0;
                            blockItem.info.sort((a, b) => (a.seqNumber < b.seqNumber) ? -1 : (a.seqNumber > b.seqNumber) ? 1 : 0);
    
                        }
                    });
                }
                const indicatorMenu = blockMenu.filter((item)=> item.info.length !== 0 || item.IsVisible)
                TimeTrackingWindow.setPerformanceTIPanelStatus(indicatorMenu, SymbolInfo);
                yield put(updateIndicatorMenu(indicatorMenu, blockMenu))
            }
        }
    }
    catch (error) {
        console.log(`Error occurs in KPIIndicatorSaga.js, getKpiMenu ${error}`, error);
    }
}

function* saveSectionToggle({ block, visible }) {
    try {
        const { majorPeriodicity, viewsSettings, SymbolType } = yield select(getDatagraphStates);
        const tiSetting = viewsSettings.TiMenuSettings[majorPeriodicity][0];
        tiSetting[block].isVisible = visible;
        const indicatorMenu = yield select(indicatorMenuSelect);
        indicatorMenu.map((item) => {
            if (item.blockType === block) {
                item.IsVisible = visible;
            }
            return null;
        })
        let availableComponents = 1;
        indicatorMenu.forEach((item)=>{
            if(item.IsVisible){
                item.info.forEach((subItem)=>{
                    if(subItem.ischecked){
                        availableComponents++;
                    }
                })
            }
        })
        yield put(updateAvailableComponent(availableComponents))
        if(SymbolType === SmartViewType.FUND){
            yield put(updateIndicatorHeight())
        }
        SettingsStore.saveSettings();
        yield put({
            type: ActionTypes.UPDATE_INDICATOR_MENU,
            indicatorMenu: [...indicatorMenu]
        });
    }
    catch (error) {
        console.log(`Error occurs in HeaderSaga.js, saveSectionToggle ${error}`);
    }
}
//pBlockType is for parent block type e.g. Fundamental metrices
//blockType is for child blck type e.g. PTOE, PTOS, EPSR
function* openCollapseBlock({ pblockType, blockType, isopen, data, listId = '' }) {
    try {
        const { majorPeriodicity, viewsSettings, nodeCount, availableComponents, SymbolType } = yield select(getDatagraphStates);
        const timeLine = yield select(getTimeLine)
        const newAvailableComponents = availableComponents + (isopen ? 1 : -1);
        yield put(updateAvailableComponent(newAvailableComponents))
        if(SymbolType === SmartViewType.FUND){
            yield put(updateIndicatorHeight())
        }

        if (BlockType.ExternalData === blockType) {
            if (isopen) {
                yield put(getCurrentListData(data.listId, nodeCount, timeLine)); //Needs to change nodecount value.
            }
            viewsSettings[IndicatorSettingName[blockType]][listId ? listId : data.listId][majorPeriodicity].IsVisibleInIndicatorMenu = isopen;
            viewsSettings[IndicatorSettingName[blockType]][listId ? listId : data.listId][majorPeriodicity].isLastUploaded = false;
        }
        else if (BlockType.KeyPerfomanceIndicator === blockType) {
            viewsSettings[IndicatorSettingName[blockType]][data.KpiId][majorPeriodicity].IsVisible = isopen;
        }
        else {
            viewsSettings[IndicatorSettingName[blockType]][majorPeriodicity].IsVisible = isopen;
        }
        const indicatorMenu = yield select(indicatorMenuSelect);
        indicatorMenu.map((item) => {
            if (item.blockType === pblockType) {
                if (isopen) {
                    item.IsVisible = true;
                    viewsSettings.TiMenuSettings[majorPeriodicity][0][pblockType].isVisible = true;
                }
                if (blockType === BlockType.KeyPerfomanceIndicator) {
                    item.info.map((value) => {
                        if (value.KpiId === data.KpiId) {
                            value.ischecked = isopen;
                        }
                        return value;
                    })
                }
                else if (blockType === BlockType.ExternalData) {
                    item.info.map((value) => {
                        if (value.listId === listId) {
                            value.ischecked = isopen;
                        }
                        return value;
                    })
                }
                else {
                    item.info.map((value) => {
                        if (value.blockType === blockType) {
                            value.ischecked = isopen;
                        }
                        return value;
                    })
                }
            }
            return item;
        })

        yield put({
            type: ActionTypes.UPDATE_INDICATOR_MENU,
            indicatorMenu: [...indicatorMenu]
        });
        SettingsStore.saveSettings();
    }
    catch (error) {
        console.log(`Error occurs in IndicatorFlyoutMenuSaga.js, openCollapseBlock ${error}`);
    }
}

function* sortIndicatorSection({ item }) {
    try {
        const { majorPeriodicity, viewsSettings } = yield select(getDatagraphStates);
        const indicatorId = item.nodes[0].node.dataset.indicatorId;
        const newIndex = item.newIndex;
        const oldIndex = item.oldIndex;
        let indicators = []
        const indicatorMenu = yield select(indicatorMenuSelect);
        indicatorMenu.map((item) => {
            if (item.Name === indicatorId) {
                indicators = item;
            }
            return null;
        })
        const visibleIndicators = indicators.info.filter((item) => item.ischecked);
        const element = visibleIndicators.splice(oldIndex, 1);
        visibleIndicators.splice(newIndex, 0, element[0]);
        indicators.info.map((item, index) => {
            if (!item.ischecked) {
                visibleIndicators.splice(index, 0, item);
            }
            return null;
        })
        indicators.info = visibleIndicators;
        indicators.info.forEach((value, index) => {
            value.seqNumber = index + 1;
            if(value.blockType === BlockType.KeyPerfomanceIndicator){
                viewsSettings[IndicatorSettingName[value.blockType]][value.KpiId][majorPeriodicity].seqNumber = value.seqNumber;
            }
            else{
                viewsSettings[IndicatorSettingName[value.blockType]][majorPeriodicity].seqNumber = value.seqNumber;
            }
        })

        yield put({
            type: ActionTypes.UPDATE_INDICATOR_MENU,
            indicatorMenu: [...indicatorMenu]
        });
        SettingsStore.saveSettings();
    }
    catch (error) {
        console.log(`Error occurs in HeaderSaga.js, sortIndicatorSection ${error}`);
    }
}

function* sortIndicator({ item }) {
    try {
        const { majorPeriodicity, viewsSettings } = yield select(getDatagraphStates);
        const TiMenuSettings = viewsSettings.TiMenuSettings[majorPeriodicity];
        const newIndex = item.newIndex - 1;
        const oldIndex = item.oldIndex - 1;
        const indicatorMenu = yield select(indicatorMenuSelect);
        const visibleIndicators = indicatorMenu.filter((item) => item.IsVisible && (!item.isDisabled));
        const element = visibleIndicators.splice(oldIndex, 1)
        visibleIndicators.splice(newIndex, 0, element[0]);
        indicatorMenu.forEach((item, index) => {
            if (!item.IsVisible || item.isDisabled) {
                visibleIndicators.splice(index, 0, item)
            }
        })
        visibleIndicators.forEach((value, index) => {
            value.seqNumber = index + 1;
            TiMenuSettings[0][value.blockType].seqNumber = value.seqNumber;
        })

        yield put({
            type: ActionTypes.UPDATE_INDICATOR_MENU,
            indicatorMenu: [...visibleIndicators]
        })
        SettingsStore.saveSettings();
    }
    catch (error) {
        console.log(`Error occurs in HeaderSaga.js, sortIndicator ${error}`);
    }
}
export function* watchPrepareIndicatorMenu() {
    yield takeLatest(DataGraphConstants.ActionTypes.PRICE_PANEL_RESPONSE_READY, getTechIndicatorMenu);
}
export function* watchReprocessIndicatorMenu() {
    yield takeLatest(PriceChartConstants.ActionTypes.PREPARE_PRICE_AND_INDICATOR_MENU, getExternalIndicatorMenu);
}
export function* watchSaveIndicatorSectionVisibility() {
    yield takeLatest(ActionTypes.SAVE_INDICATOR_TOGGLE_SECTION, saveSectionToggle);
}
export function* watchOpenCollapseBlock() {
    yield takeLatest(ActionTypes.OPEN_COLLAPSE_INDICATOR_BLOCK, openCollapseBlock);
}

export function* watchSortIndicatorSection() {
    yield takeLatest(ActionTypes.SORT_INDICATOR_SECTION, sortIndicatorSection)
}
export function* watchSortIndicator() {
    yield takeLatest(ActionTypes.SORT_INDICATOR, sortIndicator)
}
