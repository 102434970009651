import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import { TranslateHelper } from "../../../../Utils/TranslateHelper";
import GraphComponent from "../../../../Constants/GraphComponent";
const { ActionTypes } = PriceChartConstants;

const initialState = {
    priceMenu: [],
    label: TranslateHelper.PRICE
}

const PriceMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PRICE_MENU_READY:
            return {
                ...state,
                priceMenu: action.blockMenu,
                label: action.label,
                
            }
        case ActionTypes.DISABLE_DEFAULT_PRICE_LABELS:
            const blockMenu = [...state.priceMenu];
            blockMenu.forEach((menu) => {
                if (menu.graphComponent === GraphComponent.HiLoPct || menu.graphComponent === GraphComponent.HiLo) {
                    menu.isDisable = action.isDisabled;
                }
            });

            return { ...state, priceMenu: blockMenu }
        default:
            return state;
    }
}
export default PriceMenuReducer;
