import React from 'react';
import { connect } from 'react-redux';
import { setReaction } from '../../Actions/AINewsActions';
import ReactMarkdown from 'react-markdown';
import AIReactionModal from './AIReactionModal';
import BaseServiceApi from 'BaseServiceApi';
import UserInfoUtil from "UserInfoUtil";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

const AISummaryContent = ({ data, setReaction, newsType, selectedSegment }) => {

  const isAIFeedbackEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.AI_FEEDBACK_Entitlement)
  // Function to handle reactions
  const handleReaction = (text, reaction) => {
    setReaction(reaction, text, newsType, selectedSegment);
  };

  function extractText(children) {
    let text = "";
  
    React.Children.forEach(children, (child) => {
      if (typeof child === 'string') {
        text += child;
      } else if (React.isValidElement(child)) {
        text += extractText(child.props.children);
      }
    });
  
    return text.trim();;
  }

  const renderContent = () => {
    return (
      <ReactMarkdown
        components={{
          li: ({ children, ...props }) => {
            const text = extractText(children);
            return (
              <div style={{ position: 'relative' }}>
                { isAIFeedbackEntitled &&
                  <AIReactionModal text={text} handleReaction={handleReaction} />
                }
              <li {...props} style={{ listStyleType: isAIFeedbackEntitled ? 'none' : '', marginBottom: '5px', marginTop: '5px' }}>{children}</li>
              </div>
          )},
          ul: ({ children, ...props }) => (
            <ul {...props} style={{ paddingLeft: '28px' }}>{children}</ul>
          )
        }}
      >
        {data}
      </ReactMarkdown>
    );
  };


  return (
    <div className="space-y-4">
      {renderContent()}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
    setReaction: (reaction, text, newsType, selectedSegment) => dispatch(setReaction(reaction, text, newsType, selectedSegment))
});

export default connect(null, mapDispatchToProps)(AISummaryContent);
