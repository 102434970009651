import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants.js";
import SwingSize from "../../../../RayCustomControls/SwingSize/SwingSize.jsx";
import RiSubCategoryType from "../../../../Constants/RiSubCategoryType.js";
import GraphType from "../../../../Constants/GraphType.js";

const { ActionTypes } = PriceChartConstants;

const RiPanelAiInfo = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(RiSubCategoryType.HistoricalNews)

  const isZigzagVisible = useSelector((state) => state.RelatedInfoPanelReducers.RiPanelAI.aiSettings?.zigzagIndicator?.isVisible);
  const majorPeriodicity = useSelector((state) => state.DatagraphReducers.DataGraphReducer.majorPeriodicity);
  const isLineChart = useSelector((state) => state.DatagraphReducers.PriceChartReducer.isLineChart);

  const handleToggle = () => {

    dispatch({
      type: ActionTypes.UPDATE_ZZ_INDICATOR_SETTINGS,
      isVisible: !isZigzagVisible,
    });

    // As per requirement - Disable HighLow and %chg options in Price context menu and 
    // hide the price and %chg values from priceChart view when zigzag is turned on. And display zigzag price labels.
    if ((majorPeriodicity === GraphType.Daily || majorPeriodicity === GraphType.Weekly || majorPeriodicity === GraphType.Monthly) && !isLineChart) {
      dispatch({ type: ActionTypes.DISABLE_DEFAULT_PRICE_LABELS, isDisabled: !isZigzagVisible });
      dispatch({ type: ActionTypes.HI_LO_PCT_SETTINGS_UPDATE, isDisabled: !isZigzagVisible, isReqFromZigzag: true })
    }

  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="zigzag-toggle-container">
      <div className="alertTabs">
        <ul>
          <li
            className={activeTab === RiSubCategoryType.HistoricalNews ? "active" : ""}
            onClick={() => handleTabChange(RiSubCategoryType.HistoricalNews)}
          >
            <a>Historical News</a>
          </li>
          <li
            className={activeTab === RiSubCategoryType.FutureEvents ? "disabled-tab" : ""}
            onClick={() => handleTabChange(RiSubCategoryType.FutureEvents)}
          >
            <a>Future Events</a>
          </li>
        </ul>
      </div>

      {activeTab === RiSubCategoryType.HistoricalNews && (
        <div className="toggle-section">

          <div className="on-off-switch">
            <label className="switch" onClick={handleToggle}>
              <div
                className={isZigzagVisible ? "toggle-switch active" : "toggle-switch"}
                id="toggleSwitch"
              >
                <div className="switch-circle"></div>
                <span className="toggle-text off">OFF</span>
                <span className="toggle-text on">ON</span>
              </div>
            </label>
          </div>
          <div className="swing-size">
            <span className="title">
              Zigzag Indicator
              <p>Click a segment for news and events from targeted period</p>
            </span>

            <span className="heading">Minimum Swing Size</span>
            <SwingSize />
          </div>
        </div>
      )}
    </div>
  );
};

export default RiPanelAiInfo;
