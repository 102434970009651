import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class AITextFeedbackRequest extends BaseServiceRequest {
  constructor(data) {
    super();
    super.addRequestUri("feedback");
    const reqData = {};
    reqData.feedback_type = data.reaction
    reqData.feedbackdescription = data.text
    reqData.AITypeID = data.newsType
    reqData.startdate = data.startDate
    reqData.enddate = data.endDate
    reqData.trend = data.trend
    reqData.OSID = data.OSID
    reqData.periodicity = data.periodicity
    super.setContent(JSON.stringify(reqData))
  }

  getMethod() {
    return HttpMethodType.POST;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }
}

export default AITextFeedbackRequest;
