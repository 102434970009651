//Packages
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Overlay, Popover } from 'react-bootstrap';
import ReactDOM from 'react-dom';

//Components
import AISummaryContent from './AISummaryContent';
//Actions
import { setMessage } from '../../Actions/AINewsActions';

const AISummaryModal = ({ showPopup, hidePopup, target, isReqActive, data, message, setMessage, maxWidth, placement, width, className, newsType, selectedSegment }) => {

    const popoverRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            const popoverElement = ReactDOM.findDOMNode(popoverRef.current);
            const reactionPopoverElement = document.getElementById("reaction-popover");
            if (
                popoverElement &&
                !popoverElement.contains(event.target) && !(reactionPopoverElement && reactionPopoverElement.contains(event.target)) &&
                showPopup
            ) {
                hidePopup(event);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        let timeoutId;

        if (isReqActive) {
            timeoutId = setTimeout(() => {
                setMessage("It is taking time, Please wait ...");
            }, 10000);
        }

        return () => clearTimeout(timeoutId);

    }, [isReqActive]);


    return (
        <Overlay
            key={data.length}
            show={showPopup}
            target={target}
            style={{
                marginTop: '10px',
            }}
            placement={placement}
            container={document.body}
            containerMargin={10}
        >
            <Popover
                id="popover-contained"
                ref={popoverRef}
                className={`summary-popover ${className}`}
                style={{
                    marginTop: 0,
                    width: width ? width : null,
                    maxWidth: maxWidth,
                }}
                onMouseDown={(event) => event.nativeEvent?.stopImmediatePropagation()}
            >
                {message ? <div>{message}</div> : <AISummaryContent data={data} newsType={newsType} selectedSegment={selectedSegment}></AISummaryContent>}
            </Popover>
        </Overlay>
    );
};

AISummaryModal.defaultProps = {
    placement: "bottom",
    maxWidth: "500px",
    width: null,
    className: null,
}

const mapStateToProps = ({ aiNewsReducer }) => {
    const { message, isReqActive } = aiNewsReducer;

    return { message, isReqActive };
};

const mapDispatchToProps = (dispatch) => ({
    setMessage: (msg) => dispatch(setMessage(msg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AISummaryModal);
