import React, { useEffect, useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { REACTIONS } from '../../Constants/AINewsConstants';
import AddFeedbackIcon from './AddFeedbackIcon';

const AIReactionModal = ({ text, handleReaction }) => {
    const popoverRef = useRef(null);
    const reactionRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState(null);
    const handleReactionClick = (text, reaction, emoji) => {
        setSelectedEmoji(emoji);
        setShowPopup(false);
        handleReaction(text, reaction);
    }
    const EMOJI_SET = [
        {reaction: REACTIONS.LIKE, fontSize: '18px', className: 'like-emoji'}, 
        {reaction: REACTIONS.DISLIKE, fontSize: '18px', className: 'dislike-emoji'}, 
        {reaction: REACTIONS.UNSURE, fontSize: '16px', className: 'unsure-emoji'}
    ]
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            const popoverElement = ReactDOM.findDOMNode(popoverRef.current);
            if (
                popoverElement &&
                !popoverElement.contains(event.target) && showPopup
            ) {
                setShowPopup(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showPopup]);
    return (
      <>
        {
            selectedEmoji ? <div 
                    className={`selected-emoji emoji ${selectedEmoji.className}`}
                    style={{ fontSize: selectedEmoji.fontSize }}
                >
            </div> :  
            <>
                <div ref={reactionRef} className='emoji' style={{ left: '-18px', top: '2px' }}></div>
                <AddFeedbackIcon 
                    isClicked={showPopup}
                    onClick={()=> { 
                        setShowPopup(true);
                    }}
                />
            </>
        }
        <Overlay
            show={showPopup}
            placement="top"
            target={reactionRef.current}
        >
            <Popover id="reaction-popover" ref={popoverRef} className='reaction-popover'>
                <div className="row items-center gap-2 flex">
                    {
                        EMOJI_SET.map((emoji, index) => {
                            return  <div 
                                    key={index}
                                    className={`${emoji.className} emoji`}
                                    onClick={()=> handleReactionClick(text, emoji.reaction, emoji)}>
                            </div>
                        })
                    }
                </div>
            </Popover>
        </Overlay>
      </>
    )
  };

export default AIReactionModal;
