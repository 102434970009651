import BaseServiceApi from 'BaseServiceApi';
import BlockType from "../Constants/BlockType";
import { CalenderEventConstant } from "../Constants/CalenderControl/CalenderControlConst";
import { CorpEventsDialogConstants } from "../Constants/CorpEventsDialogConstants";
import { IndicatorLineConst } from "../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import IndustryTypeConstants from "../Constants/IndustryTypeConstants";
import LocalizationStore from "../Stores/Localization/LocalizationStore";
import moment from "moment";
import PeriodicityType from '../Constants/PeriodicityType';
import { RiTabTitle } from '../Constants/RiSubCategoryType';
import SymbolType from "../Constants/SymbolType";
import TechnicalIndicator from "../Constants/TechnicalIndicator";
import UserInfoUtil from "./UserInfoUtil";
import { AnnouncementConstants, CityShortDescriptionConst, CorporateEventsConst, DividendConstants, MajorArticlesConstants, PriceChartConst } from "../Constants/PriceChartConstants";
import { CustomTiDialogConstant, customTIModalDialogConstants } from "../Constants/CustomTiDialogConstants";
import GraphType, { MajorPeriodicityConst } from "../Constants/GraphType";

const IdeaType = BaseServiceApi.rayData["IdeaType"];
const ValueOperatorType = BaseServiceApi.rayData["ValueOperatorType"];

export const TranslateHelper = {}, LineEditDialogTranslateHelper = {}, countriesTranslateHelper = {}, regionTranslateHelper = {}, PriceChartTranslateHelper = {}, IndicatorHeaderTranslateHelper = {},
    IndicatorCommonTranslateHelper = {}, ORatingTranslateHelper = {}, PeriodicityTranslateHelper = {}, MajorPeriodicityTranslateHelper = {},
    LineDialogPeriodicityTranslateHelper = {}, FundamentalLineTranslateHelper = {}, FundamentalLineHeaderTranslateHelper = {}, maLineTranslateHelper = {}, TrackpricePeriodicityTranslateHelper = {},
    IndexNameTranslateHelper = {}, IndustryGroupsTranslateHelper = {}, ErrorMessageTranslateHelper = {}, DaysTranlateHelper = {}, MonthsTranlateHelper = {}, CalenderEventTranslateHelper = {},
    MajorArticlesTranslateHelper = {}, AppearanceDialogTranslateHelper = {}, IndicatorLineTranslateHelper = {}, OneilRatingIndicatorTranslateHelper = {}, TrackPriceTranslateHelper = {},
    CorporateTranslateHelper = {}, DividendTranslateHelper = {}, AnnouncementTranslateHelper = {}, CityShortDescriptionTranslateHelper = {}, AnnouncementDescriptionTranslateHelper = {},
    CorpActionTranslateHelper = {}, IndicatorSettingsTranslateHelper = {}, RiPanelHoldingViewTranslateHelper = {}, RiPanelSimilarFundsTranslateHelper = {}, CopyRightTranslateHelper = {},
    OhOlTranslateHelper = {}, EPSAnimationMessageDialogTranslateHelper = {}, IndexLineTranslateHelper = {}, MATranslateHelper = {}, PatternRectTranslateHelper = {}, RSLineTranslateHelper = {},
    UpdateAlertDialogTranslateHelper = {}, EditBoxTranslateHelper = {}, CorpEventsDialogTranslateHelper = {}, EventsDialogTranslateHelper = {},
    RiPanelConstituentsTranslateHelper = {}, HoldingsTranslateHelper = {}, HeaderTranslateHelper = {}, IdeaShortNameTranslateHelper = {}, IdeaTranslateHelper = {}, ScaleLabelTranslateHelper = {},
    RIPanelTranslateHelper = {}, FactorTranslateHelper = {}, SummaryTransLateHelper = {}, FinancialTransLateHelper = {}, OwnershipBlockTranslateHelper = {}, RiPanelNewsTransLateHelper = {},
    RiPanelMangementInfoTransLateHelper = {}, RiPanelCheckListTransLateHelper = {}, monthsName = moment.monthsShort(), AlertsDialogTranslateHelper = {}, AlertTranslateHelper = {},
    AlertPeriodicityTranslateHelper = {}, PeriodicityTypeTranslateHelper = {}, PatternRangeTranslateHelper = {}, PatterNameTranslateHelper = {}, ValueOperatorsTranslateHelper = {}, 
    PickListOperatorsTranslateHelper = {}, instrumentTypesTranslateHelper = {}, AlertSecondaryInfoTranslateHelper = {}, IdeaName={};

    export const IndicatorLabelTranslateHelper = {
        [BlockType.AccDist]: LocalizationStore.getTranslatedData("pt_ad", "ACCUM/DIST"),
        [BlockType.EPSR]: LocalizationStore.getTranslatedData('ipc_er_abbr', "EST REVISIONS"),
        [BlockType.TechIndicator]: LocalizationStore.getTranslatedData("ipc_or_abbr", "O'NEIL RTGS"),
        [BlockType.MACD]: LocalizationStore.getTranslatedData("pt_md", "MACD"),
        [BlockType.RSI]: LocalizationStore.getTranslatedData("pt_rsi", "RSI"),
        [BlockType.Extended]: "Extended",
        [BlockType.Stochastics]: LocalizationStore.getTranslatedData("pt_st", "STOCHASTICS"),
        [BlockType.WonStochastics]: LocalizationStore.getTranslatedData("pt_st", "WON STOCH"),
        [BlockType.YTD]: LocalizationStore.getTranslatedData("sb_mf_6", "YTD"),
        [BlockType.PTOE]: "P/E",
        [BlockType.PTOS]: "P/S",
    }

export const convertTranslateStaticString = function () {

    TranslateHelper["Loading"] = LocalizationStore.getTranslatedData("LM_Loading", "Loading"); // similar - LocalizationStore.getTranslatedData("ri_loading", "Loading")
    TranslateHelper["Back"] = LocalizationStore.getTranslatedData("back", "Back");
    TranslateHelper["OK"] = LocalizationStore.getTranslatedData("ok", "OK");
    TranslateHelper["Cancel"] = LocalizationStore.getTranslatedData("set_cancel", "Cancel");
    TranslateHelper["CANCEL"] = LocalizationStore.getTranslatedData("cancel", "CANCEL");
    TranslateHelper["Close"] = LocalizationStore.getTranslatedData("CCG_Close", "Close");
    TranslateHelper["CLOSE"] = LocalizationStore.getTranslatedData("ipc_cls", "CLOSE");
    TranslateHelper["Close_Bold"] = LocalizationStore.getTranslatedData("Close", "Close"); // similar - LocalizationStore.getTranslatedData("tp_pt_cw", "Close")
    TranslateHelper["Data_Not_Available"] = LocalizationStore.getTranslatedData("ipc_nodata", "Data is Not Available"); // similar - LocalizationStore.getTranslatedData("price_not_ava", "Data Not Available")
    TranslateHelper["No_Data_Available"] = LocalizationStore.getTranslatedData("ri_id_noDataFound", "No Data Available"); // similar - {LocalizationStore.getTranslatedData("ri_id93_03", "No Data Available")}
    TranslateHelper["Edit"] = LocalizationStore.getTranslatedData("edit", "Edit");
    TranslateHelper["About"] = LocalizationStore.getTranslatedData("ipc_abt", "About");
    TranslateHelper["Delete"] = LocalizationStore.getTranslatedData("delete", "Delete"); // similar - LocalizationStore.getTranslatedData("CCG_Delete", "Delete"); LocalizationStore.getTranslatedData("delete_upper","Delete")
    TranslateHelper["Yes"] = LocalizationStore.getTranslatedData("misc_id7", "Yes");
    TranslateHelper["Choose_Line_Display_Setting"] = LocalizationStore.getTranslatedData("ch_clds_1", "Choose line display settings");
    TranslateHelper["Color"] = LocalizationStore.getTranslatedData("clr", "Color");
    TranslateHelper["Weight"] = LocalizationStore.getTranslatedData("wt", "Weight");
    TranslateHelper["Restore_Defaults"] = LocalizationStore.getTranslatedData("resdef", "Restore Defaults");
    TranslateHelper["Length"] = LocalizationStore.getTranslatedData("ipc_st_stgs_lngh", "Length");
    TranslateHelper["PRICE"] = LocalizationStore.getTranslatedData("price", "PRICE");
    TranslateHelper["NAV"] = LocalizationStore.getTranslatedData("fd_price_nav", "NAV");
    TranslateHelper["ON"] = LocalizationStore.getTranslatedData("ON", "ON");
    TranslateHelper["OFF"] = LocalizationStore.getTranslatedData("OFF", "OFF");
    TranslateHelper["VOL_RATE"] = LocalizationStore.getTranslatedData("srh_volrate", "Vol Rate");
    TranslateHelper['MOST_RECENT_TRADING_DATE'] = LocalizationStore.getTranslatedData("cal_mrtd", "Most Recent Trading Date");
    TranslateHelper['VOLUME'] = LocalizationStore.getTranslatedData("volume", "VOLUME");
    TranslateHelper['INSIDERS'] = LocalizationStore.getTranslatedData("pt_insider", "INSIDERS");
    TranslateHelper['Save'] = LocalizationStore.getTranslatedData("set_ws_sv", "Save");

    LineEditDialogTranslateHelper["Region"] = LocalizationStore.getTranslatedData("ch_reg", "Region:");
    LineEditDialogTranslateHelper["Country"] = LocalizationStore.getTranslatedData("ch_cty", "Country:");
    LineEditDialogTranslateHelper["Value"] = LocalizationStore.getTranslatedData("ch_val", "Value");
    LineEditDialogTranslateHelper["Symbol"] = LocalizationStore.getTranslatedData("ch_ind_editstgs_valsym", "Symbol");
    LineEditDialogTranslateHelper["Symbols_Group"] = LocalizationStore.getTranslatedData("ch_ind_editstgs_valsymgrp", "Symbol's Group");
    LineEditDialogTranslateHelper["CountrysDefault"] = LocalizationStore.getTranslatedData("ch_ind_editstgs_valdef", "Country's Default:");
    LineEditDialogTranslateHelper["Defaults"] = LocalizationStore.getTranslatedData("ch_ind_editstgs_valdef", "Default:");
    LineEditDialogTranslateHelper["Funds_Benchmark"] = LocalizationStore.getTranslatedData("ch_index_editstgs_FB", "Fund's Benchmark");

    PriceChartTranslateHelper[PriceChartConst.BV] = LocalizationStore.getTranslatedData("T4Q_1", "Book Value Per Share");
    PriceChartTranslateHelper[PriceChartConst.CFS] = LocalizationStore.getTranslatedData("T4Q_2", "Cash Flow Per Share");
    PriceChartTranslateHelper[PriceChartConst.DIV] = LocalizationStore.getTranslatedData("T4Q_3", "Dividend Per Share");
    PriceChartTranslateHelper[PriceChartConst.FCF] = LocalizationStore.getTranslatedData("T4Q_4", "Free Cash Flow Per Share");
    PriceChartTranslateHelper[PriceChartConst.EPS] = LocalizationStore.getTranslatedData("ch_epshvr", "Earnings Per Share");
    PriceChartTranslateHelper[PriceChartConst.RPS] = LocalizationStore.getTranslatedData("ch_rpshvr", "Revenue Per Share");
    PriceChartTranslateHelper[PriceChartConst.FFO] = LocalizationStore.getTranslatedData("sym_fundsfromoper", "Funds From Operations");
    PriceChartTranslateHelper[PriceChartConst.NAV] = LocalizationStore.getTranslatedData("sym_netasstval", "Net Asset Value");
    PriceChartTranslateHelper[PriceChartConst.EXD] = "External Data",

        IndicatorHeaderTranslateHelper[BlockType.AccDist] = LocalizationStore.getTranslatedData("ips_ad", "Accumulation Distribution");
    IndicatorHeaderTranslateHelper[BlockType.EPSR] = LocalizationStore.getTranslatedData("ipc_er", "Estimate Revisions");
    IndicatorHeaderTranslateHelper[BlockType.MACD] = LocalizationStore.getTranslatedData("ipc_md", "MACD");
    IndicatorHeaderTranslateHelper[BlockType.PTOE] = LocalizationStore.getTranslatedData("ipc_pte", "Price-To-Earnings");
    IndicatorHeaderTranslateHelper[BlockType.PTOS] = LocalizationStore.getTranslatedData("ipc_pts", "Price-To-Sales");
    IndicatorHeaderTranslateHelper[BlockType.RSI] = LocalizationStore.getTranslatedData("ipc_rsi", "RSI");
    IndicatorHeaderTranslateHelper[BlockType.Extended] = "Extended";
    IndicatorHeaderTranslateHelper[BlockType.Stochastics] = LocalizationStore.getTranslatedData("ipc_st", "Stochastics");
    IndicatorHeaderTranslateHelper[BlockType.WonStochastics] ="WonStochastics";
    IndicatorHeaderTranslateHelper[BlockType.TechIndicator] = LocalizationStore.getTranslatedData("ipc_or", "O'Neil Ratings");
    IndicatorHeaderTranslateHelper[BlockType.YTD] = LocalizationStore.getTranslatedData("TI_YTDPerformance", "YTD Performance");
    IndicatorHeaderTranslateHelper[BlockType.YTD1] = LocalizationStore.getTranslatedData("TI_1-YearRollingPerformance", "1-Year Rolling Performance");
    IndicatorHeaderTranslateHelper[BlockType.YTD3] = LocalizationStore.getTranslatedData("TI_3-YearRollingPerformance", "3-Year Rolling Performance");
    IndicatorHeaderTranslateHelper[BlockType.YTD5] = LocalizationStore.getTranslatedData("TI_5-YearRollingPerformance", "5-Year Rolling Performance");
    IndicatorHeaderTranslateHelper[BlockType.YTD10] = LocalizationStore.getTranslatedData("TI_10-YearRollingPerformance", "10-Year Rolling Performance");

    IndicatorLabelTranslateHelper[BlockType.AccDist] = LocalizationStore.getTranslatedData("pt_ad", "ACCUM/DIST");
    IndicatorLabelTranslateHelper[BlockType.EPSR] = LocalizationStore.getTranslatedData('ipc_er_abbr', "EST REVISIONS");
    IndicatorLabelTranslateHelper[BlockType.TechIndicator] = LocalizationStore.getTranslatedData("ipc_or_abbr", "O'NEIL RTGS");
    IndicatorLabelTranslateHelper[BlockType.MACD] = LocalizationStore.getTranslatedData("pt_md", "MACD");
    IndicatorLabelTranslateHelper[BlockType.RSI] = LocalizationStore.getTranslatedData("pt_rsi", "RSI");
    IndicatorLabelTranslateHelper[BlockType.Extended] = "Extended";
    IndicatorLabelTranslateHelper[BlockType.Stochastics] = LocalizationStore.getTranslatedData("pt_st", "STOCHASTICS");
    IndicatorLabelTranslateHelper[BlockType.YTD] = LocalizationStore.getTranslatedData("sb_mf_6", "YTD");

    IndicatorCommonTranslateHelper[CustomTiDialogConstant.Length] = LocalizationStore.getTranslatedData("ipc_st_stgs_lngh", "Length");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.Oversold] = LocalizationStore.getTranslatedData("ipc_st_os", "Oversold");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.Overbought] = LocalizationStore.getTranslatedData("ipc_st_ob", "Overbought");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.RSILineColor] = LocalizationStore.getTranslatedData("ipc_rsi_aprn_ln", "RSI Line");
    IndicatorCommonTranslateHelper[TechnicalIndicator.Settings] = LocalizationStore.getTranslatedData("ipc_md_stgs", TechnicalIndicator.Settings);
    IndicatorCommonTranslateHelper[TechnicalIndicator.About] = LocalizationStore.getTranslatedData("ipc_abt", TechnicalIndicator.About);
    IndicatorCommonTranslateHelper[TechnicalIndicator.Appearance] = LocalizationStore.getTranslatedData("ipc_st_aprn", TechnicalIndicator.Appearance);
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.CurrentFiscalYear] = LocalizationStore.getTranslatedData("ipc_er_cfy", "Current Fiscal Year");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.NextFiscalYear] = LocalizationStore.getTranslatedData("ipc_er_nfy", "Next Fiscal Year");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.CurrentFiscalQuarter] = LocalizationStore.getTranslatedData("ipc_er_cfq", "Current Fiscal Quarter");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.FastLength] = LocalizationStore.getTranslatedData("ipc_md_stgs_fst", "Fast Length");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.SlowLength] = LocalizationStore.getTranslatedData("ipc_md_stgs_slw", "Slow Length");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.MACDLineColor] = LocalizationStore.getTranslatedData("ipc_md_aprn_mdln", "MACD Line");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.LongLineColor] = LocalizationStore.getTranslatedData("ipc_md_aprn_mdln", "Long Line");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.ShortLineColor] = LocalizationStore.getTranslatedData("ipc_md_aprn_mdln", "Short Line");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.SdUpLineColor] = LocalizationStore.getTranslatedData("ipc_md_aprn_mdln", "SdUp Line");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.SdDnLineColor] = LocalizationStore.getTranslatedData("ipc_md_aprn_mdln", "SdDn Line");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.EMA] = LocalizationStore.getTranslatedData("pt_ema", "EMA");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.MovingAverageColor] = LocalizationStore.getTranslatedData("ipc_md_aprn_ma", "Moving Average");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.MovingAverage] = LocalizationStore.getTranslatedData("ipc_st_stgs_ma", "Moving Avg");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.StochasticsColor] = LocalizationStore.getTranslatedData("ipc_st_aprn_st", "Stochastics (%K)");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.StochasticsMovingAverageColor] = LocalizationStore.getTranslatedData("ipc_st_aprn_ma", "Moving Average (%D)");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.Fast] = LocalizationStore.getTranslatedData("ipt_fst", "Fast");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.Slow] = LocalizationStore.getTranslatedData("ipt_slw", "Slow");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOEIndexLine] = LocalizationStore.getTranslatedData("ipc_er_pe", "Index P/E");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOEHLEstimates] = LocalizationStore.getTranslatedData("ipc_er_dse", "Display Stock's High/Low P/E Estimates");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOSIndexLine] = LocalizationStore.getTranslatedData("ipc_er_ps", "Index P/S");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOSHLEstimates] = LocalizationStore.getTranslatedData("ipc_er_dps", "Display Stock's High/Low P/S Estimates");
    IndicatorCommonTranslateHelper[CustomTiDialogConstant.SignalColor] = LocalizationStore.getTranslatedData("ipc_ad_aprn_ln", "Accum/Dist Line");
    IndicatorCommonTranslateHelper["SymbolType_STOCK"] = LocalizationStore.getTranslatedData("LMSymbolType_STOCK", "Stock");
    IndicatorCommonTranslateHelper["SymbolType_INDEX"] = LocalizationStore.getTranslatedData("LMSymbolType_INDEX", "Index");
    IndicatorCommonTranslateHelper["Numeric_Input_Only"] = LocalizationStore.getTranslatedData("ch_err2", "Field accepts numeric inputs only");
    IndicatorCommonTranslateHelper["SDRating"] = LocalizationStore.getTranslatedData("pt_SDrtg", "SD RATING");
    IndicatorCommonTranslateHelper["Year"] = LocalizationStore.getTranslatedData("TI_Year", "Year");
    IndicatorCommonTranslateHelper["Absolute"] = LocalizationStore.getTranslatedData("ri_iid115", "Absolute");
    IndicatorCommonTranslateHelper["Relative"] = LocalizationStore.getTranslatedData("ri_iid116", "Relative");

    ORatingTranslateHelper[CustomTiDialogConstant.DataGraph] = LocalizationStore.getTranslatedData("ipc_or_dg", "DataGraph Rating");
    ORatingTranslateHelper[CustomTiDialogConstant.Rs3Months] = LocalizationStore.getTranslatedData("ipc_or_3mntrs", "3-month RS Rating");
    ORatingTranslateHelper[CustomTiDialogConstant.Rs6Months] = LocalizationStore.getTranslatedData("ipc_or_6mntrs", "6-month RS Rating");
    ORatingTranslateHelper[CustomTiDialogConstant.Rs12Months] = LocalizationStore.getTranslatedData("ipc_or_12mntrs", "12-month RS Rating");
    ORatingTranslateHelper[CustomTiDialogConstant.GrpRs3Months] = LocalizationStore.getTranslatedData("ipc_or_3mntgrprs", "3-month Group RS Rating");
    ORatingTranslateHelper[CustomTiDialogConstant.GrpRs6Months] = LocalizationStore.getTranslatedData("ipc_or_6mntgrprs", "6-month Group RS Rating");
    ORatingTranslateHelper[CustomTiDialogConstant.InstSupDem] = LocalizationStore.getTranslatedData("ipc_or_sd", "Supply/Demand");
    ORatingTranslateHelper[CustomTiDialogConstant.SMR] = LocalizationStore.getTranslatedData("ipc_or_smr", "SMR Rating");
    ORatingTranslateHelper[CustomTiDialogConstant.Sponsorship] = LocalizationStore.getTranslatedData("ipc_or_spon", "Sponsorship Rank");
    ORatingTranslateHelper[CustomTiDialogConstant.Composite] = LocalizationStore.getTranslatedData("ipc_or_comp", "Composite Rating");
    ORatingTranslateHelper[CustomTiDialogConstant.EpsRank] = LocalizationStore.getTranslatedData("ipc_or_eps", "EPS Rank");

    PeriodicityTranslateHelper[GraphType.Intraday60Min] = LocalizationStore.getTranslatedData('tp_per_60min', '60 Min');
    PeriodicityTranslateHelper[GraphType.Intraday30Min] = LocalizationStore.getTranslatedData('tp_per_30min', '30 Min');
    PeriodicityTranslateHelper[GraphType.Intraday15Min] = LocalizationStore.getTranslatedData('tp_per_15min', '15 Min');
    PeriodicityTranslateHelper[GraphType.Intraday10Min] = LocalizationStore.getTranslatedData('tp_per_10min', '10 Min');
    PeriodicityTranslateHelper[GraphType.Intraday5Min] = LocalizationStore.getTranslatedData('tp_per_5min', '5 Min');
    PeriodicityTranslateHelper[GraphType.Intraday1Min] = LocalizationStore.getTranslatedData('tp_per_1min', '1 Min');
    PeriodicityTranslateHelper[GraphType.Annual] = LocalizationStore.getTranslatedData("tp_per_aly", "Annual");
    PeriodicityTranslateHelper[GraphType.Quarterly] = LocalizationStore.getTranslatedData("tp_per_qly", "Quarterly");
    PeriodicityTranslateHelper[GraphType.Monthly] = LocalizationStore.getTranslatedData("tp_per_mnly", "Monthly");
    PeriodicityTranslateHelper[GraphType.Weekly] = LocalizationStore.getTranslatedData("tp_per_wkly", "Weekly");
    PeriodicityTranslateHelper[GraphType.Daily] = LocalizationStore.getTranslatedData("tp_per_dly", "Daily");

    MajorPeriodicityTranslateHelper[MajorPeriodicityConst.INTRADAY] = LocalizationStore.getTranslatedData("ipc_inday", "INTRADAY");
    MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY] = LocalizationStore.getTranslatedData("ipc_dly", "DAILY");
    MajorPeriodicityTranslateHelper[MajorPeriodicityConst.WEEKLY] = LocalizationStore.getTranslatedData("ipc_wkly", "WEEKLY");
    MajorPeriodicityTranslateHelper[MajorPeriodicityConst.MONTHLY] = LocalizationStore.getTranslatedData("ipc_mnly", "MONTHLY");
    MajorPeriodicityTranslateHelper[MajorPeriodicityConst.QTRLY] = LocalizationStore.getTranslatedData("ipc_qly", "QTRLY");
    MajorPeriodicityTranslateHelper[MajorPeriodicityConst.ANNUAL] = LocalizationStore.getTranslatedData("ipc_aly", "ANNUAL");

    LineDialogPeriodicityTranslateHelper[GraphType.Intraday] = MajorPeriodicityTranslateHelper[MajorPeriodicityConst.INTRADAY];
    LineDialogPeriodicityTranslateHelper[GraphType.Daily] = MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY];
    LineDialogPeriodicityTranslateHelper[GraphType.Weekly] = MajorPeriodicityTranslateHelper[MajorPeriodicityConst.WEEKLY];
    LineDialogPeriodicityTranslateHelper[GraphType.Monthly] = MajorPeriodicityTranslateHelper[MajorPeriodicityConst.MONTHLY];
    LineDialogPeriodicityTranslateHelper[GraphType.Quarterly] = MajorPeriodicityTranslateHelper[MajorPeriodicityConst.QTRLY];
    LineDialogPeriodicityTranslateHelper[GraphType.Annual] = MajorPeriodicityTranslateHelper[MajorPeriodicityConst.ANNUAL];

    FundamentalLineTranslateHelper[PriceChartConst.BV] = LocalizationStore.getTranslatedData("ch_bvln", "BV Line");
    FundamentalLineTranslateHelper[PriceChartConst.CFS] = LocalizationStore.getTranslatedData("ch_cfsln", "CFS Line");
    FundamentalLineTranslateHelper[PriceChartConst.DIV] = LocalizationStore.getTranslatedData("ch_divln", "DIV Line");
    FundamentalLineTranslateHelper[PriceChartConst.FCF] = LocalizationStore.getTranslatedData("ch_fcfln", "FCF Line");
    FundamentalLineTranslateHelper[PriceChartConst.EPS] = LocalizationStore.getTranslatedData("ch_epsln", "EPS Line");
    FundamentalLineTranslateHelper[PriceChartConst.RPS] = LocalizationStore.getTranslatedData("ch_rpsln", "RPS Line");
    FundamentalLineTranslateHelper[PriceChartConst.EXD] = "",
        FundamentalLineTranslateHelper['HighLowEstimate'] = LocalizationStore.getTranslatedData("ch_dishilow", "Display High / Low Estimate");
    FundamentalLineTranslateHelper['EarningSurprises'] = LocalizationStore.getTranslatedData("ch_er_editstgs_sur", "Display Earnings Surprises");

    FundamentalLineHeaderTranslateHelper[PriceChartConst.BV] = LocalizationStore.getTranslatedData("T4Q_1", "BOOK VALUE PER SHARE");
    FundamentalLineHeaderTranslateHelper[PriceChartConst.CFS] = LocalizationStore.getTranslatedData("T4Q_2", "CASH FLOW PER SHARE");
    FundamentalLineHeaderTranslateHelper[PriceChartConst.DIV] = LocalizationStore.getTranslatedData("T4Q_3", "DIVIDEND PER SHARE");
    FundamentalLineHeaderTranslateHelper[PriceChartConst.FCF] = LocalizationStore.getTranslatedData("T4Q_4", "FREE CASH FLOW PER SHARE");
    FundamentalLineHeaderTranslateHelper[PriceChartConst.EPS] = LocalizationStore.getTranslatedData("ch_er_editstgs_title", "EARNINGS LINE");
    FundamentalLineHeaderTranslateHelper[PriceChartConst.RPS] = LocalizationStore.getTranslatedData("ch_rev_editstgs_title", "REVENUE PER SHARE LINE");

    maLineTranslateHelper[GraphType.Intraday] = LocalizationStore.getTranslatedData("ch_ima", "INTRADAY MOVING AVERAGE");
    maLineTranslateHelper[GraphType.Daily] = LocalizationStore.getTranslatedData("ch_dma", "DAILY MOVING AVERAGE");
    maLineTranslateHelper[GraphType.Weekly] = LocalizationStore.getTranslatedData("ch_wma", "WEEKLY MOVING AVERAGE");
    maLineTranslateHelper[GraphType.Monthly] = LocalizationStore.getTranslatedData("ch_mma", "MONTHLY MOVING AVERAGE");
    maLineTranslateHelper[GraphType.Quarterly] = LocalizationStore.getTranslatedData("ch_qma", "QUARTERLY MOVING AVERAGE");
    maLineTranslateHelper[GraphType.Annual] = LocalizationStore.getTranslatedData("ch_ama", "ANNUAL MOVING AVERAGE")

    TrackpricePeriodicityTranslateHelper[GraphType.Daily] = LocalizationStore.getTranslatedData("pt_days", "Days");
    TrackpricePeriodicityTranslateHelper[GraphType.Weekly] = LocalizationStore.getTranslatedData("pt_wks", "Wks");
    TrackpricePeriodicityTranslateHelper[GraphType.Monthly] = LocalizationStore.getTranslatedData("pt_mnts", "Mos");
    TrackpricePeriodicityTranslateHelper[GraphType.Quarterly] = LocalizationStore.getTranslatedData("pt_qtrs", "Qtrs");
    TrackpricePeriodicityTranslateHelper[GraphType.Annual] = LocalizationStore.getTranslatedData("pt_years", "Years");

    IndexNameTranslateHelper[IndustryTypeConstants.S_P_500_Index] = LocalizationStore.getTranslatedData("Index_Industry_0S&P5", "S & P 500 Index");
    IndexNameTranslateHelper[IndustryTypeConstants.China_Shanghai_SE_Composite] = LocalizationStore.getTranslatedData("Index_Industry_0CHSCOMP", "China Shanghai SE Composite");
    IndexNameTranslateHelper[IndustryTypeConstants.Hong_Kong_Hang_Seng_Composite] = LocalizationStore.getTranslatedData("Index_Industry_0HKHCOMP", "Hong Kong Hang Seng Composite");

    IndustryGroupsTranslateHelper[SymbolType.WONINDUSTRYGROUP197] = LocalizationStore.getTranslatedData("sym_ingrp", "Industry Group");
    IndustryGroupsTranslateHelper[SymbolType.WONMAJORINDUSTRY89] = LocalizationStore.getTranslatedData("ch_ind_editstgs_valsymajind", "Major Industry");
    IndustryGroupsTranslateHelper[SymbolType.WONSECTOR11] = LocalizationStore.getTranslatedData("ch_ind_editstgs_valsymsec", "Sector");
    moment.weekdaysMin().map((day) => (DaysTranlateHelper[day] = LocalizationStore.getTranslatedData(`CCG_${day}`, day)));

    monthsName.map((month) => (MonthsTranlateHelper[month] = LocalizationStore.getTranslatedData(`cal_${month}`, month)));
    UserInfoUtil.hasChinaEntitlement() ? moment.locale("zh-cn") : moment.locale("en");

    ErrorMessageTranslateHelper["ENTER_VALID_SYMBOL"] = LocalizationStore.getTranslatedData("ch_ind_editstgs_symerr", "Error: Please enter a valid symbol.");
    ErrorMessageTranslateHelper["ONEIL_SYMBOL_ONLY"] = LocalizationStore.getTranslatedData("ch_ind_editstgs_wonsym", "Error: O'Neil Symbols only")

    CalenderEventTranslateHelper[CalenderEventConstant.KEY_DATE_FOR] = LocalizationStore.getTranslatedData('cal_kdf', "KEY DATES FOR");
    CalenderEventTranslateHelper[CalenderEventConstant.Reorganization] = LocalizationStore.getTranslatedData("CorpActionDisplay_Reorganization", "Reorganization");
    CalenderEventTranslateHelper[CalenderEventConstant.Corporate_Events] = LocalizationStore.getTranslatedData("cal_core", "Corporate Events");
    CalenderEventTranslateHelper[CalenderEventConstant.Earnings] = LocalizationStore.getTranslatedData("cal_earn", "Earnings");
    CalenderEventTranslateHelper[CalenderEventConstant.Major_News] = LocalizationStore.getTranslatedData("cal_majn", "Major News");

    MajorArticlesTranslateHelper[MajorArticlesConstants.BARRONS] = LocalizationStore.getTranslatedData("ch_bar", "BARRONS");
    MajorArticlesTranslateHelper[MajorArticlesConstants.FORTUNE] = LocalizationStore.getTranslatedData("ch_fortune", "FORTUNE");
    MajorArticlesTranslateHelper[MajorArticlesConstants.BUS_WK] = LocalizationStore.getTranslatedData("ch_buswk", "BUS.WK");
    MajorArticlesTranslateHelper[MajorArticlesConstants.NY_TIME] = LocalizationStore.getTranslatedData("ch_nytime", "NY TIME");
    MajorArticlesTranslateHelper[MajorArticlesConstants.WALL_SJ] = LocalizationStore.getTranslatedData("ch_wall.sj", "WALL.SJ");
    MajorArticlesTranslateHelper[MajorArticlesConstants.FORBES] = LocalizationStore.getTranslatedData("ch_forbes", "FORBES");
    MajorArticlesTranslateHelper[MajorArticlesConstants.ELEC_B] = LocalizationStore.getTranslatedData("ch_elec", "ELEC.B");
    MajorArticlesTranslateHelper[MajorArticlesConstants.IBD] = LocalizationStore.getTranslatedData("Subscriptions_Provider_IBD", "IBD");

    AppearanceDialogTranslateHelper[customTIModalDialogConstants.Lines] = LocalizationStore.getTranslatedData("ipc_lns", "Lines");
    AppearanceDialogTranslateHelper[BlockType.AccDist] = LocalizationStore.getTranslatedData("ipc_ad_aprn_title", "ACCUM/DIST Appearance");
    AppearanceDialogTranslateHelper[BlockType.TechIndicator] = LocalizationStore.getTranslatedData("ipc_or_aprn_title", "O'NEIL RATINGS Appearance");
    AppearanceDialogTranslateHelper[BlockType.EPSR] = LocalizationStore.getTranslatedData("ipc_er_aprn_title", "ESTIMATE REVISIONS Appearance");
    AppearanceDialogTranslateHelper[BlockType.MACD] = LocalizationStore.getTranslatedData("ipc_md_aprn_title", "MACD Appearance");
    AppearanceDialogTranslateHelper[BlockType.RSI] = LocalizationStore.getTranslatedData("ipc_rsi_aprn_title", "RSI Appearance");
    AppearanceDialogTranslateHelper[BlockType.Extended] = "Extended Appearance";
    AppearanceDialogTranslateHelper[BlockType.Stochastics] = LocalizationStore.getTranslatedData("ipc_st_aprn_title", "STOCHASTICS Appearance");
    AppearanceDialogTranslateHelper[BlockType.WonStochastics] = LocalizationStore.getTranslatedData("ipc_st_aprn_title", "WON STOCHASTICS Appearance");
    AppearanceDialogTranslateHelper[BlockType.PTOE] = LocalizationStore.getTranslatedData("ipc_er_pte_title", "PRICE-TO-EARNIGS Appearance");
    AppearanceDialogTranslateHelper[BlockType.PTOS] = LocalizationStore.getTranslatedData("ipc_er_pts_title", "PRICE-TO-SALES Appearance");

    const char = UserInfoUtil.hasChinaEntitlement() ? "" : "-";

    IndicatorLineTranslateHelper[IndicatorLineConst.EPSR_CURR_FIS_QTR] = LocalizationStore.getTranslatedData('ipc_er_cfq', "Cur Qt Est");
    IndicatorLineTranslateHelper[IndicatorLineConst.EPSR_CURR_FIS_YR] = LocalizationStore.getTranslatedData('ipc_er_cfy', "Cur Yr Est");
    IndicatorLineTranslateHelper[IndicatorLineConst.EPSR_NXT_FIS_YR] = LocalizationStore.getTranslatedData('ipc_er_nfy', "Nxt Yr Est");
    IndicatorLineTranslateHelper["FAST"] = LocalizationStore.getTranslatedData("pt_fst", "FAST");
    IndicatorLineTranslateHelper["SLOW"] = LocalizationStore.getTranslatedData("pt_slw", "SLOW");
    IndicatorLineTranslateHelper["EMA"] = LocalizationStore.getTranslatedData("pt_ema", "EMA");
    IndicatorLineTranslateHelper["HISTOGRAM"] = LocalizationStore.getTranslatedData("pt_hm", "HISTOGRAM");
    IndicatorLineTranslateHelper[BlockType.YTD] = LocalizationStore.getTranslatedData("sb_mf_6", "YTD");
    IndicatorLineTranslateHelper[BlockType.YTD1] = LocalizationStore.getTranslatedData("pt_yr", "{0}YR", `1${char}`);
    IndicatorLineTranslateHelper[BlockType.YTD3] = LocalizationStore.getTranslatedData("pt_yr", "{0}YR", `3${char}`);
    IndicatorLineTranslateHelper[BlockType.YTD5] = LocalizationStore.getTranslatedData("pt_yr", "{0}YR", `5${char}`);
    IndicatorLineTranslateHelper[BlockType.YTD10] = LocalizationStore.getTranslatedData("pt_yr", "{0}YR", `10${char}`);
    IndicatorLineTranslateHelper[BlockType.AccDist] = LocalizationStore.getTranslatedData("pt_ad", "ACCUM/DIST");
    IndicatorLineTranslateHelper["RATING"] = LocalizationStore.getTranslatedData("pt_rtg", "RATING");
    IndicatorLineTranslateHelper["LONG"] = LocalizationStore.getTranslatedData("pt_rtg", "Long");
    IndicatorLineTranslateHelper["SHORT"] = LocalizationStore.getTranslatedData("pt_rtg", "Short");
    IndicatorLineTranslateHelper["SD_UP"] = LocalizationStore.getTranslatedData("pt_rtg", "Sd Up");
    IndicatorLineTranslateHelper["SD_DN"] = LocalizationStore.getTranslatedData("pt_rtg", "Sd Dn");

    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.DataGraph] = LocalizationStore.getTranslatedData("ipc_pt_dg", "DATAGRAPH");
    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.Rs3Months] = LocalizationStore.getTranslatedData('ipc_pt_3mntrs', "3m RS");
    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.Rs6Months] = LocalizationStore.getTranslatedData('ipc_pt_6mntrs', "6m RS");
    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.Rs12Months] = LocalizationStore.getTranslatedData('ipc_pt_12mntrs', "12m RS");
    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.GrpRs3Months] = LocalizationStore.getTranslatedData('ipc_pt_3mntgrprs', "3m GRP RS");
    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.GrpRs6Months] = LocalizationStore.getTranslatedData('ipc_pt_6mntgrprs', "6m GRP RS");
    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.InstSupDem] = LocalizationStore.getTranslatedData("ipc_pt_sd", "SUP/DEM");
    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.SMR] = LocalizationStore.getTranslatedData("ipc_pt_smr", "SMR");
    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.Sponsorship] = LocalizationStore.getTranslatedData("ipc_pt_spon", "SPONSOR");
    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.Composite] = LocalizationStore.getTranslatedData("ipc_pt_comp", "COMPOSITE");
    OneilRatingIndicatorTranslateHelper[CustomTiDialogConstant.EpsRank] = LocalizationStore.getTranslatedData("ipc_pt_eps", "EPS");

    TrackPriceTranslateHelper['HIGH'] = LocalizationStore.getTranslatedData("pt_high", "HIGH");
    TrackPriceTranslateHelper['LOW'] = LocalizationStore.getTranslatedData("pt_low", "LOW");
    TrackPriceTranslateHelper['EVENTS'] = LocalizationStore.getTranslatedData("pt_events", "EVENTS");
    TrackPriceTranslateHelper['TIME'] = LocalizationStore.getTranslatedData("pt_time", "TIME");
    TrackPriceTranslateHelper['DATE'] = LocalizationStore.getTranslatedData("pt_dte", "DATE");
    TrackPriceTranslateHelper['LAST'] = LocalizationStore.getTranslatedData("pt_lst", "LAST");
    TrackPriceTranslateHelper['CHG'] = LocalizationStore.getTranslatedData("pt_chg", "CHG");
    TrackPriceTranslateHelper['VOL'] = LocalizationStore.getTranslatedData("pt_vol", "VOL");
    TrackPriceTranslateHelper['RATE'] = LocalizationStore.getTranslatedData("pt_rte", "RATE");
    TrackPriceTranslateHelper['HOLIDAY'] = LocalizationStore.getTranslatedData("holiday", "Holiday");
    TrackPriceTranslateHelper['MARKET_CLOSE'] = LocalizationStore.getTranslatedData("market closed", "MarketClosed");
    TrackPriceTranslateHelper['NO_TRADE'] = LocalizationStore.getTranslatedData("notrade", "No Trade");
    TrackPriceTranslateHelper['AVG_VOL'] = LocalizationStore.getTranslatedData("pt_avol", "AVG VOL");
    TrackPriceTranslateHelper['INSIDERS'] = LocalizationStore.getTranslatedData("pt_insider", "INSIDERS");
    TrackPriceTranslateHelper['BUYS_SHRS'] = LocalizationStore.getTranslatedData("pt_buyshr", "BUYS SHRS");
    TrackPriceTranslateHelper['SELL_SHRS'] = LocalizationStore.getTranslatedData("pt_sellshr", "SELLS SHRS");
    TrackPriceTranslateHelper['REMOVED'] = LocalizationStore.getTranslatedData("pt_removed", "Removed");
    TrackPriceTranslateHelper['ADDED'] = LocalizationStore.getTranslatedData("pt_added", "Added");
    TrackPriceTranslateHelper['PERCENT_CHAGNE'] = LocalizationStore.getTranslatedData("ri_iid127", "% Chg");

    CorporateTranslateHelper[CorporateEventsConst.EARNINGS] = LocalizationStore.getTranslatedData("ch_eps", "EPS");
    CorporateTranslateHelper[CorporateEventsConst.CORPORATEREPURCHASE] = LocalizationStore.getTranslatedData("ch_buybk", "BUYBK ");
    CorporateTranslateHelper[CorporateEventsConst.INSIDERBUY] = LocalizationStore.getTranslatedData("ch_ib", "Insider Buy");
    CorporateTranslateHelper[CorporateEventsConst.INSIDERSELL] = LocalizationStore.getTranslatedData("ch_is", "Insider Sell");
    CorporateTranslateHelper[CorporateEventsConst.CO_CEO] = LocalizationStore.getTranslatedData("ch_ncoceo", "New CoCEO");
    CorporateTranslateHelper[CorporateEventsConst.ADDEDTOINDEX] = LocalizationStore.getTranslatedData("ch_addto", "Added To");
    CorporateTranslateHelper[CorporateEventsConst.CEO] = LocalizationStore.getTranslatedData("ch_nceo", "New CEO");

    DividendTranslateHelper[DividendConstants.DECR] = LocalizationStore.getTranslatedData("ch_divdecr", "DIV DECR");
    DividendTranslateHelper[DividendConstants.EXTRA] = LocalizationStore.getTranslatedData("ch_divextra", "DIV EXTRA");
    DividendTranslateHelper[DividendConstants.RSUM] = LocalizationStore.getTranslatedData("ch_divrsum", "DIV RSUM");
    DividendTranslateHelper[DividendConstants.OMIT] = LocalizationStore.getTranslatedData("ch_divomit", "DIV OMIT");
    DividendTranslateHelper[DividendConstants.INITL] = LocalizationStore.getTranslatedData("ch_initl", "DIV INITL");
    DividendTranslateHelper[DividendConstants.INCR] = LocalizationStore.getTranslatedData("ch_divincr", "DIV INCR");
    DividendTranslateHelper[DividendConstants.SPEC] = LocalizationStore.getTranslatedData("ch_divspec", "DIV SPEC");
    DividendTranslateHelper[DividendConstants.DIV] = LocalizationStore.getTranslatedData("ch_div", "DIV ");

    AnnouncementTranslateHelper[AnnouncementConstants.LPE] = LocalizationStore.getTranslatedData("ch_lpe", "LPE");
    AnnouncementTranslateHelper[AnnouncementConstants.PM] = LocalizationStore.getTranslatedData("ch_pm", "PM");
    AnnouncementTranslateHelper[AnnouncementConstants.SOF] = LocalizationStore.getTranslatedData("ch_sof", "SOF");
    AnnouncementTranslateHelper[AnnouncementConstants.PO] = LocalizationStore.getTranslatedData("ch_po", "PO");
    AnnouncementTranslateHelper[AnnouncementConstants.TO] = LocalizationStore.getTranslatedData("ch_to", "TO");
    AnnouncementTranslateHelper[AnnouncementConstants.PSO] = LocalizationStore.getTranslatedData("ch_pso", "PSO");
    AnnouncementTranslateHelper[AnnouncementConstants.REORG] = LocalizationStore.getTranslatedData("ch_reorg", "REORG");
    AnnouncementTranslateHelper[AnnouncementConstants.IPO] = LocalizationStore.getTranslatedData("CorpActionDisplay_IPO", "IPO");
    AnnouncementTranslateHelper[AnnouncementConstants.SDIS] = LocalizationStore.getTranslatedData("ch_sdis", "SDIS");
    AnnouncementTranslateHelper[AnnouncementConstants.CO] = LocalizationStore.getTranslatedData("ch_co", "CO");
    AnnouncementTranslateHelper[AnnouncementConstants.TRADE_RSUM] = LocalizationStore.getTranslatedData("ch_tradersum", "TRADE RSUM");
    AnnouncementTranslateHelper[AnnouncementConstants.PPO] = LocalizationStore.getTranslatedData("ch_ppo", "PPO");
    AnnouncementTranslateHelper[AnnouncementConstants.$DIS] = LocalizationStore.getTranslatedData("ch_dis", "$DIS");
    AnnouncementTranslateHelper[AnnouncementConstants.SO] = LocalizationStore.getTranslatedData("ch_so", "SO");
    AnnouncementTranslateHelper[AnnouncementConstants.TRADE_SUSP] = LocalizationStore.getTranslatedData("ch_tradesusp", "TRADE SUSP");

    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.BOSTON] = LocalizationStore.getTranslatedData("ch_city_BOS", "BOS");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.CHICAGO] = LocalizationStore.getTranslatedData("ch_city_CHI", "CHI");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.LOS_ANGELES] = LocalizationStore.getTranslatedData("ch_city_LSA", "LSA");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.NEW_YORK] = LocalizationStore.getTranslatedData("ch_city_NYC", "NYC");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.SAN_FRANCISCO] = LocalizationStore.getTranslatedData("ch_city_SFO", "SFO");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.ATLANTA] = LocalizationStore.getTranslatedData("ch_city_ATL", "ATL");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.HOUSTON] = LocalizationStore.getTranslatedData("ch_city_HOU", "HOU");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.PITTSBURGH] = LocalizationStore.getTranslatedData("ch_city_PTA", "PTA");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.SEATTLE] = LocalizationStore.getTranslatedData("ch_city_SEA", "SEA");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.CLEVELAND] = LocalizationStore.getTranslatedData("ch_city_CLV", "CLV");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.DETROIT] = LocalizationStore.getTranslatedData("ch_city_DET", "DET");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.DENVER] = LocalizationStore.getTranslatedData("ch_city_DEN", "DEN");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.AMERICAN_EXC] = LocalizationStore.getTranslatedData("ch_city_AMX", "AMX");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.WASHINGTON_DC] = LocalizationStore.getTranslatedData("ch_city_WDC", "WDC");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.BALTIMORE] = LocalizationStore.getTranslatedData("ch_city_BAL", "BAL");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.ST_LOUIS] = LocalizationStore.getTranslatedData("ch_city_STL", "STL");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.PHILADELPHIA] = LocalizationStore.getTranslatedData("ch_city_PHI", "PHI");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.TORONTO] = LocalizationStore.getTranslatedData("ch_city_TOR", "TOR");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.MONTREAL] = LocalizationStore.getTranslatedData("ch_city_MON", "MON");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.NEW_YORK_NYSSA] = LocalizationStore.getTranslatedData("ch_city_NYA", "NYA");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.SAN_DIEGO] = LocalizationStore.getTranslatedData("ch_city_SDG", "SDG");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.PORTLAND] = LocalizationStore.getTranslatedData("ch_city_POR", "POR");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.DALLLAS] = LocalizationStore.getTranslatedData("ch_city_DAL", "DAL");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.PHOENIX] = LocalizationStore.getTranslatedData("ch_city_PHX", "PHX");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.MILWAUKEE] = LocalizationStore.getTranslatedData("ch_city_MIL", "MIL");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.SACRAMENTO] = LocalizationStore.getTranslatedData("ch_city_SAC", "SAC");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.JACKSONVILLE] = LocalizationStore.getTranslatedData("ch_city_JAC", "JAC");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.INDIANAPOLIS] = LocalizationStore.getTranslatedData("ch_city_IND", "IND");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.JACKSON] = LocalizationStore.getTranslatedData("ch_city_JCK", "JCK");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.CINCINNATI] = LocalizationStore.getTranslatedData("ch_city_CIN", "CIN");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.CHATTANOOGA] = LocalizationStore.getTranslatedData("ch_city_CHA", "CHA");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.AUSTIN] = LocalizationStore.getTranslatedData("ch_city_AUS", "AUS");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.SALT_LAKE_CITY] = LocalizationStore.getTranslatedData("ch_city_SAL", "SAL");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.SPOKANE] = LocalizationStore.getTranslatedData("ch_city_SPO", "SPO");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.STAMFORD] = LocalizationStore.getTranslatedData("ch_city_STA", "STA");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.DES_MOINES] = LocalizationStore.getTranslatedData("ch_city_DES", "DES");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.KANSAS_CITY] = LocalizationStore.getTranslatedData("ch_city_KAN", "KAN");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.BUFFALO] = LocalizationStore.getTranslatedData("ch_city_BUF", "BUF");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.COLUMBUS] = LocalizationStore.getTranslatedData("ch_city_COL", "COL");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.NASHVILLE] = LocalizationStore.getTranslatedData("ch_city_NAS", "NAS");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.SAN_ANTONIO] = LocalizationStore.getTranslatedData("ch_city_SAN", "SAN");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.ALABAMA] = LocalizationStore.getTranslatedData("ch_city_ALA", "ALA");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.HARTFORD] = LocalizationStore.getTranslatedData("ch_city_HRT", "HRT");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.LOUISVILLE] = LocalizationStore.getTranslatedData("ch_city_LOU", "LOU");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.PROVIDENCE] = LocalizationStore.getTranslatedData("ch_city_PRV", "PRV");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.SOUTH_FLORIDA] = LocalizationStore.getTranslatedData("ch_city_SFL", "SFL");
    CityShortDescriptionTranslateHelper[CityShortDescriptionConst.OMAHA_LINCOLN] = LocalizationStore.getTranslatedData("ch_city_OMH", "OMH");

    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.PO] = LocalizationStore.getTranslatedData("ch_prio", "Primary Offering");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.SO] = LocalizationStore.getTranslatedData("CorpActionDisplay_SO", "Secondary Offering of ");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.PPO] = LocalizationStore.getTranslatedData("ch_pprio", "Proposed Primary Offering");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.PSO] = LocalizationStore.getTranslatedData("CorpActionDisplay_PSO", "Proposed Secondary Offering");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.TO] = LocalizationStore.getTranslatedData("CorpActionDisplay_TO", "Tender Offer of ");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.PM] = LocalizationStore.getTranslatedData("CorpActionDisplay_PM", "Proposed Merger");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.SOF] = LocalizationStore.getTranslatedData("CorpActionDisplay_SOF", "Spin-off");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.CO] = LocalizationStore.getTranslatedData("CorpActionDisplay_CO", "Cash take-over offer");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.SDIS] = LocalizationStore.getTranslatedData("CorpActionDisplay_SDIS", "Stock Distribution");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.$DIS] = LocalizationStore.getTranslatedData("CorpActionDisplay_$DIS", "Cash Distributions");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.$LIQ] = LocalizationStore.getTranslatedData("CorpActionDisplay_$LIQ", "Cash Liquidation");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.IPO] = LocalizationStore.getTranslatedData("CorpActionDisplay_IPO", "IPO ");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.NEW] = LocalizationStore.getTranslatedData("CorpActionDisplay_NEW", "NEW");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.PVC] = LocalizationStore.getTranslatedData("CorpActionDisplay_PVC", "PVC");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.SHLF] = LocalizationStore.getTranslatedData("CorpActionDisplay_SHLF", "Shelf Offering");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.REORG] = LocalizationStore.getTranslatedData("CorpActionDisplay_Reorganization", "Reorganization");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.LPE] = LocalizationStore.getTranslatedData("CorpActionDisplay_LPE", "LPE");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.TRADE_RSUM] = LocalizationStore.getTranslatedData("CorpActionDisplay_TRADERSUM", "Trade Resume");
    AnnouncementDescriptionTranslateHelper[AnnouncementConstants.TRADE_SUSP] = LocalizationStore.getTranslatedData("CorpActionDisplay_TRADESUSP", "Trade Suspension");

    CorpActionTranslateHelper[CorporateEventsConst.REPURCHASEANNOUNCEMENT] = LocalizationStore.getTranslatedData("ch_ra", "Repurchase Announcement");
    CorpActionTranslateHelper[CorporateEventsConst.CORPORATEREPURCHASE] = LocalizationStore.getTranslatedData("ch_correp", "Corporate Repurchase");
    CorpActionTranslateHelper[CorporateEventsConst.DIVIDEND] = LocalizationStore.getTranslatedData("ch_msa_dc", "Dividend Change");
    CorpActionTranslateHelper[CorporateEventsConst.EARNINGS] = LocalizationStore.getTranslatedData("ch_ce_eps", "Earnings");
    CorpActionTranslateHelper['LOOK_UP_PERIOD_WILL_EXPIRE'] = LocalizationStore.getTranslatedData("LockUpWillExpire", "Lock-up Period will Expire");
    CorpActionTranslateHelper['LOOK_UP_PERIOD_EXPIRED'] = LocalizationStore.getTranslatedData("LockUpExpired", "Lock-up Period Expired");
    CorpActionTranslateHelper['NEW_CEO'] = LocalizationStore.getTranslatedData("ch_nceo", "NEW CEO");
    CorpActionTranslateHelper['NEW_CO_CEO'] = LocalizationStore.getTranslatedData("ch_ncoceo", "New CoCeo");
    CorpActionTranslateHelper['SPLIT'] = LocalizationStore.getTranslatedData("ch_ce_spt", "Split");
    CorpActionTranslateHelper['ADDED_TO_SP_500'] = LocalizationStore.getTranslatedData("ch_msa_ats", "Added To S&P 500");

    IndicatorSettingsTranslateHelper[BlockType.RSI] = LocalizationStore.getTranslatedData("ipc_rsi_stgs_title", "RELATIVE STRENGTH INDICATOR (RSI) SETTINGS");
    IndicatorSettingsTranslateHelper[BlockType.TechIndicator] = LocalizationStore.getTranslatedData("ipc_or_stgs_title", "O'NEIL RATINGS SETTINGS");
    IndicatorSettingsTranslateHelper[BlockType.PTOS] = LocalizationStore.getTranslatedData("ipc_pts", "PRICE-TO-SALES");
    IndicatorSettingsTranslateHelper[BlockType.PTOE] = LocalizationStore.getTranslatedData("ipc_pte", "Price-To-Earnings");
    IndicatorSettingsTranslateHelper[BlockType.Stochastics] = LocalizationStore.getTranslatedData("ipc_st_stgs_title", "STOCHASTICS SETTINGS");
    IndicatorSettingsTranslateHelper[BlockType.WonStochastics] = LocalizationStore.getTranslatedData("ipc_st_stgs_title", "WON STOCHASTICS SETTINGS");
    IndicatorSettingsTranslateHelper[BlockType.MACD] = LocalizationStore.getTranslatedData("ipc_md_stgs_title", "MACD SETTINGS");
    IndicatorSettingsTranslateHelper[BlockType.EPSR] = LocalizationStore.getTranslatedData("ipc_er_stgs_title", "ESTIMATE REVISIONS SETTINGS");
    IndicatorSettingsTranslateHelper[customTIModalDialogConstants.Apply_To_All] = LocalizationStore.getTranslatedData("ipc_apyallint", "Apply to all intervals.");

    RiPanelHoldingViewTranslateHelper['AMT'] = LocalizationStore.getTranslatedData("ri_iid126", "Amt");
    RiPanelHoldingViewTranslateHelper['MIL'] = LocalizationStore.getTranslatedData("ri_iid126_01", "(Mil)");
    RiPanelHoldingViewTranslateHelper['CHG'] = LocalizationStore.getTranslatedData("ri_iid127", "% Chg");
    RiPanelHoldingViewTranslateHelper['OF'] = LocalizationStore.getTranslatedData("ri_iid128", "% of");
    RiPanelHoldingViewTranslateHelper['PORT'] = LocalizationStore.getTranslatedData("ri_iid128_01", "Port");
    RiPanelHoldingViewTranslateHelper['LOADING'] = LocalizationStore.getTranslatedData("ri_loading", "Loading");
    RiPanelHoldingViewTranslateHelper['NO_DATA_AVAILABLE'] = LocalizationStore.getTranslatedData("ri_id93_03", "No Data Available");

    RiPanelSimilarFundsTranslateHelper['AUM'] = LocalizationStore.getTranslatedData("ri_iid121", "AUM");
    RiPanelSimilarFundsTranslateHelper['STKS'] = LocalizationStore.getTranslatedData("ri_iid122", "# of Stks");
    RiPanelSimilarFundsTranslateHelper['OVERLAP'] = LocalizationStore.getTranslatedData("ri_iid123", "Overlap");

    CopyRightTranslateHelper['REAL_TIME_PRICES'] = LocalizationStore.getTranslatedData("ch_realtimeprices", "REAL TIME PRICES PROVIDED BY NASDAQ BASIC.");
    CopyRightTranslateHelper['REAL_TIME_QUOTES'] = LocalizationStore.getTranslatedData("ch_realtimequote", "Real time quote and/or trade prices are not sourced from all markets.");
    CopyRightTranslateHelper['DELAYED_20_MIN'] = LocalizationStore.getTranslatedData("ch_delayed20", "QUOTES DELAYED AT LEAST 20 MINUTES.");

    OhOlTranslateHelper['OL'] = LocalizationStore.getTranslatedData("ch_ol", "OL");
    OhOlTranslateHelper['OH'] = LocalizationStore.getTranslatedData("ch_oh", "OH");

    EPSAnimationMessageDialogTranslateHelper['EARNING_LINE_ANIMATION'] = LocalizationStore.getTranslatedData("ch_er_ela", "Earnings Line Animation");
    EPSAnimationMessageDialogTranslateHelper['CH_ER_ELA_MSG'] = LocalizationStore.getTranslatedData("ch_er_ela_msg", "Drag the vertical line left or right to see how the");
    EPSAnimationMessageDialogTranslateHelper['CH_ER_ELA_MSG1'] = LocalizationStore.getTranslatedData("ch_er_ela_msg1", "Earnings Line looked on a historical date.");
    EPSAnimationMessageDialogTranslateHelper['CH_ER_ELA_MSG2'] = LocalizationStore.getTranslatedData("ch_er_ela_msg2", "Click the Play button to animate forward in time");
    EPSAnimationMessageDialogTranslateHelper['VIEW_SETTINGS'] = LocalizationStore.getTranslatedData("ch_er_ela_view", "View Settings");
    EPSAnimationMessageDialogTranslateHelper['DO_NOT_SHOW_AGAIN'] = LocalizationStore.getTranslatedData("ch_er_ela_noshow", "Do not show this again");

    IndexLineTranslateHelper['Set_Index_Line_Per_Country'] = LocalizationStore.getTranslatedData("ch_sipc", "Set index line value per country.");
    IndexLineTranslateHelper['Set_Index_Line_For_Fund'] = LocalizationStore.getTranslatedData("ch_ind_editstgs_setIndLineVal", "Set index line value for Funds.");
    IndexLineTranslateHelper['Index_Line'] = LocalizationStore.getTranslatedData("ch_ind_editstgs_title", "INDEX LINE");

    MATranslateHelper['VALIDATION'] = LocalizationStore.getTranslatedData("sb_Validation", "Validation");
    MATranslateHelper['ALREADY_EXIST'] = LocalizationStore.getTranslatedData("sb_exist", "already exist.");
    MATranslateHelper['MA_FOR'] = LocalizationStore.getTranslatedData("sb_moving", " Moving Line Average for ");

    PatternRectTranslateHelper['PR_Settings'] = LocalizationStore.getTranslatedData("ch_pr_title", "PATTERN RECOGNITION SETTINGS");
    PatternRectTranslateHelper['Choose_PR_Display_Setting'] = LocalizationStore.getTranslatedData("ch_pr_cpds", "Choose Pattern display settings.");
    PatternRectTranslateHelper['Pattern_Color'] = LocalizationStore.getTranslatedData("ch_pr_pc", "Patterns Color");
    PatternRectTranslateHelper['Show_Tight_Area'] = LocalizationStore.getTranslatedData("ch_pr_sta", "Show Tight Area");
    PatternRectTranslateHelper['Tight_Area_Color'] = LocalizationStore.getTranslatedData("ch_pr_tac", "Tight Areas Color");
    PatternRectTranslateHelper['Show_Key_Price_Range'] = LocalizationStore.getTranslatedData("ch_pr_skpr", "Show Key Price Ranges");
    PatternRectTranslateHelper['ch_pr_profitr'] = LocalizationStore.getTranslatedData("ch_pr_profitr", "Profit Range (% above pivot point)");
    PatternRectTranslateHelper['ch_pr_pivotr'] = LocalizationStore.getTranslatedData("ch_pr_pivotr", "Pivot Range (% above pivot point)");
    PatternRectTranslateHelper['ch_pr_lossr'] = LocalizationStore.getTranslatedData("ch_pr_lossr", "Loss Range (% below pivot point)");
    PatternRectTranslateHelper['to'] = LocalizationStore.getTranslatedData("ch_pr_to", "to");
    PatternRectTranslateHelper['ch_pr_showpfp'] = LocalizationStore.getTranslatedData("ch_pr_showpfp", "Show Power from Pivot");
    PatternRectTranslateHelper['ch_pr_showcl'] = LocalizationStore.getTranslatedData("ch_pr_showcl", "Show Channel Lines");
    PatternRectTranslateHelper['ch_pr_showct'] = LocalizationStore.getTranslatedData("ch_pr_showct", "Show Climax Tops");
    PatternRectTranslateHelper['ch_pr_showapp'] = LocalizationStore.getTranslatedData("ch_pr_showapp", "Show Advanced Pattern Properties");

    RSLineTranslateHelper['RS_Line'] = LocalizationStore.getTranslatedData("ch_rs_line", "RS Line");
    RSLineTranslateHelper['SameAsIndexLne'] = LocalizationStore.getTranslatedData("ch_rs_editstgs_same", "Same as Index Line");
    RSLineTranslateHelper['ChooseLineDisplaySetting'] = LocalizationStore.getTranslatedData("ch_clds", "Choose line display settings.");
    RSLineTranslateHelper['ShowMALine'] = LocalizationStore.getTranslatedData("ch_rs_editstgs_cb1", "Show accompanying moving average line.");
    RSLineTranslateHelper['ShowRSRank'] = LocalizationStore.getTranslatedData("ch_rs_editstgs_cb2", "Show relative strength (RS) rank.");
    RSLineTranslateHelper['ShowADLettingGrade'] = LocalizationStore.getTranslatedData("ch_rs_editstgs_cb3", "Show accumulation/distribution (A/D) letter grade.");
    RSLineTranslateHelper['Set_Line_Per_Country'] = LocalizationStore.getTranslatedData("ch_rs_editstgs_stlvpercountry", "Set line value per country.");
    RSLineTranslateHelper['Set_Line_Value_For_Fund'] = LocalizationStore.getTranslatedData("ch_ind_editstgs_setLineVal", "Set line value for Funds.");

    UpdateAlertDialogTranslateHelper['UPDATE'] = LocalizationStore.getTranslatedData("ch_ind_editstgs_symtitle", "Update");
    UpdateAlertDialogTranslateHelper['UPDATE_ALERT'] = LocalizationStore.getTranslatedData("ch_ind_editstgs_symupdate", "RS {0} line is tied to the index chart and has been updated to reflect your recent index change.", 1);

    EditBoxTranslateHelper['RECENT_SYMBOLS'] = LocalizationStore.getTranslatedData("srh_rctSym", "RECENT SYMBOLS");
    EditBoxTranslateHelper['SEARCH_RESULTS'] = LocalizationStore.getTranslatedData("srh_srhRst", "SEARCH RESULTS");
    EditBoxTranslateHelper['INVALID_SYMBOL'] = LocalizationStore.getTranslatedData("srh_err", "Error: Invalid Symbol");

    CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.Split] = LocalizationStore.getTranslatedData("ch_ce_spt", "Splits");
    CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.Earnings] = LocalizationStore.getTranslatedData("ch_ce_epspctchg", "EPS%Change");
    CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.Announcement] = LocalizationStore.getTranslatedData("ch_ce_anncmt", "Announcements");
    CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.Ceo] = LocalizationStore.getTranslatedData("ch_ce_ceochg", "CEO Changes");
    CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.CorporateRepurchase] = LocalizationStore.getTranslatedData("ch_ce_corbuy", "Corporate Buybacks");
    CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.Dividend] = LocalizationStore.getTranslatedData("ch_ce_chgdiv", "Change in Dividend");
    CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.MajorArticles] = LocalizationStore.getTranslatedData("ch_ce_maj", "Major Articles");
    CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.AddedToIndex] = LocalizationStore.getTranslatedData("ch_ce_add", "Added to Index");
    CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.SecurityAnalystMeeting] = LocalizationStore.getTranslatedData("ch_ce_locdate", "Analysts' Meeting Location & Date");
    //CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.XDate] = LocalizationStore.getTranslatedData("ch_ce_locdate", "Change in ex-Dividend");

    EventsDialogTranslateHelper[CorpEventsDialogConstants.Split] = CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.Split],
        EventsDialogTranslateHelper[CorpEventsDialogConstants.Announcement] = CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.Announcement],
        EventsDialogTranslateHelper[CorpEventsDialogConstants.CorporateRepurchase] = LocalizationStore.getTranslatedData("ch_ce_buy", "Buybacks");
    EventsDialogTranslateHelper[CorpEventsDialogConstants.Dividend] = CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.Dividend];

    RiPanelConstituentsTranslateHelper['NAME'] = LocalizationStore.getTranslatedData("ri_iid127", "Name");
    RiPanelConstituentsTranslateHelper['MARKET_VALUE'] = LocalizationStore.getTranslatedData("ri_iid126", "Mkt Value");
    RiPanelConstituentsTranslateHelper['MILLION'] = LocalizationStore.getTranslatedData("ri_iid126_01", "(Mil)");
    RiPanelConstituentsTranslateHelper['PRICE_PER_CHANGE'] = LocalizationStore.getTranslatedData("ri_iid128", "Price, % Chg");
    RiPanelConstituentsTranslateHelper['FOUR_WEEKS'] = LocalizationStore.getTranslatedData("ri_iid128_01", "vs 4 Wks");
    RiPanelConstituentsTranslateHelper['NOT_AVAILABLE'] = LocalizationStore.getTranslatedData("sb_etf_notavailable", "Not Available");

    HoldingsTranslateHelper['AS_OF'] = LocalizationStore.getTranslatedData("ri_iid119", "As of ");
    HoldingsTranslateHelper['TOP_5_LARGEST_HOLDING'] = LocalizationStore.getTranslatedData("ri_iid125", "Top 5 Largest Holdings");
    HoldingsTranslateHelper['TOP_NEW_HOLDINGS'] = LocalizationStore.getTranslatedData("ri_iid129", "Top New Holdings");
    HoldingsTranslateHelper['TOP_5_ADDED_HOLDINGS'] = LocalizationStore.getTranslatedData("ri_iid130", "Top 5 Added Holdings");
    HoldingsTranslateHelper['TOP_5_REDUCED_HOLDINGS'] = LocalizationStore.getTranslatedData("ri_iid131", "Top 5 Reduced Holdings");

    HeaderTranslateHelper['SHARES'] = LocalizationStore.getTranslatedData("sym_shrs", "Shares");
    HeaderTranslateHelper['MARKET_CAP'] = LocalizationStore.getTranslatedData("sym_mktcap", "MktCap");
    HeaderTranslateHelper['SALES'] = LocalizationStore.getTranslatedData("sym_sls", "Sales");
    HeaderTranslateHelper['FLOAT'] = LocalizationStore.getTranslatedData("sym_flt", "Float");
    HeaderTranslateHelper['REVENUE'] = LocalizationStore.getTranslatedData("ColumnSet_Revenue", "Revenue");

    IdeaShortNameTranslateHelper[IdeaType.ALLCAPS_LAGGARD_Idea] = LocalizationStore.getTranslatedData("IdeaName_AllCapLaggard", "AC Laggard");
    IdeaShortNameTranslateHelper[IdeaType.ALLCAPS_LEADER_Idea] = LocalizationStore.getTranslatedData("IdeaName_AllCapLeader", "AC Leader");
    IdeaShortNameTranslateHelper[IdeaType.LARGECAP_LAGGARD_Idea] = LocalizationStore.getTranslatedData("IdeaName_LargeCapLaggard", "LC Laggard");
    IdeaShortNameTranslateHelper[IdeaType.LARGECAP_LEADER_Idea] = LocalizationStore.getTranslatedData("IdeaName_LargeCapLeader", "LC Leader");
    IdeaShortNameTranslateHelper[IdeaType.DEVELOPED_LONG_Idea] = LocalizationStore.getTranslatedData("IdeaName_DevelopedLong", "Developed Long");
    IdeaShortNameTranslateHelper[IdeaType.EMERGING_LONG_Idea] = LocalizationStore.getTranslatedData("IdeaName_EmergingLong", "Emerging Long");
    IdeaShortNameTranslateHelper[IdeaType.FRONTIER_LONG_Idea] = LocalizationStore.getTranslatedData("IdeaName_FrontierLong", "Frontier Long");
    IdeaShortNameTranslateHelper[IdeaType.GFL_EUROPE_LONG_Idea] = LocalizationStore.getTranslatedData("IdeaName_EuropeLong", "Europe Long");
    IdeaShortNameTranslateHelper[IdeaType.USA_LONG_Idea] = LocalizationStore.getTranslatedData("IdeaName_UnitedStatesLong", "US Long");
    IdeaShortNameTranslateHelper[IdeaType.CHINA_ASHARE_LONG_Idea] = LocalizationStore.getTranslatedData("IdeaName_ChinaASharesLong", "China A Shares Long");
    IdeaShortNameTranslateHelper[IdeaType.DEVELOPED_SHORT_Idea] = LocalizationStore.getTranslatedData("IdeaName_DevelopedShort", "Developed Short");
    IdeaShortNameTranslateHelper[IdeaType.EMERGING_SHORT_Idea] = LocalizationStore.getTranslatedData("IdeaName_EmergingShort", "Emerging Short");
    IdeaShortNameTranslateHelper[IdeaType.FRONTIER_SHORT_Idea] = LocalizationStore.getTranslatedData("IdeaName_FrontierShort", "Frontier Short");
    IdeaShortNameTranslateHelper[IdeaType.GFL_EUROPE_SHORT_Idea] = LocalizationStore.getTranslatedData("IdeaName_EuropeShort", "Europe Short");
    IdeaShortNameTranslateHelper[IdeaType.USA_SHORT_Idea] = LocalizationStore.getTranslatedData("IdeaName_UnitedStatesShort", "US Short");
    IdeaShortNameTranslateHelper[IdeaType.CHINA_ASHARE_SHORT_Idea] = LocalizationStore.getTranslatedData("IdeaName_ChinaASharesShort", "China A Shares Short");
  
    IdeaName[IdeaType.ALLCAPS_LAGGARD_Idea] = "All Cap Laggard";
    IdeaName[IdeaType.ALLCAPS_LEADER_Idea] = "All Cap Leader";
    IdeaName[IdeaType.LARGECAP_LAGGARD_Idea] = "Large Cap Laggard";
    IdeaName[IdeaType.LARGECAP_LEADER_Idea] ="Large Cap Leader";
    IdeaName[IdeaType.DEVELOPED_LONG_Idea] = "Developed Long"; 
    IdeaName[IdeaType.EMERGING_LONG_Idea] = "Emerging Long"; 
    IdeaName[IdeaType.FRONTIER_LONG_Idea] = "Frontier Long"; 
    IdeaName[IdeaType.GFL_EUROPE_LONG_Idea] = "Europe Long"; 
    IdeaName[IdeaType.USA_LONG_Idea] = "United States Long"; 
    IdeaName[IdeaType.CHINA_ASHARE_LONG_Idea] = "China A Shares Long"; 
    IdeaName[IdeaType.DEVELOPED_SHORT_Idea] = "Developed Short"; 
    IdeaName[IdeaType.EMERGING_SHORT_Idea] = "Emerging Short"; 
    IdeaName[IdeaType.FRONTIER_SHORT_Idea] = "Frontier Short"; 
    IdeaName[IdeaType.GFL_EUROPE_SHORT_Idea] = "Europe Short"; 
    IdeaName[IdeaType.USA_SHORT_Idea] = "United States Short"; 
    IdeaName[IdeaType.CHINA_ASHARE_SHORT_Idea] = "China A Shares Short";
    
    IdeaTranslateHelper["AVOID"] = LocalizationStore.getTranslatedData("avoid", "Avoid");
    IdeaTranslateHelper["BUY"] = LocalizationStore.getTranslatedData("buy", "Buy");
    IdeaTranslateHelper["REMOVE"] = LocalizationStore.getTranslatedData("lp_remove", "Remove");
    IdeaTranslateHelper["SELL"] = LocalizationStore.getTranslatedData("sell", "Sell");
    IdeaTranslateHelper["EXCLUSIVE_LONG_IDEA"] = LocalizationStore.getTranslatedData("IdeaName_ExclusiveLongIdea", "Exclusive Long Idea");
    IdeaTranslateHelper["EXCLUSIVE_SHORT_IDEA"] = LocalizationStore.getTranslatedData("IdeaName_ExclusiveShortIdea", "Exclusive Short Idea");
    IdeaTranslateHelper["NO_ACTIVITY"] = LocalizationStore.getTranslatedData("ri_id91_05", "No Activity");
    IdeaTranslateHelper["VIEW_ANALISIS"] = LocalizationStore.getTranslatedData("ri_idvda", "View Analysis");
    IdeaTranslateHelper["PRESENT"] = LocalizationStore.getTranslatedData("newceo_present", "present");
    IdeaTranslateHelper["NO_HISTORICAL_ACTIVITY"] = LocalizationStore.getTranslatedData("ri_id91_05", "No historical activity");
    IdeaTranslateHelper["LAST"] = LocalizationStore.getTranslatedData("last", "Last:");
    IdeaTranslateHelper["EXIT"] = LocalizationStore.getTranslatedData("exit", "Exit:");
    IdeaTranslateHelper["ENTRY"] = LocalizationStore.getTranslatedData("entry", "Entry:");
    IdeaTranslateHelper["DETAILED_ANALYSIS"] = LocalizationStore.getTranslatedData("ri_idvda", "View Detailed Analysis");

    ScaleLabelTranslateHelper["LOG (Fixed)"] = LocalizationStore.getTranslatedData("ch_logfixed", "LOG (Fixed)");
    ScaleLabelTranslateHelper["LIN"] = LocalizationStore.getTranslatedData("ch_LIN", "LIN")
    ScaleLabelTranslateHelper["LOG (WON)"] = "LOG (WON)"
    ScaleLabelTranslateHelper["LOG (Auto)"] = LocalizationStore.getTranslatedData("ch_logauto", "LOG (Auto)"); LocalizationStore.getTranslatedData("ch_logfixed", "LOG (Fixed)");
    ScaleLabelTranslateHelper['LOG'] = LocalizationStore.getTranslatedData("ch_log", "LOG");

    RIPanelTranslateHelper[RiTabTitle.None] = "NONE";
    RIPanelTranslateHelper[RiTabTitle.News] = LocalizationStore.getTranslatedData("main_news", "NEWS");
    RIPanelTranslateHelper[RiTabTitle.BrokerReports] = LocalizationStore.getTranslatedData("broker_reports", "BROKER REPORTS");
    RIPanelTranslateHelper[RiTabTitle.Sponsorship] = "SPONSORSHIP";
    RIPanelTranslateHelper[RiTabTitle.MeasurementTools] = "MEASUREMENT TOOLS";
    RIPanelTranslateHelper[RiTabTitle.Annotations] = "ANNOTATIONS";
    RIPanelTranslateHelper[RiTabTitle.Summary] = "SUMMARY";
    RIPanelTranslateHelper[RiTabTitle.FindModels] = "FIND MODELS";
    RIPanelTranslateHelper[RiTabTitle.FindNotes] = "FIND NOTES";
    RIPanelTranslateHelper[RiTabTitle.LlrView] = LocalizationStore.getTranslatedData("ri_id90_3", "O'NEIL");
    RIPanelTranslateHelper[RiTabTitle.Twitter] = "TWITTER";
    RIPanelTranslateHelper[RiTabTitle.Transactions] = "TRANSACTIONS";
    RIPanelTranslateHelper[RiTabTitle.Alerts] = LocalizationStore.getTranslatedData("mc_alts", "ALERTS");
    RIPanelTranslateHelper[RiTabTitle.Ownership] = LocalizationStore.getTranslatedData("OS_tab", "OWNERSHIP");
    RIPanelTranslateHelper[RiTabTitle.CheckList] = LocalizationStore.getTranslatedData("ri_id75_01", "CHECKLIST");
    RIPanelTranslateHelper[RiTabTitle.Holdings] = LocalizationStore.getTranslatedData("ri_iid124", "HOLDINGS");
    RIPanelTranslateHelper[RiTabTitle.SimilarFunds] = LocalizationStore.getTranslatedData("ri_iid120", "SIMILAR FUNDS");
    RIPanelTranslateHelper[RiTabTitle.Management] = LocalizationStore.getTranslatedData("ri_iid110", "MANAGEMENT");
    RIPanelTranslateHelper[RiTabTitle.Allocation] = LocalizationStore.getTranslatedData("ri_iid117", "ALLOCATION");
    RIPanelTranslateHelper[RiTabTitle.Constituents] = "CONSTITUENTS";
    RIPanelTranslateHelper[RiTabTitle.Factor] = LocalizationStore.getTranslatedData("ri_factor_name", "FACTORS");
    RIPanelTranslateHelper[RiTabTitle.ExternalData] = "EXTERNAL";
    RIPanelTranslateHelper[RiTabTitle.AI] = "AI";
    RIPanelTranslateHelper["RELATED INFORMATION"] = LocalizationStore.getTranslatedData('ri_id1', "RELATED INFORMATION");

    const regionCountries = UserInfoUtil.getUserInfo().regionCountries || [];
    regionCountries.forEach((country) => {
        countriesTranslateHelper[country.name] = LocalizationStore.getTranslatedData(`Country_${country.name.replace(/ /ig, "")}`, country.name);
        if (!regionTranslateHelper[country.region]) {
            regionTranslateHelper[country.region] = LocalizationStore.getTranslatedData(`Region_${country.region.replace(/ /ig, "")}`, country.region);
        }
    });

    FactorTranslateHelper["SELECT_D/W_PERIODICITY"] = LocalizationStore.getTranslatedData('ri_factor_monthly_tip', 'To view factor events, please select Daily or Weekly chart interval');
    FactorTranslateHelper["VISIBILITY_ON"] = LocalizationStore.getTranslatedData('ri_factor_turn_on', 'Turn Visibility ON');
    FactorTranslateHelper["TO_VIEW_FACTOR"] = LocalizationStore.getTranslatedData('ri_factor_to_view', 'to view factor events');
    FactorTranslateHelper["SEE_ALSO"] = LocalizationStore.getTranslatedData("ri_factor_see", "See also");
    FactorTranslateHelper["FACTOR_EVENT"] = LocalizationStore.getTranslatedData("ri_factor_001", "Factor Event");
    FactorTranslateHelper["AVG_RETURN_PATH"] = LocalizationStore.getTranslatedData("ri_factor_average", "Average Return Path");
    FactorTranslateHelper["TAGET_PRICE"] = LocalizationStore.getTranslatedData("ri_factor_target", "Target Price");
    FactorTranslateHelper["AVG_GAIN"] = LocalizationStore.getTranslatedData("ri_factor_averageGain", "Average Gain");
    FactorTranslateHelper["AVG_LOSS"] = LocalizationStore.getTranslatedData("ri_factor_averageLoss", "Average Loss");
    FactorTranslateHelper["REVISED_TARGET_PRICE"] = LocalizationStore.getTranslatedData("ri_factor_revisedTargetPrice", "Revised Target Price");
    FactorTranslateHelper["NO_FACTOR_EVENT"] = LocalizationStore.getTranslatedData("ri_factor_no", "No factor event for the timeframe in view");
    FactorTranslateHelper["NO_ACTICE_FACTOR"] = LocalizationStore.getTranslatedData('ri_factor_no_active', 'No active factor event for the timeframe in view');
    FactorTranslateHelper["UNCHECK_THE"] = LocalizationStore.getTranslatedData('ri_factor_uncheck', 'Uncheck the ');
    FactorTranslateHelper["SHOW_ACTIVE_EVENT"] = LocalizationStore.getTranslatedData('ri_factor_show_active', 'Show last active event only ');
    FactorTranslateHelper["OPTION_TO_INACTIVE_EVENT"] = LocalizationStore.getTranslatedData('ri_factor_option', 'option to view inactive events');
    FactorTranslateHelper["SELECT_EVENT"] = LocalizationStore.getTranslatedData('ri_factor_select', 'Select an event');
    FactorTranslateHelper["SELECT_EVENT_FLAG"] = LocalizationStore.getTranslatedData('ri_factor_select02', 'Select an event flag');
    FactorTranslateHelper["AVG_FORWAD_RETURN_EQUAL_WIEGHT"] = LocalizationStore.getTranslatedData("ri_factor_avg_desc", "Average forward return, after the event, equal-weighted");
    FactorTranslateHelper["SIZE_OF_AVG_GAIN"] = LocalizationStore.getTranslatedData("ri_factor_avg_gain_desc", "Size of Average Gain, after the event, equal-weighted");
    FactorTranslateHelper["SIZE_OF_AVG_LOSS"] = LocalizationStore.getTranslatedData("ri_factor_avg_loss_desc", "Size of Average Loss, after the event, equal-weighted");
    FactorTranslateHelper["AVG_FORWAD_RETURN"] = LocalizationStore.getTranslatedData("ri_factor_revised_desc", "Average forward return, after the event and subsequent price action");
    FactorTranslateHelper["VISIBILITY"] = LocalizationStore.getTranslatedData('ri_factor_visibility', 'Visibility');

    SummaryTransLateHelper["OPEN_CLOSE_SUMMARY_BLOCK"] = LocalizationStore.getTranslatedData("sb_hvr", "Click to open or close Summary Block");
    SummaryTransLateHelper["SUMMARY_BLOCK_SHOW_MORE"] = LocalizationStore.getTranslatedData("sb_more", "MORE");
    SummaryTransLateHelper["SUMMARY_BLOCK_SHOW_LESS"] = LocalizationStore.getTranslatedData("sb_less", "LESS");
    SummaryTransLateHelper["HOVER_PRETAX_TEXT"] = LocalizationStore.getTranslatedData("fb_hoverpretax", "Pretax non-recurring items are included");
    SummaryTransLateHelper["INV_TO_TEXT"] = LocalizationStore.getTranslatedData("sb_invto", "INV T/O");
    SummaryTransLateHelper["COMP_RATING_TEXT"] = LocalizationStore.getTranslatedData("sb_crg", "COMP RATING");
    SummaryTransLateHelper["SMR_RATING_TEXT"] = LocalizationStore.getTranslatedData("sb_smrrg", "SMR RATING");
    SummaryTransLateHelper["SUMMARY_BLOCK_BETA"] = LocalizationStore.getTranslatedData("sb_beta", "BETA");
    SummaryTransLateHelper["DIV_GR_RT_TEXT"] = LocalizationStore.getTranslatedData("sb_divgrrt", "DIV GR RT");
    SummaryTransLateHelper["SUB_DIV"] = LocalizationStore.getTranslatedData("sb_div", "DIV");
    SummaryTransLateHelper["SUMMARY_BLOCK_NONE_TEXT"] = LocalizationStore.getTranslatedData("sb_none", "None");
    SummaryTransLateHelper["DEBT_PERCENT"] = LocalizationStore.getTranslatedData("sb_dtpct", "DEBT %");
    SummaryTransLateHelper["TAX_RATE"] = LocalizationStore.getTranslatedData("sb_taxrt", "TAX RATE");
    SummaryTransLateHelper["BACKLOG"] = LocalizationStore.getTranslatedData("sb_bl", "BACKLOG");
    SummaryTransLateHelper["R_AND_D"] = LocalizationStore.getTranslatedData("sb_rd", "R & D");
    SummaryTransLateHelper["BK_VAL"] = LocalizationStore.getTranslatedData("sb_bkval", "BK VAL");
    SummaryTransLateHelper["ALPHA_TEXT"] = LocalizationStore.getTranslatedData("sb_alpha", "ALPHA");
    SummaryTransLateHelper["SYMBOLS"] = LocalizationStore.getTranslatedData("sb_curr_6", "SYMBOLS:");
    SummaryTransLateHelper["CENTRAL_BANK_TEXT"] = LocalizationStore.getTranslatedData("sb_curr_1", "CENTRAL BANK:");
    SummaryTransLateHelper["INVERSE_SYMBOL"] = LocalizationStore.getTranslatedData("sb_curr_2", "INVERSE SYMBOL:");
    SummaryTransLateHelper["CURRENCY_NICKNAME"] = LocalizationStore.getTranslatedData("sb_curr_3", "NICKNAME:");
    SummaryTransLateHelper["FUND_DESCRIPTION"] = LocalizationStore.getTranslatedData("sb_etf_14", "FUND DESCRIPTION");
    SummaryTransLateHelper["DESCRIPTION_NOT_AVAILABLE"] = LocalizationStore.getTranslatedData("sb_etf_des", "Description Not Available");
    SummaryTransLateHelper["FUND_DETAILS"] = LocalizationStore.getTranslatedData("sb_etf_1", "FUND DETAILS");
    SummaryTransLateHelper["CHANGE_PERCENT"] = LocalizationStore.getTranslatedData("sb_mf_12", "Chg");
    SummaryTransLateHelper["NEW_CHANGE_PERCENT"] = LocalizationStore.getTranslatedData("sb_mf_9", "New");
    SummaryTransLateHelper["DECREASE_CHANGE_PERCENT"] = LocalizationStore.getTranslatedData("sb_mf_11", "Decrease");
    SummaryTransLateHelper["INCREASE_CHANGE_PERCENT"] = LocalizationStore.getTranslatedData("sb_mf_10", "Increase");
    SummaryTransLateHelper["FOCUS_LIST_OVERLAP"] = LocalizationStore.getTranslatedData("sb_etf_16", "O'NEIL FOCUS LIST OVERLAP");
    SummaryTransLateHelper["TOP_HOLDINGS_EQUITY"] = LocalizationStore.getTranslatedData("sb_etf_15", "TOP HOLDINGS - Equities");
    SummaryTransLateHelper["COVERAGE"] = LocalizationStore.getTranslatedData("sb_curr_6", "COVERAGE:");
    SummaryTransLateHelper["TYPE"] = LocalizationStore.getTranslatedData("sb_curr_1", "TYPE:");
    SummaryTransLateHelper["EXCHANGE_TYPE"] = LocalizationStore.getTranslatedData("sb_curr_2", "EXCHANGE: ");
    SummaryTransLateHelper["MGMT_OWNS"] = LocalizationStore.getTranslatedData("sb_mgmt", "MGMT owns");
    SummaryTransLateHelper["INC_SB"] = LocalizationStore.getTranslatedData("sb_inc", "Inc");
    SummaryTransLateHelper["DAYS_VOL_SHORT"] = LocalizationStore.getTranslatedData("sb_dvolshort", "Days Vol Short");
    SummaryTransLateHelper["SB_OPTIONS"] = LocalizationStore.getTranslatedData("sb_op", "Options");
    SummaryTransLateHelper["PEGGED_CURRENCIES"] = LocalizationStore.getTranslatedData("sb_curr_4", "CURRENCIES PEGGED TO THIS CURRENCY");
    SummaryTransLateHelper["COUNTRIES_HAVING_CURRENCY"] = LocalizationStore.getTranslatedData("sb_curr_5", "COUNTRIES USING THIS CURRENCY");
    SummaryTransLateHelper["AV_DAILY_VOL"] = LocalizationStore.getTranslatedData("sb_avol", "AVG DAILY VOL (000)");
    SummaryTransLateHelper["UP_DOWN_VOL"] = LocalizationStore.getTranslatedData("sb_udvol", "UP DOWN VOLUME");
    SummaryTransLateHelper["ACCUM_DIST_RATING"] = LocalizationStore.getTranslatedData("sb_adrg", "ACCUM/DIST RATING");
    SummaryTransLateHelper["RELATIVE_STRENGTH"] = LocalizationStore.getTranslatedData("sb_rs", "RELATIVE STRENGTH");
    SummaryTransLateHelper["SUMMARY_BLOCK_LOADING"] = LocalizationStore.getTranslatedData("filter_Loading", "Loading...");

    FinancialTransLateHelper["CALENDER_YEAR_END"] = LocalizationStore.getTranslatedData("fb_cye", "Calendar Year End");
    FinancialTransLateHelper["FISCAL_YEAR_END"] = LocalizationStore.getTranslatedData("fb_fye", "Fiscal Year End");
    FinancialTransLateHelper["SMALL_BLOCK"] = LocalizationStore.getTranslatedData("fb_small_block", "Small Block");
    FinancialTransLateHelper["MEDIUM_BLOCK"] = LocalizationStore.getTranslatedData("fb_medium_block", "Medium Block");
    FinancialTransLateHelper["LARGE_BLOCK"] = LocalizationStore.getTranslatedData("fb_large_block", "Large Block");
    FinancialTransLateHelper["EPS_PERCENT_CHANGE"] = LocalizationStore.getTranslatedData("fb_sebpyl", "Show EPS % Change Based on a Prior Year Loss");
    FinancialTransLateHelper["HIDE_FINANCIAL_BLOCK"] = LocalizationStore.getTranslatedData("fb_hfp", "Hide Financials Panel");
    FinancialTransLateHelper["EST_FINANCIAL_BLOCK"] = LocalizationStore.getTranslatedData('fb_est', ' Est.:');
    FinancialTransLateHelper["FINANCIAL_BLOCK_TITLE"] = LocalizationStore.getTranslatedData("fb_fina", "FINANCIALS");

    OwnershipBlockTranslateHelper["OWNERS"] = LocalizationStore.getTranslatedData("ob_id1", "OWNERS");
    OwnershipBlockTranslateHelper["TOP_OWNERS"] = LocalizationStore.getTranslatedData("ri_id101", "Top Owners");
    OwnershipBlockTranslateHelper["HOLDINGS_RANK"] = LocalizationStore.getTranslatedData("ob_id5", "Holdings Rank");
    OwnershipBlockTranslateHelper["SHARES_HELD"] = LocalizationStore.getTranslatedData("fb_sheld", "Shares Held");
    OwnershipBlockTranslateHelper["CHANGE_IN_SHARES"] = LocalizationStore.getTranslatedData("ob_id7", "Change in Shares Held");
    OwnershipBlockTranslateHelper["PANARAY_OWNER_LIST"] = LocalizationStore.getTranslatedData("ob_id9", "PANARAY Owner Lists");
    OwnershipBlockTranslateHelper["FAV_OWNERS_LIST"] = LocalizationStore.getTranslatedData("ob_id12", "Favorite Owner Lists");
    OwnershipBlockTranslateHelper["CREATE_OWNERS_LIST"] = LocalizationStore.getTranslatedData("ob_id13", "Create New Owners Lists");
    OwnershipBlockTranslateHelper["HIDE_OWNERSHIP_BLOCK"] = LocalizationStore.getTranslatedData("ob_id14", "Hide Ownership Panel");

    RiPanelNewsTransLateHelper["NO_NEWS_AVAILABLE"] = LocalizationStore.getTranslatedData("ri_id91", "No News Available");
    RiPanelNewsTransLateHelper["NEWS_TYPE_SELECT"] = LocalizationStore.getTranslatedData("ri_id93", "Select news types.");
    RiPanelNewsTransLateHelper["THIRD_PARTY_NEWS"] = LocalizationStore.getTranslatedData("ri_id94", "Third Party News");
    RiPanelNewsTransLateHelper["RI_PANEL_NEWS_APPEARS"] = LocalizationStore.getTranslatedData("ri_id95", "(appears in Related Information panel)");
    RiPanelNewsTransLateHelper["NEWS_SETTINGS"] = LocalizationStore.getTranslatedData("ri_id92", "NEWS SETTINGS");

    RiPanelMangementInfoTransLateHelper["MANAGER"] = LocalizationStore.getTranslatedData("ri_iid112", "MANAGER");
    RiPanelMangementInfoTransLateHelper["EDUCATION"] = LocalizationStore.getTranslatedData("ri_iid113", "Education");
    RiPanelMangementInfoTransLateHelper["FUNDS_MANAGED"] = LocalizationStore.getTranslatedData("ri_iid114", "Funds Managed");
    RiPanelMangementInfoTransLateHelper["MANAGEMENT_TEAM"] = LocalizationStore.getTranslatedData("ri_iid111", "MANAGEMENT TEAM");

    /* for RiPanel CheckListInfo, RiPanelAllocations, RiPanelOwnersipInfo */
    RiPanelCheckListTransLateHelper["CHECKLIST_NO_SCREEN"] = LocalizationStore.getTranslatedData("checklist_noscreen", "This folder contains no screens");
    RiPanelCheckListTransLateHelper["CHECKLIST_SCORE"] = LocalizationStore.getTranslatedData("ri_id72", "Checklist Score :");
    RiPanelCheckListTransLateHelper["NO_FILTER"] = LocalizationStore.getTranslatedData('ri_id74', 'No filters found');
    RiPanelCheckListTransLateHelper["HIDE_METRICS_WITHOUT_THRESHOLED"] = LocalizationStore.getTranslatedData("ri_id75", "Hide Metrics without Thresholds");
    RiPanelCheckListTransLateHelper["ALLOCATIONS_SECTOR_WEIGHTINGS"] = LocalizationStore.getTranslatedData("ri_iid118", "SECTOR WEIGHTINGS");
    RiPanelCheckListTransLateHelper["OWNERSHIP_INFO_OWNER_NAME"] = LocalizationStore.getTranslatedData("ri_id102", "Owner Name");
    RiPanelCheckListTransLateHelper["OWNERSHIP_INFO_PORTFOLIO"] = LocalizationStore.getTranslatedData("ri_id103_web", "% of<br />Portfolio");
    RiPanelCheckListTransLateHelper["OWNERSHIP_INFO_RECENT_SHARES"] = LocalizationStore.getTranslatedData("ri_id104_web", "Recent<br />Shares (000)");
    RiPanelCheckListTransLateHelper['TOTAL_COUNT'] = LocalizationStore.getTranslatedData("ri_id73", "{0} OF {1} PASSED");
    RiPanelCheckListTransLateHelper['SELECT_SCREEN'] = LocalizationStore.getTranslatedData("ri_id71", "SELECT A SCREEN");

    AlertsDialogTranslateHelper["DONE"]= LocalizationStore.getTranslatedData("done", "DONE");
    AlertsDialogTranslateHelper["ALERT_PREFERENCE"]= LocalizationStore.getTranslatedData("set_apre", "Alert Preferences");
    AlertsDialogTranslateHelper["ADDITION_AND_REMOVAL"]= LocalizationStore.getTranslatedData("list_addAndRemove", "ADDITIONS AND REMOVALS");
    AlertsDialogTranslateHelper["PRICE_AND_VOLUME"]= LocalizationStore.getTranslatedData("ch_msa_pav", "PRICE AND VOLUME");
    AlertsDialogTranslateHelper["ADDITION"]= LocalizationStore.getTranslatedData("list_additions", "Additions");
    AlertsDialogTranslateHelper["REMOVAL"]= LocalizationStore.getTranslatedData("list_removals", "Removals");
    AlertsDialogTranslateHelper["PRICE"]= LocalizationStore.getTranslatedData("MetricLibraryCategory_Price", "Price");
    AlertsDialogTranslateHelper["SMA_50_200_CROSSOVER_DLY"]= LocalizationStore.getTranslatedData("ch_msa_50200x", "50 SMA - 200 SMA crossover (daily)");
    AlertsDialogTranslateHelper["PRICE_CROSSES_200_SMA_DLY"]= LocalizationStore.getTranslatedData("alert_list_pc200", "Price crosses 200 SMA (daily)");
    AlertsDialogTranslateHelper["PRICE_CROSSED_50_SMA_DLY"]= LocalizationStore.getTranslatedData("alert_list_pc50", "Price crosses 50 SMA (daily)");
    AlertsDialogTranslateHelper["GAP_DOWN_EARNING_US"]= LocalizationStore.getTranslatedData("ch_msa_sup_gd", "Gap down on earnings (U.S. only)");
    AlertsDialogTranslateHelper["GAP_UP_EARNIGN_US"]= LocalizationStore.getTranslatedData("ch_msa_sup_gu", "Gap up on earnings (U.S. only)");
    AlertsDialogTranslateHelper["RS_LINE_NEW_52W_HIGH"]= LocalizationStore.getTranslatedData("ch_msa_sup_rs", "Relative Strength Line reaches new 52-week high (based on default index)");
    AlertsDialogTranslateHelper["BREAKING_OUT_ALL_TIME_HIGH"]= LocalizationStore.getTranslatedData("ch_msa_sup_bo", "Breaking out to all-time high");
    AlertsDialogTranslateHelper["DLY_VOLM_EXCEED_50_SMA"]= LocalizationStore.getTranslatedData("ch_msa_sup_dv", "Daily volume exceeds 50 SMA");
    AlertsDialogTranslateHelper["AVG_DLY_USD_CROSSES"]= LocalizationStore.getTranslatedData("ch_msa_advdc", "Average daily $ volume, USD crosses $");
    AlertsDialogTranslateHelper["AVG_DLY_VOLM_CROSSES"]= LocalizationStore.getTranslatedData("ch_msa_advcs", "Average daily volume crosses");
    AlertsDialogTranslateHelper["CEO_CHG"]= LocalizationStore.getTranslatedData("ch_msa_ceo", "CEO change");
    AlertsDialogTranslateHelper["STOCK_SPLIT"]= LocalizationStore.getTranslatedData("ch_msa_ss", "Stock split");
    AlertsDialogTranslateHelper["BUYBACK_ANNOUNCE"]= LocalizationStore.getTranslatedData("ch_msa_ba", "Buyback announcement");
    AlertsDialogTranslateHelper["NEGATIVE_ALERT_SOCRE_10_WEEK"]= LocalizationStore.getTranslatedData("MetricLibraryCategory_NegativeAlertscore,10Weeks", "Negative Alert Score, 10 Week");
    AlertsDialogTranslateHelper["NEGATIVE_ALERT_SOCRE_1_WEEK"]= LocalizationStore.getTranslatedData("MetricLibraryCategory_NegativeAlertscore,1Week", "Negative Alert Score, 1 Week");
    AlertsDialogTranslateHelper["NEGATIVE_ALERT_SCORE"]= LocalizationStore.getTranslatedData("ch_msa_nas", "NEGATIVE ALERT SCORE");
    AlertsDialogTranslateHelper["ENTER_EXIT_LOSS_RANGE"]= LocalizationStore.getTranslatedData("ch_eoe_loss", "Enter or exit loss range");
    AlertsDialogTranslateHelper["ENTER_EXIT_PIVOT_RANGE"]= LocalizationStore.getTranslatedData("ch_eoe_pivot", "Enter or exit pivot range");
    AlertsDialogTranslateHelper["ENTER_EXIT_PROFIT_RANGE"]= LocalizationStore.getTranslatedData("ch_eoe_profit", "Enter or exit profit range");
    AlertsDialogTranslateHelper["KEY_PRICE_RANGE"]= LocalizationStore.getTranslatedData("ch_key", "KEY PRICE RANGE");
    AlertsDialogTranslateHelper["PULL_BACK_10_WEEK"]= LocalizationStore.getTranslatedData("ch_msa_sup_pt", "Pullback to 10-week line");
    AlertsDialogTranslateHelper["BREAKWAY_GAP"]= LocalizationStore.getTranslatedData("ch_msa_sup_break", "Breakaway Gap");
    AlertsDialogTranslateHelper["PRICE_CROSSOVER_PIVOT"]= LocalizationStore.getTranslatedData("ch_msa_pcop", "Price crosses over pivot (breakout)");
    AlertsDialogTranslateHelper["PERCENT_OF_PIVOT"]= LocalizationStore.getTranslatedData("ch_msa_prwp_op", "% of pivot");
    AlertsDialogTranslateHelper["PRICE_RISES_TO_WITHIN"]= LocalizationStore.getTranslatedData("ch_msa_prwp", "Price rises to within");
    AlertsDialogTranslateHelper["PIVOT"]= LocalizationStore.getTranslatedData("ch_msa_pivot", "PIVOT");
    AlertsDialogTranslateHelper["VOLUME_50_DAY_BREAKE"]= LocalizationStore.getTranslatedData("ch_msa_sup_50", "50-day break on volume (U.S Only) ");
    AlertsDialogTranslateHelper["FAILED_BREAKOUT"]= LocalizationStore.getTranslatedData("ch_msa_fbd", "Failed breakout detected");
    AlertsDialogTranslateHelper["TIGHT_AREA_DETECTED"]= LocalizationStore.getTranslatedData("ch_msa_tad", "Tight area detected");
    AlertsDialogTranslateHelper["NEW_BASE"]= LocalizationStore.getTranslatedData("ch_msa_nbd", "New base detected");
    AlertsDialogTranslateHelper["WEEKLY"]= LocalizationStore.getTranslatedData("ch_wkly", "Weekly");
    AlertsDialogTranslateHelper["DIALY"]= LocalizationStore.getTranslatedData("ch_dly", "Daily");
    AlertsDialogTranslateHelper["BASE"]= LocalizationStore.getTranslatedData("ch_msa_base", "BASE");
    AlertsDialogTranslateHelper["PATTERN_REC"]= LocalizationStore.getTranslatedData("ch_msa_pr", "PATTERN RECOGNITION");
    AlertsDialogTranslateHelper["ANNOUNCEMENT"]= LocalizationStore.getTranslatedData("ch_ce_anncmt", "Announcements");
    AlertsDialogTranslateHelper["INSIDER_SELL"]= LocalizationStore.getTranslatedData("ch_msa_sup_sell", "Insiders transactions - Sells");
    AlertsDialogTranslateHelper["INSIDER_BUY"]= LocalizationStore.getTranslatedData("ch_msa_sup_buy", "Insiders transactions - Buys");
    AlertsDialogTranslateHelper["DIVIDEND_CHANGE"]= LocalizationStore.getTranslatedData("ch_msa_dc", "Dividend change");
    AlertsDialogTranslateHelper["EARNING_REALSE"]= LocalizationStore.getTranslatedData("ch_msa_er_1", "Earnings release is in");
    AlertsDialogTranslateHelper["COPORATE_EVENTS"]= LocalizationStore.getTranslatedData("ch_msa_ceuo", "CORPORATE EVENTS (U.S. ONLY)");
    AlertsDialogTranslateHelper["DAYS"]= LocalizationStore.getTranslatedData("ch_msa_er_2", "days");
    AlertsDialogTranslateHelper["MOVING_AVG"]= LocalizationStore.getTranslatedData("ch_msa_ma", "MOVING AVERAGES");
    AlertsDialogTranslateHelper["SHARES"]= LocalizationStore.getTranslatedData("sym_shrs", "shares");
    AlertsDialogTranslateHelper["START_OF_TRADING_DAY"]= LocalizationStore.getTranslatedData("set_pre_qs_mfs", " start of trading day");
    AlertsDialogTranslateHelper["MINUTE_FROM"]= LocalizationStore.getTranslatedData("set_pre_qs_trigger", " minutes from");
    AlertsDialogTranslateHelper["DELAY"]= LocalizationStore.getTranslatedData("set_pre_qs_del", "Delay:");
    AlertsDialogTranslateHelper["DLY_VOLUME_RATE_EXCEED"]= LocalizationStore.getTranslatedData("ch_msa_dvre", "Daily volume rate exceeds");
    AlertsDialogTranslateHelper["DLY_PRICE_CHG_EXCEED"]= LocalizationStore.getTranslatedData("ch_msa_dpce", "Daily price change exceeds +/-");
    AlertsDialogTranslateHelper["PATTERN_RECOG"]= LocalizationStore.getTranslatedData("ch_pr", "Pattern Recognition");
    AlertsDialogTranslateHelper["OFFERING"]= LocalizationStore.getTranslatedData("ch_msa_Offering", "Offering");
    AlertsDialogTranslateHelper["WEEKLY_BASE_BREAKOUT"]= LocalizationStore.getTranslatedData("ch_msa_booawb", "Breaking out of a weekly base");
    AlertsDialogTranslateHelper["DAILY_BASE_BREAKOUT"]= LocalizationStore.getTranslatedData("ch_msa_booadb", "Breaking out of a daily base");
    AlertsDialogTranslateHelper["FAILED_DAILY_BASE_BREAKOUT"]= LocalizationStore.getTranslatedData("ch_msa_fdbd", "Failed daily base detected");
    AlertsDialogTranslateHelper["FAILED_WEEKLY_BASE_BREAKOUT"]= LocalizationStore.getTranslatedData("ch_msa_fwbd", "Failed weekly breakout detected");
    AlertsDialogTranslateHelper["NEW_WEEKLY_BASE"]= LocalizationStore.getTranslatedData("ch_msa_nwbd", "New weekly base detected");
    AlertsDialogTranslateHelper["NEW_DAILY_BASE"]= LocalizationStore.getTranslatedData("ch_msa_ndbd", "New daily base detected");
    AlertsDialogTranslateHelper["CROSSOVER_50_200"]= LocalizationStore.getTranslatedData("ch_msa_50200xo", "50 SMA - 200 SMA crossover");
    AlertsDialogTranslateHelper["RS_NEW_HIGH"]= LocalizationStore.getTranslatedData("ch_msa_sup_rr", "RS reaches new high");
    AlertsDialogTranslateHelper["PRICE_200_SMA_CROSS"]= LocalizationStore.getTranslatedData("EmailTitle_PriceCross200", "Price crosses 200 SMA");
    AlertsDialogTranslateHelper["PRICE_50_SMA_CROSS"]= LocalizationStore.getTranslatedData("EmailTitle_PriceCross50", "Price crosses 50 SMA");
    AlertsDialogTranslateHelper["_200_SMA"]= LocalizationStore.getTranslatedData("EmailTitle_200SMA", " 200 SMA");
    AlertsDialogTranslateHelper["_50_SMA"]= LocalizationStore.getTranslatedData("EmailTitle_50SMA", " 50 SMA");
    AlertsDialogTranslateHelper["PRICE_CROSSES"]= LocalizationStore.getTranslatedData("EmailTitle_PriceCross", "Price crosses ");
    AlertsDialogTranslateHelper["AVG_DLY_$_VOL_CROSSES"]= LocalizationStore.getTranslatedData("EmailTitle_AveDailyColVolCro", "Average daily dollar volume crosses $");
    AlertsDialogTranslateHelper["WEEKLY_VOL_EXCEED"]= LocalizationStore.getTranslatedData("EmailTitle_WeeklyVolume", "Weekly volume rate exceeds ");
    AlertsDialogTranslateHelper["DAILY_PRICE_CHG_EXCEED"]= LocalizationStore.getTranslatedData("ch_msa_dpces", "Daily price change exceeds ");
    AlertsDialogTranslateHelper["REMOVE_FROM_LIST"]= LocalizationStore.getTranslatedData("EmailTitle_removefromlist", "Removed from list");
    AlertsDialogTranslateHelper["ADDED_TO_LIST"]= LocalizationStore.getTranslatedData("la_addedtolist", "Added to list");
    AlertsDialogTranslateHelper["LIST_ALERT"]= LocalizationStore.getTranslatedData("la_listalert", "List Alert: ");
    AlertsDialogTranslateHelper["LIST_AND_CONDITION"]= LocalizationStore.getTranslatedData("la_listalert", "List Alert And-condition: ");
    AlertsDialogTranslateHelper["TIGHT_AREA"]= LocalizationStore.getTranslatedData("ch_msa_ta", "Tight Area");
    AlertsDialogTranslateHelper["NEW_BASE"]= LocalizationStore.getTranslatedData("ch_msa_nb", "New Base");
    AlertsDialogTranslateHelper["UPWARD"]= LocalizationStore.getTranslatedData("ch_msa_Upwar", "Upward");
    AlertsDialogTranslateHelper["DOWNWARD"]= LocalizationStore.getTranslatedData("ch_msa_Downward", "Downward");

    AlertTranslateHelper["PRICE_ALERT_HEADER"]= LocalizationStore.getTranslatedData("ch_pral", "PRICE ALERT");
    AlertTranslateHelper["PRICE"]= LocalizationStore.getTranslatedData("price", "Price");
    AlertTranslateHelper["NOTE"]= LocalizationStore.getTranslatedData("al_note","Note");
    AlertTranslateHelper["SAVE"]= LocalizationStore.getTranslatedData("save", "Save");
    AlertTranslateHelper["NOTIFICATION_PREFERENCE"]= LocalizationStore.getTranslatedData("mc_nopre", "Notification Preferences");
    AlertTranslateHelper["MORE"]= LocalizationStore.getTranslatedData("alert_Geography_more", "more");
    AlertTranslateHelper["SELECT_COUNTRIES"]= LocalizationStore.getTranslatedData("alert_Geography_title", "Select Countries");
    AlertTranslateHelper["GEOGRAPHY"]= LocalizationStore.getTranslatedData("alert_Geography", "Geography");
    AlertTranslateHelper["NO_COLUMN_SET"]= LocalizationStore.getTranslatedData("alert_NoColumnSet", "No Column Set");
    AlertTranslateHelper["COLUMN_SET"]= LocalizationStore.getTranslatedData("LM_TP_ColumnSet", "Column Set");
    AlertTranslateHelper["COLUMN_SET_AND_GEOGRAPHY_EXCLUDE_SYMBOL"]= LocalizationStore.getTranslatedData("alert_ColumnSetDes", "Use the Column Set and Geography buttons to exclude unwanted symbols from List Alerts.");

    AlertTranslateHelper["ALERT_PREFERENCE"]= LocalizationStore.getTranslatedData("set_apre", "Alert Preferences");
    AlertTranslateHelper["FOLLOWING_CONDITION_MET_ALERT"]= LocalizationStore.getTranslatedData("alert_list_msg", 'Alert me when ALL of the following conditions are met:');
    AlertTranslateHelper["AND_ALERT"]= LocalizationStore.getTranslatedData("ch_msa_sup_aca", "And-condition Alerts");
    AlertTranslateHelper["DELETE_ALERT"]= LocalizationStore.getTranslatedData("list_DelAlt", 'Delete Alerts');
    AlertTranslateHelper["DELETE"]= LocalizationStore.getTranslatedData("delete_upper", 'DELETE');
    AlertTranslateHelper["SELECT_COLUMN_SET"]= LocalizationStore.getTranslatedData("LM_TP_SltAColSet","SELECT A COLUMN SET");
    AlertTranslateHelper["HRS_AGO"]= LocalizationStore.getTranslatedData("RI_hrs_ago_web", " hrs ago");
    AlertTranslateHelper["MIN_AGO"]= LocalizationStore.getTranslatedData("RI_mins_ago_web", " min ago");
    AlertTranslateHelper["SEC_AGO"]= LocalizationStore.getTranslatedData("RI_sec_ago_web", " sec ago");
    AlertTranslateHelper["REMAINING"]= LocalizationStore.getTranslatedData("ri_id6", "remaining.");
    AlertTranslateHelper["TOTAL_ACTIVE_ALERT"]= LocalizationStore.getTranslatedData("ri_id5", "total active alerts.");
    AlertTranslateHelper["MA_ALERT"]= LocalizationStore.getTranslatedData("Alert_MovingAverage", "Moving Average Alert: ");
    AlertTranslateHelper["PRICE_ALERT"]= LocalizationStore.getTranslatedData("Alert_Price", "Price Alert: ");
    AlertTranslateHelper["LAST_TRIGGERED_ON"]= LocalizationStore.getTranslatedData("Alert_LastTriggeredOn", " Last Triggered On: ");
    AlertTranslateHelper["REACTIVATE"]= LocalizationStore.getTranslatedData("ch_reactivate", "Reactivate");
    AlertTranslateHelper["DEACTIVATE"]= LocalizationStore.getTranslatedData("ch_deactivate", "Deactivate");
    AlertTranslateHelper["ALERT_TYPE"]= LocalizationStore.getTranslatedData("ri_id7", "ALERT TYPE");
    AlertTranslateHelper["NO_DATA_FOUND"]= LocalizationStore.getTranslatedData("ri_id_noDataFound", "No Data Found");
    AlertTranslateHelper["NO_TRIGGERED_ALERT"]= LocalizationStore.getTranslatedData("ri_id3", "No alerts have been triggered for this symbol");
    AlertTranslateHelper["NO_ALERTS"]= LocalizationStore.getTranslatedData("ri_id4", "No alerts for this symbol");

    AlertPeriodicityTranslateHelper[PeriodicityType.Daily] = LocalizationStore.getTranslatedData("Periodicity_1", "daily");
    AlertPeriodicityTranslateHelper[PeriodicityType.Weekly] = LocalizationStore.getTranslatedData("Periodicity_2", "weekly");
    AlertPeriodicityTranslateHelper[PeriodicityType.Monthly] = LocalizationStore.getTranslatedData("Periodicity_3", "monthly");
    AlertPeriodicityTranslateHelper[PeriodicityType.Quarterly] = LocalizationStore.getTranslatedData("Periodicity_10", "quarterly");
    AlertPeriodicityTranslateHelper[PeriodicityType.Annual] = LocalizationStore.getTranslatedData("Periodicity_11", "annual");

    Object.entries(PeriodicityType).forEach(([key, value])=> { PeriodicityTypeTranslateHelper[value] = PeriodicityTranslateHelper[key] })

    PatternRangeTranslateHelper["LOSS_RANGE"]= LocalizationStore.getTranslatedData('lossRange', 'Loss Range');
    PatternRangeTranslateHelper["PIVOT_RANGE"]= LocalizationStore.getTranslatedData('pivotRange', 'Pivot Range');
    PatternRangeTranslateHelper["PROFIT_RANGE"]= LocalizationStore.getTranslatedData('profitRange', 'Profit Range');
    PatternRangeTranslateHelper["POWER_FROM_PIVOT"]= LocalizationStore.getTranslatedData("sys_powerFromPivot", "Power from Pivot");
    PatternRangeTranslateHelper["CLIMAX_TOP"]= LocalizationStore.getTranslatedData("Hover_ClimaxTop", "Climax Top");

    PatterNameTranslateHelper["Flat Base"]= LocalizationStore.getTranslatedData(`Es_FlatBase`, "Flat Base");
    PatterNameTranslateHelper["Consolidation"]= LocalizationStore.getTranslatedData(`Es_Consolidation`, "Consolidation");
    PatterNameTranslateHelper["Double Bottom"]= LocalizationStore.getTranslatedData(`Es_DoubleBottom`, "Double Bottom");
    PatterNameTranslateHelper["IPO Base"]= LocalizationStore.getTranslatedData(`Es_IPOBase`, "IPO Base");
    PatterNameTranslateHelper["Saucer with Handle"]= LocalizationStore.getTranslatedData("pt_saucerWithHandle", "Saucer with Handle");
    PatterNameTranslateHelper["Cup with Handle"]= LocalizationStore.getTranslatedData("cupwhandle", "Cup with Handle");
    PatterNameTranslateHelper["Cup"]= LocalizationStore.getTranslatedData("cup", "Cup");
    PatterNameTranslateHelper["Saucer"]= LocalizationStore.getTranslatedData("Symbol_Saucer", "Saucer");
    PatterNameTranslateHelper["Tight Area"]= LocalizationStore.getTranslatedData("tight area", "Tight Area");
    PatterNameTranslateHelper["Channel Lines"]= LocalizationStore.getTranslatedData("Hover_ChannelLine", "Channel Lines");
    PatterNameTranslateHelper["Climax Top"]= LocalizationStore.getTranslatedData("Hover_ClimaxTop", "Climax Top");
    PatterNameTranslateHelper["Ascending Base"]= LocalizationStore.getTranslatedData("ascendingbase", "Ascending Base");

    instrumentTypesTranslateHelper['1']= LocalizationStore.getTranslatedData("LMSymbolType_STOCK", "Stock");
    instrumentTypesTranslateHelper['100']= LocalizationStore.getTranslatedData("LMSymbolType_MUTUALFUNDS", "Mutual Funds");
    instrumentTypesTranslateHelper['119']= LocalizationStore.getTranslatedData("LMSymbolType_SECTOR", "Sector");
    instrumentTypesTranslateHelper['14']= LocalizationStore.getTranslatedData("LMSymbolType_INDEX", "Index");
    instrumentTypesTranslateHelper['17']= LocalizationStore.getTranslatedData("LMSymbolType_INDUSTRYGROUP", "Industry Group");
    instrumentTypesTranslateHelper['19']= LocalizationStore.getTranslatedData("LMSymbolType_CURRENCY", "Currency");

    ValueOperatorsTranslateHelper[ValueOperatorType.EQ_ValueOperator]= " = ";
    ValueOperatorsTranslateHelper[ValueOperatorType.GE_ValueOperator]= " ≥ ";
    ValueOperatorsTranslateHelper[ValueOperatorType.GT_ValueOperator]= " > ";
    ValueOperatorsTranslateHelper[ValueOperatorType.LE_ValueOperator]= " ≤ ";
    ValueOperatorsTranslateHelper[ValueOperatorType.LT_ValueOperator]= " < ";
    ValueOperatorsTranslateHelper[ValueOperatorType.NOTEQ_ValueOperator]= " ≠ ";
    ValueOperatorsTranslateHelper[ValueOperatorType.BETWEEN_ValueOperator]= " ";
    ValueOperatorsTranslateHelper[ValueOperatorType.CONTAINS_ValueOperator]= ` ${LocalizationStore.getTranslatedData("LM_Contains", "Contains")} `;
    ValueOperatorsTranslateHelper[ValueOperatorType.NOTCONTAINS_ValueOperator]= ` ${LocalizationStore.getTranslatedData("LM_DoesNotContain", "Does not Contain")} `;

    PickListOperatorsTranslateHelper[ValueOperatorType.EQ_ValueOperator]= LocalizationStore.getTranslatedData("EQ_ValueOperator", "Include:");
    PickListOperatorsTranslateHelper[ValueOperatorType.NOTEQ_ValueOperator]= LocalizationStore.getTranslatedData("NOTEQ_ValueOperator", "Exclude:");
    PickListOperatorsTranslateHelper[ValueOperatorType.CONTAINS_ValueOperator]= LocalizationStore.getTranslatedData("LM_Contains_1", "Contains:");
    PickListOperatorsTranslateHelper[ValueOperatorType.NOTCONTAINS_ValueOperator]= LocalizationStore.getTranslatedData("LM_DoesNotContain_1", "Does not Contain:");

    AlertSecondaryInfoTranslateHelper["ALERT_ICON_ON_CHART"]= LocalizationStore.getTranslatedData("ri_id2", "Display alert icons on chart");
    AlertSecondaryInfoTranslateHelper["TRIGGERED"]= LocalizationStore.getTranslatedData("mc_alts_tr", "Triggered");
    AlertSecondaryInfoTranslateHelper["ACTIVE"]= LocalizationStore.getTranslatedData("mc_active", "Active");
}