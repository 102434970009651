import keyMirror from "keymirror";

export const AINewsConstants = {
    ActionTypes: keyMirror({
        SET_MESSAGE: null,
        GET_COMPETITIVE_EDGE_REQUEST: null,
        GET_COMPETITIVE_EDGE_SUCCESS: null,
        GET_COMPETITIVE_EDGE_FAILURE: null,
        SET_AI_TEXT_REACTION: null,
        GET_ZIGZAG_TREND_DATA_REQUEST: null,
        GET_ZIGZAG_TREND_DATA_SUCCESS: null,
        GET_ZIGZAG_TREND_DATA_FAILURE: null,
    })
}

export const AINewsType = {
    COMPETITIVE_NEWS: 1,
    TREND_NEWS: 2,
}

export const TREND = {
    UP_TREND: 1,
    DOWN_TREND: 2,
}

export const REACTIONS = {
    LIKE: 1,
    DISLIKE: 2,
    UNSURE: 3
}