import SettingsStore from "SettingsStore";
import dark from "!less-vars-loader!../../Asset/css/lessvars/colors-dark.less";
import light from "!less-vars-loader!../../Asset/css/lessvars/colors-light.less";
import video from "!less-vars-loader!../../Asset/css/lessvars/colors-video.less";
import ThemeType from "ThemeType";
import LessUtil from "LessUtil";
import { PrintMode } from "../../print/printmode";

import { DefaultColors, DefaultHoverColors, DefaultTextColors, UseDefaultColor } from "../../Constants/AppColorConstants";
import UserInfoUtil from "../UserInfoUtil";
import { findKey } from "underscore";

// const transparencyLevel = { '0': '00', '5': '0D', '10': '1A', '15': '26', '20': '33', '25': '40', '30': '4D', '35': '59', '40': '66', '45': '73', '50': '80', '55': '8C', '60': '99', '65': 'A6', '70': 'B3', '75': 'BF', '80': 'CC', '85': 'D9', '90': 'E6', '95': 'F2', '100': 'FF' };
const transparencyLevel = { '100':	'FF', '99':	'FC', '98':	'FA', '97':	'F7', '96':	'F5', '95':	'F2', '94':	'F0', '93':	'ED', '92':	'EB', '91':	'E8', '90':	'E6', '89':	'E3', '88':	'E0', '87':	'DE', '86':	'DB', '85':	'D9', '84':	'D6', '83':	'D4', '82':	'D1', '81':	'CF', '80':	'CC', '79':	'C9', '78':	'C7', '77':	'C4', '76':	'C2', '75':	'BF', '74':	'BD', '73':	'BA', '72':	'B8', '71':	'B5', '70':	'B3', '69':	'B0', '68':	'AD', '67':	'AB', '66':	'A8', '65':	'A6', '64':	'A3', '63':	'A1', '62':	'9E', '61':	'9C', '60':	'99', '59':	'96', '58':	'94', '57':	'91', '56':	'8F', '55':	'8C', '54':	'8A', '53':	'87', '52':	'85', '51':	'82', '50':	'80', '49':	'7D', '48':	'7A', '47':	'78', '46':	'75', '45':	'73', '44':	'70', '43':	'6E', '42':	'6B', '41':	'69', '40':	'66', '39':	'63', '38':	'61', '37':	'5E', '36':	'5C', '35':	'59', '34':	'57', '33':	'54', '32':	'52', '31':	'4F', '30':	'4D', '29':	'4A', '28':	'47', '27':	'45', '26':	'42', '25':	'40', '24':	'3D', '23':	'3B', '22':	'38', '21':	'36', '20':	'33', '19':	'30', '18':	'2E', '17':	'2B', '16':	'29', '15':	'26', '14':	'24', '13':	'21', '12':	'1F', '11':	'1C', '10':	'1A', '9':	'17', '8':	'14', '7':	'12', '6':	'0F', '5':	'0D', '4':	'0A', '3':	'08', '2':	'05', '1':	'03', '0':	'00'};

class ThemeHelper {

  loadUserSelectedTheme() {
    const consoleSetting = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSetting.NavDatagraphSettings.TabDataGraphSettings;
    const videoMode = tabDataGraphSettings.videoMode;
    if (consoleSetting) {
      //let theme = consoleSetting.currentTheme;
      if (consoleSetting.currentTheme === ThemeType.Light || PrintMode.printing) {
        LessUtil.modifyVars(light);
      }
      else {
        if (videoMode === true) {
          LessUtil.modifyVars(video);
        } else {
          LessUtil.modifyVars(dark);
        }
      }
    }
  }

  getThemedBrush(key) {
    const consoleSetting = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSetting.NavDatagraphSettings.TabDataGraphSettings;
    const videoMode = tabDataGraphSettings.videoMode;
    if (consoleSetting) {
      let theme = videoMode === true ? ThemeType.Video : PrintMode.printing ? ThemeType.Light : consoleSetting.currentTheme;
      if(key == "positiveDataText" || key == "negativeDataText" || key == "positiveNode" || key == "negativeNode"){
        theme = consoleSetting.originalTheme;
      }
      let themeBrushObject;
      switch (theme) {
        case ThemeType.Video:
        case ThemeType.Dark: {
          if (videoMode === true) {
            themeBrushObject = video;
          } else {
            themeBrushObject = dark;
          }
          break;
        }
        case ThemeType.Light: {
          themeBrushObject = light;
          break;
        }
        default: {
          if (videoMode === true) {
            themeBrushObject = video;
          } else {
            themeBrushObject = dark;
          }
          break;
        }
      }
      
      if (!themeBrushObject[key]) {
        if (key && key[0] == '#') {
          return key;
        }
        throw new TypeError("color brush key does not exist");
      }

      return this.getCustomColor(themeBrushObject, theme, key);
    }
    else {
      throw new TypeError("console setting not defined");
    }
  }
  getFontColor(colorInfo) {
    if (colorInfo.length < 6) {
      colorInfo = colorInfo + colorInfo.substring(1, 4);
    }
    const red = colorInfo.substring(1, 3);
    const green = colorInfo.substring(3, 5);
    const blue = colorInfo.substring(5, 7);
    const redV = parseInt("0x" + red, 16);
    const greenV = parseInt("0x" + green, 16);
    const blueV = parseInt("0x" + blue, 16);
    const colorB = ((redV * 299) + (greenV * 587) + (blueV * 114)) / 1000;
    const colorD = (Math.max(redV, 0) - Math.min(redV, 0)) + (Math.max(greenV, 0) - Math.min(greenV, 0)) + (Math.max(blueV, 0) - Math.min(blueV, 0));
    if (colorD > 350 && Math.abs(colorB) > 125)  //500 & 125 are defaults
    {
      return "Black";
    }
    else {
      return "White";
    }
  }
  getCustomColor(themeBrushObject, theme, key) {
    let consoleSetting = SettingsStore.getConsoleSettings();
    let appColorSettings = consoleSetting.AppColorSettings;

    let color = themeBrushObject[key];
    switch (key) {
      case "positiveNode":
        color = (theme === ThemeType.Dark || theme === ThemeType.Video) ? appColorSettings.darkPosColor : appColorSettings.lightPosColor;
        //set default color to prevent user's setting color
        if (UserInfoUtil.hasChinaEntitlement()) {
          color = DefaultColors.Red;
        }
        break;
      case "negativeNode":
        color = (theme === ThemeType.Dark || theme === ThemeType.Video) ? appColorSettings.darkNegColor : appColorSettings.lightNegColor;
        //set default color to prevent user's setting color
        if (UserInfoUtil.hasChinaEntitlement()) {
          color = DefaultColors.Green;
        }
        break;
      case "positiveDataText":
        color = (theme === ThemeType.Dark || theme === ThemeType.Video) ? appColorSettings.darkPosColor : appColorSettings.lightPosColor;
        //In Ray_Default, default text color is slight different with default node color.
        //if user select default color, and Panaray is applying data text, then use the defaut text color.
        if ((theme === ThemeType.Dark && appColorSettings.isDefaultDarkPosColor === UseDefaultColor.Yes) ||
          (theme === ThemeType.Video && appColorSettings.isDefaultDarkPosColor === UseDefaultColor.Yes) ||
          (theme === ThemeType.Light && appColorSettings.isDefaultLightPosColor === UseDefaultColor.Yes)) {
          color = theme === ThemeType.Video ? DefaultTextColors.VideoBlue : DefaultTextColors.Blue;
        }
        if (UserInfoUtil.hasChinaEntitlement()) {
          color = DefaultTextColors.Blue;
        }
        break;
      case "negativeDataText":
        color = (theme === ThemeType.Dark || theme === ThemeType.Video) ? appColorSettings.darkNegColor : appColorSettings.lightNegColor;
        //In Ray_Default,default text color is slight different with default node color.
        //if user select default color, and Panaray is applying data text, then use the defaut text color.
        if (((theme === ThemeType.Dark || theme === ThemeType.Video) && appColorSettings.isDefaultDarkNegColor === UseDefaultColor.Yes) || (theme === ThemeType.Light && appColorSettings.isDefaultLightNegColor === UseDefaultColor.Yes)) {
          color = DefaultTextColors.Magenta;
        }
        if (UserInfoUtil.hasChinaEntitlement()) {
          color = DefaultTextColors.Magenta;
        }
        break;
      case "positiveDataTextHover":
        color = (theme === ThemeType.Dark || theme === ThemeType.Video) ? appColorSettings.darkPosHoverColor : appColorSettings.lightPosHoverColor;
        if (UserInfoUtil.hasChinaEntitlement()) {
          color = DefaultHoverColors.Blue;
        }
        break;
      case "negativeDataTextHover":
        color = (theme === ThemeType.Dark || theme === ThemeType.Video) ? appColorSettings.darkNegHoverColor : appColorSettings.lightNegHoverColor;
        if (UserInfoUtil.hasChinaEntitlement()) {
          color = DefaultHoverColors.Magenta;
        }
        break;
      default:
        color = themeBrushObject[key];
        break;
    }

    return color;
  }

  convertHexToArgb(color, opacity = 1) {
    color = dark[color]; // always save color in dark theme.
    color = color.slice(1);
    opacity *= 100;
    const alpha = transparencyLevel[Math.round(opacity)];
    return alpha.concat(color);
  }

  convertArgbToHex(color) {
    let alpha = color.slice(0, 2); // extract alpha value
    alpha = findKey(transparencyLevel, (value) => value === alpha) / 100;
    color = dark[color.substring(2)]; // always retrieve color in dark theme.

    return { color: color, opacity: alpha };
  }

  getZigzagHoverColor(color) {
    const consoleSetting = SettingsStore.getConsoleSettings();

    if (consoleSetting) {
      const theme = PrintMode.printing ? ThemeType.Light : consoleSetting.currentTheme;

      const themeBrushObject = theme === ThemeType.Dark ? dark : light;

      if (color === themeBrushObject['FF0000'] || color === themeBrushObject['CC0100']) {
        return themeBrushObject['dg-zigzagLine'];
      }
    }
    return "#FF0000";
  }

}

const themeHelper = new ThemeHelper();
export default themeHelper;
