import { cancelCorpEventDialogSetting } from "../../../Actions/PricePanelActions.js";
import DatagraphHelper from "../../../Utils/DatagraphHelper.js";
import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import GraphType from "GraphType";
import LocalizationStore from "LocalizationStore";
import PriceMenuHelper from "./PriceMenuHelper.js";
import SettingsStore from "SettingsStore";
import SplitUtil from "../../../Utils/SplitUtil.js";
import SymbolType from "SymbolType";
import { AnnouncementConstants, CorporateEventsConst, PriceChartConstants } from "../../../Constants/PriceChartConstants.js";
import { AnnouncementDescriptionTranslateHelper, CorpActionTranslateHelper, CorpEventsDialogTranslateHelper, EventsDialogTranslateHelper, LineDialogPeriodicityTranslateHelper, MajorArticlesTranslateHelper } from "../../../Utils/TranslateHelper.js";
import { CorpActionTypeEnum, CorpEventsType } from "../../../Constants/CorpEvents.js";
import { fork, put, select, takeLatest } from 'redux-saga/effects';
import { getCorpEventStates, getDatagraphStates, getTimeLine, priceChartReducerselect} from '../../../Reducers/NavDataGraph/TabDataGraph/selectors'
const { ActionTypes } = PriceChartConstants;
import { CorpEventsDialogConstants } from "../../../Constants/CorpEventsDialogConstants";
import moment from "moment"

function getCurrecyCode(currency) {
    return currency.CurrencyCode ? currency.CurrencyCode : currency.Code;
}
function GetAnnouncementFullDescription(corpEvent) {
    if (corpEvent.CorpActionDisplayValue === AnnouncementConstants.IPO) {
      return `${AnnouncementDescriptionTranslateHelper[corpEvent.CorpActionDisplayValue]}${corpEvent.Price}`
    }
    else if (AnnouncementDescriptionTranslateHelper[corpEvent.CorpActionDisplayValue]) {
      return AnnouncementDescriptionTranslateHelper[corpEvent.CorpActionDisplayValue];
    }
    return " ";
  }
function Qtr(month) {
    return Math.floor((month - 1) / 3.0);
}

function processCorporateEvents(corporateEvents, corporateEventsSettings, HiLowPoints, SymbolInfo, timeLine, periodicity, scale, endDate, nodeWidth, chartHeight, isHistoric) {
    try {
        let corpEventsData = [];
        let corpIndex = 0;
        let dataIndex = 0;
        let xGraph = 0;

        let corpDate = timeLine[dataIndex].Date;
        let graphDate = timeLine[dataIndex].Date;
        // if (!corporateEventsSettings.IsVisible) return corpEventsData; optPriceChartVisibility

        let fIndex = 0;
        const length = timeLine.length;
        const flength = HiLowPoints.allPoints.length;

        if (periodicity !== GraphType.Daily && periodicity !== GraphType.Weekly && periodicity !== GraphType.Monthly) {
            corporateEvents = corporateEvents.filter((t) => t.CorpActionDisplayValue !== "LPE");
        }
        corporateEvents = corporateEvents.filter((t) => {
            t.CorpActionDate = DateHelper.getPSTFromLong(t.CorpActionDate);
            t.GraphDate = DateHelper.getPSTFromLong(t.GraphDate);
            if (periodicity === GraphType.Annual) {
                for (let i = fIndex; i < length; i++) {
                    if (t.GraphDate.getFullYear() === timeLine[i].Date.getFullYear()) {
                        t.GraphDate = timeLine[i].Date;
                        break;
                    }
                }
            }
            else if (periodicity === GraphType.Quarterly) {
                for (let i = fIndex; i < length; i++) {
                    if (t.GraphDate.getFullYear() === timeLine[i].Date.getFullYear() &&
                        Qtr(t.GraphDate.getMonth() + 1) === Qtr(timeLine[i].Date.getMonth() + 1)) {
                        t.GraphDate = timeLine[i].Date;
                        break;
                    }
                }
            }
            t.CorpActionTypeEnum = CorpActionTypeEnum[t.CorpActionType.toUpperCase()];
            return t.GraphDate <= timeLine[fIndex].Date;
        });

        if (corporateEvents.length < 1) {
            return corpEventsData;
        }

        // EPS DUE and XDIV events will have future dates which is >endDate, hence passing isAbsolute as true to get the actual adjusted date.
        const gDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity, true);

        for (let a = 0; a < flength && fIndex < flength && HiLowPoints.allPoints[a]; a++) {
            if (HiLowPoints.allPoints[fIndex].Date > gDate) {
                fIndex++;
            }
            if (HiLowPoints.allPoints[a]) {
                HiLowPoints.allPoints[a].corpIndex = [];
            }
        }

        if (HiLowPoints.allPoints[fIndex] === undefined && fIndex > 0) { fIndex--; }
        if (fIndex > flength) { return corpEventsData; }

        let index = 0;
        
        for (; index < length && timeLine[index] && timeLine[index].Date > gDate; index++) { }

        for (; index < length && timeLine[index] && corporateEvents[corpIndex]; index++, fIndex++) {
            if (!timeLine[index]) { break; }
            xGraph = timeLine[index].xAxis;
            graphDate = timeLine[index].Date;
           
            // EPS DUE and XDIV events will have future dates which is >endDate, hence passing isAbsolute as true to get the actual adjusted date.
            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity, true);
            if (corpDate > endDate && isHistoric && corporateEvents[corpIndex].CorpActionDisplayValue !== "LPE" && corporateEvents[corpIndex].CorpActionDisplayValue !== "XDIV" && corporateEvents[corpIndex].CorpActionDisplayValue !== "EPS DUE") {
                corpIndex++;
                continue;
            }

            // EPS DUE and XDIV events should only be visible for current and future dates.
            // This logic will prevent any prior events from being displayed due to mistakes in reporting.
            if(corpDate < endDate && (corporateEvents[corpIndex].CorpActionDisplayValue === "XDIV" || corporateEvents[corpIndex].CorpActionDisplayValue === "EPS DUE")) {
                corpIndex++;
                continue;
            }
            
            while (corpDate >= graphDate) {
                switch (corporateEvents[corpIndex].CorpActionTypeEnum) {
                    case CorpEventsType.MajorArticles.value:
                        if (!corporateEventsSettings.MajorArticles) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) {
                                break;
                            }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);;
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = corporateEvents[corpIndex].CorpActionDisplayValue;

                            corporateEvents[corpIndex].toolTip = `${MajorArticlesTranslateHelper[corporateEvents[corpIndex].CorpActionDisplayValue] || corporateEvents[corpIndex].CorpActionDisplayValue} ${corporateEvents[corpIndex].CorpActionDate.toLocaleDateString("en-US")}`;
                        }
                        break;
                    case CorpEventsType.RepurchaseAnnouncement.value:
                        if (!corporateEventsSettings.RepurchaseAnnouncement) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) {
                                break;
                            }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = `BUYBK ${corporateEvents[corpIndex].CorpActionDisplayValue}`;
                            corporateEvents[corpIndex].toolTip = CorpActionTranslateHelper[CorporateEventsConst.REPURCHASEANNOUNCEMENT];
                        }
                        break;
                    case CorpEventsType.Dividend.value:
                        if (!corporateEventsSettings.Dividend) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = `DIV ${corporateEvents[corpIndex].CorpActionDisplayValue}`;
                            corporateEvents[corpIndex].toolTip = CorpActionTranslateHelper[CorporateEventsConst.DIVIDEND];
                        }
                        break;
                    case CorpEventsType.AddedToindex.value:
                        if (!corporateEventsSettings.AddedToIndex) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = "Added to S&P 500";
                            corporateEvents[corpIndex].toolTip = CorpActionTranslateHelper.ADDED_TO_SP_500;
                        }
                        break;
                    case CorpEventsType.XDate.value:
                        if (!corporateEventsSettings.Dividend) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = corporateEvents[corpIndex].CorpActionDisplayValue;
                            const date = moment(corporateEvents[corpIndex].CorpActionDate).format("MM/DD/YYYY");
                            corporateEvents[corpIndex].toolTip = corporateEvents[corpIndex].CorpActionDisplayValue;
                            corporateEvents[corpIndex].toolTip2 = date;
                        }
                        break;
                    case CorpEventsType.EDate.value:
                        if (!corporateEventsSettings.Earnings) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = corporateEvents[corpIndex].CorpActionDisplayValue;
                            const date = moment(corporateEvents[corpIndex].CorpActionDate).format("MM/DD/YYYY");
                            corporateEvents[corpIndex].toolTip = corporateEvents[corpIndex].CorpActionDisplayValue;
                            corporateEvents[corpIndex].toolTip2 = date;
                        }
                        break;
                    case CorpEventsType.Announcement.value:
                        if (!corporateEventsSettings.Announcement) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else if (corporateEvents[corpIndex].Price > 0) {
                            const dispValue = corporateEvents[corpIndex].Price > 999 ? ExtremeDataValue.abbreviateFinancialValue(corporateEvents[corpIndex].Price, 1) : ExtremeDataValue.showPrice(corporateEvents[corpIndex].Price);
                            corporateEvents[corpIndex].currency = "$";
                            corporateEvents[corpIndex].toolTip = corporateEvents[corpIndex].trDisplay
                            if (SymbolInfo.DefaultCurrency !== null) {
                                corporateEvents[corpIndex].currency = getCurrecyCode(SymbolInfo.DefaultCurrency);
                            }
                            if ((corporateEvents[corpIndex].currency === "USD" || corporateEvents[corpIndex].currency === undefined)) { corporateEvents[corpIndex].currency = "$"; }
                            if (corporateEvents[corpIndex].CorpActionDisplayValue === "LPE") { corporateEvents[corpIndex].currency = " "; }
                            // corporateEvents[corpIndex].currency = getCurrencyCode(corporateEvents[corpIndex].currency); //optPriceChart
                            corporateEvents[corpIndex].trDisplay = `${corporateEvents[corpIndex].CorpActionDisplayValue} ${corporateEvents[corpIndex].currency}${dispValue}`;

                            if (corporateEvents[corpIndex].CorpActionDisplayValue === "IPO" && fIndex < flength && timeLine[fIndex].graphData) {
                                let change = HiLowPoints.allPoints[fIndex].graphData.Close / corporateEvents[corpIndex].Price;
                                change -= 1;
                                corporateEvents[corpIndex].changeValue = 100 * change;
                            }
                            if (corporateEvents[corpIndex].CorpActionDisplayValue === "LPE") {
                                const currentDate = new Date();
                                corporateEvents[corpIndex].trDisplay = `${corporateEvents[corpIndex].CorpActionDisplayValue} for ${ExtremeDataValue.ReplaceNumberWithCommas(ExtremeDataValue.decimalFormat(corporateEvents[corpIndex].Price, 0))}`;
                                const price = corporateEvents[corpIndex].Price ? ExtremeDataValue.ReplaceNumberWithCommas(ExtremeDataValue.decimalFormat(corporateEvents[corpIndex].Price, 0)) : 0;
                                if(corporateEvents[corpIndex].Price > 0){
                                    if (corporateEvents[corpIndex].CorpActionDate && corporateEvents[corpIndex].CorpActionDate > currentDate) {
                                        corporateEvents[corpIndex].toolTip = LocalizationStore.getTranslatedData("LockUpWillExpireWithPrice", "Lock-up Period will Expire \n for {0} shares", price.toLocaleString('en'));
                                    } else {
                                        corporateEvents[corpIndex].toolTip = LocalizationStore.getTranslatedData("LockUpExpiredWithPrice", "Lock-up Period Expired \n for {0} shares", price.toLocaleString('en'));
                                    }
                                }else{
                                    if (corporateEvents[corpIndex].CorpActionDate && corporateEvents[corpIndex].CorpActionDate > currentDate) {
                                        corporateEvents[corpIndex].toolTip = CorpActionTranslateHelper.LOOK_UP_PERIOD_WILL_EXPIRE;
                                      } else {
                                        corporateEvents[corpIndex].toolTip = CorpActionTranslateHelper.LOOK_UP_PERIOD_EXPIRED;
                                      }
                                }
                                
                            }
                            else {
                                corporateEvents[corpIndex].toolTip = LocalizationStore.getTranslatedData(`CorpActionDisplay_${corporateEvents[corpIndex].CorpActionDisplayValue}`, GetAnnouncementFullDescription(corporateEvents[corpIndex]));
                                if (corporateEvents[corpIndex].Price > 0) {
                                    corporateEvents[corpIndex].toolTip += ` ${corporateEvents[corpIndex].currency} ${dispValue}`;
                                  }
                            }
                        }
                        break;
                    case CorpEventsType.Ceo.value:
                        corporateEvents[corpIndex].toolTip = CorpActionTranslateHelper.NEW_CEO;
                    case CorpEventsType.CoCeo.value:
                        if (!corporateEventsSettings.Ceo) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = "New CEO";
                            if (!corporateEvents[corpIndex].toolTip) {
                                corporateEvents[corpIndex].toolTip = CorpActionTranslateHelper.NEW_CO_CEO;
                            }
                        }
                        break;
                    case CorpEventsType.Earnings.value:
                        if (!corporateEventsSettings.Earnings) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = corporateEvents[corpIndex].CorpActionDisplayValue;
                            corporateEvents[corpIndex].toolTip = CorpActionTranslateHelper[CorporateEventsConst.EARNINGS];
                        }
                        break;
                    case CorpEventsType.InsiderBuy.value:
                        if (!corporateEventsSettings.InsiderBuy) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = corporateEvents[corpIndex].CorpActionDisplayValue;
                        }
                        break;
                    case CorpEventsType.InsiderSell.value:
                        if (!corporateEventsSettings.InsiderSell) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = corporateEvents[corpIndex].CorpActionDisplayValue;
                        }
                        break;
                    case CorpEventsType.SecurityAnalystMeeting.value:
                        if (!corporateEventsSettings.SecurityAnalystMeeting) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = corporateEvents[corpIndex].CorpActionDisplayValue;
                            corporateEvents[corpIndex].toolTip = corporateEvents[corpIndex].CorpActionDisplayValue;
                        }
                        break;
                    case CorpEventsType.Split.value:
                        if (!corporateEventsSettings.Split) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            const floatValue = corporateEvents[corpIndex].Price;
                            if (!isNaN(floatValue)) {
                                corporateEvents[corpIndex].splitDisplay = SplitUtil.DecToString(floatValue);
                                corporateEvents[corpIndex].trDisplay = corporateEvents[corpIndex].splitDisplay;
                                corporateEvents[corpIndex].toolTip = CorpActionTranslateHelper.SPLIT;
                            }
                        }
                        break;
                    case CorpEventsType.ExchangeListing.value:
                        if (!corporateEventsSettings.ExchangeListing) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = corporateEvents[corpIndex].CorpActionDisplayValue;
                        }
                        break;
                    case CorpEventsType.CorporateRepurchase.value:
                        if (!corporateEventsSettings.CorporateRepurchase) {
                            corpIndex++;
                            if (corpIndex >= corporateEvents.length) { break; }
                            corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                            continue;
                        }
                        else {
                            corporateEvents[corpIndex].trDisplay = `BUYBK ${corporateEvents[corpIndex].CorpActionDisplayValue}`;
                            corporateEvents[corpIndex].toolTip = CorpActionTranslateHelper[CorporateEventsConst.CORPORATEREPURCHASE];
                        }
                        break;
                    default:
                        corpIndex++;
                        if (corpIndex >= corporateEvents.length) { break; }
                        corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
                        continue;
                }
                if (corporateEvents[corpIndex]) {
                    corporateEvents[corpIndex].Xlocation = xGraph;
                    corporateEvents[corpIndex].Ylocation = 0;
                    corporateEvents[corpIndex].Ycollapsed = chartHeight;
                    corporateEvents[corpIndex].index = corpIndex;

                    const fdIndex = getFromGraphDate(corporateEvents[corpIndex].GraphDate, HiLowPoints.allPoints, endDate, periodicity);
                    if (HiLowPoints.allPoints[fdIndex] && HiLowPoints.allPoints[fdIndex].corpIndex) {
                        HiLowPoints.allPoints[fdIndex].corpIndex.push(dataIndex);// The Output corpEventsData index
                    }
                    corpEventsData.push(corporateEvents[corpIndex]);
                }
                dataIndex++; // The Output corpEventsData index
                corpIndex++;
                if (corpIndex >= corporateEvents.length) { break; }
                corpDate = getCorpDate(corporateEvents[corpIndex].CorpActionDisplayValue, corporateEvents[corpIndex].GraphDate, corporateEvents[corpIndex].corpActionDate, timeLine, endDate, periodicity);
            }
            if (corpIndex >= corporateEvents.length) { break; }
        }

        if (corpDate < graphDate && timeLine[index - 1].xAxis > 0) {
            while (xGraph > 0 && timeLine[index].Date > corpDate) { index++; xGraph -= nodeWidth; }
            index--;
            let showEvet = true;
            switch (corporateEvents[corpIndex].CorpActionTypeEnum) {
                case CorpEventsType.MajorArticles.value:
                    if (!corporateEventsSettings.MajorArticles) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.RepurchaseAnnouncement.value:
                    if (!corporateEventsSettings.RepurchaseAnnouncement) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.Dividend.value:
                    if (!corporateEventsSettings.Dividend) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.AddedToindex.value:
                    if (!corporateEventsSettings.AddedToIndex) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.Announcement.value:
                    if (!corporateEventsSettings.Announcement) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.Ceo.value:
                case CorpEventsType.CoCeo.value:
                    if (!corporateEventsSettings.Ceo) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.Earnings.value:
                    if (!corporateEventsSettings.Earnings) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.InsiderBuy.value:
                    if (!corporateEventsSettings.InsiderBuy) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.InsiderSell.value:
                    if (!corporateEventsSettings.InsiderSell) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.SecurityAnalystMeeting.value:
                    if (!corporateEventsSettings.SecurityAnalystMeeting) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.Split.value:
                    if (!corporateEventsSettings.Split) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.ExchangeListing.value:
                    if (!corporateEventsSettings.ExchangeListing) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.CorporateRepurchase.value:
                    if (!corporateEventsSettings.CorporateRepurchase) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.XDate.value:
                    if (!corporateEventsSettings.Dividend) {
                        showEvet = false;
                    }
                    break;
                case CorpEventsType.EDate.value:
                    if (!corporateEventsSettings.Earnings) {
                        showEvet = false;
                    }
                    break;
                default:
                    break;
            }

            if (xGraph > 0 && corporateEvents[corpIndex] && showEvet) {
                corporateEvents[corpIndex].Xlocation = xGraph;
                corporateEvents[corpIndex].Ylocation = 0;
                corporateEvents[corpIndex].Ycollapsed = chartHeight;
                corporateEvents[corpIndex].index = corpIndex;
                corpEventsData.push(corporateEvents[corpIndex]);
            }
        }
        corpEventsData = arrangeCorporateEvents([], corpEventsData, chartHeight, scale);
        return corpEventsData;
    }
    catch (error) {
        console.log(`Error occurs in CorpEventsSaga.js, processCorporateEvents ${error}`);
    }
}

function getFromGraphDate(graphDate, allPoints, endDate, periodicity) {
    const date = DatagraphHelper.NDateNode(graphDate, false, endDate, periodicity);

    const length = Object.keys(allPoints).length;
    for (let i = 0; i < length; i++) {
        if (allPoints[i] && allPoints[i].Date.getTime() === date.getTime()) {
            return i;
        }
    }
    return -1;
}
function getCorpDate(corpActionDisplayValue, graphDate, corpActionDate, timeLine, endDate, periodicity, isAbsolute = false) {
    try {
        let corpDate = graphDate;
        if (corpActionDisplayValue === "LPE" || corpActionDisplayValue === "XDIV" || corpActionDisplayValue === "EPS DUE") {
            if (corpActionDate === endDate) {
                corpDate = corpActionDate;
            } else {
                corpDate = NDateNodeLPE(graphDate, timeLine);
            }
        } else {
            corpDate = DatagraphHelper.NDateNode(graphDate, isAbsolute, endDate, periodicity);
        }
        return corpDate;
    }
    catch (error) {
        console.log(`Error occurs in CorpEventsSaga.js, getCorpDate ${error}`);
    }
}
function NDateNodeLPE(nDate, timeLine) {
    let returnDate = nDate;
    if (timeLine && timeLine[0].Date >= nDate) {
        if (timeLine.filter((t) => t.Date.getTime() === nDate.getTime()).length > 0) {
            returnDate = nDate;
        } else {
            const returnValue = timeLine.filter((t) => t.Date < nDate);
            if (returnValue[0]) {
                returnDate = returnValue[0].Date;
            }
        }
    }
    return returnDate;
}

function intersectRect(r1, r2) {
    try {
        const intersect = !(r2.left > r1.right ||
            r2.right < r1.left ||
            r2.top > r1.bottom ||
            r2.bottom < r1.top);
        return intersect;
    }
    catch (error) {
        console.log(`Error occurs in CorpEventsSaga.js, intersectRect ${error}`);
    }
}
function GetLastPoints(yPosition, xPosition, lastFewPositioning) {
    try {
        const rect1 = { left: xPosition, top: yPosition - 26, right: xPosition + 50, bottom: yPosition + 4 };
        const length = lastFewPositioning.length;
        let j = 0;

        for (; j < length; j++) {
            const point = lastFewPositioning[j];
            const rect2 = { left: point.x, top: point.y - 26, right: point.x + 50, bottom: point.y + 4 };

            if (intersectRect(rect1, rect2)) {
                const y_overlap = Math.max(0, Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top));
                yPosition -= y_overlap + 5;
                break;
            }
        }

        if (j >= 0 && j < length) { return GetLastPoints(yPosition, xPosition, Array.from(lastFewPositioning)); }

        return yPosition;
    }
    catch (error) {
        console.log(`Error occurs in CorpEventsSaga.js, GetLastPoints ${error}`);
    }
}
function arrangeCorporateEvents(lastFewPositioning, corpeventsData, chartHeight, scale) {
    try {
        if (corpeventsData) {
            const corpEvents = corpeventsData;
            const length = corpeventsData.length;
            for (let index = 0; index < length; index++) {
                let yPosition = chartHeight - 25;
                if (corpEvents[index].CorpActionDisplayValue === "IPO") {
                    yPosition = scale.ComputeY(corpEvents[index].Price);
                    lastFewPositioning.push({ x: corpEvents[index].Xlocation, y: yPosition });
                    corpEvents[index].Ylocation = yPosition;
                } else {
                    const newYAxis = GetLastPoints(yPosition, corpEvents[index].Xlocation, lastFewPositioning);
                    lastFewPositioning.push({ x: corpEvents[index].Xlocation, y: newYAxis });
                    corpEvents[index].Ylocation = newYAxis;
                    corpEvents[index].Ycollapsed = chartHeight;
                }
            }
            return corpEvents;
        } else {
            return [];
        }
    }
    catch (e) {
        console.error(e);
    }
}

function CalculateEpsPercentageChg(prevEPS, currEPS, NegPosOn = true, SymbolInfo) {
    if (prevEPS !== 0) {
        const adjustedPrevEPS = NegPosOn ? Math.abs(prevEPS) : prevEPS;

        if (!NegPosOn && (prevEPS < 0 || currEPS < 0)) {
            return null;
        }
        let epsScaleFactor = 1;
        let epsMultiplier = 1;

        if (SymbolInfo.DefaultCurrency !== null) {
            const currency = getCurrecyCode(SymbolInfo.DefaultCurrency);
            if (currency === "KRW") //KRW Currency
            {
                epsScaleFactor = .001;
            }
            if (currency === "GBP"
                || currency === "ZAR") {
                epsMultiplier = 100;
            }
        }

        const currVal = (currEPS * epsScaleFactor * epsMultiplier).toFixed(2);
        const prevVal = (prevEPS * epsScaleFactor * epsMultiplier).toFixed(2);
        const adjVal = (adjustedPrevEPS * epsScaleFactor * epsMultiplier).toFixed(2);

        return (currVal - prevVal) / adjVal * 100.00;
    }
    return null;
}
function DisplayEpsPercentageChg(calculatedPctChange, isPerRequired = true) {
    const perstring = isPerRequired ? "%" : " ";
    const p1 = Math.round(calculatedPctChange);
    if (p1 > 0 && isFinite(p1)) {
        return `+${p1}${perstring}`;
    }
    else if (!isFinite(p1)) {
        return " ";
    }
    else {
        return p1 + perstring;
    }
}

function combineCorporateEventswithEPS(timeLine, corpEventsData, epsData, allPoints, periodicity, nodeWidth, endDate, SymbolInfo) {
    try {

        if (!epsData || !allPoints || !allPoints[0] || epsData.length < 1) { return corpEventsData; }

        let corpIndex = 4;
        for (; corpIndex < epsData.length; corpIndex++) {
            const pctVal = CalculateEpsPercentageChg(epsData[corpIndex].Price, epsData[corpIndex - 4].Price, true, SymbolInfo);
            Object.assign(epsData[corpIndex - 4], {
                CorpActionDisplayValue: DisplayEpsPercentageChg(pctVal, true),
                GraphDate: DatagraphHelper.NDateNode(epsData[corpIndex - 4].GraphDate, false, endDate, periodicity),
                Uptick: pctVal >= 0
            });
        }

        corpIndex = 0;
        let cIndex = corpEventsData.length;
        const length = timeLine.length;
        const flength = allPoints.length;
        let fIndex = 0;
        let index = 0;

        // Stocks reporting before the fiscal date are not plotted on correct report dates on Daily periodicity Ex: COST
        let gDate = epsData[corpIndex].GraphDate?.getFullYear() > 1800 ? epsData[corpIndex].GraphDate : epsData[corpIndex].CorpActionDate;

        while (fIndex < flength && allPoints[fIndex]?.Date > gDate) {
            fIndex++;
        }

        if (fIndex >= flength || fIndex < 0) {
            return corpEventsData;
        }

        //const width = allPoints[fIndex].xAxis;
        while (index < length && timeLine[index]?.Date > gDate) {
            index++;
        }

        if (periodicity === GraphType.Annual) {
            while (index < length && timeLine[index]?.Date.getFullYear() > gDate.getFullYear()) index++;
        } else {
            while (index < length && timeLine[index]?.Date > gDate) index++;
        }

        fIndex = index;
        //let prGraphDate = timeLine[index].Date;
        while (index < length && timeLine[index] && corpIndex < epsData.length) {

            gDate = epsData[corpIndex].GraphDate?.getFullYear() > 1800 ? epsData[corpIndex].GraphDate : epsData[corpIndex].CorpActionDate;
            if (gDate === undefined) { break; }

            let graphDate = timeLine[index].Date;

            // Incorrect/overwriting of quarter-end dates(reported date). 
            // If the quarter-end dates are out of order, the following logic will skip the most recent reported event.
            if (epsData[corpIndex + 1] && gDate < epsData[corpIndex + 1].GraphDate) {
                console.log(`%c"Skipped EPS flag on" \n ${gDate}`, 'background-color:#FFB347;font-size:12px;font-family:cursive;')
                corpIndex++; 
                cIndex++;
                continue;
            }

            // Latest EPS flag missing on weekly, monthly, quarterly, and annual periodicity until end of the week/month/quarter/year.
            if (periodicity !== GraphType.Daily) {
                while (graphDate && gDate > graphDate) {
                    index--;
                    graphDate = timeLine[index]?.Date;
                }
            }

            if (isDateOk(periodicity, gDate, endDate, graphDate)) {
                if (epsData[corpIndex].Display === 1 && epsData[corpIndex].CorpActionDisplayValue !== " ") {
                    Object.assign(epsData[corpIndex], {
                        Xlocation: timeLine[index].xAxis,//width - xLoc * nodeWidth, 
                        Ylocation: 0,
                        index: cIndex
                    });

                    if (epsData[corpIndex].Display === 1) {
                        epsData[corpIndex].trDisplay = `EPS ${epsData[corpIndex].CorpActionDisplayValue}`;
                    }

                    corpEventsData.push(epsData[corpIndex]);
                }
                corpIndex++; cIndex++;
            }
            index++;
        }

        corpEventsData = corpEventsData.filter((t) => t.Xlocation && t.Xlocation !== null && t.Xlocation > 0);

        corpEventsData.sort((a, b) => (a.Xlocation < b.Xlocation) ? 1 : ((b.Xlocation < a.Xlocation) ? -1 : 0));
        //cIndex = corpEventsData.length;
        //corpIndex = 0;
        //for (let index = 0; index < flength && allPoints[index]; index++) {
        //    if (!allPoints[index] || corpIndex >= cIndex) { break; }
        //    const graphDate = allPoints[index].Date;
        //    let corpDate;
        //    allPoints[index].corpIndex = [];
        //    if (corpEventsData[corpIndex].GraphDate instanceof Date) { corpDate = corpEventsData[corpIndex].GraphDate; }
        //    else { corpDate = new Date(parseInt(corpEventsData[corpIndex].GraphDate.substr(6))); }
        //    while (corpDate >= graphDate) {
        //        if (corpDate >= allPoints[flength - 1].Date && corpDate <= allPoints[0].Date) {
        //            if (allPoints[index] && allPoints[index].corpIndex) {
        //                allPoints[index].corpIndex.push(corpIndex);
        //            }
        //        }
        //        //corpEventsData[corpIndex].Xlocation = allPoints[index].xAxis;
        //        corpIndex++;
        //        if (corpIndex >= cIndex) { break; }
        //        if (corpEventsData[corpIndex].GraphDate instanceof Date) { corpDate = corpEventsData[corpIndex].GraphDate; }
        //        else { corpDate = new Date(parseInt(corpEventsData[corpIndex].GraphDate.substr(6))); }
        //    }
        //}
        return corpEventsData;
    }
    catch (error) {
        console.log(`Error occurs in CorpEventsSaga.js, combineCorporateEventswithEPS ${error}`);
    }
}

// Intermediate or completely missing EPS flags for many symbols on weekly, monthly, and quarterly periodicities.
function isDateOk(periodicity, gDate, endDate, graphDate) {
    let dateOk = false
    if (periodicity === GraphType.Weekly || periodicity === GraphType.Monthly || periodicity === GraphType.Quarterly) {
        const endOfPeriod = DatagraphHelper.NDateNode(gDate, true, endDate, periodicity);
        dateOk = endOfPeriod.getTime() === graphDate.getTime();
    }
    else if (periodicity === GraphType.Annual) {
        dateOk = gDate.getFullYear() === graphDate.getFullYear();
    }
    else {
        dateOk = gDate.getTime() === graphDate.getTime();
    }
    return dateOk;
}

function getEpsEventData(epsData, SymbolInfo, chartHeight) {
    const EpsEventData = [];
    if (!epsData) {
        return EpsEventData;
    }
    epsData.forEach((item) => {
        const reportDt = DateHelper.getPSTFromLong(item.reportDt);
        const fiscalDt = DateHelper.getPSTFromLong(item.fiscalDt);

        if (item.EpsType !== undefined && item.EpsType > 0) {
            let reportDate = reportDt.getFullYear ? reportDt : new Date(parseInt(reportDt.substr(6)));
            const actionDate = fiscalDt.getFullYear ? fiscalDt : new Date(parseInt(fiscalDt.substr(6)));
            if (reportDate.getFullYear() < 1970) {
                reportDate = actionDate;
            }
            let display = item.display;
            let epsValue = item.eps;
            if (SymbolInfo.SymTypeEnum === SymbolType.INTERNATIONALSTOCK) {
                const epsqtrlyblckavailable = (item.eps && (item.QtrFlg === item.QtrFlg)) ? true : false;
                epsValue = item.epsQuaterlyBlockValue;
                if (!epsqtrlyblckavailable) {
                    display = 0;
                }
            }

            EpsEventData.push({
                CorpActionDate: actionDate,
                CorpActionDisplayValue: " ",
                CorpActionType: "Earnings",
                CorpActionTypeEnum: (CorpEventsType.Earnings.value),
                FullDescription: "Reported Earnings",
                GraphDate: reportDate,
                Price: epsValue,
                Uptick: true,
                Xlocation: 0,
                Ylocation: 0,
                Ycollapsed: chartHeight,
                Display: display,
                index: 0
            });
        }
    });
    return EpsEventData;
}

function* processCorpEvents(action) {
    try {
        //if(action?.isStreamingProc){
        //    return;
        //}
        const { HiLowPoints, scale, endDate } = yield select(priceChartReducerselect);
        const { periodicity, pricePanelData, viewsSettings, majorPeriodicity, SymbolInfo, nodeWidth, isHistoric } = yield select(getDatagraphStates)
        
        let corpEvents = [];
        const corporateEventsSettings = viewsSettings?.CorporateEventsSettings?.[majorPeriodicity];

        if (HiLowPoints.allPoints.length > 0 && corporateEventsSettings) {
            const TimeLine = yield select(getTimeLine);
            const chartHeight = PriceMenuHelper.getHeight();
            const length = TimeLine.length;
            const flength = HiLowPoints.allPoints.length;
            let tl = 0;
            for (; tl < length && TimeLine[tl]; tl++) {
                if (TimeLine[tl].Date.getTime() === HiLowPoints.allPoints[1].Date.getTime()) {
                    break;
                }
            }
            let hl = 0;
            let al = tl - 2;
            let bl = al;
            for (; al < length && hl < flength && TimeLine[al] && HiLowPoints.allPoints[hl]; al++, hl++) {
                TimeLine[al].xAxis = HiLowPoints.allPoints[hl].xAxis;
            }
            //Fill The front of the TimeLine
            for (; bl >= 0; bl--) {
                TimeLine[bl].xAxis = TimeLine[bl + 1].xAxis + 4;
            }
            //Fill The back of the TimeLine
            for (; tl < length && TimeLine[tl]; tl++) {
                TimeLine[tl].xAxis = TimeLine[tl - 1].xAxis - 4;
            }
            
            if (pricePanelData.CorpEventsData && pricePanelData.CorpEventsData.EquityEvents.length > 0) {
                corpEvents = processCorporateEvents(pricePanelData.CorpEventsData.EquityEvents, corporateEventsSettings, HiLowPoints, SymbolInfo, TimeLine, periodicity, scale, endDate, nodeWidth, chartHeight, isHistoric);
            }
            if (corporateEventsSettings.Earnings && pricePanelData.EPSSnapshotData.Results && pricePanelData.EPSSnapshotData.Results.length > 0) {
                const epsEventData = getEpsEventData(pricePanelData.EPSSnapshotData.Results, SymbolInfo, chartHeight)
                corpEvents = combineCorporateEventswithEPS(TimeLine, corpEvents, epsEventData, HiLowPoints.allPoints, periodicity, nodeWidth, endDate, SymbolInfo);
                corpEvents = processCorporateEvents(corpEvents, corporateEventsSettings, HiLowPoints, SymbolInfo, TimeLine, periodicity, scale, endDate, nodeWidth, chartHeight, isHistoric);
            }
            yield put({
                type: ActionTypes.CORP_EVENTS_DATA_READY,
                corpEvents,
                isCorpEventVisible: corporateEventsSettings.IsVisible,
                corporateEventsSettings
            });
        }
        else if(corporateEventsSettings && pricePanelData.CorpEventsData && pricePanelData.CorpEventsData.EquityEvents.length === 0){
            yield put({
                type: ActionTypes.CORP_EVENTS_DATA_READY,
                corpEvents: corpEvents,
                isCorpEventVisible: corporateEventsSettings.IsVisible,
                corporateEventsSettings
            });
        }
        else {
            yield put({
                type: ActionTypes.CLEAR_CORP_EVENTS_DATA,
                isCorpEventVisible: false
            });
        }
    }
    catch (error) {
        console.log(`Error occurs in CorpEventsSaga.js, processCorpEvents ${error}`);
    }
}

const periodicityArray = [GraphType.Daily, GraphType.Weekly, GraphType.Monthly, GraphType.Quarterly, GraphType.Annual]
function* openCorpEventEditDialog() {
    try {
        const { pricePanelData } = yield select(priceChartReducerselect);
        const SymTypeEnum = pricePanelData.SymbolInfo.SymTypeEnum
        const isETForCEF = SymbolType.ETF === SymTypeEnum ||
            SymbolType.FundClosedEnd === SymTypeEnum;
        const helper = isETForCEF ? EventsDialogTranslateHelper : CorpEventsDialogTranslateHelper;
        const instSettings = PriceMenuHelper.getInstSettings();
        const selectedPeriodicity = PriceMenuHelper.getMajorPeriodicity();
        const settings = instSettings.CorporateEventsSettings;
        const corpEventSettings = periodicityArray.map((periodicity) => ({
            periodicity,
            header: LineDialogPeriodicityTranslateHelper[periodicity],
            subItems: Object.keys(helper).map((subItem) => ({ property: subItem, value: settings[periodicity][subItem], text: helper[subItem] }))
        }))
        yield put({
            type: ActionTypes.UPDATE_CORP_EVENT_DIALOG_STATES,
            isCorpEventDialogOpen: true,
            corpEventSettings,
            selectedPeriodicity
        })
    }
    catch (error) {
        console.error("Error occured in openCorpEventEditDialog of CorpEventsSaga.js", error);

    }
}
function* restoreCorpEventSetting() {
    try {
        const state = yield select(getCorpEventStates);
        const corpEventSettings = state.corpEventSettings.map((item) => {
            if (item.periodicity === state.selectedPeriodicity) {
                item.subItems.forEach((subItem) => (subItem.value = state.defaultCorpEventSettings[item.periodicity][subItem.property]));
            }
            return item;
        });
        yield put({
            type: ActionTypes.UPDATE_CORP_EVENT_DIALOG_STATES,
            isCorpEventDialogOpen: true,
            corpEventSettings,
            selectedPeriodicity: state.selectedPeriodicity
        });
        yield fork(processCorpEvents);
    }
    catch (error) {
        console.error("Error occured in restoreCorpEventSetting of CorpEventsSaga.js", error);
    }
}
function* saveCorpEventEditDialog() {
    try {
        const state = yield select(getCorpEventStates)
        const instSettings = PriceMenuHelper.getInstSettings();
        const settings = instSettings.CorporateEventsSettings;
        state.corpEventSettings.forEach((item) => {
            item.subItems.forEach((subItem) => {
                settings[item.periodicity][subItem.property] = subItem.value;

                // Check if property is CorporateRepurchase and value is true
                if (subItem.property === CorpEventsDialogConstants.CorporateRepurchase && subItem.value) {
                    settings[item.periodicity].RepurchaseAnnouncement = true;
                }
                if (subItem.property === CorpEventsDialogConstants.Ceo && subItem.value) {
                    settings[item.periodicity].CoCeo = true;
                }
            });
        });
        yield put(cancelCorpEventDialogSetting());
        yield fork(processCorpEvents);
        SettingsStore.saveSettings();
    }
    catch (error) {
        console.error("Error occured in saveCorpEventEditDialog of CorpEventsSaga.js", error);
    }
}
/*************Watchers******************** */
export function* watchPrepareCorpEvents() {
    yield takeLatest(ActionTypes.PRICE_DATA_READY, processCorpEvents)
}
export function* watchOpenCorpEventEditDialog() {
    yield takeLatest(ActionTypes.OPEN_CORP_EVENT_DIALOG, openCorpEventEditDialog)
}
export function* watchSaveCorpEventEditDialog() {
    yield takeLatest(ActionTypes.SAVE_CORP_EVENT_SETTING, saveCorpEventEditDialog)
}
export function* watchRestoreCorpEventSetting() {
    yield takeLatest(ActionTypes.RESTORE_CORP_EVENT_SETTING, restoreCorpEventSetting)
}