import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PriceChartConstants } from "../../Constants/PriceChartConstants";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";


const { ActionTypes } = PriceChartConstants;

const SwingSize = () => {
  const dispatch = useDispatch();
  const [zzMenuOptions, setZzMenuOptions] = useState([]);
  const viewsSettings = useSelector(
    (state) =>
      state.RelatedInfoPanelReducers.RiPanelAI.aiSettings
  );

  useEffect(() => {
    if (viewsSettings) {
      const zzSwingSizeSettings = [
        {
          periodicity: "Daily",
          headerKey: "tp_per_dly",
          quantityKey: "",
          quantityLabel: "%",
          activeItem: viewsSettings?.zigzagIndicator?.swingSize.Daily,
          valueOptions: [
            { value: 5, displayValue: "5" },
            { value: 10, displayValue: "10" },
            { value: 20, displayValue: "20" },
          ],
        },
        {
          periodicity: "Weekly",
          headerKey: "tp_per_wkly",
          quantityKey: "",
          quantityLabel: "%",
          activeItem: viewsSettings?.zigzagIndicator?.swingSize.Weekly,
          valueOptions: [
            { value: 10, displayValue: "10" },
            { value: 15, displayValue: "15" },
            { value: 20, displayValue: "20" },
          ],
        },
        {
          periodicity: "Monthly",
          headerKey: "tp_per_mnly",
          quantityKey: "",
          quantityLabel: "%",
          activeItem: viewsSettings?.zigzagIndicator?.swingSize.Monthly,
          valueOptions: [
            { value: 15, displayValue: "15" },
            { value: 20, displayValue: "20" },
            { value: 30, displayValue: "30" },
          ],
        },
      ];
      setZzMenuOptions(zzSwingSizeSettings);

    }
  }, [viewsSettings]);

  const handleItemClick = (periodicity, value) => {
    dispatch({
      type: ActionTypes.UPDATE_ZZ_INDICATOR_SWING_SIZE,
      item: { periodicity, value },
    });
  };

  const handleRestoreDefaults = () => {

    dispatch({
      type: ActionTypes.UPDATE_ZZ_INDICATOR_SWING_SIZE,
      item: { periodicity: 'All', value: 0 }
    });
  };

  const renderMenu = (options) => {
    const {
      periodicity,
      headerKey,
      quantityKey,
      quantityLabel,
      valueOptions,
      activeItem,
    } = options;

    return (
      <div className="fundamental-Lines-Menu" key={periodicity}>
        <div className="header">
          {LocalizationStore.getTranslatedData(headerKey, periodicity)}
        </div>
        <div className="itemsDiv">
          <ul className="fundamental-Lines-Options">
            {valueOptions.map((item, index) => (
              <li
                key={index}
                onClick={() => handleItemClick(periodicity, item.value)}
                className={activeItem === item.value ? 'menuItem active' : ''}
              >
                {item.displayValue}
              </li>
            ))}
          </ul>
        </div>
        <div className="quantity">
          {LocalizationStore.getTranslatedData(quantityKey, quantityLabel)}
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      {zzMenuOptions.map(renderMenu)}

      <div className="restore-defaults" onClick={handleRestoreDefaults}>
        {LocalizationStore.getTranslatedData("resdef", "Restore Defaults")}
      </div>
      <div className="clearfix"></div>
    </div>
  );
};



export default SwingSize;
