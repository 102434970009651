
import BaseServiceApi from 'BaseServiceApi';
import GraphComponent from "GraphComponent";
import GraphType from "GraphType";
import IndexLineSettings from '../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/IndexLineSettings.js';
import PriceMenuHelper from '../PriceMenu/PriceMenuHelper.js';
import RelativeStrenghtLineSettings from '../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/RelativeStrengthLineSettings.js';
import SettingsStore from "SettingsStore";
import SmartViewType from "../../../Constants/SmartViewType.js";
import SymbolType from '../../../Constants/SymbolType.js';
import UserInfoUtil from 'UserInfoUtil';
import { addMALine, redrawExternalData, switchFundametalLineLabel, updateFundamentalLine, updateFundamentalLineSelectPerShare, updateFundamentalLineSetting, updateSTPVisibilty } from '../../../Actions/PricePanelActions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { clearExternalData, getCurrentListData } from '../../../Actions/ExternalDataUploaderActions';
import { getDatagraphStates, getFundamentalLineStates, priceChartReducerselect, timeLineSelect } from '../../../Reducers/NavDataGraph/TabDataGraph/selectors'
import { IndustryGroupsTranslateHelper, TranslateHelper } from '../../../Utils/TranslateHelper';
import { PriceChartConst, PriceChartConstants } from "../../../Constants/PriceChartConstants";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import { extend } from 'underscore';
import { riPanelInfoState } from '../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors';


const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
const { ActionTypes } = PriceChartConstants;
extend(ActionTypes, RiPanelConstants.ActionTypes);

function IsIndexSymbolHasEarnData(symbol) {
    if (!symbol) {
        return false;
    }
    return symbol === "0S&P5" || symbol === "0DJI" || symbol === "0DJT" || symbol === "0DJU" || symbol === "0DJIA" || symbol === "0DJUA" || symbol === "0DJTA";
}
function getZZMenu(viewsSettings) {
    try {
        const actionTexts = [{ text: "Edit", resKey: 'edit' }];
        return [{ header: "Zigzag Indicator", resKey: 'ch_epshvr', isActive: viewsSettings.isVisible, isDefault: true, graphComponent: GraphComponent.ZZIndicator, action: actionTexts, hasDivider: true },
            viewsSettings.swingSize
        ];
    }
    catch (error) {
        console.log(`Error occurs in PriceMenuSaga.js, getZZMenu ${error}`);
    }
}

function getSubMenu(viewsSettings, majorPeriodicity, isIndexView) {
    try {
        const actionTexts = [{ text: "Edit", resKey: 'edit' }];

        if (isIndexView) {
            return [{ header: "Earnings per Share", resKey: 'ch_epshvr', isActive: viewsSettings.EarningLineSetting[majorPeriodicity].IsVisible, isDefault: true, graphComponent: GraphComponent.EPSLine, lineType: PriceChartConst.EPS, action: actionTexts, hasDivider: true }];
        } else {
            return[{ header: "Book Value per Share", resKey: 'T4Q_1', isActive: viewsSettings.T4Q0LineSetting[majorPeriodicity].IsVisible, isDefault: true, graphComponent: GraphComponent.T4Q0Line, lineType: PriceChartConst.BV, action: actionTexts },
                { header: "Cash Flow per Share", resKey: 'T4Q_2', isActive: viewsSettings.T4Q1LineSetting[majorPeriodicity].IsVisible, isDefault: true, graphComponent: GraphComponent.T4Q1Line, lineType: PriceChartConst.CFS, action: actionTexts },
                { header: "Dividend per Share", resKey: 'T4Q_3', isActive: viewsSettings.T4Q2LineSetting[majorPeriodicity].IsVisible, isDefault: true, graphComponent: GraphComponent.T4Q2Line, lineType: PriceChartConst.DIV, action: actionTexts },
                { header: "Earnings per Share", resKey: 'ch_epshvr', isActive: viewsSettings.EarningLineSetting[majorPeriodicity].IsVisible, isDefault: true, graphComponent: GraphComponent.EPSLine, lineType: PriceChartConst.EPS, action: actionTexts },
                { header: "Free Cash Flow per Share", resKey: 'T4Q_4', isActive: viewsSettings.T4Q3LineSetting[majorPeriodicity].IsVisible, isDefault: true, graphComponent: GraphComponent.T4Q3Line, lineType: PriceChartConst.FCF, action: actionTexts },
                { header: "Revenues per Share", resKey: 'ch_rpshvr', isActive: viewsSettings.RevenueLineSetting[majorPeriodicity].IsVisible, isDefault: true, graphComponent: GraphComponent.RPSLine, lineType: PriceChartConst.RPS, hasDivider: true, action: actionTexts },
            viewsSettings.EarningLineSetting];
        }
    }
    catch (error) {
        console.log(`Error occurs in PriceMenuSaga.js, getSubMenu ${error}`);
    }
}
function getExternalDataSubMenu(externalDataSettings, majorPeriodicity) {
    const externalDataBlockMenu = [];
    const actionTexts = [{ text: "Edit", resKey: 'edit' }];
    //const settings = SettingsStore.getConsoleSettings();
    let isShowHelpText = false;
    Object.keys(externalDataSettings).forEach((item)=>{
        if (externalDataSettings[item][majorPeriodicity].listType !== 1 && externalDataSettings[item][majorPeriodicity].listType === 7){
            isShowHelpText = true;
        }
    })

    if (!isShowHelpText) {
        externalDataBlockMenu.push({ isShowHelpText: true, isDefault: true });
    }
    else {
      Object.keys(externalDataSettings).forEach((item)=>{
        if (item !== 'default' && externalDataSettings[item][majorPeriodicity].listType !== 1 && externalDataSettings[item][majorPeriodicity].isDateBasedEvents){
            externalDataBlockMenu.push({
                header: externalDataSettings[item][majorPeriodicity].Header, resKey: '', isActive: externalDataSettings[item][majorPeriodicity].IsVisibleInPriceMenu, isDefault: true,
                graphComponent: GraphComponent.externalData, 
                action: actionTexts, 
                isDateBasedEvents: externalDataSettings[item][majorPeriodicity].isDateBasedEvents,
                listId: item, 
                listType: externalDataSettings[item][majorPeriodicity].listType, 
                isDisabledForCurrentSymbol: externalDataSettings[item][majorPeriodicity].isDisabledForCurrentSymbol,
                isReadOnly: externalDataSettings[item][majorPeriodicity].shareAccess !== 1 ? false : true
              });
        }
    })
    externalDataBlockMenu.sort((a, b)=> (a.header.toLowerCase() < b.header.toLowerCase()) ? -1 : ((a.header.toLowerCase() > b.header.toLowerCase()) ? 1 : 0));
    }
    return externalDataBlockMenu;
  }

function getGroups(info) {
    try {
        const international = info ?  info.SymTypeEnum && info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK : false;
        const groups = [];
        groups.push({ name: `${IndustryGroupsTranslateHelper[SymbolType.WONINDUSTRYGROUP197]} (197)`, type: SymbolType.WONINDUSTRYGROUP197, symbol: info.Industry197Symbol});
        if (!international) { groups.push({ name: `${IndustryGroupsTranslateHelper[SymbolType.WONMAJORINDUSTRY89]} (89)`, type: SymbolType.WONMAJORINDUSTRY89, symbol: info.MajorIndustrySymbol}); }
        groups.push({ name: `${IndustryGroupsTranslateHelper[SymbolType.WONSECTOR11]} (11)`, type: SymbolType.WONSECTOR11, symbol: info.Sector11Symbol});
        return groups;
    }
    catch (error) {
        console.log(`Error occurs in PriceMenuSaga.js, getGroups ${error}`);
    }
}
function* getPriceChartMenu({ countryNo = 1 }) {
    try {
        const blockMenu = [];
        const { viewsSettings, SymbolInfo, majorPeriodicity, isIntraday, SymbolType } = yield select(getDatagraphStates);
        const { isLineChart } = yield select(priceChartReducerselect);
        const country = SymbolInfo.CountryCode ? SymbolInfo.CountryCode : countryNo;
        let userPROk = false;
        if (UserInfoUtil.getUserInfo()) {
            const entitlements = UserInfoUtil.getUserInfo().entitlements;
            userPROk = entitlements.includes(EntitlementType.PR_Entitlement);
        }
        const isIndexView = SmartViewType.INDEX === SymbolType;
        const isStockView = SmartViewType.STOCK === SymbolType;
        const isIndustryView = SmartViewType.INDUSTRYGROUP === SymbolType;
        const funds = SmartViewType.FUND === SymbolType;
        const isETForCEF = SmartViewType.ETF === SymbolType || SmartViewType.CEF === SymbolType;
        const future = SmartViewType.FUTURES === SymbolType;
        const IndexLineSettingsByPeriodicity = viewsSettings.IndexLineSettingsByPeriodicity;
        const IndexLineSetting = viewsSettings.IndexLineSettings;
        const isUserSPEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.SP_Entitlement);
        const isSTPEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.STREET_TARGET_PRICE_Entitlement);
        const industryGroups = getGroups(SymbolInfo)
        const { riPanelViewSettings } = yield select(riPanelInfoState);
        const zigzagSettings = riPanelViewSettings?.AiSettings?.zigzagIndicator;

        if (IndexLineSettingsByPeriodicity && !isIntraday && !future) {
            if(!IndexLineSetting[country]){
                IndexLineSetting[country] = new IndexLineSettings(true);
                IndexLineSetting[country].IndexSymbol = SymbolInfo.index
            }
            if(!IndexLineSetting[country].IndexSymbol){
                IndexLineSetting[country].IndexSymbol = SymbolInfo.index
            }
            const indexSettings = IndexLineSetting[country];
            const indexSettingsByPeriodicity = IndexLineSettingsByPeriodicity[majorPeriodicity];
            if (indexSettingsByPeriodicity && indexSettingsByPeriodicity.IsAvailable) {
                if (indexSettings.valueType === "group") {
                    const selectedGroup = industryGroups.find((item)=> indexSettings.selectedGroup === item.type)
                    if(selectedGroup){
                        indexSettings.IndexSymbol = selectedGroup.symbol;
                        const rs1Settings = viewsSettings.RelativeStrenghtLine1Settings[SymbolInfo.CountryCode];
                        if (rs1Settings.valueType === "default"){
                            rs1Settings.IndexSymbol = selectedGroup.symbol;
                        }
                    }
                    
                }
                const headerText = 'Index Line ';
                const suffix = `(${indexSettings.IndexSymbol})`;
                const actionTexts = [{ text: "Edit", resKey: 'edit' }];
                blockMenu.push({ header: headerText, suffix: suffix, resKey: 'ch_index_line', isActive: indexSettingsByPeriodicity.IsVisible, graphComponent: GraphComponent.IndexLine, symbol: indexSettings.IndexSymbol, refresh: false, action: actionTexts });
            }
        }
        const RelativeStrenghtLine1SettingsByPeriodicity = viewsSettings.RelativeStrenghtLine1SettingsByPeriodicity;
        const RelativeStrenghtLine1Settings = viewsSettings.RelativeStrenghtLine1Settings;
        if (RelativeStrenghtLine1SettingsByPeriodicity && !isIntraday && !future) {
            if(!RelativeStrenghtLine1Settings[country]){
                RelativeStrenghtLine1Settings[country] = new RelativeStrenghtLineSettings(1, true);
                RelativeStrenghtLine1Settings[country].IndexSymbol = SymbolInfo.index;
            }
            if(! RelativeStrenghtLine1Settings[country].IndexSymbol){
                RelativeStrenghtLine1Settings[country].IndexSymbol = SymbolInfo.index;
            }
            const rs1Settings = RelativeStrenghtLine1Settings[country];
            const rs1SettingsByPeriodicity = RelativeStrenghtLine1SettingsByPeriodicity[majorPeriodicity];
            if (rs1SettingsByPeriodicity && rs1SettingsByPeriodicity.IsAvailable) {
                if(rs1Settings.valueType === "group"){
                    const group = parseInt(rs1Settings.selectedGroup)
                    const selectedGroup = industryGroups.find((item)=> group === item.type);
                    if(selectedGroup && selectedGroup.symbol){
                        rs1Settings.IndexSymbol = selectedGroup.symbol;
                    }
                }
                const headerText = 'RS Line ';
                const suffix = `1 (${rs1Settings.IndexSymbol})`;
                const actionTexts = [{ text: "Edit", resKey: 'edit' }];
                blockMenu.push({ header: headerText, suffix: suffix, resKey: 'ch_rs_line', isActive: rs1SettingsByPeriodicity.IsVisible, graphComponent: GraphComponent.RSLine1, symbol: rs1Settings.IndexSymbol, refresh: false, action: actionTexts });
            }
        }
        const RelativeStrenghtLine2SettingsByPeriodicity = viewsSettings.RelativeStrenghtLine2SettingsByPeriodicity;
        const RelativeStrenghtLine2Settings = viewsSettings.RelativeStrenghtLine2Settings;
        if (RelativeStrenghtLine2SettingsByPeriodicity && !isIntraday && !funds && !future) {
            if(!RelativeStrenghtLine2Settings[country]){
                RelativeStrenghtLine2Settings[country] = new RelativeStrenghtLineSettings(2, true);
                RelativeStrenghtLine2Settings[country].IndexSymbol = SymbolInfo.Industry197Symbol;
            }
            if(!RelativeStrenghtLine2Settings[country].IndexSymbol){
                RelativeStrenghtLine2Settings[country].IndexSymbol = SymbolInfo.Industry197Symbol;
            }
            const rs2Settings = RelativeStrenghtLine2Settings[country];
            const rs2SettingsByPeriodicity = RelativeStrenghtLine2SettingsByPeriodicity[majorPeriodicity];
            if (rs2SettingsByPeriodicity && rs2SettingsByPeriodicity.IsAvailable && rs2Settings.IndexSymbol) {
                if(rs2Settings.valueType === "group"){
                    const group = parseInt(rs2Settings.selectedGroup)
                    const selectedGroup = industryGroups.find((item)=> group === item.type);
                    if(selectedGroup){
                        rs2Settings.IndexSymbol = selectedGroup.symbol;
                    }
                }

                const headerText = 'RS Line ';
                let suffix;
                if (rs2Settings.IndexSymbol) { suffix = `2 (${rs2Settings.IndexSymbol})`; }
                else
                    if (RelativeStrenghtLine1Settings[country].IndexSymbol) { suffix = `2 (${RelativeStrenghtLine1Settings[country].IndexSymbol})`; }
                const actionTexts = [{ text: "Edit", resKey: 'edit' }];
                blockMenu.push({ header: headerText, suffix: suffix, resKey: 'ch_rs_line', isActive: rs2SettingsByPeriodicity.IsVisible, graphComponent: GraphComponent.RSLine2, symbol: rs2Settings.IndexSymbol, refresh: false, action: actionTexts });
            }
        }
        const symbol = SymbolInfo.Symbol;
        const isIndexSymbolHasEarnData = IsIndexSymbolHasEarnData(symbol);
        if (!isIntraday && !future && (isStockView || (isIndexView && isUserSPEntitled && isIndexSymbolHasEarnData))) {
            const headerText = 'Fundamental Data Lines';
            blockMenu.push({ header: headerText, resKey: 'ch_fdl', isActive: false, items: getSubMenu(viewsSettings, majorPeriodicity, isIndexView), hasDivider: true });
        }
        const PatternRecognitionSettings = viewsSettings.PatternRecognitionSettings;
        if (PatternRecognitionSettings && userPROk && !future) {
            const patternRecognitionSettings = PatternRecognitionSettings[majorPeriodicity];
            if (patternRecognitionSettings && patternRecognitionSettings.IsAvailable && (majorPeriodicity === GraphType.Daily || majorPeriodicity === GraphType.Weekly)) {
                const headerText = 'Pattern Recognition';
                const actionTexts = [{ text: "Edit", resKey: 'edit' }];
                blockMenu.push({ header: headerText, resKey: 'ch_pr', isActive: patternRecognitionSettings.IsVisible, graphComponent: GraphComponent.PatternRecognition, refresh: false, itemNo: 0, action: actionTexts });
            }
        }
        const CorporateEventsSettings = viewsSettings.CorporateEventsSettings;
        if (CorporateEventsSettings && !future) {
            const corpEvetnsSettings = CorporateEventsSettings[majorPeriodicity];
            if (corpEvetnsSettings.IsAvailable) {
                const headerText = isETForCEF ? 'Events' : 'Corporate Events';
                const actionTexts = [{ text: "Edit", resKey: 'edit' }];
                blockMenu.push({ header: headerText, resKey: 'ch_ce', isActive: corpEvetnsSettings.IsVisible, graphComponent: GraphComponent.CorpEvents, symbol: corpEvetnsSettings.IndexSymbol, refresh: false, action: actionTexts });
            }
        }
        const HiLoPriceSettings = viewsSettings.HiLoPriceSettings;
        if (HiLoPriceSettings) {
            const hiLoPriceSettings = HiLoPriceSettings[majorPeriodicity];
            if (hiLoPriceSettings.IsAvailable) {
                const headerText = 'High/Low Prices';

                // As per the requirement - hide the price values from priceChart view when zigzag indicator is on.
                let isDisable = false;
                if (!isIntraday && !(majorPeriodicity === GraphType.Quarterly || majorPeriodicity === GraphType.Annual) && (isStockView || isIndexView || isETForCEF || isIndustryView) && !isLineChart) {
                    isDisable = zigzagSettings?.isVisible
                }

                blockMenu.push({ header: headerText, resKey: 'ch_hilow', isActive: hiLoPriceSettings.IsVisible, graphComponent: GraphComponent.HiLo, refresh: false, isDisable: isDisable });
            }
        }

        const HiLoPctSettings = viewsSettings.HiLoPctSettings;
        if (HiLoPctSettings) {
            const hiLoPctSettings = HiLoPctSettings[majorPeriodicity];
            const hasDivider = isSTPEntitled ? false : true;
            if (hiLoPctSettings.IsAvailable) {
                const headerText = '% Chg (High->Low, Low->High)';
                
                // As per the requirement - hide the %chg values from priceChart view when zigzag indicator is on.
                let isDisable = false;
                if (!isIntraday && !(majorPeriodicity === GraphType.Quarterly || majorPeriodicity === GraphType.Annual) && (isStockView || isIndexView || isETForCEF || isIndustryView) && !isLineChart) {
                    isDisable = zigzagSettings?.isVisible
                }

                blockMenu.push({ header: headerText, resKey: 'ch_chghilow', isActive: hiLoPctSettings.IsVisible, graphComponent: GraphComponent.HiLoPct, refresh: false, hasDivider: hasDivider, isDisable: isDisable });
            }
        }

        const UpDaysSettings = viewsSettings.UpDaysSettings;
        const isUpDaysEntitle = UserInfoUtil.IsUserEntitled(EntitlementType.WONDA_UPDAYS_Entitlement)
        if (isUpDaysEntitle && majorPeriodicity === GraphType.Daily && UpDaysSettings) {
            const upDaysSettings = UpDaysSettings[majorPeriodicity];
            if (upDaysSettings.IsAvailable) {
                const headerText = 'UpDays';
                blockMenu.push({ header: headerText, resKey: 'ch_upDays', isActive: upDaysSettings.IsVisible, graphComponent: GraphComponent.UpDays, refresh: false });
            }
        }
        
        if (!isIntraday && !(majorPeriodicity === GraphType.Quarterly || majorPeriodicity === GraphType.Annual) && (isStockView || isIndexView || isETForCEF || isIndustryView) && !isLineChart && UserInfoUtil.IsUserEntitled(EntitlementType.ZIG_ZAG_INDICATOR_Entitlement) && !UserInfoUtil.IsUserEntitled(EntitlementType.ZIGZAG_AI_INDICATOR_Entitlement)) {
            const headerText = 'Zigzag Indicator';
            blockMenu.push({ header: headerText, resKey: 'ch_zzi', isActive: false, items: getZZMenu(zigzagSettings, majorPeriodicity), hasDivider: true });
        }
        const StreetTargetPriceSettings = viewsSettings.StreetTargetPriceSettings;
        if (!isIntraday && StreetTargetPriceSettings && isSTPEntitled) {
            const STPSettings = StreetTargetPriceSettings[majorPeriodicity];
            if (STPSettings.IsAvailable) {
                const headerText = 'Street Price Targets';
                const actionTexts = [{ text: "Edit", resKey: 'edit' }];
                blockMenu.push({ header: headerText, resKey: 'ch_spt', isActive: STPSettings.IsVisible, graphComponent: GraphComponent.StreetTargetPrice, refresh: false, itemNo: 0, action: actionTexts, hasDivider: true });
            }
        }
        // add external menus optPriceChart 
        const ExternalDataSettings = viewsSettings.ExternalDataSettings;
        if (ExternalDataSettings && !isIntraday && UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement)) {
            const headerText = 'External Data';
            blockMenu.push({ header: headerText, resKey: '', isActive: false, items: getExternalDataSubMenu(ExternalDataSettings, majorPeriodicity), hasDivider: true });
        }
        const MASettings = viewsSettings.MASettings;
        if (MASettings) {
            const maSettings = MASettings[majorPeriodicity];
            const lng = maSettings.length;
            const actionTexts = [{ text: "Edit", resKey: 'edit' }, { text: "Delete", resKey: 'delete' }];
            for (let i = 0; i < lng; i++) {
                const item = maSettings[i];
                if (item.ma > 0) {
                    const header = `${item.ma} ${item.maType}`;
                    if (i === lng - 1) {
                        blockMenu.push({ header: header, resKey: header, isActive: item.IsVisible, graphComponent: GraphComponent.mAverage, refresh: false, itemNo: i, ma: item.ma, maType: item.maType, hasDivider: true, action: actionTexts });
                    } else {
                        blockMenu.push({ header: header, resKey: header, isActive: item.IsVisible, graphComponent: GraphComponent.mAverage, refresh: false, itemNo: i, ma: item.ma, maType: item.maType, action: actionTexts });
                    }
                }
            }
            if (maSettings) {
                const headerText = 'Add a Moving Average';
                let isDisable = false;
                if (lng >= 5) {
                    isDisable = true;
                }
                blockMenu.push({ header: headerText, resKey: 'ch_ma', isActive: false, graphComponent: GraphComponent.addMAverage, refresh: false, isDisable: isDisable });
            }
        }
        const label = funds ? TranslateHelper.NAV : TranslateHelper.PRICE
        yield put({
            type: ActionTypes.PRICE_MENU_READY,
            blockMenu, label
        })
    }
    catch (error) {
        console.log(`Error occurs in PriceMenuSaga.js, getPriceChartMenu ${error}`);
    }
}

function* SetGraphComponent(item) {
    try {
        const component = item.graphComponent;
        const on = item.isActive;
        const { viewsSettings, majorPeriodicity, nodeCount, pricePanelData } = yield select(getDatagraphStates)
        const epsMultiplierSettings = viewsSettings.EPSMultiplierSettings;
        const rpsMultiplierSettings = viewsSettings.RPSMultiplierSettings;
        const t4q0MultiplierSettings = viewsSettings.T4Q0MultiplierSettings;
        const t4q1MultiplierSettings = viewsSettings.T4Q1MultiplierSettings;
        const t4q2MultiplierSettings = viewsSettings.T4Q2MultiplierSettings;
        const t4q3MultiplierSettings = viewsSettings.T4Q3MultiplierSettings;

        const { chartHeight, scale, ExdTimeSeriesData, HiLowPoints } = yield select(priceChartReducerselect);
        const timeline = yield select(timeLineSelect)
        const { multiplier, minPrice, maxPrice} = yield select(getFundamentalLineStates)
        const StockInformation = pricePanelData.SymbolInfo;
        const country = StockInformation.CountryCode;

        let hiLoPriceSettings = '', hiLoPctSettings = '', externalDataSettings = '', stpSettings = '', maSettings = '',
            corpEventsSettings = '', patternRecognitionSettings = '', indexSettings = '', indexSettingsByPeriodicity = '',
            rs1Settings = '', rs1SettingsByPeriodicity = '', rs2Settings = '', rs2SettingsByPeriodicity = '', epsSettings = '',
            rpsSettings = '', t4QSettings = '', upDaysSettings = '';

        switch (component) {
            case GraphComponent.HiLo:
                hiLoPriceSettings = viewsSettings.HiLoPriceSettings[majorPeriodicity];
                hiLoPriceSettings.IsVisible = on;
                yield put({
                    type: ActionTypes.HI_LO_SETTINGS_UPDATE,
                    isHoLoPriceVisible: hiLoPriceSettings.IsVisible,
                });
                break;
            case GraphComponent.UpDays:
                upDaysSettings = viewsSettings.UpDaysSettings[majorPeriodicity];
                upDaysSettings.IsVisible = on;
                yield put({
                    type: ActionTypes.UPDAYS_UPDATE,
                    isUpDaysVisible: upDaysSettings.IsVisible,
                });
                break;
            case GraphComponent.HiLoPct:
                hiLoPctSettings = viewsSettings.HiLoPctSettings[majorPeriodicity];
                hiLoPctSettings.IsVisible = on;
                yield put({
                    type: ActionTypes.HI_LO_PCT_SETTINGS_UPDATE,
                    isHoLoPctVisible: hiLoPctSettings.IsVisible,
                });
                break;
            case GraphComponent.mAverage:
                maSettings = viewsSettings.MASettings[majorPeriodicity];
                if (maSettings[item.itemNo]) {
                    maSettings[item.itemNo].IsVisible = on;
                    yield put({
                        type: ActionTypes.MA_SETTINGS_UPDATE,
                        maSettings
                    });
                }
                break;
            case GraphComponent.CorpEvents:
                corpEventsSettings = viewsSettings.CorporateEventsSettings[majorPeriodicity];
                if (corpEventsSettings.IndexSymbol !== item.symbol) {
                    viewsSettings.CorporateEventsSettings[majorPeriodicity].IndexSymbol = item.symbol;
                    item.refresh = true;
                }
                corpEventsSettings.IsVisible = on;
                yield put({
                    type: ActionTypes.CORP_EVENTS_SETTINGS_UPDATE,
                    isCorpEventVisible: corpEventsSettings.IsVisible
                });
                break;
            case GraphComponent.PatternRecognition:
                patternRecognitionSettings = viewsSettings.PatternRecognitionSettings[majorPeriodicity];
                patternRecognitionSettings.IsVisible = on;
                yield put({
                    type: ActionTypes.PATTERN_REC_SETTINGS_UPDATE,
                    IsVisible: patternRecognitionSettings.IsVisible
                });
                break;
            case GraphComponent.IndexLine:
                indexSettings = viewsSettings.IndexLineSettings[country];
                indexSettingsByPeriodicity = viewsSettings.IndexLineSettingsByPeriodicity[majorPeriodicity];
                if (indexSettings.IndexSymbol !== item.symbol) {
                    indexSettings.IndexSymbol = item.symbol;
                    item.refresh = true;
                }
                indexSettingsByPeriodicity.IsVisible = on;
                yield put({
                    type: ActionTypes.INDEX_LINE_SETTINGS_UPDATE,
                    indexSettingsByPeriodicity
                });
                break;
            case GraphComponent.RSLine1:
                rs1Settings = viewsSettings.RelativeStrenghtLine1Settings[country];
                rs1SettingsByPeriodicity = viewsSettings.RelativeStrenghtLine1SettingsByPeriodicity[majorPeriodicity];
                if (rs1Settings.IndexSymbol !== item.symbol) {
                    rs1Settings.IndexSymbol = item.symbol;
                    item.refresh = true;
                }
                rs1SettingsByPeriodicity.IsVisible = on;
                yield put({
                    type: ActionTypes.RS1_LINE_SETTINGS_UPDATE,
                    rs1SettingsByPeriodicity
                });
                break;
            case GraphComponent.RSLine2:
                rs2Settings = viewsSettings.RelativeStrenghtLine2Settings[country];
                rs2SettingsByPeriodicity = viewsSettings.RelativeStrenghtLine2SettingsByPeriodicity[majorPeriodicity];
                if (rs2Settings.IndexSymbol !== item.symbol) {
                    rs2Settings.IndexSymbol = item.symbol;
                    item.refresh = true;
                }
                rs2SettingsByPeriodicity.IsVisible = on;
                yield put({
                    type: ActionTypes.RS2_LINE_SETTINGS_UPDATE,
                    rs2SettingsByPeriodicity
                });
                break;
            case GraphComponent.ZZIndicator:
                yield put({
                    type: ActionTypes.UPDATE_ZZ_INDICATOR_SETTINGS,
                    isVisible: on
                });
                yield put({
                    type: ActionTypes.HI_LO_PCT_SETTINGS_UPDATE,
                    isDisabled: on, isReqFromZigzag: true
                });
                break;
            case GraphComponent.EPSLine:
                epsSettings = viewsSettings.EarningLineSetting[majorPeriodicity];
                epsSettings.IsVisible = on;
                yield put(updateFundamentalLineSetting(PriceChartConst.EPS, epsSettings, epsMultiplierSettings))
                if(on){
                    yield put(updateFundamentalLineSelectPerShare(PriceChartConst.EPS))
                }else{
                    yield put(switchFundametalLineLabel(PriceChartConst.EPS))
                }
                break;
            case GraphComponent.RPSLine:
                rpsSettings = viewsSettings.RevenueLineSetting[majorPeriodicity];
                rpsSettings.IsVisible = on;
                yield put(updateFundamentalLineSetting(PriceChartConst.RPS, rpsSettings, rpsMultiplierSettings))
                if(on){
                    yield put(updateFundamentalLineSelectPerShare(PriceChartConst.RPS))
                }else{
                    yield put(switchFundametalLineLabel(PriceChartConst.RPS))
                }
                break;
            case GraphComponent.T4Q0Line:
                {
                    t4QSettings = viewsSettings.T4Q0LineSetting[majorPeriodicity];
                    t4QSettings.IsVisible = on;
                    yield put(updateFundamentalLineSetting(PriceChartConst.BV, t4QSettings, t4q0MultiplierSettings))
                    if(on){
                        yield put(updateFundamentalLineSelectPerShare(PriceChartConst.BV))
                    }else{
                        yield put(switchFundametalLineLabel(PriceChartConst.BV))
                    }
                }
                break;
            case GraphComponent.T4Q1Line:
                {
                    t4QSettings = viewsSettings.T4Q1LineSetting[majorPeriodicity];
                    t4QSettings.IsVisible = on;
                    yield put(updateFundamentalLineSetting(PriceChartConst.CFS, t4QSettings, t4q1MultiplierSettings))
                    if(on){
                        yield put(updateFundamentalLineSelectPerShare(PriceChartConst.CFS))
                    }else{
                        yield put(switchFundametalLineLabel(PriceChartConst.CFS))
                    }
                }
                break;
            case GraphComponent.T4Q2Line:
                {
                    t4QSettings = viewsSettings.T4Q2LineSetting[majorPeriodicity];
                    t4QSettings.IsVisible = on;
                    yield put(updateFundamentalLineSetting(PriceChartConst.DIV, t4QSettings, t4q2MultiplierSettings));
                    if(on){
                        yield put(updateFundamentalLineSelectPerShare(PriceChartConst.DIV))
                    }else{
                        yield put(switchFundametalLineLabel(PriceChartConst.DIV))
                    }
                }
                break;
            case GraphComponent.T4Q3Line:
                {
                    t4QSettings = viewsSettings.T4Q3LineSetting[majorPeriodicity];
                    t4QSettings.IsVisible = on;
                    yield put(updateFundamentalLineSetting(PriceChartConst.FCF, t4QSettings, t4q3MultiplierSettings));
                    if(on){
                        yield put(updateFundamentalLineSelectPerShare(PriceChartConst.FCF))
                    }else{
                        yield put(switchFundametalLineLabel(PriceChartConst.FCF))
                    }
                }
                break;
            case GraphComponent.epsAuto:
                if (on) {
                    epsMultiplierSettings.Auto = true;
                    epsMultiplierSettings.Fixed = false;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.EPS]))
                }
                break;
            case GraphComponent.epsFixed:
                if (on) {
                    epsMultiplierSettings.Auto = false;
                    epsMultiplierSettings.Fixed = true;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.EPS]))
                }
                break;
            case GraphComponent.rpsAuto:
                if (on) {
                    rpsMultiplierSettings.Auto = true;
                    rpsMultiplierSettings.Fixed = false;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.RPS]))
                }
                break;
            case GraphComponent.rpsFixed:
                if (on) {
                    rpsMultiplierSettings.Auto = false;
                    rpsMultiplierSettings.Fixed = true;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.RPS]))
                }
                break;
            case GraphComponent.t4q0Auto:
                if (on) {
                    t4q0MultiplierSettings.Auto = true;
                    t4q0MultiplierSettings.Fixed = false;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.BV]))
                }
                break;
            case GraphComponent.t4q0Fixed:
                if (on) {
                    t4q0MultiplierSettings.Auto = false;
                    t4q0MultiplierSettings.Fixed = true;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.BV]))
                }
                break;
            case GraphComponent.t4q1Auto:
                if (on) {
                    t4q1MultiplierSettings.Auto = true;
                    t4q1MultiplierSettings.Fixed = false;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.CFS]))
                }
                break;
            case GraphComponent.t4q1Fixed:
                if (on) {
                    t4q1MultiplierSettings.Auto = false;
                    t4q1MultiplierSettings.Fixed = true;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.CFS]))
                }
                break;
            case GraphComponent.t4q2Auto:
                if (on) {
                    t4q2MultiplierSettings.Auto = true;
                    t4q2MultiplierSettings.Fixed = false;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.DIV]))
                }
                break;
            case GraphComponent.t4q2Fixed:
                if (on) {
                    t4q2MultiplierSettings.Auto = false;
                    t4q2MultiplierSettings.Fixed = true;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.DIV]))
                }
                break;
            case GraphComponent.t4q3Auto:
                if (on) {
                    t4q3MultiplierSettings.Auto = true;
                    t4q3MultiplierSettings.Fixed = false;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.FCF]))
                }
                break;
            case GraphComponent.t4q3Fixed:
                if (on) {
                    t4q3MultiplierSettings.Auto = false;
                    t4q3MultiplierSettings.Fixed = true;
                    yield put(updateFundamentalLine(pricePanelData, chartHeight, [PriceChartConst.FCF]))
                }
                break;
            case GraphComponent.EPSAnimation:
                {
                    const epsAnimationSettings = viewsSettings.EPSAnimationSettings[majorPeriodicity];
                    epsAnimationSettings.IsEarningsAnimationDisplayed = on;
                }
                break;
            case GraphComponent.externalData:
                
                if (on) {
                    const isPriceChartView = true;
                    const t4QMultipliers =[multiplier[PriceChartConst.BV], multiplier[PriceChartConst.CFS], multiplier[PriceChartConst.DIV], multiplier[PriceChartConst.FCF]];
                    yield put(getCurrentListData(item.listId, nodeCount, timeline.dates, scale, minPrice, maxPrice, chartHeight, isPriceChartView, multiplier[PriceChartConst.EPS], multiplier[PriceChartConst.RPS], t4QMultipliers, HiLowPoints.allPoints));
                }
                else {
                    yield put(clearExternalData(false, item.listId, ExdTimeSeriesData.isShowToggleButton, chartHeight, true));
                    yield put(redrawExternalData(true, item.listId));
                }
                externalDataSettings = viewsSettings.ExternalDataSettings[item.listId];
                externalDataSettings[majorPeriodicity].IsVisibleInPriceMenu = on;
                externalDataSettings[majorPeriodicity].isLastUploaded = false;
                break;
            case GraphComponent.StreetTargetPrice:
                stpSettings = viewsSettings.StreetTargetPriceSettings[majorPeriodicity];
                stpSettings.IsVisible = on;
                yield put(updateSTPVisibilty(on));
                break;
            case GraphComponent.addMAverage:
                yield put(addMALine(item));
                break;
            default:
                break;
        }
        SettingsStore.saveSettings();
        yield call(getPriceChartMenu, 1);
    }
    catch (error) {
        console.log(`Error occurs in PriceMenuSaga.js, SetGraphComponent ${error}`);
    }
}
function* priceMenuItemClicked({ item }) {
    try {
        if(!item){ 
            return;
        }
        item.isActive = !item.isActive;

        yield call(SetGraphComponent, item);
        return;
        // const settings = PriceMenuHelper.getInstSettings();
        // const periodicityM = PriceMenuHelper.getPeriodicity();
        // const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicityM);

        // const epsSettings = settings.EarningLineSetting[majorPeriodicity];
        // const rpsSettings = settings.RevenueLineSetting[majorPeriodicity];
        // const t4Q0Settings = settings.T4Q0LineSetting[majorPeriodicity];
        // const t4Q1Settings = settings.T4Q1LineSetting[majorPeriodicity];
        // const t4Q2Settings = settings.T4Q2LineSetting[majorPeriodicity];
        // const t4Q3Settings = settings.T4Q3LineSetting[majorPeriodicity];
        // const externalDataSettings = item.listId && settings.ExternalDataSettings[item.listId][majorPeriodicity];

        // const currEpsVisibility = epsSettings.IsVisible;
        // const currRpsVisibility = rpsSettings.IsVisible;
        // const currT4QVisibility = t4Q0Settings.IsVisible || t4Q1Settings.IsVisible || t4Q2Settings.IsVisible || t4Q3Settings.IsVisible;

        // const nextEpsVisibility = epsSettings.IsVisible;
        // const nextRpsVisibility = rpsSettings.IsVisible;
        // const nextT4QVisibility = t4Q0Settings.IsVisible || t4Q1Settings.IsVisible || t4Q2Settings.IsVisible || t4Q3Settings.IsVisible;
        // dispatch(updateEpsRpsWithPeriodicity(nextEpsVisibility, majorPeriodicity)) //This is for updating Eps line visibilty in nup view

        // if (item.isActive) {
        //     if (item.graphComponent === GraphComponent.EPSLine) {
        //         this.switchLabelsTo("EPS");
        //     }
        //     if (item.graphComponent === GraphComponent.RPSLine) {
        //         this.switchLabelsTo("RPS");
        //     }
        //     if (item.graphComponent === GraphComponent.T4Q0Line) {
        //         this.switchLabelsTo("BV");
        //     }
        //     if (item.graphComponent === GraphComponent.T4Q1Line) {
        //         this.switchLabelsTo("CFS");
        //     }
        //     if (item.graphComponent === GraphComponent.T4Q2Line) {
        //         this.switchLabelsTo("DIV");
        //     }
        //     if (item.graphComponent === GraphComponent.T4Q3Line) {
        //         this.switchLabelsTo("FCF");
        //     }
        //     if (item.graphComponent === GraphComponent.externalData && !externalDataSettings.isDateBasedEvents) {
        //         this.switchLabelsTo("EXD", item);
        //     }
        // } else {
        //     if (epsSettings.IsVisible && (this.state.labelText === "EPS" || this.state.labelText === "FFO") ||
        //         rpsSettings.IsVisible && this.state.labelText === "RPS" ||
        //         t4Q0Settings.IsVisible && this.state.labelText === "BV" ||
        //         t4Q1Settings.IsVisible && this.state.labelText === "CFS" ||
        //         t4Q2Settings.IsVisible && this.state.labelText === "DIV" ||
        //         t4Q3Settings.IsVisible && this.state.labelText === "FCF" ||
        //         (externalDataSettings && externalDataSettings.IsVisibleInPriceMenu && !externalDataSettings.isDateBasedEvents && this.state.labelText === "EXD")) {
        //     } else {
        //         this.switchLabels();
        //     }
        // }
        // if (item.graphComponent === GraphComponent.addMAverage) {
        //     MenuCommonDialogActions.openAddMADialog({ item: item, isEdit: false });
        // }
        // const itemIsFundamentalLine = item.graphComponent === GraphComponent.EPSLine ||
        //     item.graphComponent === GraphComponent.RPSLine ||
        //     item.graphComponent === GraphComponent.T4Q0Line ||
        //     item.graphComponent === GraphComponent.T4Q1Line ||
        //     item.graphComponent === GraphComponent.T4Q2Line ||
        //     item.graphComponent === GraphComponent.T4Q3Line ||
        //     item.graphComponent === GraphComponent.externalData;
        // const currItemNotVisible = !currEpsVisibility && !currRpsVisibility && !currT4QVisibility;
        // const nextItemNotVisible = !nextEpsVisibility && !nextRpsVisibility && !nextT4QVisibility;
        // if (itemIsFundamentalLine &&
        //     (currItemNotVisible || nextItemNotVisible)) {
        //     TabDataGraphActionClass.onEPSRPSChanged(item);
        // } else {
        //     TabDataGraphActionClass.onComponentChanged(item);
        // }

        // //Log Usage
        // this.logUsage(item);
    }
    catch (error) {
        console.log(`Error occurs in PriceMenuSaga.js, priceMenuItemClicked ${error}`);
    }
}

function SetGraphEpsRpsQuarters(item) {
    const instSettings = PriceMenuHelper.getInstSettings();
    if (item.qtrs === 0) {
        instSettings.EarningLineSetting[GraphType.Daily].NumOfQuarters = 1;
        instSettings.EarningLineSetting[GraphType.Weekly].NumOfQuarters = 4;
        instSettings.EarningLineSetting[GraphType.Monthly].NumOfQuarters = 8;
        instSettings.EarningLineSetting[GraphType.Quarterly].NumOfQuarters = 8;
        instSettings.EarningLineSetting[GraphType.Annual].NumOfQuarters = 8;
    } else {
        instSettings.EarningLineSetting[item.periodicity].NumOfQuarters = item.qtrs;
    }
    /* Fixed Bug: PANWEB-917 */
    const fcQtrs = instSettings.EarningLineSetting[_state.periodicity].NumOfQuarters;
    FinancialBlockStore.setQuarters(fcQtrs);
    SettingsStore.saveSettings();
}
/*****************watchers*****************/
export function* watchInitPriceMenu() {
    yield takeLatest(ActionTypes.PRICE_DATA_READY, getPriceChartMenu);
    yield takeLatest(ActionTypes.PREPARE_PRICE_MENU, getPriceChartMenu);
}
export function* watchPreparePriceMenu() {
    yield takeLatest(ActionTypes.PREPARE_PRICE_AND_INDICATOR_MENU, getPriceChartMenu);
}
export function* watchPriceMenuItemClicked() {
    yield takeLatest(ActionTypes.PRICE_MENU_ITEM_CLICK, priceMenuItemClicked);
}