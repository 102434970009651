import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale";
import BlockType from "../../../../Constants/BlockType";
import { DatagraphConst } from "../../../../Utils/DatagraphHelper";
import GraphType from "GraphType";
import { IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import SymbolType from "SymbolType";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getDatagraphStates, getIndicatorStates } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import UpDays from "../../../../Utils/Calcs/UpDays.js";
import { IndicatorLabelTranslateHelper } from "../../../../Utils/TranslateHelper";
import { updateIndicatorData, updateIndicatorGraphData, updateIndicatorSettings } from "../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";
import ThemeHelper from "ThemeHelper";

const { ActionTypes } = IndicatorsConstants;
// let lastnodeIndex = 500;
function getExtendedChart(hsfResults, chartType, firstNodeIndex, startXPoint, scale) {
    let prcLength = hsfResults.length;
    let xAxis = startXPoint;
    const chartData = { ShortVal: [], LongVal: [], SdUp: [], SdDn: [] };

    for (let j = firstNodeIndex; j < prcLength; j++) {
        if (xAxis < 0) break;
        if (isNaN(hsfResults[j].ShortVal)) {
            xAxis -= DatagraphConst.nodeWidth;
            continue;
        }
        if(hsfResults[j].ShortVal){
            const yPrice = scale.ComputeY(hsfResults[j].ShortVal);
            const info = {
                Date: hsfResults[j].Date,
                yPrice: yPrice,
                xAxis: xAxis,
                yValue: hsfResults[j].ShortVal
            };
            chartData.ShortVal.push(info);
        }
        if(hsfResults[j].LongVal){
            const yPrice2 = scale.ComputeY(hsfResults[j].LongVal);
            const info2 = {
                Date: hsfResults[j].Date,
                yPrice: yPrice2,
                xAxis: xAxis,
                yValue: hsfResults[j].LongVal
            };
            chartData.LongVal.push(info2);
        }
        if(hsfResults[j].SdUp){
            const yPrice3 = scale.ComputeY(hsfResults[j].SdUp);
            const info3 = {
                Date: hsfResults[j].Date,
                yPrice: yPrice3,
                xAxis: xAxis,
                yValue: hsfResults[j].SdUp
            };
            chartData.SdUp.push(info3);
        }
        if(hsfResults[j].SdDn){
            const yPrice4 = scale.ComputeY(hsfResults[j].SdDn);
            const info4 = {
                Date: hsfResults[j].Date,
                yPrice: yPrice4,
                xAxis: xAxis,
                yValue: hsfResults[j].SdDn
            };
            chartData.SdDn.push(info4);
        }
        xAxis -= DatagraphConst.nodeWidth;
    }
    return chartData;
}
// PANWEB-2900
// Fixing the issues created by PANWEB-2539. This code now matches to the Desktop
function CalculateExtended(hsfData) {
    var upDays = new UpDays();
    return upDays.Proc_Extended_Daily(hsfData);
}

function initExtendedScale(graphType, indicatorsHeight, graphData, startXPoint) {
    const lastNode = 0;
    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;
    //graphdata.forEach((extData) => {
    //    if (extData.ShortVal < minValue) {
    //        minValue = extData.ShortVal;
    //    }
    //    if (extData.ShortVal > maxValue) {
    //        maxValue = extData.ShortVal;
    //    }
    //    if (extData.LongVal < minValue) {
    //        minValue = extData.LongVal;
    //    }
    //    if (extData.LongVal > maxValue) {
    //        maxValue = extData.LongVal;
    //    }
    //})
    const gLength = graphData.length;
    for (let minMaxIndex = lastNode; minMaxIndex < gLength; minMaxIndex++) {
        if (graphData[minMaxIndex].ShortVal === undefined || graphData[minMaxIndex].LongVal === undefined) {
            continue;
        }
        if (graphData[minMaxIndex] === null) {
            break;
        }

        if (minValue > graphData[minMaxIndex].ShortVal) {
            minValue = graphData[minMaxIndex].ShortVal;
        }
        if (maxValue < graphData[minMaxIndex].ShortVal) {
            maxValue = graphData[minMaxIndex].ShortVal;
        }
        if (minValue > graphData[minMaxIndex].LongVal) {
            minValue = graphData[minMaxIndex].LongVal;
        }
        if (maxValue < graphData[minMaxIndex].LongVal) {
            maxValue = graphData[minMaxIndex].LongVal;
        }

        startXPoint -= DatagraphConst.nodeWidth;
        if (startXPoint < 0) {
            break;
        }
    }
    const scale = new ArithmaticScale();
    scale.InitScale(minValue, maxValue, indicatorsHeight, graphType, 2, 28 * DatagraphConst.nodeWidth, SymbolType.USSTOCK, NaN, NaN, true);
    return scale;
}

function* PrepareData({ chartType }) {
    const { SettingDict } = yield select(getIndicatorStates);
    const { pricePanelData, indicatorsHeight, startXPoint, padding } = yield select(getDatagraphStates);
    const graphData = CalculateExtended(pricePanelData.HsfData.HSFResults);
    yield call(processData, graphData, indicatorsHeight, BlockType.Extended, SettingDict[chartType], startXPoint, padding);
}

function* processData(graphData, indicatorsHeight, chartType = BlockType.Extended, SettingDict, startXPoint, padding) {
    const indicatorVisuals = { [chartType]: [] };
    const indicatorLabels = { [chartType]: { labels: [] } };
    let LongLineColor, LongWeight, ShortLineColor, ShortWeight, SdUpColor, SdUpWeight, SdDnLineColor, SdDnWeight;
    SettingDict.LongLineColor.forEach((item) => {
        LongLineColor = ThemeHelper.getThemedBrush(item.color);
        LongWeight = item.weight;
    });
    SettingDict.ShortLineColor.forEach((item) => {
        ShortLineColor = ThemeHelper.getThemedBrush(item.color);
        ShortWeight = item.weight;
    });
    SettingDict.SdUpLineColor.forEach((item) => {
        SdUpColor = ThemeHelper.getThemedBrush(item.color);
        SdUpWeight = item.weight;
    });
    SettingDict.SdDnLineColor.forEach((item) => {
        SdDnLineColor = ThemeHelper.getThemedBrush(item.color);
        SdDnWeight = item.weight;
    });
    let MainDataSource;
    let DataSource;
    if (graphData) {
        const scale = initExtendedScale(GraphType.Weekly, indicatorsHeight, graphData, startXPoint);
        MainDataSource = getExtendedChart(graphData, chartType, padding, startXPoint, scale);
        DataSource = MainDataSource.ShortVal;
        indicatorVisuals[chartType].push({ key: "Line", DataSource, LineColor: ShortLineColor, LineThickness: ShortWeight, Draggable: false, lineID: 'Short' });
        DataSource = MainDataSource.LongVal;
        indicatorVisuals[chartType].push({ key: "Line1", DataSource, LineColor: LongLineColor, LineThickness: LongWeight, Draggable: false, lineID: 'Long' });
        DataSource = MainDataSource.SdUp;
        indicatorVisuals[chartType].push({ key: "Line2", DataSource, LineColor: SdUpColor, LineThickness: SdUpWeight, Draggable: false, lineID: 'Sd Up' });
        DataSource = MainDataSource.SdDn;
        indicatorVisuals[chartType].push({ key: "Line3", DataSource, LineColor: SdDnLineColor, LineThickness: SdDnWeight, Draggable: false, lineID: 'Sd Dn' });
        indicatorLabels[chartType].isScale = true;
        indicatorLabels[chartType].class = "tech-indicators";
        indicatorLabels[chartType].LabelMenu = { label: IndicatorLabelTranslateHelper[BlockType.Extended], isTICustomModal: true, isShowSettingsDialog: false, isShowAppearanceDialog: true, isShowAboutDialog: false }
        yield put(updateIndicatorData(indicatorVisuals, indicatorLabels, chartType, scale));
    }
}

function* initIndicator() {
    try {
        const { pricePanelData, viewsSettings, majorPeriodicity, indicatorsHeight, startXPoint, padding } = yield select(getDatagraphStates);
        const ExtendedChartSettings = viewsSettings.ExtendedChartSettings;
        const SettingDict = {}
        SettingDict[BlockType.Extended] = ExtendedChartSettings ? ExtendedChartSettings[majorPeriodicity] : {};
        yield put(updateIndicatorSettings(SettingDict));
        if (ExtendedChartSettings && SettingDict[BlockType.Extended].IsAvailable) {
            const graphData = CalculateExtended(pricePanelData.HsfData.HSFResults);
            yield put(updateIndicatorGraphData(graphData, BlockType.Extended));
            yield call(processData, graphData, indicatorsHeight, BlockType.Extended, SettingDict[BlockType.Extended], startXPoint, padding);
        }
    }
    catch (error) {
        console.error("Error occurs in ExtendedIndicatorSaga.js, initIndicator", error);
    }
}
//===================================================

export function* watchInitExtendedIndicator() {
    yield takeLatest(ActionTypes.PROCESS_INDICATOR, initIndicator)
}

export function* watchPrepareExtendedIndicatorData() {
    yield takeLatest(ActionTypes.PREPARE_EXTENDED_INDICATOR_DATA, PrepareData);
}

export function* watchToggleExtendedChart() {
    // yield takeLatest(ActionTypes.TOGGLE_YTD_CHART, togglePTOECHart)
}

export function* watchUpdateExtendedIndicatorHeight() {
    yield takeLatest(PriceChartConstants.ActionTypes.INDICATOR_RESIZE, initIndicator)
}