import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import DefaultSettings from "./DefaultSettings/DefaultSettings.js";
import SettingApi from "../../../ServiceApi/SettingApi.js";
import { SettingsConstants } from "../../../Constants/SettingsConstants.js";
import GridLineUtil from "GridLineUtil";
import Base64Serialization from "Base64Serialization";
import TabONeilSettings from './Modules/ListManager/TabONeilSettings.js';
import BaseServiceApi from 'BaseServiceApi';
import DefaultDataGraphSetting from "./Modules/DataGraph/DefaultDataGraphSettings";
import { map, each } from "underscore";
import { PrintMode } from '../../../print/printmode.js';
import GraphType from "GraphType";
import CompareSettings from "./Modules/Compare/CompareSettings.js";

let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];

const CHANGE_EVENT = "change";
let _consoleSettings;
let _currentAction = null;
let _isResetStart = false;

Array.prototype.contains = function (obj) {
  const index = this.indexOf(obj)
  if (index === -1){
    return false;
  }
  return true;
}
class SettingsStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    this.serialization = new Base64Serialization();
    this.settingApi = new SettingApi();
    this.getDefaultSettingForUser();
    this.settingsDataUpdated = false;
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getCurrentAction() {
    return _currentAction;
  }

  setSettingsforUserId(data) {
    if (_consoleSettings !== undefined) {
      for (let key in data) {
        //    if(_consoleSettings[key] !== undefined){
        _consoleSettings[key] = data[key];
        //   }
      }
    }
  }

  saveSettingsFunct() {
    console.time("Save Settings");
    console.time("Serialize");

    const strSettings = this.serialization.serialize(_consoleSettings);
    console.timeEnd("Serialize");
    this.settingApi.saveUserSettings(strSettings).then((result) => {
      this.workSpaceId = result.workspace.versionId;
      console.timeEnd("Save Settings");
      localStorage.setItem("_activeNav", _consoleSettings && _consoleSettings.ActiveNav);
      this.saveSettingsObj = null;
      this.emit(CHANGE_EVENT);
    });
  }

  saveSettings(isReset = false) {
    if (this.saveSettingsObj) {
      clearTimeout(this.saveSettingsObj);
    }

    if (PrintMode.printing) {
      return
    }

    this.saveSettingsObj = setTimeout(this.saveSettingsFunct(), 100)
  }

  resetSettings() {
    _isResetStart = true;
    if (this.saveSettingsObj) {
      clearTimeout(this.saveSettingsObj);
    }
    localStorage.setItem("isResetCalled", true);
    const isUploadedExternalData = _consoleSettings.isUploadedExternalData;
    _consoleSettings = this.getDefaultSettingForUser();
    // reassigning the previous state and saving
    _consoleSettings.isUploadedExternalData = isUploadedExternalData;
    this.saveSettingsObj = setTimeout(this.saveSettingsFunct(), 0);
    localStorage.setItem("_activeNav", _consoleSettings && _consoleSettings.ActiveNav);
    _currentAction = SettingsConstants.ActionTypes.RESET_SETTINGS;
  }

  getIsResetStart() {
    return _isResetStart;
  }
  getSettingsforUserId(settings) {
    // forceSet = false
    if (settings) {
      /* Temporarily restoring settings to avoid compatibility issues */
      if(!settings.NavCompareSettings.isSettingsRestored) {
        let compareSettings = new CompareSettings();
        settings.NavCompareSettings = compareSettings.getDefaultCompareSettings();
        settings.NavCompareSettings.isSettingsRestored = true;
      }

      const categoryType = settings.NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedCategoryType;

      if (categoryType === ListCategoryType.TEMPORARYLIST_ListCategory || categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
        let tabONeilSettings = new TabONeilSettings();
        settings.NavListManagerSettings.TabONeilSettings.SelectedListInfo = tabONeilSettings.getDefaultSelectedListInfo();
      }

      // on each refresh/login, user is provided to view the latest date
      settings.NavDatagraphSettings.TabDataGraphSettings.userEndDate = null;

      settings.KeyDownActiveZone = null;
      /* Temporary settings begin 
      Temporarily setting indicator panels to default values. 
      TODO : Remove this code on release 5.1 */
      const StockViewSettings = settings.NavDatagraphSettings.TabDataGraphSettings.StockViewSettings;
      const CEFViewSettings = settings.NavDatagraphSettings.TabDataGraphSettings.CEFViewSettings;
      const IndexViewSettings = settings.NavDatagraphSettings.TabDataGraphSettings.IndexViewSettings;
      const ETFViewSettings = settings.NavDatagraphSettings.TabDataGraphSettings.ETFViewSettings;
      const FUNDViewSettings = settings.NavDatagraphSettings.TabDataGraphSettings.FUNDViewSettings;
      const IndustryGroupViewSettings = settings.NavDatagraphSettings.TabDataGraphSettings.IndustryGroupViewSettings;

      map(StockViewSettings && StockViewSettings.AccDistChartSettings, (value, key) => {
        if (value == null || value.SdLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getAccDistChartSetting(null, key);
          StockViewSettings.AccDistChartSettings[key] = defaultSetting[key];
        }
      });

      map(StockViewSettings && StockViewSettings.EPSRChartSettings, (value, key) => {
        if (value == null || value.CurrentFiscalYear == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getEPSRChartSetting(key);
          StockViewSettings.EPSRChartSettings[key] = defaultSetting[key];
        }
      });

      map(StockViewSettings && StockViewSettings.MacdChartSettings, (value, key) => {
        if (value == null || value.FastLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getMacdChartSetting(key);
          StockViewSettings.MacdChartSettings[key] = defaultSetting[key];
        }
      });

      map(StockViewSettings && StockViewSettings.PTOEChartSettings, (value, key) => {
        if (value == null || value.PTOEIndexLine == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getPTOEChartSetting(key);
          StockViewSettings.PTOEChartSettings[key] = defaultSetting[key];
        }
      });

      map(StockViewSettings && StockViewSettings.PTOSChartSettings, (value, key) => {
        if (value == null || value.PTOSIndexLine == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getPTOSChartSetting(key);
          StockViewSettings.PTOSChartSettings[key] = defaultSetting[key];
        }
      });

      map(StockViewSettings && StockViewSettings.RsiChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getRSiChartSetting(key);
          StockViewSettings.RsiChartSettings[key] = defaultSetting[key];
        }
      });

      map(StockViewSettings && StockViewSettings.ExtendedChartSettings, (value, key) => {
        if (value == null || value.IsRelative == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getExtendedChartSetting(key);
          StockViewSettings.ExtendedChartSettings[key] = defaultSetting[key];
        }
      });

      map(StockViewSettings && StockViewSettings.StochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getStochasticsChartSetting(key);
          StockViewSettings.StochasticsChartSettings[key] = defaultSetting[key];
        }
      });

      map(StockViewSettings && StockViewSettings.TechChartSettings, (value, key) => {
        if (value == null || value.Rs3MonthsColor == undefined || typeof(value.Rs3MonthsColor) !== "object") {
            const defaultSetting = DefaultDataGraphSetting.getTechChartSetting(null, key);
            StockViewSettings.TechChartSettings[key] = defaultSetting[key];
        }
      });

      map(StockViewSettings && StockViewSettings.EPSAnimationSettings, (value, key) => {
            if(key == settings.NavDatagraphSettings.TabDataGraphSettings.Periodicity && StockViewSettings.EPSAnimationSettings[key].IsEarningsAnimationDisplayed === true){
              StockViewSettings.EPSAnimationSettings[key].IsEarningsAnimationDocked = true;
            }
      });

      map(StockViewSettings && StockViewSettings.WonStochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsChartSetting(key);
            StockViewSettings.WonStochasticsChartSettings[key] = defaultSetting[key];
        }
      });

      map(StockViewSettings && StockViewSettings.WonStochasticsUsageSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsUsageSetting(key);
            StockViewSettings.WonStochasticsUsageSettings[key] = defaultSetting[key];
        }
      });

      map(CEFViewSettings && CEFViewSettings.AccDistChartSettings, (value, key) => {
        if (value == null || value.SdLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getAccDistChartSetting(null, key);
          CEFViewSettings.AccDistChartSettings[key] = defaultSetting[key];
        }
      });

      map(CEFViewSettings && CEFViewSettings.MacdChartSettings, (value, key) => {
        if (value == null || value.FastLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getMacdChartSetting(key);
          CEFViewSettings.MacdChartSettings[key] = defaultSetting[key];
        }
      });

      map(CEFViewSettings && CEFViewSettings.RsiChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getRSiChartSetting(key);
          CEFViewSettings.RsiChartSettings[key] = defaultSetting[key];
        }
      });

      map(CEFViewSettings && CEFViewSettings.ExtendedChartSettings, (value, key) => {
        if (value == null || value.IsRelative == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getExtendedChartSetting(key);
          CEFViewSettings.ExtendedChartSettings[key] = defaultSetting[key];
        }
      });

      map(CEFViewSettings && CEFViewSettings.StochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getStochasticsChartSetting(key);
          CEFViewSettings.StochasticsChartSettings[key] = defaultSetting[key];
        }
      });

      map(CEFViewSettings && CEFViewSettings.TechChartSettings, (value, key) => {
         if (value == null || value.Rs3MonthsColor == undefined || typeof(value.Rs3MonthsColor) !== "object") {
            const defaultSetting = DefaultDataGraphSetting.getTechChartSetting('CEF', key);
            CEFViewSettings.TechChartSettings[key] = defaultSetting[key];
         }
      });
      map(CEFViewSettings && CEFViewSettings.WonStochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsChartSetting(key);
            CEFViewSettings.WonStochasticsChartSettings[key] = defaultSetting[key];
        }
      });

      map(CEFViewSettings && CEFViewSettings.WonStochasticsUsageSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsUsageSetting(key);
            CEFViewSettings.WonStochasticsUsageSettings[key] = defaultSetting[key];
        }
      });
      map(IndexViewSettings && IndexViewSettings.AccDistChartSettings, (value, key) => {
        if (value == null || value.SdLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getAccDistChartSetting(null, key);
          IndexViewSettings.AccDistChartSettings[key] = defaultSetting[key];
        }
      });

      map(IndexViewSettings && IndexViewSettings.MacdChartSettings, (value, key) => {
        if (value == null || value.FastLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getMacdChartSetting(key);
          IndexViewSettings.MacdChartSettings[key] = defaultSetting[key];
        }
      });

      map(IndexViewSettings && IndexViewSettings.RsiChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getRSiChartSetting(key);
          IndexViewSettings.RsiChartSettings[key] = defaultSetting[key];
        }
      });

      map(IndexViewSettings && IndexViewSettings.ExtendedChartSettings, (value, key) => {
        if (value == null || value.IsRelative == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getExtendedChartSetting(key);
            IndexViewSettings.ExtendedChartSettings[key] = defaultSetting[key];
        }
      });

      map(IndexViewSettings && IndexViewSettings.StochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getStochasticsChartSetting(key);
          IndexViewSettings.StochasticsChartSettings[key] = defaultSetting[key];
        }
      });

      map(IndexViewSettings && IndexViewSettings.TechChartSettings, (value, key) => {
         if (value == null || value.Rs3MonthsColor == undefined || typeof(value.Rs3MonthsColor) !== "object") {
            const defaultSetting = DefaultDataGraphSetting.getTechChartSetting('Index', key);
            IndexViewSettings.TechChartSettings[key] = defaultSetting[key];
         }
      });

      map(IndexViewSettings && IndexViewSettings.EPSAnimationSettings, (value, key) => {
        if(key == settings.NavDatagraphSettings.TabDataGraphSettings.Periodicity && IndexViewSettings.EPSAnimationSettings[key].IsEarningsAnimationDisplayed === true){
          IndexViewSettings.EPSAnimationSettings[key].IsEarningsAnimationDocked = true;
        }
      });
      map(IndexViewSettings && IndexViewSettings.WonStochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsChartSetting(key);
            IndexViewSettings.WonStochasticsChartSettings[key] = defaultSetting[key];
        }
      });

      map(IndexViewSettings && IndexViewSettings.WonStochasticsUsageSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsUsageSetting(key);
            IndexViewSettings.WonStochasticsUsageSettings[key] = defaultSetting[key];
        }
      });
      map(ETFViewSettings && ETFViewSettings.AccDistChartSettings, (value, key) => {
        if (value == null || value.SdLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getAccDistChartSetting(null, key);
          ETFViewSettings.AccDistChartSettings[key] = defaultSetting[key];
        }
      });

      map(ETFViewSettings && ETFViewSettings.MacdChartSettings, (value, key) => {
        if (value == null || value.FastLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getMacdChartSetting(key);
          ETFViewSettings.MacdChartSettings[key] = defaultSetting[key];
        }
      });

      map(ETFViewSettings && ETFViewSettings.RsiChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getRSiChartSetting(key);
          ETFViewSettings.RsiChartSettings[key] = defaultSetting[key];
        }
      });

      map(ETFViewSettings && ETFViewSettings.ExtendedChartSettings, (value, key) => {
        if (value == null || value.IsRelative == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getExtendedChartSetting(key);
            ETFViewSettings.ExtendedChartSettings[key] = defaultSetting[key];
        }
      });

      map(ETFViewSettings && ETFViewSettings.StochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getStochasticsChartSetting(key);
          ETFViewSettings.StochasticsChartSettings[key] = defaultSetting[key];
        }
      });

      map(ETFViewSettings && ETFViewSettings.TechChartSettings, (value, key) => {
         if (value == null || value.Rs3MonthsColor == undefined || typeof(value.Rs3MonthsColor) !== "object") {
            const defaultSetting = DefaultDataGraphSetting.getTechChartSetting('ETF', key);
            ETFViewSettings.TechChartSettings[key] = defaultSetting[key];
         }
      });
      map(ETFViewSettings && ETFViewSettings.WonStochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsChartSetting(key);
            ETFViewSettings.WonStochasticsChartSettings[key] = defaultSetting[key];
        }
      });

      map(ETFViewSettings && ETFViewSettings.WonStochasticsUsageSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsUsageSetting(key);
            ETFViewSettings.WonStochasticsUsageSettings[key] = defaultSetting[key];
        }
      });
      map(FUNDViewSettings && FUNDViewSettings.MacdChartSettings, (value, key) => {
        if (value == null || value.FastLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getMacdChartSetting(key);
          FUNDViewSettings.MacdChartSettings[key] = defaultSetting[key];
        }
      });

      map(FUNDViewSettings && FUNDViewSettings.RsiChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getRSiChartSetting(key);
          FUNDViewSettings.RsiChartSettings[key] = defaultSetting[key];
        }
      });

      map(FUNDViewSettings && FUNDViewSettings.StochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getStochasticsChartSetting(key);
          FUNDViewSettings.StochasticsChartSettings[key] = defaultSetting[key];
        }
      });
      map(FUNDViewSettings && FUNDViewSettings.WonStochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsChartSetting(key);
            FUNDViewSettings.WonStochasticsChartSettings[key] = defaultSetting[key];
        }
      });

      map(FUNDViewSettings && FUNDViewSettings.WonStochasticsUsageSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsUsageSetting(key);
            FUNDViewSettings.WonStochasticsUsageSettings[key] = defaultSetting[key];
        }
      });
      map(IndustryGroupViewSettings && IndustryGroupViewSettings.AccDistChartSettings, (value, key) => {
        if (value == null || value.SdLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getAccDistChartSetting(null, key);
          IndustryGroupViewSettings.AccDistChartSettings[key] = defaultSetting[key];
        }
      });

      map(IndustryGroupViewSettings && IndustryGroupViewSettings.MacdChartSettings, (value, key) => {
        if (value == null || value.FastLength == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getMacdChartSetting(key);
          IndustryGroupViewSettings.MacdChartSettings[key] = defaultSetting[key];
        }
      });

      map(IndustryGroupViewSettings && IndustryGroupViewSettings.RsiChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getRSiChartSetting(key);
          IndustryGroupViewSettings.RsiChartSettings[key] = defaultSetting[key];
        }
      });

      map(IndustryGroupViewSettings && IndustryGroupViewSettings.StochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
          const defaultSetting = DefaultDataGraphSetting.getStochasticsChartSetting(key);
          IndustryGroupViewSettings.StochasticsChartSettings[key] = defaultSetting[key];
        }
      });
      map(IndustryGroupViewSettings && IndustryGroupViewSettings.WonStochasticsChartSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsChartSetting(key);
            IndustryGroupViewSettings.WonStochasticsChartSettings[key] = defaultSetting[key];
        }
      });

      map(IndustryGroupViewSettings && IndustryGroupViewSettings.WonStochasticsUsageSettings, (value, key) => {
        if (value == null || value.Length == undefined) {
            const defaultSetting = DefaultDataGraphSetting.getWonStochasticsUsageSetting(key);
            IndustryGroupViewSettings.WonStochasticsUsageSettings[key] = defaultSetting[key];
        }
      });
      // PatternRecognition setting -> "previou version - same across all the periodicity" && "current version - specific to periodicity"
      // So populating current settings of all the periodicity with older settings.  
      if (StockViewSettings && StockViewSettings.PatternRecognitionSettings && StockViewSettings.PatternRecognitionSettings["Weekly"] === undefined) {
        const defaultPatternRecSettings = DefaultDataGraphSetting.getPatternRecognitionSetting();
        
        for (const periodicity in defaultPatternRecSettings) {
          if (periodicity === GraphType.Daily || periodicity === GraphType.Weekly) {
            each(StockViewSettings.PatternRecognitionSettings, (value, key) => {
              for(const defaultKey in defaultPatternRecSettings[periodicity]) {
                if (defaultKey === key) {
                  defaultPatternRecSettings[periodicity][defaultKey] = value;
                }
              }
            });
          }
        }
        StockViewSettings.PatternRecognitionSettings = defaultPatternRecSettings;
      }

      if (ETFViewSettings && ETFViewSettings.PatternRecognitionSettings && ETFViewSettings.PatternRecognitionSettings["Weekly"] === undefined) {
        const defaultPatternRecSettings = DefaultDataGraphSetting.getPatternRecognitionSetting();

        for (const periodicity in defaultPatternRecSettings) {
          if (periodicity === GraphType.Daily || periodicity === GraphType.Weekly) {
            each(ETFViewSettings.PatternRecognitionSettings, (value, key) => {
              for(const defaultKey in defaultPatternRecSettings[periodicity]) {
                if (defaultKey === key) {
                  defaultPatternRecSettings[periodicity][defaultKey] = value;
                }
              }
            });
          }
        }
        ETFViewSettings.PatternRecognitionSettings = defaultPatternRecSettings;
      }

      if (CEFViewSettings && CEFViewSettings.PatternRecognitionSettings && CEFViewSettings.PatternRecognitionSettings["Weekly"] === undefined) {
        const defaultPatternRecSettings = DefaultDataGraphSetting.getPatternRecognitionSetting();

        for (const periodicity in defaultPatternRecSettings) {
          if (periodicity === GraphType.Daily || periodicity === GraphType.Weekly) {
            each(CEFViewSettings.PatternRecognitionSettings, (value, key) => {
              for(const defaultKey in defaultPatternRecSettings[periodicity]) {
                if (defaultKey === key) {
                  defaultPatternRecSettings[periodicity][defaultKey] = value;
                }
              }
            });
          }
        }
        CEFViewSettings.PatternRecognitionSettings = defaultPatternRecSettings;
      }
      
      /* Temporary settings end */

      _consoleSettings = settings;
    }
    else {
      // avaoiding restore of "isUploadedExternalData" - so saving the previous state;
      const isUploadedExternalData = _consoleSettings ? _consoleSettings.isUploadedExternalData : false;
      _consoleSettings = this.getDefaultSettingForUser();
      localStorage.setItem("_activeNav", _consoleSettings && _consoleSettings.ActiveNav);

      // reassigning the previous state and saving
      _consoleSettings.isUploadedExternalData = isUploadedExternalData;
    }
  }

  getConsoleSettings() {
    return _consoleSettings;
  }

  getDefaultSettingForUser() {
    return DefaultSettings.getDefaultSettings();
  }

  updateUrl() {
    _currentAction = SettingsConstants.ActionTypes.SET_USER_SETTINGS;
    this.emit(CHANGE_EVENT);
  }

  clearCurrentAction() {
    _currentAction = null;
  }

  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {
      case SettingsConstants.ActionTypes.RESET_SETTINGS:
        this.resetSettings();
        break;
      case SettingsConstants.ActionTypes.SAVE_USER_SETTTINGS:
        _currentAction = SettingsConstants.ActionTypes.SAVE_USER_SETTTINGS;
        this.saveSettings();
        break;
      case SettingsConstants.ActionTypes.SET_USER_SETTINGS:
        this.setSettingsforUserId(data);
        _currentAction = SettingsConstants.ActionTypes.SET_USER_SETTINGS;
        this.saveSettings();
        this.emit(CHANGE_EVENT);
        break;

      case SettingsConstants.ActionTypes.GET_USER_SETTINGS:
        this.settingsDataUpdated = true;
        this.getSettingsforUserId(data);
        GridLineUtil.updateTheme(_consoleSettings);
        _currentAction = SettingsConstants.ActionTypes.GET_USER_SETTINGS;
        this.emit(CHANGE_EVENT);
        break;
      case SettingsConstants.ActionTypes.THEME_CHANGED:
        _consoleSettings.currentTheme = data;
        _consoleSettings.originalTheme = data;
        GridLineUtil.updateTheme(_consoleSettings);
        this.saveSettings();
        break;
      case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:
        _currentAction = SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT;
        _consoleSettings.NavDatagraphSettings.symbol = data.Symbol;
        _consoleSettings.NavDatagraphSettings.symbolTypeEnum = data.SymTypeEnum;
        _consoleSettings.NavDatagraphSettings.msId = data.MsId;
        this.emit(CHANGE_EVENT);
        break;
      default:
        break;
    }

  }
}

const settingStore = new SettingsStore();
export default settingStore;
