import Serializable from "Serializable";
export default class ExtendedChartSettings extends Serializable {
    constructor() {
        super();
    }

    getSerizableObject(obj) {
        return Object.assign(new Serializable, { ...obj });
    }

    getDefaultseqNumber() {
        return 1;
    }

    getDefaultLongLineObj() {
        const LongLineObj = [{ isActive: true, color: "P0066CC3399FF", weight: 2 }];
        return LongLineObj.map((item) => this.getSerizableObject(item));
    }
    getDefaultShortLineObj() {
        const ShortLineObj = [{ isActive: false, color: "P4C4C4C999999", weight: 1 }];
        return ShortLineObj.map((item) => this.getSerizableObject(item));
    }
    getDefaultSdUpLineObj() {
        const SdUpLineObj = [{ isActive: false, color: "P4C4C4C999999", weight: 1 }];
        return SdUpLineObj.map((item) => this.getSerizableObject(item));
    }
    getDefaultSdDnLineObj() {
        const SdDnLineObj = [{ isActive: false, color: "P4C4C4C999999", weight: 1 }];
        return SdDnLineObj.map((item) => this.getSerizableObject(item));
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.IsVisible = serializedValue.IsVisible ? serializedValue.IsVisible : false;
        this.Height = serializedValue.Height ? serializedValue.Height : 100;
        this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : false;
        this.IsRelative = serializedValue.IsRelative ? serializedValue.IsRelative : false;
        this.isApplyToAllIntervals = serializedValue.isApplyToAllIntervals ? serializedValue.isApplyToAllIntervals : false;
        this.seqNumber = serializedValue.seqNumber ? serializedValue.seqNumber : this.getDefaultseqNumber();
        this.LongLineColor = serializedValue.LongLineColor ? serializedValue.LongLineColor : this.getDefaultLongLineObj();
        this.ShortLineColor = serializedValue.ShortLineColor ? serializedValue.ShortLineColor : this.getDefaultShortLineObj();
        this.SdUpLineColor = serializedValue.SdUpLineColor ? serializedValue.SdUpLineColor : this.getDefaultSdUpLineObj();
        this.SdDnLineColor = serializedValue.SdDnLineColor ? serializedValue.SdDnLineColor : this.getDefaultSdDnLineObj();
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            Height: this.Height,
            IsAvailable: this.IsAvailable,
            IsRelative: this.IsRelative,
            isApplyToAllIntervals: this.isApplyToAllIntervals,
            seqNumber: this.seqNumber,
            LongLineColor: this.LongLineColor,
            ShortLineColor: this.ShortLineColor,
            SdUpLineColor: this.SdUpLineColor,
            SdDnLineColor: this.SdDnLineColor,
        }
    }

    getDefaultExtendedChartSettings() {
        this.IsVisible = true;
        this.Height = 100;
        this.IsAvailable = false;
        this.IsRelative = false;
        this.seqNumber = this.getDefaultseqNumber();
        this.isApplyToAllIntervals = false;
        this.LongLineColor = this.getDefaultLongLineObj();
        this.ShortLineColor = this.getDefaultShortLineObj();
        this.SdUpLineColor = this.getDefaultSdUpLineObj();
        this.SdDnLineColor = this.getDefaultSdDnLineObj();
        return this;
    }

    getObjectMapKey() {
        return "ExtendedChartSettings";
    }
}
