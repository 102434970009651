import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { updateAnalyzerUrl } from "../../../Actions/DatagraphActions";

const NavAccAnalyzer = (props) => {
    const getEnvironmentLink = () => {
        let url = sessionStorage.getItem('analyzerUrl')
        if (url) {
            return url
        }
        switch (process.env.BRANCH_ENV.toUpperCase() || '') {
            case "DEV":
                return "https://analyzerdev.panaray.com";
            case "STAGE":
                return "https://analyzerstage.panaray.com";
            case "PROD":
                return "https://analyzer.panaray.com";
            default:
                return "https://analyzerdev.panaray.com";
        }
    };

    const [linkState, setLinkState] = useState(getEnvironmentLink());
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        
        if (iframe) {
            // Attach the onload event handler
            iframe.onload = () => {
                const access_token = sessionStorage.getItem('_pUser');
                
                // Post the message after the iframe is fully loaded
                iframe.contentWindow.postMessage({ action: 'SESSION_COOKIE', access_token }, linkState);
            };
        }
    }, [linkState]);

    return (
        <div className="listConsole">
            <div className="extender">
                <div className="COLS research-dashboard">
                    <iframe
                        ref={iframeRef}
                        src={linkState}
                        allowFullScreen={true}
                        title="Account-Analyser"
                        width="100%"
                        height="100%">
                    </iframe>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ DatagraphReducers }) => {
    const { analyzerUrl } = DatagraphReducers.DataGraphReducer;
    return { analyzerUrl };
};

const mapDispatchToProps = (dispatch) => ({
    updateAnalyzerUrl: (url) => dispatch(updateAnalyzerUrl(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(NavAccAnalyzer);

