import PropTypes from 'prop-types';
import React from "react";
import ChartVisual from "./ChartVisual.jsx";
import Pointer from "Pointer";
import ThemeHelper from "ThemeHelper";
import ExtremeDataValue from "ExtremeDataValue";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import textWidth from "text-width";
import textHeight from "text-height";
import LabelText from '../Timeline/LabelText.jsx';
import SettingsStore from "SettingsStore";
import TimeTrackingWindow from "TimeTrackingWindow";
import { connect } from 'react-redux';

class BarVisual extends ChartVisual {
  constructor(props) {
    super(props);
    this.state = { DataSource: props.DataSource };
    this.state.highLightNode = "";
    this.state.highLightColor = null;
    this.state.lastNodeData = null; 
    this.state.currentNodeData = null;
    this.state.refresh = true;

    this.hitTest = this.hitTest.bind(this);
    this.isPointInStroke = this.isPointInStroke.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.onContextMenuClick = this.onContextMenuClick.bind(this);
    this.labelPointsArr = [];
    this.lineWidth = props.nodeWidth ? props.nodeWidth < 12 ? 2 : ((props.nodeWidth * 4) / 16) : 2;
    this.priceWidth = props.nodeWidth ? props.nodeWidth < 12 ? 0 : ((props.nodeWidth * 4) / 16) : 0;
    this.getTextWidth = this.getTextWidth.bind(this);
    this.getTextHeight = this.getTextHeight.bind(this);
    this.hitTestForLabel = this.hitTestForLabel.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleMouseDown, false);
    this.setState({})
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleMouseDown, false);
  }

  getTextWidth (text) {
    let width = textWidth(text, {
      family: "calibri",
      size: 8
    });
    return width;
  }

  getTextHeight (text) {
    let height = textHeight(text, {
      family: "calibri",
      size: 8
    });
    return height.height;
  }

  hitTestForLabel (x, y) {
    let isHit = false;
    for (let i = 0; i < this.labelPointsArr.length; i++) {
      if (x >= this.labelPointsArr[i].x - ((this.labelPointsArr[i].width + 8) / 2) && x <= this.labelPointsArr[i].x + (this.labelPointsArr[i].width + 8) / 2
        && y >= this.labelPointsArr[i].y - ((this.labelPointsArr[i].height + 10) / 2) && y <= this.labelPointsArr[i].y + ((this.labelPointsArr[i].height) / 2)) {
        isHit = true;
        return isHit;
      }
    }

    return isHit;
  }

  hitTest(x, y) {
    //   let ctx = this.mainCanvas.getContext("2d");
    let ishit = this.isPointInStroke(x, y)// ctx.isPointInPath(x, y);
    return ishit;
  }

  isPointInStroke(x, y) {
    if (this.state.DataSource && this.state.DataSource.allPoints) {
      let prcLength = this.state.DataSource.allPoints.length;
      for (var j = 0; j < prcLength; j++) {
        let linePoint = this.state.DataSource.allPoints[j];
        if (linePoint && (linePoint.xAxis >= x - 3 && linePoint.xAxis < x + 3) && (linePoint.yLow >= y - 3 && linePoint.yHigh <= y + 3)) {
          return true;
        }
      }
    }
    return false;
  }

  handleMouseDown(e) {

    if (!this.mainCanvas || e.which != 3) return;

    let dimensions = this.mainCanvas.getBoundingClientRect();
    let xAxis = parseInt(e.clientX - dimensions.left);
    let yAxis = parseInt(e.clientY - dimensions.top);
    let isHit = this.hitTest(xAxis, yAxis);

    if (isHit) {
      DatagraphStore.setChartContextHit({});
      e.preventDefault();
    }
    else {
      isHit = this.hitTestForLabel(xAxis, yAxis);

      if (isHit) {
        DatagraphStore.setChartContextHit({});
        e.preventDefault();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.DataSource || nextProps.DataSource.length === 0) {
      this.clearChart();
    }
    this.lineWidth = nextProps.nodeWidth ? nextProps.nodeWidth < 12 ? 2 : ((nextProps.nodeWidth * 4) / 16) : 2;
    this.priceWidth = nextProps.nodeWidth ? nextProps.nodeWidth < 12 ? 0 : ((nextProps.nodeWidth * 4) / 16) : 0;
    const refresh = !this.state.refresh;
    this.setState({ DataSource: nextProps.DataSource, refresh: refresh });
  }
  removeLightNode() {
      this.setState({ highLightNode: "", highLightColor: null, lastNodeData: null });
  }
  highLightSVGNode() {
      if (this.state.lastNodeData === null) {
          return (<div></div>);
      }
      const nodeData = this.state.lastNodeData;
      const lineStyle = {
          fitPosition: "fill",
          position: "absolute",
          left: "0px",
          top: "0px",
          zIndex: this.state.zIndex,
          pointerEvents: "none",
          opacity: "0.4"
      };
      const pathData = this.prepareSingleSvgLine(nodeData);
      const color = "White";
      return (<svg style={lineStyle}
                  className="svg svg-barvisual-rslayer"
                  id="RSLayer"
                  height='100%'
                  width='100%'>
                  <path d={pathData} data-disable-track-price="true"
                        ref={(ref) => this.line = ref}
                        stroke={color}
                        strokeWidth={this.lineWidth}
                        fill="none"
                        pointerEvents="none">
                  </path>
              </svg>);
  }
  highLightNode(nodeIndex) {
    const nodeData = this.state.DataSource.allPoints[nodeIndex];
    if (!nodeData) return;
    if (nodeData.IsVisible === false) {
      return;
    }
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const useCanvas = tabDataGraphSettings.useCanvas;
    if (!useCanvas) {
        this.setState({ lastNodeData: nodeData });
        return;
    }
    let ctx = this.mainCanvas.getContext("2d");
    ctx.lineWidth = this.lineWidth;
    ctx.imageSmoothingEnabled = true;
    if (nodeData.UpTick) {
      ctx.strokeStyle = this.props.posColor;
    }
    else {
      ctx.strokeStyle = this.props.negColor;
    }
    if (this._previousHighLightNode && this._previousHighLightNode !== nodeData) {
      // this.removeHighLightedNode(this._previousHighLightNode);
    }
    this._previousHighLightNode = nodeData;
    this.drawNode(ctx, nodeData);

  }
  updateSVGLastNode() {
      if (this.state.currentNodeData === null) {
          return (<div></div>);
      }
      const nodeData = this.state.currentNodeData;
      const lineStyle = {
          fitPosition: "fill",
          position: "absolute",
          left: "0px",
          top: "0px",
          zIndex: this.state.zIndex,
          pointerEvents: "none",
          opacity: "1"
      };
      const pathData = this.prepareSingleSvgLine(nodeData);
      let color = this.props.negColor;
      if (nodeData.UpTick) {
          color = this.props.posColor;
      }
      return (<svg  style={lineStyle}
                    className="svg svg-barvisual-rslayer"
                    id="RSLayer"
                    height='100%'
                    width='100%'>
                  <path d={pathData} data-disable-track-price="true"
                        ref={(ref) => this.line = ref}
                        stroke={color}
                        strokeWidth={this.lineWidth}
                        fill="none"
                        pointerEvents="none">
                  </path>
              </svg>);
  }

  updateLastNode(nodeData, prvNodeData) {
    if (!nodeData) return;
    if (nodeData.IsVisible === false) {
      return;
    }
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const useCanvas = tabDataGraphSettings.useCanvas;
    if (!useCanvas) {
        this.setState({ currentNodeData: nodeData });
        return;
    }
    const ctx = this.mainCanvas.getContext("2d");
    ctx.lineWidth = this.lineWidth;
    ctx.imageSmoothingEnabled = true;
    if (nodeData.UpTick) {
      ctx.strokeStyle = this.props.posColor;
    } else {
      ctx.strokeStyle = this.props.negColor;
    }
    this.drawBarNode(ctx, nodeData, prvNodeData);
  }
  removeHighLightedNode(nodeIndex) {
    const nodeData = this.state.DataSource.allPoints[nodeIndex];
    if (!nodeData) return;
    if (nodeData.IsVisible === false) {
      return;
    }
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const useCanvas = tabDataGraphSettings.useCanvas;
    if (!useCanvas) {
        if (nodeData === this.state.lastNodeData) {
            this.setState({ lastNodeData: null });
        }
        return;
    }
    let ctx = this.mainCanvas.getContext("2d");
    ctx.lineWidth = this.lineWidth;
    ctx.imageSmoothingEnabled = true;
    if (nodeData.UpTick) {
      ctx.strokeStyle = this.props.posColor;
    }
    else {
      ctx.strokeStyle = this.props.negColor;
    }
    this.drawNode(ctx, nodeData);
  }

  clearChart() {
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const useCanvas = tabDataGraphSettings.useCanvas;
    if (this.mainCanvas && useCanvas) {
      let rect = this.mainCanvas.getBoundingClientRect();
      let ctx = this.mainCanvas.getContext("2d");
      ctx.clearRect(0, 0, rect.width, rect.height);
    }

  }
  drawHiLoPoints() {
      const source = this.state.DataSource;
      const textColor = ThemeHelper.getThemedBrush("scaleText");
      if (!source ||
          this.state.height === 0.0 ||
          this.state.width === 0.0 ||
          !this.mainCanvas) {

          return(<div></div>);
      }

      return (
         
          <svg className="svg svg-barvisual-figure">
              {source.highPoints && this.props.isHoLoPriceVisible && !this.props.isPriceLabelsDisabled && source.highPoints.map((itm, i) => (itm.IsVisible != undefined ? itm.IsVisible : true) && itm.HiLoPoints && 
                  itm.HiLoPoints.map((sItm, j) => this.props.isHoLoPriceVisible && sItm.IsHigh &&
                  <LabelText
                      key={i*10+j}
                      textAnchor="middle"
                      isHighlighted={true}
                      textValue={ExtremeDataValue.showHiLoPrices(sItm.HighLowValue ? sItm.HighLowValue : '')}
                      dx="0"
                      dy="0"
                      style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                      textPosX={itm.xAxis}
                      textPosY={itm.yHigh - (this.props.isHoLoPctVisible ? 4 + 9 : 4) - (this.props.isUpDaysVisible && itm.graphData.UpDays ? 12 : 0)} />))}
              {source.highPoints && this.props.isHoLoPctVisible && !this.props.isPriceLabelsDisabled && source.highPoints.map((itm, i) => (itm.IsVisible != undefined ? itm.IsVisible : true) && itm.HiLoPoints &&
                  itm.HiLoPoints.map((sItm, j) => this.props.isHoLoPctVisible && sItm.IsHigh &&
                  <LabelText
                      key={i * 10 + j}
                      textAnchor="middle"
                      isHighlighted={true}
                      textValue={sItm.PercentChangedText ? sItm.PercentChangedText : ''}
                      dx="0"
                      dy="0"
                      style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                      textPosX={itm.xAxis}
                      textPosY={itm.yHigh - 4 - (this.props.isUpDaysVisible && itm.graphData.UpDays ? 12 : 0)} />))}
              {source.highPoints && this.props.isHoLoPriceVisible && !this.props.isPriceLabelsDisabled && source.highPoints.map((itm, i) => (itm.IsVisible != undefined ? itm.IsVisible : true) && itm.HiLoPoints &&
                  itm.HiLoPoints.map((sItm, j) => this.props.isHoLoPriceVisible && sItm.IsLow &&
                  <LabelText
                      key={i * 10 + j}
                      textAnchor="middle"
                      isHighlighted={true}
                      textValue={ExtremeDataValue.showHiLoPrices(sItm.HighLowValue ? sItm.HighLowValue : '')}
                      dx="0"
                      dy="0"
                      style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                      textPosX={itm.xAxis}
                      textPosY={itm.yLow + 12} />))}
              {source.highPoints && this.props.isHoLoPctVisible && !this.props.isPriceLabelsDisabled && source.highPoints.map((itm, i) => (itm.IsVisible != undefined ? itm.IsVisible : true) && itm.HiLoPoints &&
                  itm.HiLoPoints.map((sItm, j) => this.props.isHoLoPctVisible && sItm.IsLow &&
                  <LabelText
                      key={i * 10 + j}
                      textAnchor="middle"
                      isHighlighted={true}
                      textValue={sItm.PercentChangedText ? sItm.PercentChangedText: ''}
                      dx="0"
                      dy="0"
                      style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                      textPosX={itm.xAxis}
                      textPosY={itm.yLow + (this.props.isHoLoPriceVisible ? (12 + 9) : 12)} />))}
              {source.lowPoints && this.props.isHoLoPriceVisible && !this.props.isPriceLabelsDisabled && source.lowPoints.map((itm, i) => (itm.IsVisible != undefined ? itm.IsVisible : true) && itm.HiLoPoints &&
                  itm.HiLoPoints.map((sItm, j) => this.props.isHoLoPriceVisible && sItm.IsHigh &&
                  <LabelText
                      key={i * 10 + j}
                      textAnchor="middle"
                      isHighlighted={true}
                      textValue={ExtremeDataValue.showHiLoPrices(sItm.HighLowValue ? sItm.HighLowValue : '')}
                      dx="0"
                      dy="0"
                      style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                      textPosX={itm.xAxis}
                      textPosY={itm.yHigh - (this.props.isHoLoPctVisible ? 4 + 9 : 4)} />))}
              {source.lowPoints && this.props.isHoLoPctVisible && !this.props.isPriceLabelsDisabled && source.lowPoints.map((itm, i) => (itm.IsVisible != undefined ? itm.IsVisible : true) && itm.HiLoPoints &&
                  itm.HiLoPoints.map((sItm, j) => this.props.isHoLoPctVisible && sItm.IsHigh &&
                  <LabelText
                      key={i * 10 + j}
                      textAnchor="middle"
                      isHighlighted={true}
                      textValue={sItm.PercentChangedText ? sItm.PercentChangedText : ''}
                      dx="0"
                      dy="0"
                      style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                      textPosX={itm.xAxis}
                      textPosY={itm.yHigh - 4} />))}
              {source.lowPoints && this.props.isHoLoPriceVisible && !this.props.isPriceLabelsDisabled && source.lowPoints.map((itm, i) => (itm.IsVisible != undefined ? itm.IsVisible : true) && itm.HiLoPoints &&
                  itm.HiLoPoints.map((sItm, j) => this.props.isHoLoPriceVisible && sItm.IsLow &&
                  <LabelText
                      key={i * 10 + j}
                      textAnchor="middle"
                      isHighlighted={true}
                      textValue={ExtremeDataValue.showHiLoPrices(sItm.HighLowValue ? sItm.HighLowValue : '')}
                      dx="0"
                      dy="0"
                      style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                      textPosX={itm.xAxis}
                      textPosY={itm.yLow + 12} />))}
              {source.lowPoints && this.props.isHoLoPctVisible && !this.props.isPriceLabelsDisabled && source.lowPoints.map((itm, i) => (itm.IsVisible != undefined ? itm.IsVisible : true) && itm.HiLoPoints &&
                  itm.HiLoPoints.map((sItm, j) => this.props.isHoLoPctVisible && sItm.IsLow &&
                  <LabelText
                      key={i * 10 + j}
                      textAnchor="middle"
                      isHighlighted={true}
                      textValue={sItm.PercentChangedText ? sItm.PercentChangedText : ''}
                      dx="0"
                      dy="0"
                      style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                      textPosX={itm.xAxis}
                      textPosY={itm.yLow + (this.props.isHoLoPriceVisible ? (12 + 9) : 12)} />))}
          </svg>
      );

  }
  prepareSingleSvgLine(obj) {
      if (obj === undefined || obj === null) {
          return " ";
      }
      let pathData = "";
      if (obj.yPrice !== undefined) {
          if (obj.IsVisible === true || obj.IsVisible === undefined) {
              pathData += " M " +
                  obj.xAxis +
                  " " +
                  obj.yHigh +
                  " L " +
                  obj.xAxis +
                  " " +
                  obj.yLow +
                  " M " +
                  (obj.xAxis - (2 + this.priceWidth)).toString() +
                  " " +
                  obj.yPrice +
                  " L " +
                  (obj.xAxis + (3 + this.priceWidth)).toString() +
                  " " +
                  obj.yPrice;
          }
      } else {
          if (obj.IsVisible || obj.IsVisible === undefined) {
              if (obj.yHigh !== undefined && obj.yLow !== undefined) {
                  pathData += " M " +
                      obj.xAxis +
                      " " +
                      obj.yHigh +
                      " L " +
                      obj.xAxis +
                      " " +
                      obj.yLow;
              }
          }
      }

      return pathData;
  }
  prepareSvgLineSource(lineData, currDate) {
      if (lineData === undefined || lineData === null || lineData[0] === undefined || lineData.length < 1) {
          return " ";
      }
      let pathData = "";
      let upData = "";
      if (lineData[0].yPrice !== undefined) {
          lineData.map((obj) => {
            if(!isNaN(obj.yLow) && !isNaN(obj.yHigh))
            {
              if (obj.IsVisible === true && obj.Date <= currDate || obj.IsVisible === undefined) {
                  pathData += " M " +
                      obj.xAxis +
                      " " +
                      obj.yHigh +
                      " L " +
                      obj.xAxis +
                      " " +
                      obj.yLow +
                      " M " +
                      (obj.xAxis - (2 + this.priceWidth)).toString() +
                      " " +
                      obj.yPrice +
                      " L " +
                      (obj.xAxis + (3 + this.priceWidth)).toString() +
                      " " +
                      obj.yPrice;
                  if (this.props.isUpDaysVisible && obj.graphData.UpDays) {
                     upData += " M " +
                        obj.xAxis +
                        " " +
                        (obj.yHigh - 4) +
                        " L " +
                        obj.xAxis +
                        " " +
                        (obj.yHigh - 12);
                 }
              }
            }  
          });
      } else {
          lineData.map((obj) => {
            if(!isNaN(obj.yLow) && !isNaN(obj.yHigh))
            {
              if (obj.IsVisible && obj.Date <= currDate || obj.IsVisible === undefined) {
                  if (obj.yHigh !== undefined && obj.yLow !== undefined) {
                      pathData += " M " +
                          obj.xAxis +
                          " " +
                          obj.yHigh +
                          " L " +
                          obj.xAxis +
                          " " +
                          obj.yLow;
                  }
              }
            } 
          });
      }
      
      return { pathData, upData };
  }

  drawSVGBars(source, color, currDate) {
      const lineStyle = {
          fitPosition: "fill",
          position: "absolute",
          left: "0px",
          top: "0px",
          zIndex: this.state.zIndex,
          pointerEvents: "none",
          opacity: this.state.opacity
      };
      const pathData = this.prepareSvgLineSource(source, currDate);
      const textColor = ThemeHelper.getThemedBrush("scaleText");
      return (<svg  style={lineStyle}
                    className="svg svg-barvisual-rslayer"
                    id="RSLayer"
                    height='100%'
                    width='100%'>
          <path d={pathData.pathData} data-disable-track-price="true"
              ref={(ref) => this.line = ref}
              stroke={color}
              strokeWidth={this.lineWidth}
              fill="none"
              pointerEvents="none">
          </path>
          <path d={pathData.upData} data-disable-track-price="true"
              ref={(ref) => this.line2 = ref}
              stroke={textColor}
              strokeWidth={this.lineWidth}
              fill="none"
              pointerEvents="none">
          </path>
        </svg>);

  }

  drawBars(useCanvas) {
    var source = this.state.DataSource;
    if (!source || !useCanvas ||
      this.state.height === 0.0 ||
      this.state.width === 0.0 ||
      !this.mainCanvas) {

      return;
    }
    let rect = this.mainCanvas.getBoundingClientRect();
    let ctx = this.mainCanvas.getContext("2d");

    ctx.canvas.height = rect.height;
    ctx.canvas.width = rect.width;
    ctx.clearRect(0, 0, rect.width, rect.height);
    if (!source.highPoints ||
      !source.lowPoints) {
      return;
    }
    ctx.lineWidth = this.lineWidth;
    ctx.textAlign = "center";
    ctx.imageSmoothingEnabled = true;
    ctx.strokeStyle = this.props.posColor;
    ctx.fillStyle = ThemeHelper.getThemedBrush("scaleText");
    ctx.font = "8pt Calibri";
    ctx.beginPath();
    let max = source.highPoints.length;
    for (let i = 0; i < max; i++) {
      var hiLowData = source.highPoints[i];
      if (hiLowData.IsVisible === false) {
        continue;
      }
      ctx.moveTo(hiLowData.xAxis, hiLowData.yHigh);
      ctx.lineTo(hiLowData.xAxis, hiLowData.yLow);
      if (hiLowData.yPrice) {
          ctx.moveTo(hiLowData.xAxis - (2 + this.priceWidth), hiLowData.yPrice);
          ctx.lineTo(hiLowData.xAxis + (3 + this.priceWidth), hiLowData.yPrice);
      }
      if (hiLowData.HiLoPoints) {
        let ptLen = hiLowData.HiLoPoints.length;
        let up = -3;
        let lo = 12;
        if (hiLowData.HiLoPoints && ptLen > 0) {
          for (var x = 0; x < ptLen; x++) {
            if (hiLowData.HiLoPoints[x].IsHigh) {
              if (hiLowData.HiLoPoints[x].showPct) {
                if (hiLowData.HiLoPoints[x].PercentChangedText) {
                    if (useCanvas) {
                        ctx.fillText(hiLowData.HiLoPoints[x].PercentChangedText, hiLowData.xAxis, hiLowData.yHigh + up);
                    }
                    this.labelPointsArr.push({ 'x': hiLowData.xAxis, "y": hiLowData.yHigh + up, "width": this.getTextWidth(hiLowData.HiLoPoints[x].PercentChangedText), "height": this.getTextHeight(hiLowData.HiLoPoints[x].PercentChangedText) })
                }
                up -= 9;
              }
              if (hiLowData.HiLoPoints[x].showPrice) {
                if (useCanvas) {
                    ctx.fillText(ExtremeDataValue.showHiLoPrices(hiLowData.HiLoPoints[x].HighLowValue),
                               hiLowData.xAxis, hiLowData.yHigh + up);
                }

                this.labelPointsArr.push({
                    'x': hiLowData.xAxis,
                    "y": hiLowData.yHigh + up,
                    "width": this.getTextWidth(ExtremeDataValue.showHiLoPrices(hiLowData.HiLoPoints[x].HighLowValue)),
                    "height": this.getTextHeight(ExtremeDataValue.showHiLoPrices(hiLowData.HiLoPoints[x].HighLowValue))
                });
              }
            }
            if (hiLowData.HiLoPoints[x].IsLow) {
              if (hiLowData.HiLoPoints[x].showPrice) {
                  if (useCanvas) {
                      ctx.fillText(ExtremeDataValue.showHiLoPrices(hiLowData.HiLoPoints[x].HighLowValue),
                                     hiLowData.xAxis, hiLowData.yLow + lo);    
                  }

                this.labelPointsArr.push({
                  'x': hiLowData.xAxis, "y": hiLowData.yLow + lo,
                  "width": this.getTextWidth(ExtremeDataValue.showHiLoPrices(hiLowData.HiLoPoints[x].HighLowValue)),
                  "height": this.getTextHeight(ExtremeDataValue.showHiLoPrices(hiLowData.HiLoPoints[x].HighLowValue))
                })
                lo += 9;
              }
              if (hiLowData.HiLoPoints[x].showPct && hiLowData.HiLoPoints[x].PercentChangedText) {
                  if (useCanvas) {
                      ctx.fillText(hiLowData.HiLoPoints[x].PercentChangedText, hiLowData.xAxis, hiLowData.yLow + lo);
                  }

                this.labelPointsArr.push({
                  'x': hiLowData.xAxis, "y": hiLowData.yLow + lo,
                  "width": this.getTextWidth(hiLowData.HiLoPoints[x].PercentChangedText),
                  "height": this.getTextHeight(hiLowData.HiLoPoints[x].PercentChangedText)
                })
              }

            }
          }
        }
      }
    }
    ctx.stroke();
    ctx.closePath();
    ctx.strokeStyle = this.props.negColor;
    ctx.beginPath();
    max = source.lowPoints.length;
    for (let i = 0; i < max; i++) {
      var lowData = source.lowPoints[i];
      if (lowData.IsVisible === false) {
        continue;
      }
      ctx.moveTo(lowData.xAxis, lowData.yHigh);
      ctx.lineTo(lowData.xAxis, lowData.yLow);
      if (lowData.yPrice) {
          ctx.moveTo(lowData.xAxis - (2 + this.priceWidth), lowData.yPrice);
          ctx.lineTo(lowData.xAxis + (3 + this.priceWidth), lowData.yPrice);
      }
      if (lowData.HiLoPoints) {
        let ptLen = lowData.HiLoPoints.length;
        let up = -3;
        let lo = 12;
        if (lowData.HiLoPoints && ptLen > 0) {
          for (let x = 0; x < ptLen; x++) {
            if (lowData.HiLoPoints[x].IsHigh) {
              if (lowData.HiLoPoints[x].showPct) {
                if (lowData.HiLoPoints[x].PercentChangedText) {
                    if (useCanvas) {
                        ctx.fillText(lowData.HiLoPoints[x].PercentChangedText, lowData.xAxis, lowData.yHigh + up);
                    }

                  this.labelPointsArr.push({
                    'x': lowData.xAxis, "y": lowData.yHigh + up,
                    "width": this.getTextWidth(lowData.HiLoPoints[x].PercentChangedText),
                    "height": this.getTextHeight(lowData.HiLoPoints[x].PercentChangedText)
                  })
                }
                up -= 9;
              }
              if (lowData.HiLoPoints[x].showPrice) {
                  if (useCanvas) {
                      ctx.fillText(ExtremeDataValue.showHiLoPrices(lowData.HiLoPoints[x].HighLowValue),
                  lowData.xAxis, lowData.yHigh + up);
                  }

                this.labelPointsArr.push({
                  'x': lowData.xAxis, "y": lowData.yHigh + up,
                  "width": this.getTextWidth(ExtremeDataValue.showHiLoPrices(lowData.HiLoPoints[x].HighLowValue)),
                  "height": this.getTextHeight(ExtremeDataValue.showHiLoPrices(lowData.HiLoPoints[x].HighLowValue))
                })
              }
            }
            if (lowData.HiLoPoints[x].IsLow) {
              if (lowData.HiLoPoints[x].showPrice) {
                  if (useCanvas) {
                      ctx.fillText(ExtremeDataValue.showHiLoPrices(lowData.HiLoPoints[x].HighLowValue),
                  lowData.xAxis, lowData.yLow + lo);
                  }

                this.labelPointsArr.push({
                  'x': lowData.xAxis, "y": lowData.yLow + lo,
                  "width": this.getTextWidth(ExtremeDataValue.showHiLoPrices(lowData.HiLoPoints[x].HighLowValue)),
                  "height": this.getTextHeight(ExtremeDataValue.showHiLoPrices(lowData.HiLoPoints[x].HighLowValue))
                })
                lo += 9;
              }
              if (lowData.HiLoPoints[x].showPct && lowData.HiLoPoints[x].PercentChangedText) {
                  if (useCanvas) {
                      ctx.fillText(lowData.HiLoPoints[x].PercentChangedText, lowData.xAxis, lowData.yLow + lo);
                  }

                this.labelPointsArr.push({
                  'x': lowData.xAxis, "y": lowData.yLow + lo,
                  "width": this.getTextWidth(lowData.HiLoPoints[x].PercentChangedText),
                  "height": this.getTextHeight(lowData.HiLoPoints[x].PercentChangedText)
                });
              }
            }
          }
        }
      }
    }
    ctx.stroke();
    ctx.closePath();
  }
  updatePointer(contentL, contentR, yValue, boxL, boxR, numL) {
    if (this.pointer && !isNaN(yValue)) {
      this.pointer.updatePointer(contentL, contentR, yValue, boxL, boxR, numL);
    }
  }
  drawNode(ctx, hiLowData) {
    ctx.beginPath();
    ctx.moveTo(hiLowData.xAxis, hiLowData.yHigh);
    ctx.lineTo(hiLowData.xAxis, hiLowData.yLow);
    if (hiLowData.yPrice) {
        ctx.moveTo(hiLowData.xAxis - (2 + this.priceWidth), hiLowData.yPrice);
        ctx.lineTo(hiLowData.xAxis + (3 + this.priceWidth), hiLowData.yPrice);
    }
    ctx.stroke();
    ctx.closePath();
  }

  drawBarNode(ctx, hiLowData, prvNodeData) {
    if (prvNodeData && prvNodeData.xAxis === hiLowData.xAxis) {
        ctx.clearRect(prvNodeData.xAxis - (2 + this.priceWidth), prvNodeData.yHigh - 4, (13 + this.priceWidth), prvNodeData.yLow - prvNodeData.yHigh + 10);
    } else {
        ctx.clearRect(hiLowData.xAxis - (2 + this.priceWidth), hiLowData.yHigh - 4, (13 + this.priceWidth), hiLowData.yLow - hiLowData.yHigh + 10);
    }

    ctx.beginPath();
    ctx.moveTo(hiLowData.xAxis, hiLowData.yHigh);
    ctx.lineTo(hiLowData.xAxis, hiLowData.yLow);
    if (hiLowData.yPrice) {
        ctx.moveTo(hiLowData.xAxis - (2 + this.priceWidth), hiLowData.yPrice);
        ctx.lineTo(hiLowData.xAxis + (3 + this.priceWidth), hiLowData.yPrice);
    }
    ctx.stroke();
    ctx.closePath();

  }

  getUpTickDownTickGeometry() {
    var upTickGeometry = "";
    var downTickGeometry = "";
    if (this.state.DataSource == undefined || this.state.DataSource.length === 0) return { UpTick: null, DownTick: null };
    for (let i = 0; i < this.state.DataSource.length; i++) {
      var hiLowData = this.state.DataSource[i];
      if (hiLowData.UpTick) {
        upTickGeometry = upTickGeometry.concat(this.getGeometryString(hiLowData));
      }
      else {

        downTickGeometry = downTickGeometry.concat(this.getGeometryString(hiLowData));
      }
    }
    return { UpTick: upTickGeometry, DownTick: downTickGeometry };
  }
  // updated
   getGeometryString(hiLowData) {
    var returnValue = "";
    returnValue = returnValue.concat(" M" + hiLowData.xAxis + " " + hiLowData.yHigh + " " + "V" + hiLowData.yLow);
    if (hiLowData.yPrice !== undefined) {
      returnValue = returnValue.concat(" M" + (hiLowData.xAxis - (2 + this.priceWidth)) + " " + hiLowData.yPrice + " " + "H" + (hiLowData.xAxis + (3 + this.priceWidth)));    }
    return returnValue;
  }
  onContextMenuClick(e) {
    e.preventDefault();
    return false;
  }
  render() {
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const useCanvas = tabDataGraphSettings.useCanvas;
    const today = new Date();
    const currDate = this.state.DataSource.allPoints && this.state.DataSource.allPoints.length > 0 && this.state.DataSource.allPoints[0] && this.state.DataSource.allPoints[0].Date ? this.state.DataSource.allPoints[0].Date : today;
    TimeTrackingWindow.trackChartLoadingTime();
    this._previousHighLightNode = undefined;
    this.drawBars(useCanvas);

    const refresh = this.state.refresh;
    return (
      <div>
        <canvas
            onContextMenu={this.onContextMenuClick}
            className="chartVisual canvas-chart-visual"
            ref={(ref) => this.mainCanvas = ref}
            style={{ fitPosition: "fill", width: "100%", height: "100%", position: "absolute" }}>
        </canvas>
        { refresh === true &&
           !useCanvas && this.state.DataSource && this.state.DataSource.highPoints && this.state.DataSource.highPoints.length > 0 && this.drawSVGBars(this.state.DataSource.highPoints, this.props.posColor, currDate)
        }
        { refresh === true &&
           !useCanvas && this.state.DataSource && this.state.DataSource.lowPoints && this.state.DataSource.lowPoints.length > 0 && this.drawSVGBars(this.state.DataSource.lowPoints, this.props.negColor, currDate)
        }
        { refresh === false &&
           !useCanvas && this.state.DataSource && this.state.DataSource.highPoints && this.state.DataSource.highPoints.length > 0 && this.drawSVGBars(this.state.DataSource.highPoints, this.props.posColor, currDate)
        }
        { refresh === false &&
           !useCanvas && this.state.DataSource && this.state.DataSource.lowPoints && this.state.DataSource.lowPoints.length > 0 && this.drawSVGBars(this.state.DataSource.lowPoints, this.props.negColor, currDate)
        }
        {(refresh === true) &&
           !useCanvas && this.drawHiLoPoints()
        }
        {(refresh === true) &&
           !useCanvas && this.highLightSVGNode()
        }
        {(refresh === false) &&
           !useCanvas && this.drawHiLoPoints()
        }
        {(refresh === false) &&
           !useCanvas && this.highLightSVGNode()
        }
        {/* {!useCanvas && this.drawHiLoPoints()}
        {!useCanvas && this.highLightSVGNode()} */}
        {/*!useCanvas && this.updateSVGLastNode()*/}
        {/* {this.props.showPointer &&
          <Pointer
            ref={(ref) => this.pointer = ref}
            hasBox={this.props.showBox}
            height={20}
            width={115}
            fill={ThemeHelper.getThemedBrush("pointerColor")}
            lcolor={lColor}
            rcolor={rColor} />
        } */}
      </div>
    );
  }
}

BarVisual.propTypes = {
  DataSource: PropTypes.object,
  showPointer: PropTypes.bool,
  showBox: PropTypes.bool
};
const mapStateToProps = ({appColor})=>{
  const { posColor, negColor  } = appColor;

  return { posColor, negColor }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(BarVisual)