class UpDays {
    constructor() {
    }
    computeLargestIncreaseDecrease(stockQuotesIn, scale) {
        const stkLength = stockQuotesIn.length;
        const scaleType = scale.getObjectMapKey();
        const logScale = scaleType === "FixedWonLogScale" || scaleType === "FixedLogScale" || scaleType === "LogScale";

        let Hdif = 0;
        let Ldif = 0;
        let Hpt = 0;
        let Lpt = 0;
        let Xtmp;

        for (let PricePtr = 0; PricePtr < stkLength; PricePtr++) {
            stockQuotesIn[PricePtr].IncreaseDecrase = 0;
            if (!stockQuotesIn[PricePtr].IsVisible) continue;
            if (stockQuotesIn[PricePtr + 1] === undefined) break;
            if (!logScale) {
                Xtmp = Math.abs(stockQuotesIn[PricePtr].Close - stockQuotesIn[PricePtr + 1].Close);
            }
            else {
                Xtmp = Math.abs(stockQuotesIn[PricePtr].Close - stockQuotesIn[PricePtr + 1].Close) / stockQuotesIn[PricePtr].Close;
            }

            if (stockQuotesIn[PricePtr].Close < stockQuotesIn[PricePtr + 1].Close) {
                if (Xtmp > Ldif) {
                    Ldif = Xtmp;
                    Lpt = PricePtr;
                }
            }

            if (stockQuotesIn[PricePtr].Close > stockQuotesIn[PricePtr + 1].Close) {
                if (Xtmp > Hdif) {
                    Hdif = Xtmp;
                    Hpt = PricePtr;
                }
            }
        }
        stockQuotesIn[Lpt].IncreaseDecrase = 2;
        stockQuotesIn[Hpt].IncreaseDecrase = 1;
    }
    checkUpDays(stockQuotesIn, index) {
        const stkLength = stockQuotesIn.length;
        if (stkLength - index < 260) {
            return false;
        }
        if (stockQuotesIn[index].Close === undefined) {
            return false;
        }
        let stkPr = stockQuotesIn[index].Close;
        let stkPr15 = stockQuotesIn[index + 14].Close;
        let upDayFlg = false;
        let perInc = 0;
        let upDay15 = 0;
        let totDays = 0;

        if (stkPr15 > 0) {
            perInc = (stkPr / stkPr15 - 1) * 100;
        }

        if (perInc >= 18) {
            for (let i = index; i < index + 30; i++) {
                if (stockQuotesIn[i]._volume > 0) {
                    if (stockQuotesIn[i].Close >= stockQuotesIn[i + 1].Close) {
                        upDay15++;
                    }
                    totDays++;
                    if (totDays > 14) break;
                }
            }
            upDayFlg = upDay15 >= 12;
        }

        let volInc = 0;

        if (upDayFlg) {
            let vol15 = 0;
            let vol50 = 0;
            for (let i = index; i < index + 51; i++) {
                if (i - index < 15) {
                    vol15 += stockQuotesIn[i]._volume;
                }
                if (i - index < 50) {
                    vol50 += stockQuotesIn[i]._volume;
                }
            }
            vol15 /= 15;
            vol50 /= 50;
            if (vol50 > 0 && vol15 > 0) {
                volInc = (vol15 / vol50 - 1) * 100;
            }
        }

        let offHi = -999;

        if (volInc > 0 && upDayFlg) {
            let maxPr = stockQuotesIn[index].Close;
            for (let i = index; i < index + 260; i++) {
                if (stockQuotesIn[i].Close > maxPr) {
                    maxPr = stockQuotesIn[i].Close;
                }
            }
            offHi = (stkPr / maxPr - 1) * 100;
        }

        if (volInc > 0 && upDayFlg && offHi > -14.99) {
            return true;
        }
        else {
            return false;
        }

    }
    Proc_SD(SdRec) {
        let i = 0;
        let sumval = 0;
        let avgval = 0;
        let sumdiff = 0;

        if (SdRec.NDaySd <= 0)   // cannot calculate avg and sd
            return;

        for (i = 0; i < SdRec.NDaySd; i++) {// sum data for NDaySd days
            sumval = sumval + SdRec.Val[i];
        }

        avgval = sumval / (SdRec.NDaySd); // find average
        sumdiff = 0;

        for (i = 0; i < SdRec.NDaySd; i += 1) {
            sumdiff = sumdiff + Math.pow((SdRec.Val[i] - avgval), 2);
        }
        // square and sum the diff from avg.

        SdRec.SdValue = Math.sqrt((sumdiff / (SdRec.NDaySd)));   // standard deviation
        SdRec.AvgValue = avgval;  // average va
    }

    Proc_Extended_Daily(cRec) {
        let ten = 0;
        let five = 0;
        let shortt = 0;
        let longt = 0;
        let PrChg = 0;
        let Sum_Pr = 0;
        let AvgPr = 0;
        let i = 0, j = 0, k = 0;

        const NoDaySd = 120 - 1;  // will calculate for 120 days
        const NoDayAvg = 50;    // will calculate for 50 days
        const stkLength = cRec.length;
        const SdRec = { Val: [], NDaySd: 0, AvgValue: 0, SdValue: 0 };
        const stockQuotesIn = [];
        const ExtRec = [];

        //reorder the array to match the WONDA calculation
        for (i = 0; i < stkLength; i++) {
            stockQuotesIn[stkLength - i] = cRec[i];
        }

        for (i = 2; i <= stkLength; i++) {
            if (!stockQuotesIn[i].IsVisible) {
                const dow = stockQuotesIn[i].Date.getDay();
                var date = stockQuotesIn[i].Date;
                if (dow == 0) {
                    date.setDate(date.getDate() + 1);
                }
                if (dow == 6) {
                    date.setDate(date.getDate() + 2);
                }
                const ExtStr = { ShortVal: undefined, SdUp: undefined, SdDn: undefined, LongVal: undefined, Date: date };
                ExtRec.push(ExtStr);
                continue;
            }
            if (stockQuotesIn[i - 1].Close > 0) {
                if (stockQuotesIn[i - 1].Close > 0)
                    PrChg = ((stockQuotesIn[i].Close / stockQuotesIn[i - 1].Close) - 1) * 100;  // daily change
                else
                    PrChg = 0;
                ten = (0.9 * ten + 0.1 * PrChg);     // 10% exp avg of daily change
                five = (0.95 * five + 0.05 * PrChg); // 5% exp avg of daily change
                shortt = (ten - five) * 50;          // oscillator of change

                const ExtStr = { ShortVal: undefined, SdUp: undefined, SdDn: undefined, LongVal: undefined, Date: undefined };

                ExtStr.Date = stockQuotesIn[i].Date;
                ExtStr.ShortVal = shortt;

                for (j = 0; j <= NoDaySd; j++) {     // pushdown the values in array
                    SdRec.Val[j] = SdRec.Val[j + 1]; // SdRec by 1
                }

                SdRec.Val[NoDaySd] = shortt;   // put the latest value of
                // shortt at top in SdRec
                if (i > NoDaySd + 1) {
                    SdRec.NDaySd = NoDaySd;
                    this.Proc_SD(SdRec);       // calculate upper and lower limit
                    ExtStr.SdUp = (SdRec.AvgValue + 1.5 * SdRec.SdValue);
                    ExtStr.SdDn = (SdRec.AvgValue - 1.5 * SdRec.SdValue);
                }

                // calculate 50 day moving average
                if (i >= NoDayAvg) {
                    Sum_Pr = 0;        // calculate avg price for NoDayAvg
                    for (k = i; k >= (i - NoDayAvg + 1); k -= 1) {
                        if (k > 0) {
                            Sum_Pr = Sum_Pr + stockQuotesIn[k].Close;
                        }
                    }
                    AvgPr = Sum_Pr / NoDayAvg; // Avg Price for NoDayAvg
                    if (AvgPr > 0) {
                        longt = (stockQuotesIn[i].Close / AvgPr - 1) * 100;   // long term value
                    }
                    ExtStr.LongVal = longt;
                }
                ExtRec.push(ExtStr);
            }
        }
        ExtRec.sort((a, b) => (a.Date > b.Date) ? -1 : (a.Date < b.Date) ? 1 : 0);
        return ExtRec;
    }
}
export default UpDays; 
