import BaseServiceApi from 'BaseServiceApi';
import BrowserUtil from "BrowserUtil";
import { connect } from 'react-redux';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import PortalContainer from 'PortalContainer';
import ScrollBar from "ScrollBar";
import { ShareActionConstants } from '../../../../../../Constants/ShareConstants';
import textWidth from "text-width";
import { updateStorewithSharedData } from '../../../../../../Actions/ShareAction';
import React, { PureComponent } from 'react';
import { setActiveLayer, onLayerItemRenamed, relocateLayer, toggleVisibility } from 'Actions/AnnotationActions';

let stop = true;
let timeout;
const mousePos = {
    cX: 0,
    cY: 0
};
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];
class RIPanelPointerInfo extends PureComponent {
    constructor() {
        super();
        this.inputRef = [];
        this.state = {
            tooltipText: "",
            isShowTooltip: false,
            recipTooltip: "",
            isShowRecipTooltip: false,
            recipTooltipStyle: {},
            style: {}
        }
        this.onDocumentKeyPress = this.onDocumentKeyPress.bind(this);
        this.showSharedData = this.showSharedData.bind(this);
        this.handleDocumentDrag = this.handleDocumentDrag.bind(this);
        this.handleDocumentDragover = this.handleDocumentDragover.bind(this);
        this.layerListUpdate = false;
    }

    componentDidMount() {
        document.addEventListener('dragend', this.handleDocumentDragEnd, false);
        document.addEventListener("keydown", this.onDocumentKeyPress, false);
        document.addEventListener('drag', this.handleDocumentDrag, false);
        document.addEventListener('dragover', this.handleDocumentDragover, false);
        const pointerLayerScroll = document.getElementById("annotateIntrumentsSelect");
        let layerIndex = 0;
        if (this.props.selectedLayer) {
            layerIndex = this.props.LayersList.findIndex((item) => item.layerID === this.props.selectedLayer.layerID);
        }
        const scrollPositionVal = layerIndex * 46;
        if (pointerLayerScroll && !this.layerListUpdate) {
            window.setTimeout(() => { pointerLayerScroll.scrollTop = scrollPositionVal; }, 0);
        }
        document.body.classList.add('riPanel');
    }

    componentWillUnmount() {
        document.removeEventListener('dragend', this.handleDocumentDragEnd, false);
        document.removeEventListener("keydown", this.onDocumentKeyPress, false);
        document.removeEventListener('drag', this.handleDocumentDrag, false);
        document.removeEventListener('dragover', this.handleDocumentDragover, false);
        document.body.classList.remove('riPanel');
    }

    componentDidUpdate() {
        if (this.inputRef.length > 0 && this.props.selectedLayer !== null && this.props.selectedLayer.isNewLayer) {
            // by default user should get the option to rename the new layer
            this.enterToEditMode();
            this.props.selectedLayer.isNewLayer = false;
        }
        if (!this.layerListUpdate) {
            const pointerLayerScroll = document.getElementById("annotateIntrumentsSelect");
            if (pointerLayerScroll) {
                let layerIndex = 0;
                if (this.props.selectedLayer) {
                    layerIndex = this.props.LayersList.findIndex((item) => item.layerID === this.props.selectedLayer.layerID);
                }
                const scrollPositionVal = layerIndex * 46;
                window.setTimeout(() => { pointerLayerScroll.scrollTop = scrollPositionVal; }, 0);
            }
        }
    }

    showSharedData(layer) {
        const sharedListData = {
            showSharedDialog: true,
            id: layer.layerID,
            name: layer.layerName,
            action: ShareActionConstants.SHARE_ANNOTATIONS,
            IncludeAllUsers: 0,
            ntid: "5"
        }
        updateStorewithSharedData(sharedListData);
    }

    enterToEditMode = () => {
        if (this.inputRef.length > 0) {
            const width = textWidth(this.props.selectedLayer.layerName, {
                family: "calibri",
                size: 18
            });

            this.inputRef[this.props.selectedLayer.layerID].parentNode.previousSibling.style.display = "none";
            this.inputRef[this.props.selectedLayer.layerID].parentNode.style.display = "block";
            this.inputRef[this.props.selectedLayer.layerID].style.width = `${width + 8}px`;
            this.inputRef[this.props.selectedLayer.layerID].value = this.props.selectedLayer.layerName;
            this.inputRef[this.props.selectedLayer.layerID].focus();
            this.inputRef[this.props.selectedLayer.layerID].select();
            document.body.classList.add("annotation-active"); // temporarily remove the primary focus from symbol entry input box until layer rename is over
        }
    }

    onChange = (e) => {
        if (this.inputRef.length > 0) {

            const width = textWidth(e.target.value, {
                family: "calibri",
                size: 18
            });

            this.inputRef[this.props.selectedLayer.layerID].style.width = `${width + 8}px`;
            this.inputRef[this.props.selectedLayer.layerID].value = e.target.value;
        }
    }

    renameLayer = () => {
        if (this.inputRef.length > 0) {
            this.inputRef[this.props.selectedLayer.layerID].blur();
            this.inputRef[this.props.selectedLayer.layerID].parentNode.style.display = "none";
            this.inputRef[this.props.selectedLayer.layerID].parentNode.previousSibling.style.display = "block";
        }

        document.body.classList.remove("annotation-active"); // add the primary focus back to symbol entry input box
        this.props.onLayerItemRenamed(this.inputRef[this.props.selectedLayer.layerID].value);
    }

    onDocumentKeyPress(e) {
        const charCode = (e.which) ? e.which : e.keyCode;
        const pointerLayerScroll = document.getElementById("annotateIntrumentsSelect");
        if (charCode === KeyCodes.ENTER && document.body.classList.contains("annotation-active")) {
            this.renameLayer();
        }
        else if (e.keyCode === KeyCodes.UP_ARROW && this.props.LayersList.findIndex((item) => item.layerID === this.props.selectedLayer.layerID) > 0) {
            const upLayer = this.props.LayersList[this.props.LayersList.findIndex((item) => item.layerID === this.props.selectedLayer.layerID) - 1]
            this.props.setActiveLayer(upLayer)
            if (pointerLayerScroll) {
                const scrollPositionVal = this.props.LayersList.findIndex((item) => item.layerID === this.props.selectedLayer.layerID) * 46;
                window.setTimeout(() => { pointerLayerScroll.scrollTop = scrollPositionVal; }, 0);
            }
        }
        else if (e.keyCode === KeyCodes.DOWN_ARROW && this.props.LayersList.findIndex((item) => item.layerID === this.props.selectedLayer.layerID) < this.props.LayersList.length - 1) {
            const downLayer = this.props.LayersList[this.props.LayersList.findIndex((item) => item.layerID === this.props.selectedLayer.layerID) + 1]
            this.props.setActiveLayer(downLayer)
            if (pointerLayerScroll) {
                const scrollPositionVal = this.props.LayersList.findIndex((item) => item.layerID === this.props.selectedLayer.layerID) * 46;
                window.setTimeout(() => { pointerLayerScroll.scrollTop = scrollPositionVal; }, 0);
            }
        }

    }

    showTooltip = (e, layer) => {
        if (this.isDragEventTriggered) {
            return;
        }
        const width = textWidth(e.target.innerText, {
            family: "calibri",
            size: 14
        });
        let xPoint = e.clientX;
        if (xPoint + width > window.innerWidth) {
            xPoint = window.innerWidth - (width + 20);
        }
        const style = { left: xPoint, top: e.clientY + 10 };
        this.layerListUpdate = true;
        this.setState({ ...this.state, style, isShowTooltip: true, tooltipText: `${e.target.innerText}` });
    }

    hideTooltip = () => {
        this.setState({ ...this.state, isShowTooltip: false, style: {}, tooltipText: "" });
    }


    showRecipTooltip = (e, layer) => {
        if (this.isDragEventTriggered) {
            return;
        }
        const recipientsList = layer.recipients.split(';').filter((i) => i !== '');
        const recipTooltip = recipientsList ? recipientsList.map((item, i) => <li key={i}>{item}</li>) : null;
        const style = { right: 1, top: e.clientY + 10 };
        this.setState({ ...this.state, recipTooltipStyle: style, isShowRecipTooltip: true, recipTooltip: recipTooltip });
    }

    hideRecipTooltip = () => {
        this.setState({ ...this.state, isShowRecipTooltip: false, recipTooltipStyle: {}, recipTooltip: "" });
    }

    handleDragStart = (e) => {
        this.hideTooltip();
        if (document.body.classList.contains("annotation-active")) {
            e.stopPropagation();
            e.preventDefault();
            return;
        }

        this.isDragEventTriggered = true;
        const target = e.currentTarget;
        if (target.parentElement.querySelector(".selective")) {
            target.parentElement.querySelector(".selective").classList.remove("selective"); // remove last/previously selective class
        }

        this.cloneNode = target.cloneNode(true);
        this.cloneNode.classList.add("annotation-drag-layer");

        const rect = document.querySelector(".annotation-layers").getBoundingClientRect();
        this.top = rect.top;
        this.bottom = rect.bottom;
        this.dragDiv = document.querySelector('#layer-drag');
        this.dragDiv.appendChild(this.cloneNode);
        this.dragDiv.style.position = 'fixed';
        this.dragDiv.style.pointerEvents = 'none';

        const tx = e.clientY - this.top;
        this.dragDiv.style.transform = `translate3d(0px, ${tx}px, 0px)`;

        this.dragId = parseInt(target.id);

        if (BrowserUtil.isIEBrowser()) {
            const cloneNode = target.cloneNode(true);
            target.parentNode.insertBefore(cloneNode, target);
            target.style.display = "none";
            window.setTimeout(() => {
                target.parentNode.removeChild(cloneNode);
                target.style.display = "block";
            }, 0);
        }
        else {
            const ghost = target.cloneNode(true);
            ghost.style.display = 'none';
            e.dataTransfer.setDragImage(ghost, 0, 0);
        }
        e.stopPropagation();
    }

    handleDocumentDragover(e) {
        mousePos.cX = e.clientX;
        mousePos.cY = e.clientY;
        e.stopPropagation();
    }

    handleDocumentDrag(e) {
        if (BrowserUtil.isIEBrowser()) {
            this.handleDragMove(e);
            e.stopPropagation();
        }
    }

    handleDragMove() {
        if (!document.querySelector(".annotation-layers")) {
            return;
        }
        const bRectOfWindow = document.querySelector(".annotation-layers").getBoundingClientRect();
        const offSetForScroll = 10;
        const verticalScrollbar = document.getElementById("annotateIntrumentsSelect");
        stop = true;
        if (mousePos.cY <= (bRectOfWindow.top + offSetForScroll) && mousePos.cY >= bRectOfWindow.top && mousePos.cX <= bRectOfWindow.right) {
            stop = false;
            this.scrollAnnotationLayers(mousePos.cY - (bRectOfWindow.top + offSetForScroll), verticalScrollbar);
        }
        if (mousePos.cY >= (bRectOfWindow.bottom - offSetForScroll) && mousePos.cY <= bRectOfWindow.bottom && mousePos.cX <= bRectOfWindow.right) {
            stop = false;
            this.scrollAnnotationLayers(mousePos.cY - (bRectOfWindow.bottom - offSetForScroll), verticalScrollbar)
        }
    }

    scrollAnnotationLayers(step, windowDiv) {
        const self = this;
        window.clearTimeout(timeout);
        const scrollY = windowDiv.scrollTop;
        windowDiv.scrollTop = (scrollY + step);
        if (!stop) {
            timeout = window.setTimeout(() => { self.scrollAnnotationLayers(step, windowDiv) }, 10);
        }
    }

    handleDragOver = (e) => {
        e.preventDefault();
        e.currentTarget.classList.add('selective');

        if (e.clientY - 5 >= this.top && e.clientY + 46 <= this.bottom) {
            const tx = e.clientY - this.top;
            this.dragDiv.style.transform = `translate3d(0px, ${tx}px, 0px)`;
        }
        e.stopPropagation();
    }

    handleDragleave = (e) => {
        const curIndex = Number(e.currentTarget.getAttribute("list-index")) + 1;
        if (curIndex === this.props.LayersList.length && e.clientY >= this.top + (curIndex * 46)) // keep the last list in selective mode when drag position is moved greater than last list
        {
            return;
        }
        e.currentTarget.classList.remove('selective');

        if (e.clientY - 5 >= this.top && e.clientY + 46 <= this.bottom) {
            const tx = e.clientY - this.top;
            this.dragDiv.style.transform = `translate3d(0px, ${tx}px, 0px)`;
        }
        e.stopPropagation();
    }

    handleDrop = (e) => {
        const dropId = parseInt(e.currentTarget.id);

        this.dragDiv.innerHTML = '';
        this.dragDiv.style.position = '';
        this.dragDiv.style.pointerEvents = '';
        this.dragDiv.style.transform = '';

        if (this.dragId === dropId) {
            e.target.classList.add('selective');
        }
        else {
            e.currentTarget.classList.remove('selective');
            this.props.relocateLayer(this.dragId, dropId);
        }
        this.isDragEventTriggered = false;
        e.stopPropagation();
    }

    handleDocumentDragEnd = (e) => {
        if (!this.isDragEventTriggered) {
            return;
        }
        stop = true;
        if (this.dragDiv) {
            this.dragDiv.innerHTML = '';
            this.dragDiv.style.position = '';
            this.dragDiv.style.pointerEvents = '';
            this.dragDiv.style.transform = '';
        }
        const { LayersList, selectedLayer } = this.props;
        let dropId = parseInt(selectedLayer.layerID);

        if (e.clientY < this.top) {
            dropId = parseInt(LayersList[0].layerID);
        }
        else if (e.clientY > this.bottom) {
            dropId = parseInt(LayersList[LayersList.length - 1].layerID);
        }
        else if (LayersList.length > 1) { // drag element is within the layer boundary but dragged away from last list
            dropId = parseInt(LayersList[LayersList.length - 1].layerID);
        }

        if (this.dragId === dropId) {
            e.target.classList.add('selective');
        }
        else {
            e.target.classList.remove('selective');
            this.props.relocateLayer(this.dragId, dropId);
        }
        this.isDragEventTriggered = false;
        e.stopPropagation();
    }

    getLayerList = (layer, index) =>
        <li id={layer.layerID} key={`${layer.layerID}-${index}`} list-index={index}
            className={layer.isSelected ? "selective" : ""} draggable="true"
            onClick={() => this.props.setActiveLayer(layer)}
            onDragStart={this.handleDragStart}
            onDragOver={this.handleDragOver}
            onDragLeave={this.handleDragleave}
            onDrop={this.handleDrop}
        >
            <div className="layer-eye-check">
                <input id="toggleButton1" type="checkbox" checked={!layer.isVisible} onClick={(e) => { e.stopPropagation(); this.props.toggleVisibility(layer); }} />
                <div className="icn-eye-n-check"></div>
            </div>
            <div className="layer-name" >
                <div className={layer.isSharedLayer ? "layer-name-shared" : "layer-name-text"} onDoubleClick={layer.isSharedLayer ? null : this.enterToEditMode} onMouseEnter={(e) => this.showTooltip(e, layer)} onMouseOut={this.hideTooltip} onBlur={this.hideTooltip}>
                    {`${layer.layerName}${layer.isSharedLayer ? ` (${layer.ownerName})` : ''}`}
                    {this.state.isShowTooltip && <PortalContainer>
                        <div className="annotation-layer-tooltip" style={this.state.style}>{this.state.tooltipText}</div>
                    </PortalContainer>}
                </div>
                <span className="layer-name-edit" style={{ 'display': 'none' }} onBlur={this.renameLayer} >
                    <input type="text" maxLength="50" defaultValue={layer.layerName} ref={(ref) => (this.inputRef[layer.layerID] = ref)} onChange={this.onChange} />
                </span>
                {(layer.hasBeenShared) && <div className="icn-access-readonly" onMouseEnter={(e) => this.showRecipTooltip(e, layer)} onMouseOut={this.hideRecipTooltip} onBlur={this.hideRecipTooltip} onClick={(e) => { e.stopPropagation(); this.showSharedData(layer); }}></div>}
                {(layer.isSharedLayer && layer.shareAccess === ShareAccessType.SHARE_READANDEDIT) && <div className="icn-access-readandedit"></div>}
                {this.state.isShowRecipTooltip &&
                    <PortalContainer>
                        <div className="annotation-recip-tooltip" style={this.state.recipTooltipStyle}>
                            <ul className="shared-with">
                                {this.state.recipTooltip}
                            </ul>
                        </div>
                    </PortalContainer>
                }
            </div>
        </li>

    render() {
        return (
            <div className="annotation-selection anno-point">
                <div className="title"><h4>ANNOTATION LAYERS</h4></div>
                <div className="custom-scroll">
                    <div className="annotation-layers" id="annotateIntrumentsSelect">
                        <ul>
                            {this.props.LayersList.length > 0 &&
                                this.props.LayersList.map((layer, index) => this.getLayerList(layer, index))
                            }
                        </ul>
                        <div id="layer-drag" className="annotation-layers-draggable"></div>
                    </div>
                    <ScrollBar scrollId="annotateIntrumentsSelect" vScroll={true} id="annotateIntrumentsSelectScroll" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { LayersList, selectedLayer } = annotationReducers.annotationLayerManagementReducer;

    const { riPanelToggle } = annotationReducers.annotationMenuReducer;

    return ({ LayersList, selectedLayer, riPanelToggle });
}

const mapDispatchToProps = (dispatch) => ({
    setActiveLayer: (layer) => dispatch(setActiveLayer(layer)),
    onLayerItemRenamed: (newName) => dispatch(onLayerItemRenamed(newName)),
    relocateLayer: (dragId, dropId) => dispatch(relocateLayer(dragId, dropId)),
    toggleVisibility: (layer) => dispatch(toggleVisibility(layer))
});

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelPointerInfo);
