import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';

import ThemeHelper from "ThemeHelper";
import ExtremeDataValue from "ExtremeDataValue";
import { ActionTypes } from "Constants/AppColorConstants";
import { PriceChartConstants, PriceChartConst } from "Constants/PriceChartConstants.js";

import AppColorStore from "Stores/AppColor/AppColorStore";

import { fetchZigzagTrendData } from "../../../Actions/AINewsActions";

import AISummaryModal from "../../AIContent/AISummaryModal";
import LabelText from "../Timeline/LabelText";
import { AINewsType } from "../../../Constants/AINewsConstants";


const textWidth = require("text-width");

const ZigzagVisual = ({ isVisible, zigzagIndicatorData, lineSettings, updateContextHit, zigzagTrendData, showZigzagAi, isLoading, dimension, isUpDaysVisible }) => {

    const dispatch = useDispatch()

    // eslint-disable-next-line no-unused-vars
    const [themeChanged, setThemeChanged] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [hovered, setHovered] = useState(false);
    const [hoveredSegment, setHoveredSegment] = useState(null);
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [popupConfig, setPopupConfig] = useState({
        target: null,
        placement: "left",
        width: 0,
    });
    const chartRef = useRef(null);
    const selectedSegmentRef = useRef(null);
    const selectedSegmentStartRef = useRef(null);
    const selectedSegmentEndRef = useRef(null);

    const colorInfo = lineSettings ? ThemeHelper.getThemedBrush(lineSettings.color) : ThemeHelper.getThemedBrush('dg-zigzagLine');
    const hoverColor = lineSettings ? ThemeHelper.getZigzagHoverColor(lineSettings.color) : "#FF0000";
    const textColor = ThemeHelper.getThemedBrush("scaleText");
    const strokeWidth = lineSettings ? lineSettings.weight : 2;

    const lineStyle = {
        position: "absolute",
        pointerEvents: "none",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%",
        zIndex: 2,
        fill: "none",
        cursor: hoveredSegment ? "pointer" : "default",
        strokeWidth: strokeWidth,
    };

    /* Re-rendering the component with dummy boolean flag upon theme change to apply the relevant theme colors */
    const AppColorStoreStateChanged = () => {
        const currentAction = AppColorStore.getAction();
        if (currentAction === ActionTypes.CUSTOM_THEME_CHANGED) {
            setThemeChanged((prev) => !prev);
        }
    };


    useEffect(() => {
        AppColorStore.addChangeListener(AppColorStoreStateChanged);

        return () => {
            AppColorStore.removeChangeListener(AppColorStoreStateChanged);
        };
    }, []);


    useEffect(() => {
        // Hide the popup when 
        // 1. symbol is changed   2. dimension changed
        if ((isLoading || dimension) && showPopup) {
            setShowPopup(false);
            setSelectedSegment(null);
            setHoveredSegment(null);
        }
        // When the segment is selected, the target will be set first, followed by the rendering of the AI popup.
        else if (popupConfig.target && !showPopup && selectedSegment) {
            setShowPopup(true);
        }
    }, [isLoading, popupConfig.target, dimension])


    useEffect(() => {
        if (!showPopup && selectedSegmentRef.current) {
            calculatePopupPosition();

            const trendType = selectedSegment.upTrend ? "UP" : "DOWN";
            dispatch(fetchZigzagTrendData(selectedSegment.startDate, selectedSegment.endDate, trendType));
        }
    }, [selectedSegment])


    useEffect(() => {
        document.addEventListener("mousedown", handleSegmentOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleSegmentOutsideClick);
        };
    }, []);


    const calculatePopupPosition = () => {
        if (selectedSegmentRef.current) {
            const startRect = selectedSegmentStartRef.current.getBoundingClientRect();
            const endRect = selectedSegmentEndRef.current.getBoundingClientRect();

            // Calculate available space
            const windowWidth = window.innerWidth;
            const startLeftSpace = startRect.left;
            const endRightSpace = windowWidth - endRect.right;

            const MIN_SPACE = 300;
            const PADDING = 20;

            // Determine preferred side for popup
            const isStartPreferred = startLeftSpace > endRightSpace;
            const targetRef = isStartPreferred ? selectedSegmentStartRef.current : selectedSegmentEndRef.current;

            let placement, availableSpace;
            if (isStartPreferred) {
                placement = startLeftSpace < MIN_SPACE ? "right" : "left";
                availableSpace = placement === "right" ? windowWidth - startLeftSpace : startLeftSpace;
            } else {
                placement = endRightSpace < MIN_SPACE ? "left" : "right";
                availableSpace = placement === "left" ? endRect.right : endRightSpace;
            }

            const width = Math.round((availableSpace - PADDING) / 10) * 10;

            setPopupConfig({ target: targetRef, placement, width });
        }
    }


    const handleMouseEnterSegment = (e, segment) => {
        if (showZigzagAi) {
            setHoveredSegment(segment);
        }

        const { pageX, pageY } = e.nativeEvent;
        const dimensions = chartRef.current.getBoundingClientRect();
        const chartHeight = dimensions.height;
        const offsetX = pageX - dimensions.x;
        const offsetY = pageY - dimensions.y

        const tooltipX = offsetX + 5;
        const tooltipY = offsetY > chartHeight - 40 ? offsetY - 20 : offsetY + 20;

        setTooltipPosition({ x: tooltipX, y: tooltipY });
        setHovered(true);
    };

    const handleMouseLeaveSegment = () => {
        setHoveredSegment(null);
        setHovered(false);
    };

    const handleSegmentOutsideClick = (event) => {
        // Check if the click is outside the selected segment
        if (selectedSegmentRef.current && !selectedSegmentRef.current.contains(event.target)) {
            setSelectedSegment(null);
            setShowPopup(false)
        }
    };

    const handleSegmentClick = (e, segment) => {
        setSelectedSegment(segment);
    };

    const prepareLineSegments = (zigzagIndicatorData) => {
        if (!zigzagIndicatorData?.length || isNaN(zigzagIndicatorData[0]?.yPrice)) {
            return [];
        }

        const segments = []
        for (let i = 0; i < zigzagIndicatorData.length - 1; i++) {
            const { xAxis: x1, yHigh: y1High, yLow: y1Low, upTrend: up1 } = zigzagIndicatorData[i];
            const { xAxis: x2, yHigh: y2High, yLow: y2Low, upTrend: up2 } = zigzagIndicatorData[i + 1];

            // Determine points based on upTrend
            let y1 = up1 ? y1High : y1Low;

            if (i === 0) {
                y1 = up1 ? y1Low : y1High;
            }

            const y2 = up2 ? y2High : y2Low;
            const startDate = zigzagIndicatorData[i].Date
            const endDate = zigzagIndicatorData[i + 1].Date
            const upTrend = zigzagIndicatorData[i + 1].upTrend
            segments.push({ x1, y1, x2, y2, startDate, endDate, upTrend });
        }
        return segments
    };


    const prepareZigzag = (zigzagIndicatorData) => {
        if (!zigzagIndicatorData?.length || isNaN(zigzagIndicatorData[0]?.yPrice)) {
            return " ";
        }

        /*{ Move to the first data point without drawing a line
            Determine the starting point based on trend direction:
            - If the trend is upward (`upTrend === true`), start from the lowest point (`yLow`).
            - If the trend is downward (`upTrend === false`), start from the highest point (`yHigh`).
        }*/
        const pathData = [`M ${zigzagIndicatorData[0].xAxis} ${zigzagIndicatorData[0].upTrend ? zigzagIndicatorData[0].yLow : zigzagIndicatorData[0].yHigh}`];

        // Iterate over the data points starting from the second one and draw a line to each subsequent point based on trend direction
        const lineSegments = zigzagIndicatorData.slice(1).map(({ xAxis, yHigh, yLow, upTrend }) =>
            `L ${xAxis} ${upTrend ? yHigh : yLow}`
        );

        return pathData.concat(lineSegments).join(" ");
    };


    const pathData = prepareZigzag(zigzagIndicatorData);
    const segments = prepareLineSegments(zigzagIndicatorData);


    function getDistance(deltaX, deltaY) {
        return Math.sqrt((deltaX) ** 2 + (deltaY) ** 2);
    }

    function checkLabelOverlapping(current, previousNodes) {
        const labelXPadding = 4;
        const labelYthreshold = 20;
        const textProps = {
            family: "calibri",
            size: 10
        }
        const currentTextWidth = Math.max(
            textWidth(ExtremeDataValue.showHiLoPrices(current.upTrend ? current.graphData.High : current.graphData.Low), textProps),
            textWidth(current.change ? current.upTrend ? `+${(current.change * 100).toFixed(0)}%` : `${(current.change * 100).toFixed(0)}%` : '', textProps)
        );

        for (let i = 0; i < 2; i++) {
            const previous = previousNodes[i]
            if (!previous) {
                return [false, i]
            }
            const previousTextWidth = Math.max(
                textWidth(ExtremeDataValue.showHiLoPrices(previous.upTrend ? previous.graphData.High : previous.graphData.Low), textProps),
                textWidth(previous.change ? previous.upTrend ? `+${(previous.change * 100).toFixed(0)}%` : `${(previous.change * 100).toFixed(0)}%` : '', textProps)
            );
            const xThreshold = (currentTextWidth + previousTextWidth + labelXPadding) / 2
            if ((current.xAxis - previous.xAxis > xThreshold) // Checking if x-direction distance between data points is greater than xThreshold
                || ((current.upTrend !== previous.upTrend) && (current.yHigh < previous.yLow)) // Checking if (High/ Low nodes) are in opposite direction and they never overlap.
                || (Math.abs((current.upTrend ? current.yHigh : current.yLow) - (previous.upTrend ? previous.yHigh : previous.yLow))) > labelYthreshold
                || (getDistance(current.xAxis - previous.xAxis,
                    (Math.abs((current.upTrend ? current.yHigh : current.yLow) - (previous.upTrend ? previous.yHigh : previous.yLow))))
                    > getDistance(xThreshold, labelYthreshold)) // Checking if euclidean distance between data points is greater than euclidean threshold.
            ) {
                continue;
            } else {
                return [true, 0]
            }
        }
        return [false, 0]
    }

    function backtrackToFitData(current, previous, skippedNodes) {
        if (skippedNodes.length <= 1) return [];
        let fittedNodes = [];
        for (let i = skippedNodes.length - 2; i >= 0; i--) {
            let node = skippedNodes[i];
            let lastAdded = fittedNodes.length > 0 ? fittedNodes[fittedNodes.length - 1] : previous;
            if (!lastAdded && !checkLabelOverlapping(current, [node])[0]) {
                fittedNodes.push(node);
            } else if (!checkLabelOverlapping(current, [node])[0] && !checkLabelOverlapping(node, [lastAdded])[0]) {
                fittedNodes.push(node);
            }
        }
        return fittedNodes;
    }

    function filterZigzagData(zigzagIndicatorData) {
        let highGroup = [];
        let lowGroup = [];
        let currentSkippedHighGroup = [];
        let currentSkippedLowGroup = [];

        for (let i = 0; i < zigzagIndicatorData.length; i++) {
            let current = zigzagIndicatorData[i];
            if (current.upTrend === true) {
                if (highGroup.length === 0) {
                    highGroup.push(current);
                    continue;
                }
                const [isOverlapping, overlappingNodeIndex] = checkLabelOverlapping(current, [highGroup[highGroup.length - 1],
                highGroup.length > 1 ? highGroup[highGroup.length - 2] : null]);

                if (currentSkippedHighGroup.length > 1) {
                    const fittedData = backtrackToFitData(highGroup[highGroup.length - 1], highGroup.length > 1 ? highGroup[highGroup.length - 2] : null, currentSkippedHighGroup);
                    if (fittedData.length > 0) {
                        highGroup = [
                            ...highGroup.slice(0, -1),
                            ...fittedData,
                            highGroup[highGroup.length - 1]
                        ];
                        currentSkippedLowGroup = []
                    }
                }
                if (!isOverlapping) {
                    highGroup.push(current);
                    currentSkippedHighGroup = [];
                } else if (current.graphData.High > highGroup[highGroup.length - 1 - overlappingNodeIndex].graphData.High) {
                    currentSkippedHighGroup.push(highGroup[highGroup.length - 1 - overlappingNodeIndex])
                    highGroup[highGroup.length - 1 - overlappingNodeIndex] = current;
                }
            }
            else if (current.upTrend === false) {
                if (lowGroup.length === 0) {
                    lowGroup.push(current);
                    continue;
                }
                if (currentSkippedLowGroup.length > 1) {
                    const fittedData = backtrackToFitData(lowGroup[lowGroup.length - 1], lowGroup.length > 1 ? lowGroup[lowGroup.length - 2] : null, currentSkippedLowGroup);
                    if (fittedData.length > 0) {
                        lowGroup = [
                            ...lowGroup.slice(0, -1),
                            ...fittedData,
                            lowGroup[lowGroup.length - 1]
                        ];
                        currentSkippedLowGroup = []
                    }
                }
                const [isOverlapping, overlappingNodeIndex] = checkLabelOverlapping(current, [lowGroup[lowGroup.length - 1],
                lowGroup.length > 1 ? lowGroup[lowGroup.length - 2] : null]);
                if (!isOverlapping) {
                    lowGroup.push(current);
                    currentSkippedLowGroup = [];
                } else if (current.graphData.Low < lowGroup[lowGroup.length - 1 - overlappingNodeIndex].graphData.Low) {
                    currentSkippedLowGroup.push(lowGroup[lowGroup.length - 1 - overlappingNodeIndex])
                    lowGroup[lowGroup.length - 1 - overlappingNodeIndex] = current;
                }
            }
        }
        let filteredHighGroup = [];
        for (let i = 0; i < highGroup.length; i++) {
            let highData = highGroup[i];
            let shouldKeep = true;

            for (let j = 0; j < lowGroup.length; j++) {
                let lowData = lowGroup[j];
                let highIndex = zigzagIndicatorData.findIndex((d) => d.Date === highData.Date);
                let lowIndex = zigzagIndicatorData.findIndex((d) => d.Date === lowData.Date);
                let isNeighbor = Math.abs(highIndex - lowIndex) === 1;
                if (isNeighbor) {
                    continue;
                }

                if (checkLabelOverlapping(highData, [lowData])[0]) {
                    let highChange = Math.abs(highData.change);
                    let lowChange = Math.abs(lowData.change);

                    if (highChange < lowChange) {
                        shouldKeep = false;
                        break;
                    } else {
                        lowGroup.splice(j, 1);
                        j--;
                    }
                }
            }
            if (shouldKeep) {
                filteredHighGroup.push(highData);
            }
        }
        return [...filteredHighGroup, ...lowGroup];
    };

    const getPctLabels = () => {
        const filteredZigzagData = zigzagIndicatorData ? filterZigzagData(zigzagIndicatorData) : [];
        return (
            zigzagIndicatorData && filteredZigzagData.map((itm, i) =>
                <g key={i} id="zigzag-labels">
                    <LabelText
                        textAnchor="middle"
                        isHighlighted={true}
                        textValue={ExtremeDataValue.showHiLoPrices(itm.upTrend ? itm.graphData.High : itm.graphData.Low)}
                        dx="0"
                        dy="0"
                        style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                        textPosX={itm.xAxis}
                        textPosY={itm.upTrend ? (itm.yHigh - 13) - (isUpDaysVisible && itm.graphData.UpDays ? 12 : 0) : (itm.yLow + 12)}
                    />
                    <LabelText
                        textAnchor="middle"
                        isHighlighted={true}
                        textValue={itm.change ? itm.upTrend ? `+${(itm.change * 100).toFixed(0)}%` : `${(itm.change * 100).toFixed(0)}%` : ''}
                        dx="0"
                        dy="0"
                        style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                        textPosX={itm.xAxis}
                        textPosY={itm.upTrend ? (itm.yHigh - 4) - (isUpDaysVisible && itm.graphData.UpDays ? 12 : 0) : (itm.yLow + 21)}
                    />
                </g>
            )
        );
    }

    const getLineSegments = () => (
        segments.map((segment, index) =>
            <line
                id="zigzag-segments"
                key={index}
                x1={segment.x1}
                y1={segment.y1}
                x2={segment.x2}
                y2={segment.y2}
                pointerEvents="stroke"
                stroke="transparent"
                strokeWidth={strokeWidth + 4}
                onClick={(e) => showZigzagAi && handleSegmentClick(e, segment)}
                onContextMenu={(e) => updateContextHit(e)}
                onMouseEnter={(e) => handleMouseEnterSegment(e, segment)}
                onMouseLeave={handleMouseLeaveSegment}
                data-disable-track-price="true"
            />)
    )

    const getActiveSegment = () => (
        selectedSegment && (
            <g>
                <line
                    id="zigzag-active-segment"
                    x1={selectedSegment.x1}
                    y1={selectedSegment.y1}
                    x2={selectedSegment.x2}
                    y2={selectedSegment.y2}
                    ref={selectedSegmentRef}
                    strokeLinecap="round"
                    stroke={hoverColor}
                    strokeWidth={strokeWidth}
                    cursor="pointer"
                    key={`${selectedSegment.x1}-${selectedSegment.y1}-${selectedSegment.x2}-${selectedSegment.y2}`}
                    data-disable-track-price="true"
                />
                <circle
                    cx={selectedSegment.x1}
                    cy={selectedSegment.y1}
                    ref={selectedSegmentStartRef}
                    r="2"
                    stroke="transparent"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    cursor="pointer"
                />
                <circle
                    cx={selectedSegment.x2}
                    cy={selectedSegment.y2}
                    ref={selectedSegmentEndRef}
                    r="2"
                    stroke="transparent"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    cursor="pointer"
                />
            </g>
        )
    )

    const getHoveredSegment = () => (
        hoveredSegment &&
        (
            <line
                id="zigzag-hovered-segment"
                x1={hoveredSegment.x1}
                y1={hoveredSegment.y1}
                x2={hoveredSegment.x2}
                y2={hoveredSegment.y2}
                strokeLinecap="round"
                stroke={hoverColor}
                strokeWidth={strokeWidth}
                cursor="pointer"
                data-disable-track-price="true"
                pointerEvents="none"
            />
        )
    );


    return (isVisible ?
        <div ref={chartRef}>
            <svg
                style={lineStyle}
                id="zigzag-area"
            >
                <path
                    d={pathData}
                    data-disable-track-price="true"
                    stroke={colorInfo}
                />

                {getPctLabels()}
                {getLineSegments()}
                {showZigzagAi && getActiveSegment()}
                {showZigzagAi && getHoveredSegment()}

            </svg>
            {
                showZigzagAi && showPopup &&
                <AISummaryModal
                    showPopup={showPopup}
                    hidePopup={(event) => { handleSegmentOutsideClick(event) }}
                    data={zigzagTrendData}
                    target={popupConfig.target}
                    placement={popupConfig.placement}
                    maxWidth="400px"
                    width={popupConfig.width}
                    selectedSegment={selectedSegment}
                    newsType={AINewsType.TREND_NEWS}
                >
                </AISummaryModal>
            }
            {hovered && <div
                className="zigzag-tooltip"
                style={{
                    top: `${tooltipPosition.y}px`,
                    left: `${tooltipPosition.x}px`,
                    backgroundColor: colorInfo,
                    color: ThemeHelper.getFontColor(colorInfo),
                }}
            >
                Zigzag Indicator
            </div>}

        </div>
        :
        <></>
    );
};

const mapStateToProps = ({ RelatedInfoPanelReducers, aiNewsReducer, DatagraphReducers }) => {
    const { aiSettings, zigzagIndicatorData, activePeriodLineSettings, isZigzagAIEntitled } = RelatedInfoPanelReducers.RiPanelAI;
    const { zigzagTrendData, applicableInstrumentsZigzag } = aiNewsReducer;
    const { isLoading, SymTypeEnum } = DatagraphReducers.DataGraphReducer;
    const { dimension, isUpDaysVisible } = DatagraphReducers.PriceChartReducer;
    const zzSettings = aiSettings?.zigzagIndicator;
    const showZigzagAi = isZigzagAIEntitled && applicableInstrumentsZigzag.includes(SymTypeEnum);

    return { isVisible: zzSettings?.isVisible, zigzagIndicatorData, lineSettings: activePeriodLineSettings, zigzagTrendData, showZigzagAi, isLoading, dimension, isUpDaysVisible };
};

const mapDispatchToProps = (dispatch) => ({
    updateContextHit: (event) => {
        dispatch({ type: PriceChartConstants.ActionTypes.UPDATE_CONTEXT_HIT, contextMenuObj: { lineID: PriceChartConst.ZZ_INDICATOR } });
        dispatch({ type: PriceChartConstants.ActionTypes.SHOW_PRICE_CONTEXT_MENU, event, top: event.clientY })
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ZigzagVisual);