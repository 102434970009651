import PropTypes from 'prop-types';
import React from "react";
import ReactDOM from "react-dom";
import Position from "Position";
import ListActions from "ListActions";
import GraphComponent from "GraphComponent";
import SmartViewType from "../../Constants/SmartViewType.js";
import MenuCommonDialogActions from "../../Actions/MenuCommonDialogActions.js";
import TabDataGraphActionClass from "../../Actions/TabDataGraphAction.js";
import TabDataGraphStore from "../../Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore.js";
import LocalizationStore from 'LocalizationStore';
import onClickOutside from "react-onclickoutside";
import PortalContainer from 'PortalContainer';
import { openCustomTIModal, openCustomKPIModal } from "../../Actions/CustomTIModalActions";
import { openExternalDataEditDialog } from "../../Actions/ExternalDataUploaderActions";
import { dispatch } from "../../Redux/dispatch";
import { ListExplorerConstants } from "ListExplorerConstants";
import ScrollBar from "ScrollBar";
import UserInfoUtil from "UserInfoUtil";
import StringUtil from "StringUtil";
import { TabDataGraphConstants } from "Constants/TabDataGraphConstants.js"
import { deleteMALine, openCorpEventDialog, openIndexLineDialog, openMALineSettingDialog, openPatternRecDialog, openRSLineDialog, openSTPSettingsDialog, showFundamentalLineDialog } from '../../Actions/PricePanelActions.js';
import { showZZIndicatorEditDialog } from '../../Actions/RelatedInfoPanelActions/RiPanelAiActions.js';

class ClickMenu extends React.Component {

  constructor(props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.handleOnSelection = this.handleOnSelection.bind(this);
    this.getMenu = this.getMenu.bind(this);  
    this.handleStopPropagation = this.handleStopPropagation.bind(this);
    this.state = {
      position: props.position ? props.position : Position.mouse,
      isopen: false,
      items: props.items ? props.items : [],
      button: props.button ? props.button : Position.Right,
      hideOnItemClick: props.hideOnItemClick !== undefined ? props.hideOnItemClick : true,
      isPosUpdated: false
    };
  }

  componentDidMount() {
    window.addEventListener("resize",this.handleHide)
    let parentNode = ReactDOM.findDOMNode(this).parentNode;
    if (parentNode && this.state.button === Position.Right) {
      parentNode.addEventListener("contextmenu", this.openMenu);
    }
    if (parentNode && this.state.button === Position.Left) {
      parentNode.addEventListener("click", this.openMenu);
    }
    if (this.props.isSubmenu) {
      parentNode.addEventListener("mouseenter", this.openMenu);
      parentNode.addEventListener("mouseleave", this.handleHide);
    }
  }

  handleStopPropagation(e) {
    e.stopPropagation();
  }

  handleClickOutside() {
    this.handleHide();
  }

  registerEvent() {
    document.addEventListener("contextmenu", this.onContextMenu);
  }

  unregisterHandlers() {
    document.removeEventListener("contextmenu", this.onContextMenu);
  }

  onContextMenu(e) {
    if (this.state.isopen) return true;
    let parentNode = ReactDOM.findDOMNode(this).parentNode;
    if (e.target !== parentNode) {
      this.handleHide();
    }
  }
  handleHide() {
    this.unregisterHandlers();
    if (this.props.menuType === 'ownershipReport' || this.props.menuType === 'holdingsReport') {
      this.props.hideToolTip(false);
    }
    this.setState({ isopen: false, isPosUpdated: false });
  }
  UNSAFE_componentWillMount(){
    TabDataGraphStore.addChangeListener(this.handleHide, TabDataGraphConstants.ActionTypes.HIDE_OTHER_MENU);
  }
  componentWillUnmount() {
    let parentNode = ReactDOM.findDOMNode(this).parentNode;
    if (parentNode && this.state.button === Position.Right) {
      parentNode.removeEventListener("contextmenu", this.openMenu);
    }
    if (parentNode && this.state.button === Position.Left) {
      parentNode.removeEventListener("click", this.openMenu);
    }
    if (this.props.isSubmenu) {
      parentNode.removeEventListener("mouseenter", this.openMenu);
      parentNode.removeEventListener("mouseleave", this.handleHide);
    }
    if (this.props.ownershipMenu) {
    this.listViewArea = null;
    this.listManagerBGElm = null;
    }
    TabDataGraphStore.removeChangeListener(this.handleHide, TabDataGraphConstants.ActionTypes.HIDE_OTHER_MENU);
    window.removeEventListener("resize",this.handleHide)
  }
  UNSAFE_componentWillReceiveProps(props) {    
    if (props.items) {
      this.setState({ items: props.items });
    }
    if (props.menuTitle) {
      this.setState({ menuTitle: props.menuTitle });
    }
  }

  toggleActions(e, actionitem, item, index) {
   let type = TabDataGraphStore.getState();
    if (actionitem === 'Edit') {
      if (item.graphComponent === GraphComponent.mAverage) {
        dispatch(openMALineSettingDialog(item));
      }
      if (item.graphComponent === GraphComponent.CorpEvents) {
        dispatch(openCorpEventDialog(item))
      }
      if (item.graphComponent === GraphComponent.externalData) {
        document.body.classList.add('ExternalDataEditDialogOpened');
        dispatch(openExternalDataEditDialog(item, true));
      }
      if (item.graphComponent === GraphComponent.EPSLine) {
        dispatch(showFundamentalLineDialog(item))
      }
      if (item.graphComponent === GraphComponent.RPSLine) {
        dispatch(showFundamentalLineDialog(item))
      }
      if (item.graphComponent === GraphComponent.T4Q0Line) {
        dispatch(showFundamentalLineDialog(item))
      }
      if (item.graphComponent === GraphComponent.T4Q1Line) {
        dispatch(showFundamentalLineDialog(item))
      }
      if (item.graphComponent === GraphComponent.T4Q2Line) {
        dispatch(showFundamentalLineDialog(item))
      }
      if (item.graphComponent === GraphComponent.T4Q3Line) {
        dispatch(showFundamentalLineDialog(item))
      }
      if (item.graphComponent === GraphComponent.IndexLine) {
        dispatch(openIndexLineDialog(item))
      }
      else
        if(item.graphComponent === GraphComponent.RSLine1) {
          dispatch(openRSLineDialog(item))
        }
      else
        if(item.graphComponent === GraphComponent.RSLine2) {
          dispatch(openRSLineDialog(item))
        }
    else
        if (item.graphComponent === GraphComponent.PatternRecognition) {
          dispatch(openPatternRecDialog(item))

        }
      else if (item.graphComponent === GraphComponent.StreetTargetPrice) {
        dispatch(openSTPSettingsDialog());
      }
      else if (item.graphComponent === GraphComponent.ZZIndicator) {
        dispatch(showZZIndicatorEditDialog());
      }
      if(item.graphComponent === GraphComponent.OwnershipBlockMenuFavorites)
      {
        this.onSelect(e,item,index,true);
      }  
      this.handleHide();
    }
    if (actionitem === 'Delete') {
      if (item.graphComponent === GraphComponent.mAverage) {
        dispatch(deleteMALine(item))
      }
    }
    e.stopPropagation();
  }

  componentDidUpdate() {
    / Fixes PANWEB-1229 /
    if (!this.state.isopen) return;
    if (this.state.isPosUpdated) return;

    let style = { position: "absolute", zIndex: "999" };
    const height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
        if (this.props.externalDataMenu) {
          this.listViewArea = null;
          this.listManagerBGElm = null;
          }
    if (this.props.isSubmenu) {
      / Fix for PANWEB-3202 /
      const subMenu = document.getElementById("subContextMenu");
      const currentElmRectBox = ReactDOM.findDOMNode(this).getBoundingClientRect();
      if (currentElmRectBox.bottom > height - 10 && this.state.items[0].graphComponent != "OwnershipBlockMenuFavorites") {
        subMenu.style.top = `-${currentElmRectBox.bottom - height + 17}px`; 
      }
      if(this.state.items[0].graphComponent == "externalData"){
        let top = this.state.items.length > 10 ? 96 : this.state.items.length == 1 ? 0 : this.state.items.length * 24 / 2;
        subMenu.style.top = `-${ top }px`;
        subMenu.style.height = this.state.items.length > 10 ? '240px' : `${this.state.items.length * 24}px`;
      }
      if(currentElmRectBox.bottom > height - 10 && this.state.items[0].graphComponent == "OwnershipBlockMenuFavorites"){
        const ownerSubMenu = document.getElementById("owner-subContextMenu");
        ownerSubMenu.style.top = `-${currentElmRectBox.bottom - height + 56}px`; 
      }
    }
    else if (this.props.alignment === Position.Right) {
      const currentElmRectBox = ReactDOM.findDOMNode(this).getBoundingClientRect();
      style.left = `${currentElmRectBox.left - currentElmRectBox.width}px`;
      if (currentElmRectBox.bottom > height - 10) {
        style.top = `${currentElmRectBox.top - currentElmRectBox.height}px`;
      }
      else {
        style.top = `${currentElmRectBox.top}px`;
      }
      /*eslint-disable*/
      this.setState({ isopen: true, style: style, isPosUpdated: true });
      /*eslint-enable*/
    }
    else if (this.state.position === Position.Top) {
      const subMenu = document.getElementById("subContextMenu");
      const currentElmRectBox = ReactDOM.findDOMNode(this).getBoundingClientRect();
      // style.right = `${currentElmRectBox.right - currentElmRectBox.width}px`;
      if (currentElmRectBox.top < 150) {
        style.top = subMenu.style.bottom;
      }
      else {
        style.top = '';
      }
      style.bottom = subMenu.style.bottom;
      style.left = subMenu.style.left;
      /*eslint-disable*/
        this.setState({ isopen: true, style: style, isPosUpdated: true });
      /*eslint-enable*/
    }
  }

  openMenu(e) {
    if (this.state.isopen) return;

    if(this.props.menuType === 'ownershipReport' || this.props.menuType === 'holdingsReport') {
      this.props.hideToolTip(true);
    }

    e.preventDefault();
    let style = { position: "absolute", zIndex: "999" };
    if (this.state.position === Position.Top) {
      style.bottom = (e.target.clientHeight + 1) + "px";
      style.left = "0px";
    }
    if (this.props.ownershipMenu === "ownershipMenu") {      
        this.listViewArea = document.getElementsByClassName("list-view-area")[0];
        this.listManagerBGElm = document.getElementById('listManagerBG');
        let listViewAreaBottom = this.listViewArea.getBoundingClientRect().bottom - 12;        
        //let topCal = e.clientY-25;
        let topCal = e.clientY + 30;
        let leftCal = e.clientX + 10;
        let contextMenuList = null;
            contextMenuList = this.getMenu();
            style.marginTop = "-25px";
            if (contextMenuList != null) {  
              let ulHeight = (contextMenuList.props.children.props.children[0].props.children.props.children[1].length * 24) + 24;      
            if (listViewAreaBottom <= (e.clientY + ulHeight)){
              topCal = topCal - ulHeight;
              style.marginTop = "-5px";
            }
                  }
            style.left = leftCal;
            style.top = topCal; 
            style.position = "fixed";           

        
    }

    if (this.props.externalDataMenu === "externalDataMenu") {      
      if(this.props.undoActionObj && this.props.undoActionObj.action == ListExplorerConstants.ActionTypes.UNDO_EXTERNAL_LIST)      
      ListActions.isDeleteUndoExistExternal();        
      this.listViewArea = document.getElementsByClassName("list-view-area")[0];
      this.listManagerBGElm = document.getElementById('listManagerBG');
      let listViewAreaBottom = this.listViewArea.getBoundingClientRect().bottom - 12;        
      //let topCal = e.clientY-25;
      let topCal = e.clientY + 30;
      let leftCal = e.clientX + 10;
      let contextMenuList = null;
          contextMenuList = this.getMenu();
          style.marginTop = "-25px";
          if (contextMenuList != null) {  
            let ulHeight = (contextMenuList.props.children.props.children[0].props.children.props.children[1].length * 24) + 24;      
          if (listViewAreaBottom <= (e.clientY + ulHeight)){
            topCal = topCal - ulHeight;
            style.marginTop = "-5px";
          }
                }
          style.left = leftCal;
          style.top = topCal; 
          style.position = "fixed";           
          
      
  }
    if (this.props.alignment === Position.Right) {

      if (!this.props.isSubmenu) {
        const targetRectBox = e.target.getBoundingClientRect();
        style.left = targetRectBox.left;
        style.top = targetRectBox.bottom;
      }

    }
    if (this.props.isSubmenu) {
      let width = e.target.clientWidth + 1;
      style.right = width + "px";
    
      if (this.props.alignment === Position.Right){
        style.right = width + "px";
        //style.top = "0";
      }
      else
      {
        style.left = width + "px";
        //style.top = "0";
      }
      if (this.props.items[0] && this.props.items[0].graphComponent && this.props.items[0].graphComponent === "externalData") {
        style.height = `${this.props.items.length * 24 + 2}px`;
        style.overflow = "hidden";
        style.borderRadius = "3px";
      }
    }
    this.setState({ isopen: true, style: style });
    this.registerEvent();
  }

  getItem(item, index) {
    if(item.isShowHelpText){
      return (
        <div className="ext-help-block xx-small-normal" >
         <div>You can upload external data into Panaray.</div>
          <a className="ext-help-block-details" href="https://www.williamoneil.com/panaray/knowledge-center/" target="_blank">Click here to learn how</a>
        </div>
      );
    }

    let className = "";
    if(this.props.menuType === 'holdingsReport'){
        className = "default chart-multi-option holdings-flag";
    }
      else{ 
        className = item.graphComponent == "externalData" ? (item.listType === 1 || item.isDisabledForCurrentSymbol ? "default chart-multi-option edu-subcontextmenu disable-div" : "default chart-multi-option edu-subcontextmenu") : "default chart-multi-option";
    }

    return (item.isVisible == false?'':
      item.hasDivider ?
        <span className={className}>
          {item.action && <span className="context-action submenulist-action">{item.action.map((actionitem, index) => (<a key={index} className={actionitem.text + ' movingActions'} role="link" tabIndex="0" onClick={(e) => this.toggleActions(e, actionitem.text, item, index)}>{LocalizationStore.getTranslatedData(actionitem.resKey, actionitem.text)}</a>))}</span>}
          <span className="submenulist-text" data-disable-track-price="true" role="link" tabIndex="0" onClick={(e) => this.onSelect(e, item, index)}>
            {item.graphComponent !== GraphComponent.addMAverage && item.isActive && <span className={item.isDisable ? "disabled-icon" : "icn-right-icon"}></span>}
            {LocalizationStore.getTranslatedData(item.resKey, item.header)}{item.suffix}
            {item.Type == "FavoriteOwnerLists" && item.header.length > 28 &&
              <div className="tooltip-box">{LocalizationStore.getTranslatedData(item.resKey, item.header)}{item.suffix}</div>}
          </span>
          <hr />
        </span>
        :
        <span className={className} onClick={item.Type && item.Type == "ExternalLists" ? (e) => this.onSelect(e, item, index) : undefined}>
          {item.action && <span className="context-action submenulist-action">
            {item.action.map((actionitem, index) => (<a key={index} className={actionitem.text + ' movingActions'} role="link" tabIndex="0"
              onClick={(e) => this.toggleActions(e, actionitem.text, item, index)}>{LocalizationStore.getTranslatedData(actionitem.resKey, actionitem.text)}</a>))}</span>}
          <span className={item.className === "ownershipItemDisabled"?"submenulist-text ownershipItemDisabled":"submenulist-text"} data-disable-track-price="true" role="link" tabIndex="0" onClick={item.className === "ownershipItemDisabled" ? undefined:(e) => this.onSelect(e, item, index)}>			 
            {item.graphComponent !== GraphComponent.addMAverage && item.isActive && <span className={item.isDisable ? "disabled-icon" : "icn-right-icon"}></span>}
            {item.graphComponent === GraphComponent.externalData ?
            <span className="extrnal-sub-data-text">{LocalizationStore.getTranslatedData(item.resKey, item.header)}{item.suffix}</span>
            :
            <span>{LocalizationStore.getTranslatedData(item.resKey, item.header)}{item.suffix}</span>}
            {item.Type == "FavoriteOwnerLists" && item.header.length > 28 && <div className="tooltip-box">{LocalizationStore.getTranslatedData(item.resKey, item.header)}{item.suffix}</div>}
          </span>
        </span>
    );
  }

  getItemWithSubmenu(item, index, subMenuRender) {
    let subMenuButtonDirectionClass="arrow-left";
    if(this.props.subMenuButtonDirection)
    {
      if(this.props.subMenuButtonDirection!=="left")
      {
        subMenuButtonDirectionClass="arrow-right"
      }
    }
    return (      
      item.hasDivider ?
            <div className="sub-menu-holder" rel={(item.resKey, item.header)} data-disable-track-price="true" role="link" tabIndex="0" onClick={(e) => this.onSelect(e, item, index)}>
          <div className={subMenuButtonDirectionClass}></div>
          <span className="sub-ownership-dropdown">{LocalizationStore.getTranslatedData(item.resKey, item.header)}{item.suffix} </span>
          <ClickMenu hideOnItemClick={this.state.hideOnItemClick} isSubmenu={true} items={item.items} alignment={this.props.subMenuAlignment ? this.props.subMenuAlignment : this.props.alignment} itemRenderer={subMenuRender} />
          <hr />
        </div>
        :
            <div className="sub-menu-holder" rel={(item.resKey, item.header)} data-disable-track-price="true" role="link" tabIndex="0" onClick={(e) => this.onSelect(e, item, index)}>
          <div className={subMenuButtonDirectionClass}></div>
          <span className="sub-ownership-dropdown">{LocalizationStore.getTranslatedData(item.resKey, item.header)}{item.suffix}</span>
            <ClickMenu hideOnItemClick={this.state.hideOnItemClick} isSubmenu={true} items={item.items} alignment={this.props.subMenuAlignment ? this.props.subMenuAlignment : this.props.alignment} itemRenderer={subMenuRender} />
        </div>
    );
  }
  getDefaultRenderer(item, index, subMenuRender) {
    return item.items ? this.getItemWithSubmenu(item, index, subMenuRender) : this.getItem(item, index);
  }

  onSelect(e, item, index, isEditButton=false) {
    if (item.header === "Fundamental Data Lines" || item.header === "External Data") {
      return;
    }
    // stop click action in favourite and owners list
    if((item.Type == "FavoriteOwnerLists" || item.Type == "PanarayOwnerLists") && (!item.listId || item.isActive == true) && !isEditButton){
      return;
    }
    if (this.props.onselect) {
    this.props.onselect(item /*index*/,isEditButton);
    }
    if (this.props.items && this.props.items[index].onSelect) {
      this.props.items[index].onSelect(item /*index*/,isEditButton);
    }
    if (this.state.hideOnItemClick) {
      this.handleHide();
      e.stopPropagation();
    }

  }  
  getRenderer(item, index) { 
    let subMenuItemRender = this.props.subMenuItemRender;
    if (item.header === 'Zigzag Indicator') subMenuItemRender = this.props.subMenuItemZZRender; 
    if (this.props.itemRenderer) {
      let renderer = this.props.itemRenderer(item);
      return renderer ? renderer : this.getDefaultRenderer(item, index, subMenuItemRender)
    }
    else {
      return this.getDefaultRenderer(item, index, subMenuItemRender)
    }

  }

  handleOnSelection (e) {
    if (this.props.listId) {
      let item = {
      
        "header": this.props.selectedTIChart,
        "listId": this.props.listId
      }
      dispatch(openExternalDataEditDialog(item, false));
    }else if(this.props.isKpiMenu){
      dispatch(openCustomKPIModal({ isShowModal: true, selectedItem: this.props.item }));
    }
    else {
      dispatch(openCustomTIModal({ isShowModal: true, selectedOption: e.target.innerHTML, selectedTiTab: this.props.selectedTIChart }));
    }
    this.handleHide();
  }

  renderCustomTiModal() {
    return (
      <PortalContainer>
        <div onClick={this.handleStopPropagation} className="context-menu dropdown stack-title-dropdown ignore-react-onclickoutside" style={this.state.style}>
          <ul className="context-menu-inner list-contex" aria-labelledby="contextMenu">
            {this.props.isShowSettingsDialog && <li data-disable-track-price="true">
              <span className="default" data-disable-track-price="true" onMouseDown={this.handleOnSelection}>
                {LocalizationStore.getTranslatedData("ipc_md_stgs","Settings")}
              </span>
            </li>}
            {this.props.isShowAppearanceDialog && <li data-disable-track-price="true">
              <span className="default" data-disable-track-price="true" onClick={this.handleOnSelection}>
                {LocalizationStore.getTranslatedData("ipc_st_aprn","Appearance")}
              </span>
            </li>}
            {this.props.isShowAboutDialog && <li data-disable-track-price="true">
              <span className="default" data-disable-track-price="true" role="link" tabIndex="0" onClick={this.handleOnSelection}>
                {LocalizationStore.getTranslatedData("ipc_abt","About")}
              </span>
            </li>}
          </ul>
        </div>
      </PortalContainer>
    )
  }

  renderMenuToolTip() {
    let subContextMenu = "subContextMenu";
    if (this.state.items[0] && this.state.items[0].graphComponent == "OwnershipBlockMenuFavorites"){
      subContextMenu = "owner-subContextMenu"
    }
    const isShowScrollBar = UserInfoUtil.hasExternalDataUploaderEntitlement() && !StringUtil.isEmpty(this.state.items) && this.state.items[0].graphComponent && this.state.items[0].graphComponent === "externalData" && this.state.items.length > 10;
    const dropDownCls = isShowScrollBar ? "context-menu dropdown external-dropdown" : "context-menu dropdown";
    const scrollId = isShowScrollBar ? 'scrollExternalSubMenu-price' : '';
    
    return (
      this.props.alignment === Position.Right && !this.props.isSubmenu ?
        <PortalContainer>
          <div onClick={this.handleStopPropagation} className="context-menu dropdown ignore-react-onclickoutside" style={this.state.style} >
            <ul style={{ height: "auto" }} className="context-menu-inner list-contex small-normal" aria-labelledby="contextMenu">
              {this.state.items.map((item, index) =>
                (item.isDisable ? <li data-disable-track-price="true" key={index} className='menu-item-disabled'>
                  {this.getRenderer(item, index)}
                </li>
                  :
                  <li data-disable-track-price="true" key={index}>
                    {this.getRenderer(item, index)}
                  </li>)
              )}
            </ul>
          </div>
        </PortalContainer>
        :
        <div onClick={this.handleStopPropagation} id={subContextMenu} className={dropDownCls} style={this.state.style} >
          <div className="custom-scroll-light">
          <div id={scrollId}>
              <ul style={{ height: "auto" }} className="context-menu-inner list-contex" aria-labelledby="contextMenu">
                 {this.state.menuTitle && <div id="title" className="chart-multiple-title">{this.state.menuTitle}</div>}
                {this.state.items.map((item, index) =>
                  item.isDisable ? <li data-disable-track-price="true" key={index} className='menu-item-disabled'>
                    {this.getRenderer(item, index)}
                  </li>
                    :
                    <li data-disable-track-price="true" key={index}>
                      {this.getRenderer(item, index)}
                    </li>
                )}
              </ul>
            </div>
            <ScrollBar scrollId='scrollExternalSubMenu-price' vScroll={true} />
          </div>
        </div>
      )
}

getMenu () {
  const menuComponent = this.props.isTICustomModal ? this.renderCustomTiModal() : this.renderMenuToolTip();
    return menuComponent;
  }

  render() {
    return this.state.isopen ? this.getMenu() : (<div id="contextmenu"></div>);
  }
}
const Menu = onClickOutside(ClickMenu);
ClickMenu.propTypes = {
  position: PropTypes.string,
  itemRenderer: PropTypes.func,
  // items: PropTypes.array.isRequired,
  onselect: PropTypes.func,
  button: PropTypes.string,
  subMenuButtonDirection:PropTypes.string,
  alignment: PropTypes.string,
  subMenuItemRender: PropTypes.func,
  isSubmenu: PropTypes.bool,
  hideOnItemClick: PropTypes.bool,
  menuTitle: PropTypes.string,
  ownershipMenu: PropTypes.string,
  externalDataMenu: PropTypes.string
};

export default Menu;
