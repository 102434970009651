import { put, select, call, takeLatest } from "redux-saga/effects";
import { extend } from 'underscore';

import DatagraphHelper from "../../../Utils/DatagraphHelper";
import UserInfoUtil from 'UserInfoUtil';
import BaseServiceApi from 'BaseServiceApi';

import { PriceChartConstants } from "../../../Constants/PriceChartConstants.js";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import GraphType from "GraphType";

import { getDatagraphStates, priceChartReducerselect } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors.js";
import { getRiPanelAIState } from "../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors.js";

import SettingsStore from "SettingsStore";
import SymbolType from "SymbolType";

const { ActionTypes } = RiPanelConstants;
extend(ActionTypes, PriceChartConstants.ActionTypes);

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

function checkTrendUp(allPoints, inValue, stPoint = 0) {
    let i = stPoint;
    let obj = allPoints[i];
    let lngt = allPoints.length;
    let lastObj = allPoints[i];
    let changeUp = 0;
    let changeDown = 0;
    i++;
    for (; i < lngt; i++) {
        obj = allPoints[i];
        if (obj.graphData._volume <= 0) continue;
        changeUp = (obj.graphData.High / lastObj.graphData.Low) - 1;
        changeDown = (obj.graphData.Low / lastObj.graphData.High) - 1;
        if (changeUp >= inValue && changeUp >= Math.abs(changeDown)) {
            return true;
        }
        if (changeDown <= -inValue) {
            return false;
        }
    }
    return true;
}

function* computeData(inValue) {
    const { HiLowPoints, isLineChart } = yield select(priceChartReducerselect);
    const { SymbolInfo } = yield select(getDatagraphStates);
    const isPreIPO = SymbolInfo.SymTypeEnum === SymbolType.PREIPO;

    if (!HiLowPoints.allPoints || !inValue || isLineChart || isPreIPO) return [];

    const allPointsSelected = HiLowPoints.allPoints.filter((item) => item.xAxis > 0 && item.Date >= SymbolInfo.IPODate);
    const allPoints = allPointsSelected.sort((a, b) => a.Date - b.Date);
    let zzValue = inValue;
    let newPoints = [];
    let i = 0;
    let obj = allPoints[i];
    let lngt = allPoints.length;
    let prevPoint = 0;
    let prevChange = 0;
    let prevexpIndex = 0;
    if (lngt < 1 || allPoints[i].graphData === undefined) return newPoints;
    let lastObj = allPoints[i];
    let expObj = allPoints[i];
    let expIndex = i;
    let lastIndex = 0;
    let upTrend = yield call(checkTrendUp, allPoints, inValue);
    let updateLast = false;
    let updateIndex = 0;
    lastObj.upTrend = upTrend;
    lastObj.dataIndex = i;
    expObj.change = prevPoint;
    newPoints.push(lastObj); // Mark The Starting Point
    lastObj.IsHigh = true;
    let change = 0;
    let lastProcessed = true;
    i++;
    for (; i < lngt; i++) {
        obj = allPoints[i];
        if (obj.graphData._volume <= 0) continue;
        if (upTrend) {
            change = (obj.graphData.High / lastObj.graphData.Low) - 1;
            if (change >= zzValue && prevPoint <= change) {
                expObj = allPoints[i];
                expIndex = i;
                prevPoint = change;
                lastProcessed = false;
                continue;
            } else
                if (expObj.Date !== lastObj.Date) {
                    expObj.upTrend = true;
                    expObj.dataIndex = expIndex;
                    expObj.change = prevPoint;
                    if (updateLast) {
                        newPoints[updateIndex] = expObj;
                        updateLast = false;
                    } else {
                        newPoints.push(expObj);
                        lastIndex++;
                    }
                    lastProcessed = true;
                    updateIndex = lastIndex;
                    if (lastIndex > 2 && !updateLast) {
                        let lastObj2 = newPoints[lastIndex - 2];
                        let obj2 = newPoints[lastIndex - 1];
                        for (let j = obj2.dataIndex + 1; j < expIndex; j++) {
                            change = (allPoints[j].graphData.Low / lastObj2.graphData.High) - 1;
                            if (change <= -zzValue && prevChange > change && obj2.change >= change && allPoints[j].graphData.Low < newPoints[lastIndex - 1].graphData.Low) {
                                allPoints[j].dataIndex = j;
                                allPoints[j].upTrend = false;
                                allPoints[j].change = change;
                                newPoints[lastIndex - 1] = allPoints[j];
                                prevChange = change;
                                continue;
                            }
                        }
                    }
                    i = prevexpIndex >= expIndex ? i : expIndex;
                    prevexpIndex = i;
                    //upTrend = false;
                    upTrend = yield call(checkTrendUp, allPoints, inValue, i);
                    if (upTrend) {
                        updateLast = true;
                    } else {
                        lastObj = expObj;
                        prevChange = 0;
                        prevPoint = 0;
                    }
                    continue;
                }
            if (obj.graphData.Low < lastObj.graphData.Low &&
                updateIndex > 0 && newPoints[updateIndex - 1].change > change) {
                i--;
                lastObj = newPoints[updateIndex - 1];
                upTrend = false;
                updateLast = true;
                continue;
            }
        } else {
            change = (obj.graphData.Low / lastObj.graphData.High) - 1;
            if (change <= -zzValue && prevPoint >= change) {
                expObj = allPoints[i];
                expIndex = i;
                prevPoint = change;
                lastProcessed = false;
                continue;
            } else {
                if (expObj.Date !== lastObj.Date) {
                    expObj.upTrend = false;
                    expObj.dataIndex = expIndex;
                    expObj.change = prevPoint;
                    if (updateLast) {
                        newPoints[updateIndex] = expObj;
                        updateLast = false;
                    } else {
                        newPoints.push(expObj);
                        lastIndex++;
                    }
                    lastProcessed = true;
                    updateIndex = lastIndex;
                    if (lastIndex > 2 && !updateLast) {
                        let lastObj2 = newPoints[lastIndex - 2];
                        let obj2 = newPoints[lastIndex - 1];
                        for (let j = obj2.dataIndex + 1; j < expIndex; j++) {
                            change = (allPoints[j].graphData.High / lastObj2.graphData.Low) - 1;
                            if (change >= zzValue && prevChange < change && obj2.change <= change && allPoints[j].graphData.High > newPoints[lastIndex - 1].graphData.High) {
                                allPoints[j].dataIndex = j;
                                allPoints[j].upTrend = true;
                                allPoints[j].change = change;
                                newPoints[lastIndex - 1] = allPoints[j];
                                prevChange = change;
                                continue;
                            }
                        }
                    }
                    i = prevexpIndex >= expIndex ? i : expIndex;
                    prevexpIndex = i;
                    //upTrend = true;
                    upTrend = yield call(checkTrendUp, allPoints, inValue, i);
                    if (!upTrend) {
                        updateLast = true;
                    } else {
                        lastObj = expObj;
                        prevChange = 0;
                        prevPoint = 0;
                    }
                    continue;
                }
                if (obj.graphData.High > lastObj.graphData.High &&
                    updateIndex > 0 && newPoints[updateIndex - 1].change < change) {
                    i--;
                    lastObj = newPoints[updateIndex - 1];
                    upTrend = true;
                    updateLast = true;
                    continue;
                }
            }
        }
    }
    if (!lastProcessed) {
        expObj.dataIndex = expIndex;
        expObj.change = prevPoint;

        /*
           Follow the opposite direction of the previous trend only after the second trendline is formed. Until then, continue in the same direction as the current trend line. 
           Note: The first data point is only used to determine the starting position of the first trendline. This is why at least two trendlines are required to predict the upcoming trend direction.
           */
        if (updateLast) {
            expObj.upTrend = newPoints.length > 2 ? !newPoints[updateIndex - 1].upTrend : newPoints[updateIndex - 1].upTrend;
            newPoints[updateIndex] = expObj;
            updateLast = false;
        } else {
            expObj.upTrend = newPoints.length > 1 ? !newPoints[updateIndex].upTrend : newPoints[updateIndex].upTrend;
            newPoints.push(expObj);
        }
    }
    return newPoints;
}

function* updateZZSwingSize({ item }) {
    try {
        const { majorPeriodicity } = yield select(priceChartReducerselect);
        let { aiSettings, zigzagIndicatorData } = yield select(getRiPanelAIState);
        const { swingSize } = aiSettings.zigzagIndicator;

        if (item.value === 0) {
            swingSize[GraphType.Daily] = 10;
            swingSize[GraphType.Weekly] = 15;
            swingSize[GraphType.Monthly] = 20;
        } else {
            swingSize[item.periodicity] = item.value;
        }

        if (item.periodicity === 'All' || majorPeriodicity === item.periodicity) {
            zigzagIndicatorData = yield call(computeData, swingSize[majorPeriodicity] / 100);
        }

        yield put({
            type: ActionTypes.ZZ_INDICATOR_DATA_READY,
            newState: { zigzagIndicatorData, aiSettings: Object.assign({}, aiSettings) }
        });

        SettingsStore.saveSettings();
    }
    catch (error) {
        console.error("Error occured in RiPanelAI.js, updateZZSwingSize", error);
    }
}

function* processZigzagIndicatorData() {
    try {
        const isZigzagBasicEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.ZIG_ZAG_INDICATOR_Entitlement);
        const isZigzagAIEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.ZIGZAG_AI_INDICATOR_Entitlement);

        if (isZigzagBasicEntitled || isZigzagAIEntitled) {
            const { majorPeriodicity } = yield select(priceChartReducerselect);

            /* Retrieve the settings according to the instrument type */
            const consoleSettings = SettingsStore.getConsoleSettings();
            const aiSettings = DatagraphHelper.getSettingsObject(consoleSettings, consoleSettings.NavDatagraphSettings.RelatedInformationSettings).AiSettings;
            let zigzagIndicatorData = [];

            /* Intraday, Quarterly and Annual periodicities are currently out of scope */
            if (!(majorPeriodicity === GraphType.Intraday || majorPeriodicity === GraphType.Quarterly || majorPeriodicity === GraphType.Annual)) {

                const percent = aiSettings?.zigzagIndicator?.swingSize[majorPeriodicity] / 100;

                zigzagIndicatorData = yield call(computeData, percent);

            }

            yield put({
                type: ActionTypes.ZZ_INDICATOR_DATA_READY,
                newState: { zigzagIndicatorData, aiSettings, activePeriodLineSettings: aiSettings?.zigzagIndicator?.lineStyle[majorPeriodicity], majorPeriodicity, isZigzagAIEntitled }
            });
        }
    } catch (error) {
        console.log(`Error occured in RiPanelAI.js, processZigzagIndicatorData ${error}`)
    }
}


/*** watchers ***/

export function* watchInitZZIndicator() {
    yield takeLatest(ActionTypes.PRICE_DATA_READY, processZigzagIndicatorData)
}

export function* watchUpdateZZSwingSize() {
    yield takeLatest(ActionTypes.UPDATE_ZZ_INDICATOR_SWING_SIZE, updateZZSwingSize)
}