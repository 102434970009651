import ArrayUtil from "ArrayUtil";
import BaseServiceApi from 'BaseServiceApi';
import DatagraphHelper from "../../../Utils/DatagraphHelper";
import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import DocUtil from "DocUtil";
import ExtremeDataValue from "ExtremeDataValue";
import GraphType from "GraphType";
import moment from "moment";
import { PriceChartConstants } from "../../../Constants/PriceChartConstants";
import { riPanelInfoState } from "../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors";
import SmartViewType from "SmartViewType";
import StockMarketUtil from "../../../Utils/StockMarketUtil";
import StringUtil from "StringUtil";
import ThemeHelper from "ThemeHelper";
import { IdeaShortNameTranslateHelper, IdeaTranslateHelper } from "../../../Utils/TranslateHelper";
import { priceChartReducerselect, getDatagraphStates, IdeasSelect, RiPanelOneil } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import { takeLatest, select, put, fork } from "redux-saga/effects";
import { RIPanelOneilConstants } from "../../../Constants/RIPanelOneilConstants";
import SettingsStore from "SettingsStore";

const { ActionTypes } = DataGraphConstants;
const IdeaType = BaseServiceApi.rayData["IdeaType"];
const IdeaColorConst = {
    [IdeaType.ALLCAPS_LAGGARD_Idea]: "#980BB2",
    [IdeaType.ALLCAPS_LEADER_Idea]: "#20A7FD",
    [IdeaType.LARGECAP_LAGGARD_Idea]: "#F55D99",
    [IdeaType.LARGECAP_LEADER_Idea]: "#0E9897",
    [IdeaType.DEVELOPED_LONG_Idea]: "#1B7400",
    [IdeaType.EMERGING_LONG_Idea]: "#65A251",
    [IdeaType.FRONTIER_LONG_Idea]: "#00FFFF",
    [IdeaType.GFL_EUROPE_LONG_Idea]: "#0F72BD",
    [IdeaType.USA_LONG_Idea]: "#E25915",
    [IdeaType.CHINA_ASHARE_LONG_Idea]: "#FF0000",
    [IdeaType.DEVELOPED_SHORT_Idea]: "#FE1301",
    [IdeaType.EMERGING_SHORT_Idea]: "#9C0101",
    [IdeaType.FRONTIER_SHORT_Idea]: "#FF00FF",
    [IdeaType.GFL_EUROPE_SHORT_Idea]: "#FF3333",
    [IdeaType.USA_SHORT_Idea]: "#CF8E11",
    [IdeaType.CHINA_ASHARE_SHORT_Idea]: "#999900"
}



export const flagIconStyleConst = {
    [IdeaType.USA_LONG_Idea]: "icn-log1",
    [IdeaType.USA_SHORT_Idea]: "icn-log-usa-short",
    [IdeaType.CHINA_ASHARE_LONG_Idea]: "icn-log6",
    [IdeaType.CHINA_ASHARE_SHORT_Idea]: "icn-log7",
    [IdeaType.ALLCAPS_LEADER_Idea]: "icn-log-allcaps-leader",
    [IdeaType.ALLCAPS_LAGGARD_Idea]: "icn-log-allcaps-laggard",
    [IdeaType.LARGECAP_LEADER_Idea]: "icn-log-largecap-leader",
    [IdeaType.LARGECAP_LAGGARD_Idea]: "icn-log-largecap-laggard",
    [IdeaType.DEVELOPED_LONG_Idea]: "icn-log2",
    [IdeaType.DEVELOPED_SHORT_Idea]: "icn-log-developed-short",
    [IdeaType.EMERGING_LONG_Idea]: "icn-log3",
    [IdeaType.EMERGING_SHORT_Idea]: "icn-log-emerging-short",
    [IdeaType.FRONTIER_LONG_Idea]: "icn-log4",
    [IdeaType.FRONTIER_SHORT_Idea]: "icn-log-frontier-short",
    [IdeaType.GFL_EUROPE_LONG_Idea]: "icn-log5",
    [IdeaType.GFL_EUROPE_SHORT_Idea]: "icn-log-europe-short"
}

function checkDisplayFlag(ideaData, activeIdeaTypes) {
    if (activeIdeaTypes.length === 0) {
        return false;
    }
    return activeIdeaTypes.includes(ideaData.ideaType);
}
function SetTimelineDate(xDate, endDate, periodicity) {
    try {
        const date = DatagraphHelper.NDateNode(xDate, false, endDate, periodicity);
        return date;
    } catch (error) {
        console.log(`Error occurs in IdeasSaga.js, SetTimelineDate ${error}`)
    }
}
function FindStockIndex(date, HiLowPoints) {
    let index = 0;
    const stockData = HiLowPoints.allPoints;
    const lng = HiLowPoints.allPoints.length;
    if (stockData[lng - 1] && date.getTime() < stockData[lng - 1].Date.getTime()) {
        return -1;
    }
    for (; index < lng; index++) {
        if (date.getDate() === stockData[index].Date.getDate() &&
            date.getFullYear() === stockData[index].Date.getFullYear() &&
            date.getMonth() === stockData[index].Date.getMonth()) {
            break;
        }
    }
    return index < lng ? index : -1;
}
function chgS(chg, isPrice = true) {
    let price;
    price = Math.abs(chg);

    const round = price < 1.0 && isPrice ? 3 : 2;

    price = parseFloat(price.toFixed(round));

    const chgS = price > 999999
        ? ExtremeDataValue.abbreviateValue(price, 2)
        : (price > 99999
            ? Math.round(price)
            : (price < 1.0 && isPrice
                ? price.toFixed(3)
                : price.toFixed(2)));

    return chgS;
}
function* CalcIdeasData() {
    try{
        const ideas = [];
        let ideasIndex = 0;
        let ideasDisplay = [];
        const { endDate, HiLowPoints } = yield select(priceChartReducerselect);
        const { SymbolInfo, periodicity, viewsSettings, SymbolType, leftScaleWidth } = yield select(getDatagraphStates);
        const { riPanelViewSettings } = yield select(riPanelInfoState)
        const ideasData = yield select(IdeasSelect);
        if (!ideasData || !riPanelViewSettings.OneilListSettings || !ideasData.ideas || HiLowPoints === undefined || HiLowPoints.allPoints === undefined || !SymbolType === SmartViewType.STOCK) {
            return;
        }
        const activeIdeaTypes = riPanelViewSettings.OneilListSettings.EntitledIdeaTypes;
        yield fork(prepareTempIdeas, ideasData, HiLowPoints, endDate, periodicity, activeIdeaTypes);
        // if (_state.ideaCurrencyCode != DatagraphStore.getCurrencyCode(this.getCurrecyCode(SymbolInfo.DefaultCurrency))) {
        //     return;
        // } *****optPriceChart  
        const current = ideasData.ideas.currentIdeas;
        const historic = ideasData.ideas.historicalIdeas;
        const lastPriceNode = HiLowPoints.allPoints[0];
        let lng = current.length;
        const pText = ThemeHelper.getThemedBrush("positiveNode");
        const nText = ThemeHelper.getThemedBrush("negativeNode");
        let inDateKey;
        let outDateKey;
        let PriceIn;
        let inPriceNode;
        const chinaAIdea = current.find((itm) => itm.ideaType === IdeaType.CHINA_ASHARE_LONG_Idea);
    
        for (let i = lng - 1; i > -1; i--) {
            const node = current[i];
            if (node === null) {
                continue;
            }
            if (chinaAIdea && SymbolInfo.CountryCode === 4 && node.ideaType === 6) {
                continue;
            }
            if (!checkDisplayFlag(node, activeIdeaTypes)) {
                continue;
            }
            inDateKey = new Date(node.inDt.toNumber());
            if (inDateKey.getTime() > endDate.getTime()) {
                continue;
            }
            outDateKey = node.outDt ? new Date(node.outDt.toNumber()) : endDate;
            node.InActionDate = new Date(inDateKey);
            node.InDate = SetTimelineDate(new Date(inDateKey), endDate, periodicity);
            node.IdeaColor = IdeaColorConst[node.ideaType] || "#FFFFFF";
            node.IdeaShortName = IdeaShortNameTranslateHelper[node.ideaType] || "";
            node.IdeaStyle = flagIconStyleConst[node.ideaType] || "";
            node.short = node.ideaType === 1 || node.ideaType === 3 || node.ideaType === 10 || node.ideaType === 11 || node.ideaType === 12 ||
                node.ideaType === 13 || node.ideaType === 15 || node.ideaType === 17 || node.ideaType === 19
            PriceIn = FindStockIndex(node.InDate, HiLowPoints);
            if (PriceIn > -1) {
                inPriceNode = HiLowPoints.allPoints[PriceIn];
                const change = (100.0 * ((HiLowPoints.allPoints[0].graphData.Close / node.inPriceSplitAdj) - 1.0));
                const sign = node.short ? (change < 0 ? '+' : '-') : (change >= 0 ? '+' : '-');
                let ideaDaysText = "";
                let IdeaInfoText = "";
                let ideaTextColor = "";
                const outDate = outDateKey ? outDateKey : endDate;
                const inDate = inDateKey ? inDateKey : endDate;
                let isPositive = false;
                if (inDate !== undefined && outDate !== undefined) {
                    const days = DocUtil.CalculatDuration(inDate, outDate, periodicity, false);
                    IdeaInfoText = ` ${sign}${chgS(Math.abs(change), false)}% `;
                    if (node.short) {
                        ideaTextColor = change < 0 ? pText : nText;
                        isPositive = (change < 0);
                    }
                    else {
                        ideaTextColor = change >= 0 ? pText : nText;
                        isPositive = (change >= 0);
                    }
                    ideaDaysText = days;
                }
                node.xAxisIn = inPriceNode.xAxis;// + leftScaleWidth;
                node.inPriceY = inPriceNode.yLow;
                if (SymbolInfo) {
                    const cNode = {
                        Type: 0,
                        xAxisIn: inPriceNode.xAxis,// + leftScaleWidth,
                        yAxisIn: inPriceNode.yPrice,
                        xAxisLast: lastPriceNode.xAxis,// + leftScaleWidth,
                        yAxisLast: lastPriceNode.yPrice,
                        inPriceY: inPriceNode.yLow,
                        IdeaStyle: node.IdeaStyle,
                        IdeaType: node.ideaType,
                        InDate: node.InDate,
                        InActionDate: node.InActionDate,
                        IdeaColor: node.IdeaColor,
                        short: node.short,
                        IdeaShortName: `${node.IdeaShortName} (${node.short ? IdeaTranslateHelper.AVOID : IdeaTranslateHelper.BUY })`,
                        IdeaDaysText: ideaDaysText,
                        ideaTextColor: ideaTextColor,
                        IdeaInfoText: IdeaInfoText,
                        Entry: ExtremeDataValue.getFormattedNumber(node.inPriceSplitAdj, 2),
                        Exit: ExtremeDataValue.getFormattedNumber(HiLowPoints.allPoints[0].graphData.Close, 2),
                        isPdfDataAvailable: !StringUtil.isEmpty(node.researchPDFPath),
                        pdfResearchPath: node.researchPDFPath ? node.researchPDFPath : "",
                        isVideoUrlAvailable: !StringUtil.isEmpty(node.researchVideo),
                        videoResearchPath: node.researchVideo ? node.researchVideo : "",
                        isPositive: isPositive
                    };
                    ideas.push(cNode);
                    if (!ArrayUtil.contains(HiLowPoints.allPoints[PriceIn].IdeasTr, ideasIndex)) {
                        HiLowPoints.allPoints[PriceIn].IdeasTr.push(ideasIndex);
                    }
                    ideasIndex++;
                }
            }
        }
    
        lng = historic.length;
        // if (viewsSettings.LLRSettings && viewsSettings.LLRSettings.ShowHistorical) {
            for (let i = lng - 1; i > -1; i--) {
                const node = historic[i];
                if (node === null) {
                    continue;
                }
                if (!StringUtil.isEmpty(current)) {
                    if (!checkDisplayFlag(node, activeIdeaTypes)) {
                        continue;
                    }
                }
                let dateKey = moment(new Date(node.inDt.toNumber())).format("M/D/YYYY");
                inDateKey = new Date(dateKey);
                if (inDateKey.getTime() > endDate.getTime()) {
                    continue;
                }
                node.InActionDate = new Date(inDateKey);
                node.InDate = SetTimelineDate(new Date(dateKey), endDate, periodicity);
                dateKey = moment(new Date(node.outDt.toNumber())).format("M/D/YYYY");
                outDateKey = new Date(dateKey);
                node.OutActionDate = new Date(dateKey);
                node.OutDate = SetTimelineDate(new Date(dateKey), endDate, periodicity);
                node.IdeaColor = IdeaColorConst[node.ideaType] || "#FFFFFF";
                node.IdeaShortName = IdeaShortNameTranslateHelper[node.ideaType] || "";
                node.short = node.ideaType === 1 || node.ideaType === 3 || node.ideaType === 10 || node.ideaType === 11 || node.ideaType === 12 ||
                    node.ideaType === 13 || node.ideaType === 15 || node.ideaType === 17
                PriceIn = FindStockIndex(node.InDate, HiLowPoints);
                const PriceOut = FindStockIndex(node.OutDate, HiLowPoints);
                inPriceNode = PriceIn > -1 ? HiLowPoints.allPoints[PriceIn] : null;
                const outPriceNode = PriceOut > -1 ? HiLowPoints.allPoints[PriceOut] : null;
                if (PriceIn > -1 && PriceOut > -1) {
                    const change = (100.0 * ((node.outPriceSplitAdj / node.inPriceSplitAdj) - 1.0));
                    const sign = node.short ? (change < 0 ? '+' : '-') : (change >= 0 ? '+' : '-');
                    let ideaDaysText = "";
                    let IdeaInfoText = "";
                    let ideaTextColor = "";
                    let isPositive = false;
                    if (inDateKey !== undefined && outDateKey !== undefined) {
                        const days = DocUtil.CalculatDuration(inDateKey, outDateKey, periodicity, false);
                        IdeaInfoText = ` ${sign}${chgS(Math.abs(change), false)}% `;
                        ideaDaysText = days;
                        if (node.short) {
                            ideaTextColor = change < 0 ? pText : nText;
                            isPositive = (change < 0);
                        }
                        else {
                            ideaTextColor = change >= 0 ? pText : nText;
                            isPositive = (change >= 0);
                        }
                    }
                    node.xAxisIn = inPriceNode.xAxis;// + leftScaleWidth;
                    node.inPriceY = inPriceNode.yLow;
                    const bNode = {
                        Type: 1,
                        xAxisIn: inPriceNode.xAxis,// + leftScaleWidth,
                        yAxisIn: inPriceNode.yPrice,
                        xAxisLast: outPriceNode === null ? inPriceNode.xAxis: outPriceNode.xAxis,
                        yAxisLast: outPriceNode === null ? inPriceNode.yPrice : outPriceNode.yPrice,
                        inPriceY: inPriceNode.yLow,
                        outPriceY: inPriceNode.yHigh,
                        IdeaStyle: node.IdeaStyle,
                        IdeaType: node.ideaType,
                        InDate: node.InDate,
                        InActionDate: node.InActionDate,
                        OutDate: node.OutDate,
                        OutActionDate: node.OutActionDate,
                        IdeaColor: node.IdeaColor,
                        short: node.short,
                        IdeaShortName: `${node.IdeaShortName} (${node.short ? IdeaTranslateHelper.AVOID : IdeaTranslateHelper.BUY })`,
                        IdeaDaysText: ideaDaysText,
                        ideaTextColor: ideaTextColor,
                        IdeaInfoText: IdeaInfoText,
                        Entry: ExtremeDataValue.getFormattedNumber(node.inPriceSplitAdj, 2),
                        Exit: ExtremeDataValue.getFormattedNumber(node.outPriceSplitAdj, 2),
                        isPdfDataAvailable: !StringUtil.isEmpty(node.researchPDFPath),
                        pdfResearchPath: node.researchPDFPath ? node.researchPDFPath : "",
                        isVideoUrlAvailable: !StringUtil.isEmpty(node.researchVideo),
                        videoResearchPath: node.researchVideo ? node.researchVideo : "",
                        isPositive: isPositive
                    };
                    ideas.push(bNode);
                    if (!ArrayUtil.contains(HiLowPoints.allPoints[PriceIn].IdeasTr, ideasIndex)) {
                        HiLowPoints.allPoints[PriceIn].IdeasTr.push(ideasIndex);
                    }
                    ideasIndex++;
                }
                if (PriceIn > -1 && PriceOut > -1 && outDateKey.getTime() < endDate.getTime()) {
                    const change = (100.0 * ((node.outPriceSplitAdj / node.inPriceSplitAdj) - 1.0));
                    const sign = node.short ? (change < 0 ? '+' : '-') : (change >= 0 ? '+' : '-');
                    let ideaDaysText = "";
                    let IdeaInfoText = "";
                    let ideaTextColor = "";
                    let isPositive = false;
                    if (inDateKey !== undefined && outDateKey !== undefined) {
                        const days = DocUtil.CalculatDuration(inDateKey, outDateKey, periodicity, false);
                        IdeaInfoText = ` ${sign}${chgS(Math.abs(change), false)} `;
                        ideaDaysText = days;
                        if (node.short) {
                            ideaTextColor = change < 0 ? pText : nText;
                            isPositive = (change < 0);
                        }
                        else {
                            ideaTextColor = change >= 0 ? pText : nText;
                            isPositive = (change >= 0);
                        }
                    }
                    node.xAxisOut = outPriceNode.xAxis;// + leftScaleWidth;
                    node.outPriceY = outPriceNode.yHigh;
                    const sNode = {
                        Type: 2,
                        xAxisIn: inPriceNode === null ? outPriceNode.xAxis + leftScaleWidth : inPriceNode.xAxis,
                        yAxisIn: inPriceNode === null ? outPriceNode.yPrice : inPriceNode.yPrice,
                        xAxisLast: outPriceNode.xAxis,// + leftScaleWidth,
                        yAxisLast: outPriceNode.yPrice,
                        xAxisOut: outPriceNode.xAxis,// + leftScaleWidth,
                        inPriceY: outPriceNode.yLow,
                        outPriceY: outPriceNode.yHigh,
                        IdeaStyle: node.IdeaStyle,
                        IdeaType: node.ideaType,
                        InDate: node.InDate,
                        InActionDate: node.InActionDate,
                        OutDate: node.OutDate,
                        OutActionDate: node.OutActionDate,
                        IdeaColor: node.IdeaColor,
                        short: node.short,
                        IdeaShortName: `${node.IdeaShortName} (${node.short ? IdeaTranslateHelper.REMOVE : IdeaTranslateHelper.SELL })`,
                        IdeaDaysText: ideaDaysText,
                        ideaTextColor: ideaTextColor,
                        IdeaInfoText: IdeaInfoText,
                        Entry: ExtremeDataValue.getFormattedNumber(node.inPriceSplitAdj, 2),
                        Exit: ExtremeDataValue.getFormattedNumber(node.outPriceSplitAdj, 2),
                        isPdfDataAvailable: !StringUtil.isEmpty(node.researchPDFPath),
                        pdfResearchPath: node.researchPDFPath ? node.researchPDFPath : "",
                        isVideoUrlAvailable: !StringUtil.isEmpty(node.researchVideo) && node.short,
                        videoResearchPath: node.researchVideo && node.short ? node.researchVideo : "",
                        isPositive: isPositive
                    };
                    ideas.push(sNode);
                    if (!ArrayUtil.contains(HiLowPoints.allPoints[PriceOut].IdeasTr, ideasIndex)) {
                        HiLowPoints.allPoints[PriceOut].IdeasTr.push(ideasIndex);
                    }
                    ideasIndex++;
                }
            }
        // }
        const { stick } = yield select(RiPanelOneil);
        for (let ind = 0; ind < stick.length; ind++) {
            const s = stick[ind];
            const findInd = ideas.findIndex((t) => (t.IdeaShortName === s.IdeaShortName && t.InDate.getTime() === s.InDate.getTime() && t.Type === s.Type));
            if (findInd >= 0) {
                ideas[findInd].stick = s.stick;
            }
        }
        ideasDisplay = ideas;
        yield put({
            type: PriceChartConstants.ActionTypes.ONEIL_IDEAS_INFO,
            ideasDisplay
        });
    }catch(error){
        console.log(`Error occurs in IdeasSaga.js, CalcIdeasData, ${error}`)
    }
    

}
function* prepareTempIdeas(ideasData, HiLowPoints, endDate, periodicity, activeIdeaTypes) {
    const tempIdeas = [];
    const pText = ThemeHelper.getThemedBrush("positiveNode");
    const nText = ThemeHelper.getThemedBrush("negativeNode");
    let ideaDiaplayData = [];
    let sign = '';
    let idea = {};
    if (ideasData && ideasData.ideas) {
        if (ideasData.ideas.xflType === 1) {
            idea.ideaType = IdeaType.NONE;
            idea.IdeaShortText = `    ${IdeaTranslateHelper.EXCLUSIVE_LONG_IDEA}`;
            idea.IdeaPDFPath = ideasData.ideas.pdfFilename ? ideasData.ideas.pdfFilename : "";
            idea.IdeaVideoPath = ideasData.ideas.videoPath ? ideasData.ideas.videoPath : "";
        } else if (ideasData.ideas.xflType === 2) {
            idea.ideaType = IdeaType.NONE;
            idea.IdeaShortText = `    ${IdeaTranslateHelper.EXCLUSIVE_SHORT_IDEA}`;
            idea.IdeaPDFPath = ideasData.ideas.pdfFilename ? ideasData.ideas.pdfFilename : "";
            idea.IdeaVideoPath = ideasData.ideas.videoPath ? ideasData.ideas.videoPath : "";
        }
        tempIdeas.push(idea);
        idea = {};
    }
    if (ideasData &&
        ideasData.ideas &&
        ideasData.ideas.currentIdeas.length > 0) {
        const chinaAIdea = ideasData.ideas.currentIdeas.find((itm) => itm.ideaType === IdeaType.CHINA_ASHARE_LONG_Idea);
        (chinaAIdea) ? ideaDiaplayData.push(chinaAIdea) : ideaDiaplayData = ideasData.ideas.currentIdeas;

        ideaDiaplayData.forEach((currentIdeaList) => {
            if (checkDisplayFlag(currentIdeaList, activeIdeaTypes)) {
                const change = (100.0 * ((HiLowPoints.allPoints[0].graphData.Close / currentIdeaList.inPriceSplitAdj) - 1.0));
                sign = currentIdeaList.short ? (change < 0 ? '+' : '-') : (change >= 0 ? '+' : '-');
                const outDt = currentIdeaList.outDt ? new Date(currentIdeaList.outDt.toNumber()) : endDate;
                const inDt = currentIdeaList.inDt ? new Date(currentIdeaList.inDt.toNumber()) : endDate;
                if (inDt !== undefined && endDate !== undefined && outDt !== undefined && inDt.getTime() < endDate.getTime()) {
                    const days = DocUtil.CalculatDuration(inDt, outDt, periodicity, false);
                    idea.ideaType = currentIdeaList.ideaType;
                    idea.IdeaShortText = `    ${IdeaShortNameTranslateHelper[currentIdeaList.ideaType] || ""}`;
                    idea.IdeaInfoText = ` ${sign}${chgS(Math.abs(change), false)}% ${days}`;
                    if (currentIdeaList.short) {
                        idea.ideaTextColor = change < 0 ? pText : nText;
                        idea.isPositive = change < 0;
                    }
                    else {
                        idea.ideaTextColor = change >= 0 ? pText : nText;
                        idea.isPositive = change >= 0;
                    }
                    idea.IdeaStyle =  flagIconStyleConst[currentIdeaList.ideaType] || "";
                    idea.IdeaPDFPath = currentIdeaList.researchPDFPath ? currentIdeaList.researchPDFPath : null;
                    idea.IdeaVideoPath = currentIdeaList.researchVideo ? currentIdeaList.researchVideo : "";
                }
            }
            if (idea && idea.IdeaShortText && idea.IdeaShortText.length > 0) {
                tempIdeas.push(idea);
            }
            idea = {};
        })
    }
    yield put({
        type: PriceChartConstants.ActionTypes.ONEIL_TEMP_IDEAS_INFO,
        tempIdeas
    });
}
function* IdeasMouseDown({ ideaType }) {
    try{
        const { ideasDisplay } = yield select(RiPanelOneil);
        if (ideasDisplay.length > 0) {
            let idea = ideasDisplay[0]
            const tempData = ideasDisplay.find((itm) => itm.IdeaType === ideaType);
            if (tempData) {
                idea = tempData
                const stickData = ideasDisplay.find((itm) => itm.stick === true);
                if (stickData) {
                    idea.zIndex = stickData.zIndex + 1
                }
            }
            idea.stick = idea.stick ? false : true;
            const stick = ideasDisplay.filter((c) => c.stick);
            yield put({
                type: PriceChartConstants.ActionTypes.STORE_STICK_IDEAS,
                stick
            });
        }
    }
    catch(error){
        console.log(`Error occurs in IdeasSaga.js, IdeasMouseDown, ${error}`);
    }
    
}
function* IdeasMouseOver({ ideaType }) {
    try{
        yield put({
            type: PriceChartConstants.ActionTypes.SELECTED_IDEA,
            selectIdeaType: ideaType
        });
        let { ideasDisplay } = yield select(RiPanelOneil);
        if (ideasDisplay.length > 0) {
            ideasDisplay = ideasDisplay.map((itm, k) => {
                if (k === 0) {
                    itm.highLight = false
                }
                return itm;
            })
        }
        yield put({
            type: PriceChartConstants.ActionTypes.SHOW_IDEA_BOX,
            showBox: true,
            ideasDisplay
        });
    }
    catch(error){
        console.log(`Error occurs in IdeasSaga.js, IdeasMouseOver, ${error}`)
    }
    

}
function* ideasMouseLeave() {
    try{
        const { ideasDisplay } = yield select(RiPanelOneil);

        if (ideasDisplay.length > 0) {
            ideasDisplay[0].highLight = false;
        }
        yield put({
            type: PriceChartConstants.ActionTypes.SHOW_IDEA_BOX,
            showBox: false,
            ideasDisplay
        });
    }catch(error){
        console.log(`Error ocurres in IdeasSaga.js, ideasMouseLeave, ${error}`);
    }
    
}
function* updatePricePanelLeaderFlagStatus({clickedItem,isleaderflag}){
    try{
    const state = yield select(RiPanelOneil);
    const currentEntitlementArray = state.currentEntitlementInfo.map((item)=>{
        if (isleaderflag) {
            if (clickedItem.ideaType === item.LeaderIdeaType) {
                item.IsCheckedLeaderFlag = !item.IsCheckedLeaderFlag;
            }
        }
        else {
            if (clickedItem.ideaType === item.LaggardIdeaType) {
                item.IsCheckedLaggardFlag = !item.IsCheckedLaggardFlag;
            }
        }
        return item;
    });
    state.OneilListSettings.setEntitlementList(currentEntitlementArray);
    SettingsStore.saveSettings();
    yield put({
        type: RIPanelOneilConstants.ActionTypes.UPDATE_RI_LEADER_FLAG_STATUS_SAVE,
        currentEntitlementArray: currentEntitlementArray
    });
    yield fork(CalcIdeasData);
    }catch(error){
        console.log(`Error ocurres in IdeasSaga.js, updatePricePanelLeaderFlagStatus, ${error}`);
    }
}
function* updatePricePanelHistoricalFlagStatus({showHistorical}){
    try{
        const {viewsSettings} = yield select(getDatagraphStates);
        viewsSettings.LLRSettings.ShowHistorical = showHistorical;
        SettingsStore.saveSettings();
        yield put({
            type: RIPanelOneilConstants.ActionTypes.UPDATE_RI_HISTORIC_FLAG_STATUS,
            showHistorical
        });
    }catch(error){
        console.log(`Error ocurres in IdeasSaga.js, updatePricePanelLeaderFlagStatus, ${error}`);
    }
}
/*******************Watchers*************************/
export function* watchUpdateOneilIdeasData() {
    // yield takeLatest(ActionTypes.UPDATE_ONEIL_IDEAS_INFO, updateIdeasData);
}
export function* watchUpdateOneilLeaderFlag() {
    yield takeLatest(RIPanelOneilConstants.ActionTypes.UPDATE_RI_LEADER_FLAG_STATUS, updatePricePanelLeaderFlagStatus);
}
export function* watchUpdateOneilHistoricFlag() {
    yield takeLatest(RIPanelOneilConstants.ActionTypes.UPDATE_SHOW_HISTORICAL_FLAG, updatePricePanelHistoricalFlagStatus);
}
export function* watchCalculateIdeasData() {
    yield takeLatest(PriceChartConstants.ActionTypes.CALCULATE_IDEA_DG, CalcIdeasData);
}

export function* watchIdeasMouseDown() {
    yield takeLatest(PriceChartConstants.ActionTypes.IDEA_MOUSE_DOWN, IdeasMouseDown);
}

export function* watchIdeasMouseOver() {
    yield takeLatest(PriceChartConstants.ActionTypes.IDEA_MOUSE_OVER, IdeasMouseOver);
}

export function* watchIdeasMouseLeave() {
    yield takeLatest(PriceChartConstants.ActionTypes.IDEA_MOUSE_LEAVE, ideasMouseLeave);
}