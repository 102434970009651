import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetZigzagTrendDataRequest extends BaseServiceRequest {
  constructor(symbol, startDate, endDate, trendType) {
    super();
    super.addRequestUri("getpricetrendanalysis");
    super.addRequestParameter("req_symbol", symbol);
    super.addRequestParameter("req_sdate", startDate);
    super.addRequestParameter("req_edate", endDate);
    super.addRequestParameter("req_trendtype", trendType);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  // getMessageName() {
  //   return "CompetitiveEdgeResponseData";
  // }
}

export default GetZigzagTrendDataRequest;
