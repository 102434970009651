import {
    AINewsConstants
} from '../Constants/AINewsConstants.js';

const {
    ActionTypes
} = AINewsConstants;


export const setMessage = (message) => ({
    type: ActionTypes.SET_MESSAGE,
    message
});

export const fetchCompetitiveEdgeData = (symbol) => ({
    type: ActionTypes.GET_COMPETITIVE_EDGE_REQUEST,
    symbol
});

export const setReaction = (reaction, text, newsType, selectedSegment = null) => ({
    type: ActionTypes.SET_AI_TEXT_REACTION,
    reaction, text, newsType, selectedSegment
});
export const fetchZigzagTrendData = (startDate, endDate, trendType) => ({
    type: ActionTypes.GET_ZIGZAG_TREND_DATA_REQUEST,
    startDate,
    endDate,
    trendType
});
