import BaseServiceApi from "../ServiceApi/BaseServiceApi";
import ContextMenuConstants from "./ContextMenuConstants";
import keyMirror from "keymirror";
import { TranslateHelper } from "../Utils/TranslateHelper";
import UserInfoUtil from "../Utils/UserInfoUtil";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

const RiSubCategoryType =
    keyMirror({
        None: 0,
        News: 1,
        BrokerReports: 2,
        Sponsorship: 3,
        MeasurementTools: 4,
        Annotations: 5,
        Summary: 6,
        FindModels: 7,
        FindNotes: 8,
        LlrView: 9,
        Twitter: 10,
        Transactions: 11,
        Alerts: 12,
        Ownership: 13,
        CheckList: 14,
        Holdings: 15,
        SimilarFunds: 16,
        Management: 17,
        Allocation: 18,
        Constituents: 19,
        Factor: 20,
        ExternalData: 21,
        AI: 22
    });

export default RiSubCategoryType;

export const mainTitle = "RELATED INFORMATION";

export const RiTabTitle = {
    None: "NONE",
    News: "NEWS",
    BrokerReports: "BROKER REPORTS",
    Sponsorship: "SPONSORSHIP",
    MeasurementTools: "MEASUREMENT TOOLS",
    Annotations: "ANNOTATIONS",
    Summary: "SUMMARY",
    FindModels: "FIND MODELS",
    FindNotes: "FIND NOTES",
    LlrView: "O'NEIL",
    Twitter: "TWITTER",
    Transactions: "TRANSACTIONS",
    Alerts: "ALERTS",
    Ownership: "OWNERSHIP",
    CheckList: "CHECKLIST",
    Holdings: "HOLDINGS",
    SimilarFunds: "SIMILAR FUNDS",
    Management: "MANAGEMENT",
    Allocation: "ALLOCATION",
    Constituents: "CONSTITUENTS",
    Factor: "FACTORS",
    ExternalData: "EXTERNAL",
    AI: "AI",
    HistoricalNews: "Historical News",
    FutureEvents: "Future Events"
}

export function getTabCollection() {
    const contextMenuArr = [{ headerText: TranslateHelper["About"], action: ContextMenuConstants.ALERT_ABOUT, isEnabled: true }];

    const stockList = [
        { Title: RiTabTitle.Alerts, SubCategory: RiSubCategoryType.Alerts, contextMenu: contextMenuArr },
        { Title: RiTabTitle.Annotations, SubCategory: RiSubCategoryType.Annotations },
        ...(UserInfoUtil.IsUserEntitled(EntitlementType.BROKER_REPORTS_Entitlement) ? [{ Title: RiTabTitle.BrokerReports, SubCategory: RiSubCategoryType.BrokerReports }] : []),
        { Title: RiTabTitle.CheckList, SubCategory: RiSubCategoryType.CheckList },
        { Title: RiTabTitle.LlrView, SubCategory: RiSubCategoryType.LlrView },
        { Title: RiTabTitle.News, SubCategory: RiSubCategoryType.News, isDefaultTab: true },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement) ? [{ Title: RiTabTitle.ExternalData, SubCategory: RiSubCategoryType.ExternalData }] : [],
        { Title: RiTabTitle.Ownership, SubCategory: RiSubCategoryType.Ownership },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.FACTOR_RESEARCH_Entitlement) ? [{ Title: RiTabTitle.Factor, SubCategory: RiSubCategoryType.Factor }] : [],
        ...UserInfoUtil.IsUserEntitled(EntitlementType.ZIGZAG_AI_INDICATOR_Entitlement) ? [{ Title: RiTabTitle.AI, SubCategory: RiSubCategoryType.AI, contextMenu: '' }] : []
    ];

    const historicStockList = [
        { Title: RiTabTitle.Alerts, SubCategory: RiSubCategoryType.Alerts, contextMenu: contextMenuArr },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.BROKER_REPORTS_Entitlement) ? [{ Title: RiTabTitle.BrokerReports, SubCategory: RiSubCategoryType.BrokerReports }] : [],
        { Title: RiTabTitle.CheckList, SubCategory: RiSubCategoryType.CheckList },
        { Title: RiTabTitle.LlrView, SubCategory: RiSubCategoryType.LlrView },
        { Title: RiTabTitle.News, SubCategory: RiSubCategoryType.News, isDefaultTab: true },
        { Title: RiTabTitle.Ownership, SubCategory: RiSubCategoryType.Ownership },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.ZIGZAG_AI_INDICATOR_Entitlement) ? [{ Title: RiTabTitle.AI, SubCategory: RiSubCategoryType.AI, contextMenu: '' }] : []
    ];

    const etfList = [
        { Title: RiTabTitle.Alerts, SubCategory: RiSubCategoryType.Alerts, contextMenu: contextMenuArr },
        { Title: RiTabTitle.Annotations, SubCategory: RiSubCategoryType.Annotations },
        { Title: RiTabTitle.Holdings, SubCategory: RiSubCategoryType.Holdings },
        { Title: RiTabTitle.LlrView, SubCategory: RiSubCategoryType.LlrView },
        { Title: RiTabTitle.News, SubCategory: RiSubCategoryType.News, isDefaultTab: true },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement) ? [{ Title: RiTabTitle.ExternalData, SubCategory: RiSubCategoryType.ExternalData }] : [],
        { Title: RiTabTitle.Ownership, SubCategory: RiSubCategoryType.Ownership },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.ZIGZAG_AI_INDICATOR_Entitlement) ? [{ Title: RiTabTitle.AI, SubCategory: RiSubCategoryType.AI, contextMenu: '' }] : [],
    ];

    const fundList = [
        { Title: RiTabTitle.Management, SubCategory: RiSubCategoryType.Management, isDefaultTab: true },
        { Title: RiTabTitle.News, SubCategory: RiSubCategoryType.News },
        { Title: RiTabTitle.Allocation, SubCategory: RiSubCategoryType.Allocation },
        { Title: RiTabTitle.SimilarFunds, SubCategory: RiSubCategoryType.SimilarFunds },
        { Title: RiTabTitle.Holdings, SubCategory: RiSubCategoryType.Holdings },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement) ? [{ Title: RiTabTitle.ExternalData, SubCategory: RiSubCategoryType.ExternalData }] : []
    ];

    const currencyList = [
        { Title: RiTabTitle.Annotations, SubCategory: RiSubCategoryType.Annotations },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement) ? [{ Title: RiTabTitle.ExternalData, SubCategory: RiSubCategoryType.ExternalData, isDefaultTab: true }] : []
    ];

    const indexList = [
        { Title: RiTabTitle.Alerts, SubCategory: RiSubCategoryType.Alerts, contextMenu: contextMenuArr },
        { Title: RiTabTitle.Allocation, SubCategory: RiSubCategoryType.Allocation },
        { Title: RiTabTitle.Annotations, SubCategory: RiSubCategoryType.Annotations },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement) ? [{ Title: RiTabTitle.ExternalData, SubCategory: RiSubCategoryType.ExternalData }] : [],
        { Title: RiTabTitle.Constituents, SubCategory: RiSubCategoryType.Constituents, isDefaultTab: true },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.ZIGZAG_AI_INDICATOR_Entitlement) ? [{ Title: RiTabTitle.AI, SubCategory: RiSubCategoryType.AI, contextMenu: '' }] : [],
    ];

    const economicIndList = [
        { Title: RiTabTitle.Alerts, SubCategory: RiSubCategoryType.Alerts, isDefaultTab: true, contextMenu: contextMenuArr },
        { Title: RiTabTitle.Annotations, SubCategory: RiSubCategoryType.Annotations },
        { Title: RiTabTitle.CheckList, SubCategory: RiSubCategoryType.CheckList },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.ZIGZAG_AI_INDICATOR_Entitlement) ? [{ Title: RiTabTitle.AI, SubCategory: RiSubCategoryType.AI, contextMenu: '' }] : [],
    ];

    const igList = [
        { Title: RiTabTitle.Alerts, SubCategory: RiSubCategoryType.Alerts, contextMenu: contextMenuArr },
        { Title: RiTabTitle.Annotations, SubCategory: RiSubCategoryType.Annotations },
        { Title: RiTabTitle.Constituents, SubCategory: RiSubCategoryType.Constituents, isDefaultTab: true },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement) ? [{ Title: RiTabTitle.ExternalData, SubCategory: RiSubCategoryType.ExternalData }] : [],
        ...UserInfoUtil.IsUserEntitled(EntitlementType.ZIGZAG_AI_INDICATOR_Entitlement) ? [{ Title: RiTabTitle.AI, SubCategory: RiSubCategoryType.AI, contextMenu: '' }] : []
    ];

    const fundClosedList = [
        { Title: RiTabTitle.Alerts, SubCategory: RiSubCategoryType.Alerts, contextMenu: contextMenuArr },
        { Title: RiTabTitle.Annotations, SubCategory: RiSubCategoryType.Annotations },
        { Title: RiTabTitle.Holdings, SubCategory: RiSubCategoryType.Holdings },
        { Title: RiTabTitle.Management, SubCategory: RiSubCategoryType.Management },
        { Title: RiTabTitle.News, SubCategory: RiSubCategoryType.News, isDefaultTab: true },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement) ? [{ Title: RiTabTitle.ExternalData, SubCategory: RiSubCategoryType.ExternalData }] : [],
        { Title: RiTabTitle.Ownership, SubCategory: RiSubCategoryType.Ownership },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.ZIGZAG_AI_INDICATOR_Entitlement) ? [{ Title: RiTabTitle.AI, SubCategory: RiSubCategoryType.AI, contextMenu: '' }] : [],
    ];

    const cryptoCurrencyList = [
        { Title: RiTabTitle.Alerts, SubCategory: RiSubCategoryType.Alerts, contextMenu: contextMenuArr, isDefaultTab: true },
        { Title: RiTabTitle.Annotations, SubCategory: RiSubCategoryType.Annotations },
        ...UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement) ? [{ Title: RiTabTitle.ExternalData, SubCategory: RiSubCategoryType.ExternalData }] : []
    ];

    return {
        1: stockList,               // USSTOCK
        2: stockList,               // PREIPO
        3: stockList,               // ADR
        4: historicStockList,       // HISTORICSTOCK
        5: stockList,               // INTERNATIONALSTOCK
        6: [],                      // MODELSTOCK
        7: etfList,                 // ETF
        8: fundList,                // FUND
        9: [],                      // FUTURE
        10: [],                     // COMMODITY
        11: indexList,              // INDEX
        12: indexList,              // INDEXNOINTRADAYVOL
        13: indexList,              // INDEXNOINTRADAYDATA
        14: indexList,              // INDEXNOINTRADAYVOLDATA
        15: indexList,              // INTERNATIONALINDEX
        16: indexList,              // HISTORICINDEX
        17: economicIndList,        // ECONOMICINDICATOR
        18: currencyList,           // CURRENCY
        19: [],                     // EXCHANGERATE
        20: igList,                 // WONINDUSTRYGROUP197
        21: igList,                 // WONMAJORINDUSTRY89
        22: [],                     // GIC
        23: igList,                 // WONSECTOR11
        24: igList,                 // WONSUBSECTOR33
        25: stockList,              // REIT
        26: fundClosedList,         // FundClosedEnd
        27: historicStockList,      // HISTORICALMODELSTOCK
        28: currencyList,           // FUTURECONTRACTS
        29: currencyList,           // CASHSPOTS
        160: cryptoCurrencyList     // CRYPTOCURRENCY
    }
}
