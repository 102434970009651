import keyMirror from "keymirror";
import LocalizationStore from "LocalizationStore";

export const TabDataGraphConstants = {
  ActionTypes: keyMirror({
    UPDATE_GLOBAL_FLAG: null,
    PERIODICITY_CHANGED: null,
    SET_DEFAULT_STATE: null,
    SCALE_CHANGED: null,
    LLR_CHANGED: null,
    TECH_CHANGED: null,
    KPI_CHANGED: null,
    MACD_CHART_CHANGED: null,
    RSI_CHART_CHANGED: null,
    EXTENDED_CHART_CHANGED: null,
    STOCHASTICS_CHART_CHANGED: null,
    YTD_SCALE_CHANGED: null,
    PTOE_CHANGED: null,
    PTOS_CHANGED: null,
    ACC_DIST_CHANGED: null,
    VOLUME_SCALE_CHANGED: null,
    COMPONENT_CHANGED: null,
    EPSRPS_CHANGED: null,
    CHART_RESIZE: null,
    OHTYPE_CHANGED: null,
    TIMELINE_CHANGED: null,
    CHARTDATA_RECIVED: null,
    FINANCIAL_PAGE_CHANGED: null,
    FINANCIAL_SIZE_CHANGED: null,
    COLLAPSE_BLOCK: null,
    OPEN_BLOCK: null,
    COLLAPSE_KPI_BLOCK: null,
    OPEN_KPI_BLOCK: null,
    UPDATE_STICK: null,
    DISPLAY_MODE_CHANGED: null,
    SHOW_EPSPERC_LOS: null,
    EPS_RPS_SCALECHANGED: null,
    EPS_RPS_SWITCHED: null,
    EPS_RPS_SELECTED: null,
    EPS_INDEX_RPS_SCALECHANGED: null,
    EPS_INDEX_RPS_SWICHED: null,
    EPS_INDEX_RPS_SELECTED: null,
    LINE_VISUAL_SELECTED: null,
    OPEN_EPSLINE_DIALOG: null,
    OPEN_RPSLINE_DIALOG: null,
    OPEN_T4Q0LINE_DIALOG: null,
    OPEN_T4Q1LINE_DIALOG: null,
    OPEN_T4Q2LINE_DIALOG: null,
    OPEN_T4Q3LINE_DIALOG: null,

    UPDATE_SPTLINE_SETTINGS:null,
    UPDATE_SPTTAG_SETTINGS: null,
    UPDATE_EPSLINE_SETTINGS: null,
    UPDATE_RPSLINE_SETTINGS: null,
    UPDATE_T4Q0LINE_SETTINGS: null,
    UPDATE_T4Q1LINE_SETTINGS: null,
    UPDATE_T4Q2LINE_SETTINGS: null,
    UPDATE_T4Q3LINE_SETTINGS: null,
    OPEN_EPS_ANIMATION_DIALOG: null,
    UPDATE_EPS_ANIMATION_SETTINGS: null,
    OPEN_INDEX_EPS_ANIMATION_DIALOG: null,
    UPDATE_INDEX_EPS_ANIMATION_SETTINGS: null,
    OPEN_CORPEVENTS_DIALOG: null,
    OPEN_ETF_CORPEVENTS_DIALOG: null,
    OPEN_CEF_CORPEVENTS_DIALOG: null,
    OPEN_CURRENCY_CORPEVENTS_DIALOG: null,
    UPDATE_CORPEVENTS_SETTINGS: null,
    UPDATE_CEF_CORPEVENTS_SETTINGS: null,
    UPDATE_ETF_CORPEVENTS_SETTINGS: null,
    UPDATE_CURRENCY_CORPEVENTS_SETTINGS: null,
    UPDATE_CRYPTO_CURRENCY_CORPEVENTS_SETTINGS: null,
    ADD_MOVING_AVERAGE: null,
    ADD_CEF_MOVING_AVERAGE: null,
    ADD_INDEX_MOVING_AVERAGE: null,
    ADD_ETF_MOVING_AVERAGE: null,
    ADD_INDUSTRYGROUP_MOVING_AVERAGE: null,
    ADD_CURRENCY_MOVING_AVERAGE: null,
    ADD_CRYPTO_CURRENCY_MOVING_AVERAGE: null,
    ADD_FUNDS_MOVING_AVERAGE: null,
    PATTERN_RECOGNITION: null,
    REMOVE_MOVING_AVERAGE: null,
    REMOVE_CEF_MOVING_AVERAGE: null,
    REMOVE_INDEX_MOVING_AVERAGE: null,
    REMOVE_ETF_MOVING_AVERAGE: null,
    REMOVE_INDUSTRYGROUP_MOVING_AVERAGE: null,
    REMOVE_CURRENCY_MOVING_AVERAGE: null,
    REMOVE_CRYPTO_CURRENCY_MOVING_AVERAGE: null,
    REMOVE_FUNDS_MOVING_AVERAGE: null,
    MOVING_AVERAGE_ALERT: null,
    CEF_MOVING_AVERAGE_ALERT: null,
    INDEX_MOVING_AVERAGE_ALERT: null,
    ETF_MOVING_AVERAGE_ALERT: null,
    INDUSTRYGROUP_MOVING_AVERAGE_ALERT: null,
    CURRENCY_MOVING_AVERAGE_ALERT: null,
    CRYPTO_CURRENCY_MOVING_AVERAGE_ALERT: null,
    FUNDS_MOVING_AVERAGE_ALERT: null,
    EDIT_MOVING_AVERAGE: null,
    EDIT_CEF_MOVING_AVERAGE: null,
    EDIT_INDEX_MOVING_AVERAGE: null,
    EDIT_ETF_MOVING_AVERAGE: null,
    EDIT_INDUSTRYGROUP_MOVING_AVERAGE: null,
    EDIT_CURRENCY_MOVING_AVERAGE: null,
    EDIT_CRYPTO_CURRENCY_MOVING_AVERAGE: null,
    EDIT_FUNDS_MOVING_AVERAGE: null,
    EDIT_INDEX_LINE:null,
    EDIT_CEF_INDEX_LINE:null,
    EDIT_INDEX_INDEX_LINE:null,
    EDIT_ETF_INDEX_LINE:null,
    EDIT_FUNDS_INDEX_LINE: null,
    EDIT_INDUSTRYGROUP_INDEX_LINE: null,
    EDIT_RS_LINE:null,
    EDIT_CEF_RS_LINE: null,
    EDIT_INDEX_RS_LINE: null,
    EDIT_INDUSTRYGROUP_RS_LINE: null,
    EDIT_ETF_RS_LINE: null,
    EDIT_FUND_RS_LINE: null,
    EDIT_INDUSTRYGROUP_RS_LINE: null,
    EDIT_PATTERN_RECOGNITION: null,
    EDIT_ETF_PATTERN_RECOGNITION: null,
    EDIT_CEF_PATTERN_RECOGNITION: null,
    NONE: null,
    HIGHLIGHT_MA_LINE: null,
    GICSSELECTED: null,
    SET_METRIC_ACTION_ALRET: null,
    CHANGE_ISGICS_STATUS: null,
    CHANGE_METRIC_ACTION_STATUS: null,
    STEP_BACK_IN_TIME: null,
    FULL_SCREEN: null,
    STEP_FORWARD_IN_TIME: null,
    DAY_CHANGE: null,
    SET_MOST_RECENT_TRADE_DATE: null,
    PAGINATED_GRAPH_VIEW: null,
    
    UPDATE_EPS_LINE: null, 
    UPDATE_INDEX_EPS_LINE: null,
       
    ON_EPS_SCRUBBER_MOVE:null,
    ON_INDEX_EPS_SCRUBBER_MOVE:null,
    ON_ANIMATION_CHART_LOAD:null,
    EPSANIMATION_HIGHLIGHT_NODE:null,
    PROCESS_JPEG: null,

    GET_RI_FACTOR:null,
    ADD_CASHSPOTS_MOVING_AVERAGE: null,
    ADD_FUTURES_MOVING_AVERAGE: null,
    VOLUME_SCALE_SETTINGS_DIALOG_CHANGED: null,
    REMOVE_CASHSPOTS_MOVING_AVERAGE: null,
    REMOVE_FUTURES_MOVING_AVERAGE: null,
    FUTURES_MOVING_AVERAGE_ALERT: null,
    CASHSPOTS_MOVING_AVERAGE_ALERT: null,
    UPDATE_VOLUME_SCALE_SETTINGS: null,
    EDIT_CASHSPOTS_MOVING_AVERAGE: null,
    EDIT_FUTURES_MOVING_AVERAGE: null,
    OPEN_FINANCIALBLOCK_DIALOG: null,

    UPDATE_MINILIST_TOGGLE_STATUS: null,
    UPDATE_PERIODICITY_BUTTON_STATE:null,
    UPDATE_SYMBOL_INPUT_STATE: null,
    FACTOR_RESEARCH_DATA_READY: null,

    PROCESS_INDICATOR_CHART_DATA: null,
    KPI_CHART_PROCESSED: null,
    HIDE_OTHER_MENU: null,
    RELOAD_CHART: null
  })
};

export const rightMenuItem = [
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vc', 'View Chart'), key: 'VC' },
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vlc', 'View Local Constituents'), key: 'VLC' },
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vgc', 'View Global Constituents'), key: 'VGC' },
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vrg', 'View Rotation Graph'), key: 'VRG' },
  { header: LocalizationStore.getTranslatedData('sym_ingrp_gics', 'Switch to GICS Industry'), key: 'SGI' },
  { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
];

export const rightMenuItem_withoutGraph = [
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vc', 'View Chart'), key: 'VC' },
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vlc', 'View Local Constituents'), key: 'VLC' },
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vgc', 'View Global Constituents'), key: 'VGC' },
  { header: LocalizationStore.getTranslatedData('sym_ingrp_gics', 'Switch to GICS Industry'), key: 'SGI' },
  { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
];

export const rightMenuItem_withoutGraphandGICS = [
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vc', 'View Chart'), key: 'VC' },
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vlc', 'View Local Constituents'), key: 'VLC' },
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vgc', 'View Global Constituents'), key: 'VGC' },
  { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
];

export const leftMenuItemGICS = [
  { header: LocalizationStore.getTranslatedData('sym_ingrp_gics', 'Switch to GICS Industry'), key: 'SGI' },
  { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
];

export const leftMenuItemWON = [
  { header: LocalizationStore.getTranslatedData('sym_ingrp_won', 'Switch to WON Industry Group'), key: 'SWI' }
];

export const leftMenuItemNonStock = [
  { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
];

export const rightMenuItem_withoutChart = [
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vlc', 'View Local Constituents'), key: 'VLC' },
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vgc', 'View Global Constituents'), key: 'VGC' }
];

export const leftMenuItem_withoutGlobal = [
  { header: LocalizationStore.getTranslatedData('sym_ingrp_vlc', 'View Local Constituents'), key: 'VLC' }
];


export const definationSymbols = {
  DEF_EXCHANGE: 'Exchange',
  DEF_MARKETCAP: 'MarketCap',
  DEF_SALES: 'Sales',
  DEF_SHARES: 'Shares',
  DEF_FLOAT: 'Float',
  DEF_EPSDUEDATE: 'EpsDueDate',
  DEF_EPSNEARDATE: 'EpsNearDate',
  DEF_EXTERNALDATA: 'ExternalData'
}

export const customTIModalConstants = {
  ActionTypes: keyMirror({
    OPEN_CLOSE_MODAL: null,
    INIT_TI_MODULE: null,
    INIT_KPI_MODULE: null,
    UPDATE_MENU_OBJECT: null,
    HANDLE_MODAL_HIDE: null,
    UPDATE_PERIODICITY_HEADER: null,
    UPDATE_ITEM_STATUS: null,
    UPDATE_TITLE: null,
    ON_TAB_SELECTION_CHANGED: null,
    ON_TAB_SELECTION_CHANGE_SUCCESS: null,
    ON_INPUT_VALUE_CHANGE: null,
    ON_INPUT_FOCUS_LOST: null,
    ON_INPUT_VALIDATION_SUCCESS: null,
    UPDATE_CLASSNAME: null,
    VALIDATION_ERROR: null,
    RESTORE_DEFAULTS: null,
    ON_APPLY_TO_ALL_INTERVALS: null,
    UPDATE_ABOUT_DIALOG_URL: null,
    UPDATE_RESTORING: null,
    INIT_KPI_APPEARANCE: null
  })
}

export const TimelineConstant = {
  ActionTypes: keyMirror({
    INIT_TIMELINE: null,
    CREATE_TIMELINE: null,
    PRICE_PANEL_RESPONSE_READY_TIMELINE: null,
    UPDATE_TIMELINE: null,
    UPDATE_IS_CALENDER: null
  })
};