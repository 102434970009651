export const ipodateShow = (state) => state.DatagraphReducers.HeaderReducer.showIPODate;
export const epsDateShow = (state) => state.DatagraphReducers.HeaderReducer.showEPSDate;
export const stockInfo = (state) => state.DatagraphReducers.HeaderReducer.stockInfo;
export const endDate = (state) => state.DatagraphReducers.HeaderReducer.endDate;
export const epsDueDateSelect = (state) => state.DatagraphReducers.HeaderReducer.epsDueDate;
export const ipoDateSelect = (state) => state.DatagraphReducers.HeaderReducer.ipoDate;
export const tiMenuSelect = (state) => state.DatagraphReducers.HeaderReducer.tiMenu;
export const getHeaderStates = (state)=> state.DatagraphReducers.HeaderReducer;

export const getDatagraphStates = (state) => state.DatagraphReducers.DataGraphReducer;
export const pricePanelRawDataSelect = (state) => state.DatagraphReducers.DataGraphReducer.pricePanelData;

export const headerDataSelect = (state) => state.DatagraphReducers.DataGraphReducer.headerData;

export const scaleSelect = (state) => state.DatagraphReducers.PriceChartReducer.scale;
export const OhOlTypeSelect = (state) => state.DatagraphReducers.PriceChartReducer.OHtype;
export const pricePanelDataSelect = (state) => state.DatagraphReducers.PriceChartReducer.pricePanelData;
export const scaleLabelSelect = (state) => state.DatagraphReducers.PriceChartReducer.scaleLabel;
export const endDateSelect = (state) => state.DatagraphReducers.PriceChartReducer.endDate;
export const symbolSelect = (state) => state.DatagraphReducers.DataGraphReducer.Symbol;
export const nodecountSelect = (state) => state.DatagraphReducers.PriceChartReducer.nodeCount;
export const paddingSelect = (state) => state.DatagraphReducers.PriceChartReducer.padding;
export const isHistoricSelect = (state) => state.DatagraphReducers.PriceChartReducer.isHistoric;

export const HsfDataSelect = (state) => state.DatagraphReducers.PriceChartReducer.HsfData;
export const getEpslineDialogSettings = (state) => state.DatagraphReducers.PriceChartReducer.epslineDialogSettings;
export const getExternalDataSettings = (state) => state.DatagraphReducers.PriceChartReducer.externalDataSettings;
export const getRpslineDialogSettings = (state) => state.DatagraphReducers.PriceChartReducer.rpslineDialogSettings;
export const getT4q0lineDialogSettings = (state) => state.DatagraphReducers.PriceChartReducer.t4q0lineDialogSettings;
export const getT4q1lineDialogSettings = (state) => state.DatagraphReducers.PriceChartReducer.t4q1lineDialogSettings;
export const getT4q2lineDialogSettings = (state) => state.DatagraphReducers.PriceChartReducer.t4q2lineDialogSettings;
export const getT4q3lineDialogSettings = (state) => state.DatagraphReducers.PriceChartReducer.t4q3lineDialogSettings;
export const getPrSettings = (state) => state.DatagraphReducers.PriceChartReducer.prSettings;
export const getCorpEventsSettings = (state) => state.DatagraphReducers.PriceChartReducer.corpEventsSettings;
export const getHiLoPriceSettings = (state) => state.DatagraphReducers.PriceChartReducer.hiLoPriceSettings;
export const getHiLoPctSettings = (state) => state.DatagraphReducers.PriceChartReducer.hiLoPctSettings;
export const getUpDaysSettings = (state) => state.DatagraphReducers.PriceChartReducer.upDaysSettings;
export const getStreetTargetPriceSettings = (state) => state.DatagraphReducers.PriceChartReducer.streetTargetPriceSettings;
export const getMaSettings = (state) => state.DatagraphReducers.PriceChartReducer.maSettings;

export const priceChartReducerselect = (state) => state.DatagraphReducers.PriceChartReducer;

export const getFundamentalLineStates = (state)=> state.DatagraphReducers.FundamentalLineReducer;
export const getFundamentalLineMultiplier = (state)=> state.DatagraphReducers.FundamentalLineReducer.multiplier;
export const getMALineStates = (state)=> state.DatagraphReducers.MAReducer;
export const getRSLineStates = (state)=> state.DatagraphReducers.RSLineReducer;
export const getIndexLineStates = (state)=> state.DatagraphReducers.IndexLineReducer;
export const getCorpEventStates = (state)=> state.DatagraphReducers.CorpEventsReducer;
export const getPatternRecStates = (state)=> state.DatagraphReducers.PRReducer;
export const DataGraphReducerselect = (state) => state.DatagraphReducers.DataGraphReducer;
export const timeLineSelect = (state) => state.DatagraphReducers.TimeLineReducer.TimeLineData;
export const timeLineReducerSelect = (state) => state.DatagraphReducers.TimeLineReducer;
export const getCalenderControlState = (state)=> state.DatagraphReducers.CalenderControlReducer;

export const getSymbolInfo = (state)=> state.DatagraphReducers.DataGraphReducer.SymbolInfo;

export const getTimeLine = (state) => state.DatagraphReducers.TimeLineReducer.TimeLineData.dates;
export const getTimeLineEndDate = (state) => state.DatagraphReducers.TimeLineReducer.endDate;

//Indicator states
export const getIndicatorStates = (state) => state.DatagraphReducers.IndicatorReducer;
export const getIndicatorSymbolInfo = (state) => state.DatagraphReducers.IndicatorReducer.SymbolInfo;
export const indicatorMenuSelect = (state) => state.DatagraphReducers.IndicatorReducer.indicatorMenu;

//Ownership state
export const getOwnerShipState = (state) => state.DatagraphReducers.ownershipBlockReducer;
//PriceMenu stae
export const priceMenuSelect = (state) => state.DatagraphReducers.PriceMenuReducer.priceMenu;

export const getSymbolEntryStates = (state) => state.DatagraphReducers.SymbolEntryReducer;

export const getVolumeChartStates = (state) => state.DatagraphReducers.volumeChartReducer;

export const getFactorStates = (state) => state.DatagraphReducers.FactorResearchReducer;

/* Financial Block */
export const getFinancialBlockState = (state) => state.DatagraphReducers.financialBlockReducer;
export const getFinancialDialogBoxState = (state) => state.DatagraphReducers.financialDialogBoxReducer;

export const IdeasSelect = (state) => state.RelatedInfoPanelReducers.RIPanelOneilReducers.ideasData;
export const symbolTypeSelect = (state) => state.RelatedInfoPanelReducers.RIPanelOneilReducers.symbolType;

export const RiPanelOneil = (state) => state.RelatedInfoPanelReducers.RIPanelOneilReducers;

export const KpiModuleReducerState = (state) => state.kpiMenu.KpiModuleReducer;

export const getContextMenuStates = (state) => state.DatagraphReducers.ContextMenuReducer;