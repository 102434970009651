
import keyMirror from "keymirror"

export const PriceChartConstants = {
    ActionTypes: keyMirror({
        PRICE_DATA_READY: null,
        UPDATE_IS_PRICE_DATA_READY: null,
        UPDATE_IS_RECEIVED_QUOTE: null,
        SET_PRICE_SCALE: null,
        UPDATE_CHART_ON_STREAMING: null,
        TOGGEL_OH_OL: null,
        SET_OH_OL_TYPE: null,
        CHANGE_CHART_SCALE: null,
        RE_DRAW_EXTERNAL_DATA: null,
        POINTER_DATA_READY: null,
        PRICE_MENU_READY: null,
        INDEX_LINE_DATA_READY: null,
        RS1_LINE_DATA_READY: null,
        RS2_LINE_DATA_READY: null,
        PATTERN_REC_DATA_READY: null,
        PREPARE_PATTERN_REC: null,
        INIT_STREET_TARGET_PRICE_STATES: null,
        UPDATE_SPTTAG_SETTINGS: null,
        UPDATE_SPT_STATES: null,
        OPEN_STP_SETTINGS_DIALOG: null,
        SAVE_STP_SETTINGS_DIALOG: null,
        CANCEL_STP_SETTINGS_DIALOG: null,
        RESTORE_STP_SETTINGS_DIALOG: null,
        CHANGE_STP_DIALOG_TAB: null,
        UPDATE_STP_VISIBILTY: null,
        CHANGE_STP_HI_LOW_STATUS: null,
        CHANGE_STP_PRICE_STATUS: null,
        HANDLE_STP_COLOR_CHANGE: null,
        CLEAR_STREET_TARGET_PRICE_STATES: null,
        CORP_EVENTS_DATA_READY: null,
        PREPARE_CORP_EVENTS_DATA: null,
        SAVE_CORP_EVENTS_STATE_DATA: null,
        OPEN_CORP_EVENT_DIALOG: null,
        CANCEL_CORP_EVENT_DIALOG: null,
        RESTORE_CORP_EVENT_SETTING: null,
        SAVE_CORP_EVENT_SETTING: null,
        UPDATE_CORP_EVENT_DIALOG_STATES: null,
        CHANGE_CORP_EVENT_SELECTED_PERIODICITY: null,
        TOGGLE_CORP_EVENT_PROPERTY: null,
        UPDATE_PATTERN_REC_DIALOG_SETTING: null,
        OPEN_PATTERN_REC_DIALOG: null,
        CANCEL_PATTERN_REC_DIALOG: null,
        CLOSE_PATTERN_REC_DIALOG: null,
        RESTORE_PATTERN_REC_SETTING: null,
        SAVE_PATTERN_REC_SETTING: null,
        CHANGE_PR_PATTERN_COLOR: null,
        CHANGE_PR_PROFIT_START: null,
        CHANGE_PR_PROFIT_END: null,
        CHANGE_PR_PIVOT_START: null,
        CHANGE_PR_PIVOT_END: null,
        CHANGE_PR_LOSS_START: null,
        CHANGE_PR_LOSS_END: null,
        TOGGLE_PR_TIGHT_AREA_PATTERN: null,
        CHANGE_PR_TIGHT_AREA_COLOR: null,
        TOGGLE_PATTERN_REC_KPR: null,
        TOGGLE_PR_POWER_FROM_PIVOT: null,
        TOGGLE_PR_CHANNEL_LINES: null,
        TOGGLE_PR_CLIMAX_TOP: null,
        TOGGLE_PR_ADVANCE_PATTERN: null,
        PREPARE_MA_LINES: null,
        MA_DATA_READY: null,
        OPEN_MA_LINE_EDIT_DIALOG: null,
        ADD_MA_LINE: null,
        DELETE_MA_LINE: null,
        UPDATE_MA_LINE_STATES: null,
        SAVE_MA_LINE_EDIT_DIALOG: null,
        CANCEL_MA_LINE_EDIT_DIALOG: null,
        TOGGLE_MA_VALIDATION_DIALOG: null,
        CHANGE_MA_LINE_WEIGHT: null,
        CHANGE_MA_LINE_COLOR: null,
        CHANGE_MA_LINE_TYPE: null,
        CHANGE_MA_LINE_LENGTH: null,
        SET_MA_LINE_ERROR: null,
        UPDATE_RSLINE_DIALOG_STATES: null,
        CANCEL_RS_DIALOG_SETTING: null,
        HANDLE_RS_DIALOG_COUNTRY_CHANGE: null,
        HANDLE_RS_DIALOG_REGION_CHANGE: null,
        HANDLE_RS_DIALOG_GROUP_CHANGE: null,
        HANDLE_RS_VALUE_TYPE_CHANGE: null,
        HANDLE_RS_SYMBOL_CHANGE: null,
        SAVE_RS_DIALOG_SETTING: null,
        OPEN_RS_LINE_DIALOG: null,
        TOGGLE_SHOW_RS_RANK: null,
        TOGGLE_SHOW_RS_GRADE: null,
        TOGGLE_SHOW_RS_MOVING_AVERAGE: null,
        TOGGLE_RS_MOVING_AVERAGE_LINE_TYPE: null,
        UPDATE_RS_LINE_COLOR: null,
        UPDATE_RS_LINE_THICKNESS: null,
        UPDATE_RS_LENGTH: null,
        UPDATE_RS_LENGTH_ERROR: null,
        UPDATE_RS_SYMBOL_ERROR: null,
        UPDATE_SYMBOL_ERROR: null,
        PREPARE_PRICE_MENU: null,
        PREPARE_PRICE_AND_INDICATOR_MENU: null,
        PRICE_MENU_ITEM_CLICK: null,
        INDEX_LINE_SETTINGS_UPDATE: null,
        OPEN_INDEX_LINE_DIALOG: null,
        CANCEL_INDEX_DIALOG_SETTING: null,
        HANDLE_INDEX_DIALOG_COUNTRY_CHANGE: null,
        HANDLE_INDEX_DIALOG_REGION_CHANGE: null,
        HANDLE_INDEX_DIALOG_GROUP_CHANGE: null,
        HANDLE_INDEX_VALUE_TYPE_CHANGE: null,
        HANDLE_INDEX_SYMBOL_CHANGE: null,
        SAVE_INDEX_DIALOG_SETTING: null,
        UPDATE_INDEX_DIALOG_SHOW_ALERT: null,
        UPDATE_INDEX_LINE_COLOR: null,
        UPDATE_INDEX_LINE_THICKNESS: null,
        UPDATE_INDEX_SYMBOL_ERROR: null,
        UPDATE_INDEXLINE_DIALOG_STATES: null,
        FUNDAMENTAL_LINE_DATA_READY: null,
        UPDATE_FUNDAMENTAL_LINE_DATA: null,
        UPDATE_SELECT_PER_SHARE: null,
        SWITCH_FUNDAMENTAL_LINE_LABEL: null,
        FUNDAMENTAL_LINE_SETTING_UPDATE: null,
        SHOW_FUNDAMENTAL_EDIT_DIALOG: null,
        SAVE_FUNDAMENTAL_EDIT_DIALOG: null,
        CANCEL_FUNDAMENTAL_EDIT_DIALOG: null,
        CHANGE_FUNDAMENTAL_EDIT_DIALOG_TAB: null,
        UPDATE_EXTERNAL_DATA_TOGGLE_BUTTON: null,
        RESET_FUNDAMENTAL_lINE_STATE: null,
        UPDATE_FUNDAMENTAL_LINE_STATES: null,
        UPDATE_IS_EPS_ANIMATION_DOCKED: null,
        UPDATE_IS_HISTORIC_EPS_LINE: null,
        UPDATE_IS_EPS_CHART_LOADED: null,
        GET_EARNINGS_ANIMATION_SET_FROM_CONTROLLER: null,
        TOGGLE_EPS_ANIMAITON_IS_PLAYING: null,
        TOGGLE_EPS_ANIMAITON_DISPLAYED: null,
        TOGGLE_EPS_ANIMAITON_MESSAGE_DIALOG: null,
        TOGGLE_EPS_ANIMAITON_DIALOG: null,
        OPEN_EPS_ANIMAITON_DIALOG: null,
        SAVE_EPS_ANIMAITON_DIALOG: null,
        CANCEL_EPS_ANIMAITON_DIALOG: null,
        RESET_EPS_ANIMAITON_DIALOG: null,
        TOGGLE_EPS_ANIMAITON_MESSAGE_DIALOG_AVAILABLE: null,
        CHANGE_EPS_ANIMATION_DIALOG_PROPERTY: null,
        UPDATE_EPS_ANIMATION_DIALOG_SETTING: null,
        CHANGE_FUNDAMENTAL_LINE_ESTIMATE: null,
        CHANGE_FUNDAMENTAL_LINE_EARNING: null,
        CHANGE_FUNDAMENTAL_LINE_WEIGHT: null,
        CHANGE_FUNDAMENTAL_LINE_COLOR: null,
        RESTORE_FUNDAMENTAL_LINE_SETTING: null,
        UPDATE_FUNDAMENTAL_LINE_MULTIPLIER: null,
        UPDATE_FUNDAMENTAL_LINE_DIALOG_SETTING: null,
        UPDATE_FUNDAMENTAL_LINE: null,
        PROCESS_EPS_ANIMATION: null,
        UPDATE_FUNDAMENTAL_QTR: null,
        RS1_LINE_SETTINGS_UPDATE: null,
        RS2_LINE_SETTINGS_UPDATE: null,
        PATTERN_REC_SETTINGS_UPDATE: null,
        CORP_EVENTS_SETTINGS_UPDATE: null,
        MA_SETTINGS_UPDATE: null,
        HI_LO_SETTINGS_UPDATE: null,
        HI_LO_PCT_SETTINGS_UPDATE: null,
        UPDAYS_UPDATE: null,
        PREPARE_INDEX_LINE: null,
        PREPARE_PDR_DATA: null,
        PREPARE_RS_LINE_DATA: null,
        CLEAR_CORP_EVENTS_DATA: null,
        CLEAR_PATTERN_REC: null,
        CLEAR_INDEX_LINE: null,
        SET_HO_LO_DATA: null,
        CLEAR_RS1_LINE_DATA: null,
        CLEAR_RS2_LINE_DATA: null,
        UPDATE_PRICE_PANE_TIME_SERIES_DATA_TO_STORE: null,
        UPDATE_PRICE_PANE_EVENT_SERIES_DATA_TO_STORE: null,
        UPDATE_EXTERNAL_DATA_REQ_INITIATED: null,
        CHART_RESIZE: null,
        INDICATOR_RESIZE: null,
        SET_HSFDATA: null,
        UPDATE_CHARTS_REF: null,
        SHOW_PRICE_CONTEXT_MENU: null,
        SHOW_CONTEXT_MENU_POS: null,
        HIDE_PRICE_CONTEXT_MENU: null,
        UPDATE_CONTEXT_HIT: null,
        UPDATE_CONTEXT_MENU_OPTIONS: null,
        ON_CONTEXT_OPTION_SELECT: null,
        STOP_DATAGRAPH_STREAMING: null,

        ONEIL_IDEAS_INFO: null,
        ONEIL_TEMP_IDEAS_INFO: null,
        STORE_STICK_IDEAS: null,
        IDEA_MOUSE_DOWN: null,
        IDEA_MOUSE_OVER: null,
        SELECTED_IDEA: null,
        SHOW_IDEA_BOX: null,
        IDEA_MOUSE_LEAVE: null,
        CALCULATE_IDEA_DG: null,

        PRICE_FR_DATA_READY: null,
        PREPARE_CONTEXTMENU: null,
        REDRAW_INDEX_LINE: null,

        DISABLE_DEFAULT_PRICE_LABELS: null,
    })
}

export const PriceChartConst = {
    BV: "BV",
    CFS: "CFS",
    DIV: "DIV",
    FCF: "FCF",
    EPS: "EPS",
    EPS_ANI: "EPS_ANI",
    RPS: "RPS",
    FFO: "FFO",
    NAV: "NAV",
    EXD: "EXD",
    T4Q0: "T4Q0",
    T4Q1: "T4Q1",
    T4Q2: "T4Q2",
    T4Q3: "T4Q3",
    INDEX_LINE: "INDEX_LINE",
    RS1_LINE: "RS1_LINE",
    RS2_LINE: "RS2_LINE",
    ZZ_INDICATOR: "ZIGZAG",
}

export const CorporateEventsConst = {
    CORPORATEREPURCHASE: "CORPORATEREPURCHASE",
    SPLIT: "SPLIT",
    INSIDERBUY: "INSIDERBUY",
    INSIDERSELL: "INSIDERSELL",
    CO_CEO: "CO-CEO",
    DIVIDEND: "DIVIDEND",
    REPURCHASEANNOUNCEMENT: "REPURCHASEANNOUNCEMENT",
    CEO: "CEO",
    MAJORARTICLES: "MAJORARTICLES",
    SECURITYANALYSTMEETING: "SECURITYANALYSTMEETING",
    ANNOUNCEMENT: "ANNOUNCEMENT",
    ADDEDTOINDEX: "ADDEDTOINDEX",
    EARNINGS: "EARNINGS",
    XDATE: "XDATE"
}

export const MajorArticlesConstants = {
    BARRONS: "BARRONS",
    FORTUNE: "FORTUNE",
    BUS_WK: "BUS.WK",
    NY_TIME: "NY TIME",
    WALL_SJ: "WALL.SJ",
    FORBES: "FORBES",
    ELEC_B: "ELEC.B",
    IBD: "IBD"
}

export const DividendConstants = {
    DECR: "DECR",
    EXTRA: "EXTRA",
    RSUM: "RSUM",
    OMIT: "OMIT",
    INITL: "INITL",
    INCR: "INCR",
    SPEC: "SPEC",
    DIV: "DIV",
}

export const AnnouncementConstants = {
    LPE: "LPE",
    PM: "PM",
    SOF: "SOF",
    PO: "PO",
    TO: "TO",
    PSO: "PSO",
    REORG: "REORG",
    IPO: "IPO",
    SDIS: "SDIS",
    CO: "CO",
    TRADE_RSUM: "TRADE RSUM",
    PPO: "PPO",
    $DIS: "$DIS",
    SO: "SO",
    TRADE_SUSP: "TRADE SUSP",
    $LIQ: "$LIQ",
    NEW: "NEW",
    PVC: "PVC",
    SHLF: "SHLF"
}

export const CityShortDescriptionConst = {
    BOSTON: "Boston",
    CHICAGO: "Chicago",
    LOS_ANGELES: "Los Angeles",
    NEW_YORK: "New York",
    SAN_FRANCISCO: "San Francisco",
    ATLANTA: "Atlanta",
    HOUSTON: "Houston",
    PITTSBURGH: "Pittsburgh",
    SEATTLE: "Seattle",
    CLEVELAND: "Cleveland",
    DETROIT: "Detroit",
    DENVER: "Denver",
    AMERICAN_EXC: "American Exc",
    WASHINGTON_DC: "Washington DC",
    BALTIMORE: "Baltimore",
    ST_LOUIS: "St. Louis",
    PHILADELPHIA: "Philadelphia",
    TORONTO: "Toronto",
    MONTREAL: "Montreal",
    NEW_YORK_NYSSA: "New York (NYSSA)",
    SAN_DIEGO: "San Diego",
    PORTLAND: "Portland",
    DALLLAS: "Dallas",
    PHOENIX: "Phoenix",
    MILWAUKEE: "Milwaukee",
    SACRAMENTO: "Sacramento",
    JACKSONVILLE: "Jacksonville",
    INDIANAPOLIS: "Indianapolis",
    JACKSON: "Jackson",
    CINCINNATI: "Cincinnati",
    CHATTANOOGA: "Chattanooga",
    AUSTIN: "Austin",
    SALT_LAKE_CITY: "Salt Lake City",
    SPOKANE: "Spokane",
    STAMFORD: "Stamford",
    DES_MOINES: "Des Moines",
    KANSAS_CITY: "Kansas City",
    BUFFALO: "Buffalo",
    COLUMBUS: "Columbus",
    NASHVILLE: "Nashville",
    SAN_ANTONIO: "San Antonio",
    ALABAMA: "Alabama",
    HARTFORD: "Hartford",
    LOUISVILLE: "Louisville",
    PROVIDENCE: "Providence",
    SOUTH_FLORIDA: "South Florida",
    OMAHA_LINCOLN: "Omaha-Lincoln"
}