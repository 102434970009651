import BlockType from '../../../../../../Constants/BlockType';
import { map } from "underscore";
import PropTypes from 'prop-types';
import { Shortcuts } from 'react-shortcuts';
import { CustomTiDialogConstant, customTIModalDialogConstants } from '../../../../../../Constants/CustomTiDialogConstants';
import { IndicatorCommonTranslateHelper, IndicatorLabelTranslateHelper, IndicatorSettingsTranslateHelper, MajorPeriodicityTranslateHelper, ORatingTranslateHelper, TranslateHelper } from '../../../../../../Utils/TranslateHelper';
import { Modal, Tabs, Tab, Checkbox, Radio } from "react-bootstrap";
import React, { PureComponent } from 'react';

class SettingsDialog extends PureComponent {

    componentDidUpdate() {
        if (this.props.isTextRestricted) {
            this.props.handleClick(false, true);
        }
    }

    handleShortcuts = (action) => {
        if (action === 'ENTER_APPLY') {
            this.props.handleClick(true);
        }
    }

    handleOnCancel = () => {
        this.props.handleClick(false, false);
    }

    handleSave = () => {
        this.props.handleClick(true, false);
    }

    onCheckBoxSelect = (e, item) => {
        const isActive = e.target.checked;
        this.props.updateItemStatus(isActive, item);
    }

    onTabSelectionChanged = (eventKey) => {
        this.props.onTabSelectionChanged(eventKey);
    }

    selectValue = (e) => {
        e.target.focus();
        e.target.select();
    }

    onInputValChange = (e, item) => {
        this.props.onInputValChange(e, item);
    }

    restoreDefaults = () => {
        this.props.restoreDefaults();
    }

    onApplyToAllIntClick = (e) => {
        this.props.onApplyToAllIntervals(e);
    }

    onFocusLost = (e, item) => {
        this.props.onFocusLost(e, item);
    }

    getErrorMsg() {
        return (
            <div className="macd-error-box x-small-normal">
                {this.props.errorMessage}
            </div>
        )
    }

    renderSubItem(item, index) {
        const selectedTiTab = this.props.selectedTiTab;
        const isTechnicalIndiactors = selectedTiTab === IndicatorLabelTranslateHelper[BlockType.MACD] || selectedTiTab === IndicatorLabelTranslateHelper[BlockType.RSI] || selectedTiTab === IndicatorLabelTranslateHelper[BlockType.Stochastics] || selectedTiTab === IndicatorLabelTranslateHelper[BlockType.WonStochastics] || selectedTiTab === IndicatorLabelTranslateHelper[BlockType.Extended];
        const isSlow = item.header === IndicatorCommonTranslateHelper[CustomTiDialogConstant.Slow];
        const isFast = item.header === IndicatorCommonTranslateHelper[CustomTiDialogConstant.Fast];
        let header = "";
        switch (item.header) {
            case customTIModalDialogConstants.CurrentFiscalYear:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.CurrentFiscalYear];
                break;
            case customTIModalDialogConstants.NextFiscalYear:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.NextFiscalYear];
                break;
            case customTIModalDialogConstants.CurrentFiscalQuarter:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.CurrentFiscalQuarter];
                break;
            case customTIModalDialogConstants.PTOEIndexLine:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOEIndexLine];
                break;
            case customTIModalDialogConstants.PTOEHLEstimates:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOEHLEstimates];
                break;
            case customTIModalDialogConstants.Rs3Months:
                header = ORatingTranslateHelper[CustomTiDialogConstant.Rs3Months];
                break;
            case customTIModalDialogConstants.Rs6Months:
                header = ORatingTranslateHelper[CustomTiDialogConstant.Rs6Months];
                break;
            case customTIModalDialogConstants.Rs12Months:
                header = ORatingTranslateHelper[CustomTiDialogConstant.Rs12Months];
                break;
            case customTIModalDialogConstants.GrpRs3Months:
                header = ORatingTranslateHelper[CustomTiDialogConstant.GrpRs3Months];
                break;
            case customTIModalDialogConstants.GrpRs6Months:
                header = ORatingTranslateHelper[CustomTiDialogConstant.GrpRs6Months];
                break;
            case customTIModalDialogConstants.Composite:
                header = ORatingTranslateHelper[CustomTiDialogConstant.Composite];
                break;
            case customTIModalDialogConstants.DataGraph:
                header = ORatingTranslateHelper[CustomTiDialogConstant.DataGraph];
                break;
            case customTIModalDialogConstants.EpsRank:
                header = ORatingTranslateHelper[CustomTiDialogConstant.EpsRank];
                break;
            case customTIModalDialogConstants.SMR:
                header = ORatingTranslateHelper[CustomTiDialogConstant.SMR];
                break;
            case customTIModalDialogConstants.Sponsorship:
                header = ORatingTranslateHelper[CustomTiDialogConstant.Sponsorship];
                break;
            case customTIModalDialogConstants.InstSupDem:
                header = ORatingTranslateHelper[CustomTiDialogConstant.InstSupDem];
                break;
            case CustomTiDialogConstant.Overbought:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Overbought];
                break;
            case CustomTiDialogConstant.Oversold:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Oversold];
                break;
            case CustomTiDialogConstant.EMA:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.EMA];
                break;
            case customTIModalDialogConstants.SlowLength:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.SlowLength];
                break;
            case customTIModalDialogConstants.FastLength:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.FastLength];
                break;
            case CustomTiDialogConstant.Slow:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Slow];
                break;
            case CustomTiDialogConstant.Fast:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Fast];
                break;
            case customTIModalDialogConstants.MovingAverage:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.MovingAverage];
                break;
            case CustomTiDialogConstant.Length:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Length];
                break;
            default:
                header = item.header;
                break;
        }
        if (!isTechnicalIndiactors) {
            return (
                <li key={index}>
                    <Checkbox id="month3" className="custom-check" type="checkbox" checked={item.value || item.isActive} onChange={(e) => this.onCheckBoxSelect(e, item)}>
                        <label className="oneil-rate-label"></label>
                        <span className="oneil-rate-txt">{header}</span>
                    </Checkbox>
                </li>
            )
        }
        else if (isTechnicalIndiactors) {
            if (isSlow || isFast) {
                return;
            }
            return (
                <li className="rating-macd" key={index}>
                    <div className="rating-macd-measure">{header}</div>
                    <div className="rating-macd-measure-digit">
                        <input id={index} type="text" ref={(ref) => (this.input = ref)} value={item.value} className="rating-macd-measure-input" onClick={(e) => this.selectValue(e)}
                            onChange={(e) => this.onInputValChange(e, item)} onBlur={(e) => this.onFocusLost(e, item)} onFocus={(e) => this.onFocusLost(e, item)} />
                        <span className="rating-macd-measure-criteria">{item.inputRange}</span>
                        {this.props.isInvalidInput && this.props.inputId === index && this.getErrorMsg()}
                    </div>
                </li>
            )
        }
    }

    renderStochasticsSubMenu(item, index) {
        const isSlow = item.header === IndicatorCommonTranslateHelper[CustomTiDialogConstant.Slow];
        const isFast = item.header === IndicatorCommonTranslateHelper[CustomTiDialogConstant.Fast];
        let header = "";
        switch (item.header) {
            case CustomTiDialogConstant.Slow:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Slow];
                break;
            case CustomTiDialogConstant.Fast:
                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Fast];
                break;
            default:
                header = item.header;
                break;
        }
        if (isSlow || isFast) {
            return (
                <span className="pullingleft" key={index}>
                    <Radio id="slowFast" className="custom-check" type="radio" checked={item.isActive} onChange={(e) => this.onCheckBoxSelect(e, item)}>
                        <label className="oneil-rate-label"></label>
                        <span className="oneil-rate-txt">{header}</span>
                    </Radio>
                </span>
            )
        }
    }

    renderTabItem(tabItem) {
        const selectedTiTab = this.props.selectedTiTab;
        const isShowApplyToAllInt = selectedTiTab === IndicatorLabelTranslateHelper[BlockType.EPSR] || selectedTiTab === IndicatorLabelTranslateHelper[BlockType.PTOE] || selectedTiTab === IndicatorLabelTranslateHelper[BlockType.PTOS];
        const isStochastics = selectedTiTab === IndicatorLabelTranslateHelper[BlockType.Stochastics];
        return (
            <Tab eventKey={tabItem} key={`${tabItem.DisplayText}`} title={MajorPeriodicityTranslateHelper[tabItem.DisplayText]}>
                <div className="oneil-rate-check">
                    {isStochastics &&
                        <div className="oneil-rate-stochastics-radio">
                            {map(tabItem.menuObj, (item, index) => this.renderStochasticsSubMenu(item, index))}
                        </div>}
                    <ul className="ti-indicator-setting">
                        {map(tabItem.menuObj, (item, index) =>
                            this.renderSubItem(item, index)
                        )}
                    </ul>
                    <div className="rating-default">
                        <a className="link-restore" onClick={this.restoreDefaults} >{TranslateHelper["Restore_Defaults"]}</a>
                    </div>
                    {!isShowApplyToAllInt && <div className="rating-intervals">
                        <Checkbox id="applyintervals" className="custom-check" type="checkbox" checked={tabItem.isApplyToAllIntervals} onChange={(e) => this.onApplyToAllIntClick(e)}>
                            <label className="oneil-rate-label"></label>
                            <span className="oneil-rate-txt">{IndicatorSettingsTranslateHelper[customTIModalDialogConstants.Apply_To_All]}</span>
                        </Checkbox>
                    </div>}
                </div>
            </Tab>
        )
    }

    renderSettingName(title) {
        let header = "";
        switch (title) {
            case IndicatorLabelTranslateHelper[BlockType.EPSR]:
                header = IndicatorSettingsTranslateHelper[BlockType.EPSR];
                break;
            case IndicatorLabelTranslateHelper[BlockType.MACD]:
                header = IndicatorSettingsTranslateHelper[BlockType.MACD];
                break;
            case IndicatorLabelTranslateHelper[BlockType.RSI]:
                header = IndicatorSettingsTranslateHelper[BlockType.RSI];
                break;
            case IndicatorLabelTranslateHelper[BlockType.Extended]:
                header = IndicatorSettingsTranslateHelper[BlockType.Extended];
                break;
            case IndicatorLabelTranslateHelper[BlockType.Stochastics]:
                header = IndicatorSettingsTranslateHelper[BlockType.Stochastics];
                break;
            case IndicatorLabelTranslateHelper[BlockType.WonStochastics]:
                header = IndicatorSettingsTranslateHelper[BlockType.WonStochastics];
                break;
            case IndicatorLabelTranslateHelper[BlockType.PTOE]:
                header = IndicatorSettingsTranslateHelper[BlockType.PTOE];
                break;
            case IndicatorLabelTranslateHelper[BlockType.PTOS]:
                header = IndicatorSettingsTranslateHelper[BlockType.PTOS];
                break;
            case IndicatorLabelTranslateHelper[BlockType.TechIndicator]:
                header = IndicatorSettingsTranslateHelper[BlockType.TechIndicator];
                break;
            default:
                header = `${title} Settings`
        }
        return header;
    }
    render() {
        const { isShowModal, menu, title, activePeriodicityTab, className } = this.props;
        const openDialogClass = isShowModal ? " dialog-open" : "";
        return (
            <Shortcuts
                name='NAV_LIST_MANAGER'
                handler={this.handleShortcuts}
                targetNodeSelector=".customIndicatorModalOpened"
            >
                <div>
                    <Modal className={`modal-popup ti-indicators ${className} ${openDialogClass}`} show={isShowModal}>
                        <Modal.Header>
                            <Modal.Title> <span className="cap-header">{this.renderSettingName(title)}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="oneil-rating-box">
                                <Tabs id="periodicity" onSelect={this.onTabSelectionChanged} activeKey={activePeriodicityTab}>
                                    {map(menu, (tabItem) => this.renderTabItem(tabItem))}
                                </Tabs>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <span className="btn-box righter">
                                <button type="button" className="btn btn-xs btn-default btn-secondary cancel" onClick={this.handleOnCancel}>{TranslateHelper["CANCEL"]}</button>
                                <button type="button" className="btn btn-xs btn-default btn-secondary ok" onClick={this.handleSave}>{TranslateHelper["OK"]}</button>
                            </span>
                        </Modal.Footer>
                    </Modal>
                </div>
            </Shortcuts>
        )
    }
}

SettingsDialog.propTypes = {
    isShowModal: PropTypes.bool.isRequired,
    menu: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    updateItemStatus: PropTypes.func.isRequired,
    onTabSelectionChanged: PropTypes.func.isRequired,
    onInputValChange: PropTypes.func.isRequired,
    restoreDefaults: PropTypes.func.isRequired,
    onApplyToAllIntervals: PropTypes.func.isRequired
}


export default SettingsDialog;