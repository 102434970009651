import BarVisual from "BarVisual";
import ZigzagVisual from "../../../../../RayCustomControls/Chart/Visuals/ZigzagVisual";
import BlockType from "../../../../../Constants/BlockType";
import { connect } from 'react-redux';
import FactorResearch from "./FactorResearch";
import LineVisual from "LineVisual";
import Pointer from "Pointer";
import PriceMenuLines from "./PriceMenuLines";
import RayChart from "RayChart";
import ThemeHelper from "ThemeHelper";
import { dgDimensionChange, updateChartRefs } from "../../../../../Actions/PricePanelActions"
import React, { useEffect, useRef } from 'react';

const PriceAction = (props) => {
    const PriceChartVisual = () => {
        const positiveColor = ThemeHelper.getThemedBrush("positiveNode");
        const showChart = props.HiLowPoints && props.HiLowPoints.allPoints && props.HiLowPoints.allPoints[0];
        if (!showChart) {
            return <div></div>;
        }
        if (props.isLineChart) {
            return <LineVisual showPointer={props.videoMode === false} showBox={props.videoMode === false} nodeWidth={props.nodeWidth}// ref={(ref) => this.LineVisual = ref}
                DataSource={props.HiLowPoints.allPoints} LineColor={positiveColor}
                LineThickness={2} Draggable={false} isHoLoPriceVisible={props.isHoLoPriceVisible} isHoLoPctVisible={props.isHoLoPctVisible} isPriceLabelsDisabled={props.isPriceLabelsDisabled} isUpDaysVisible={props.isUpDaysVisible} />;
        } else {
            return (
                <>
                    <BarVisual showPointer={props.videoMode === false} ref={barVisual} showBox={props.videoMode === false} nodeWidth={props.nodeWidth} //ref={(ref) => this.BarVisual = ref} 
                        DataSource={props.HiLowPoints} isHoLoPriceVisible={props.isHoLoPriceVisible} isHoLoPctVisible={props.isHoLoPctVisible} isPriceLabelsDisabled={props.isPriceLabelsDisabled} isUpDaysVisible={props.isUpDaysVisible} />;

                    <ZigzagVisual />
                </>
            )
        }
    }
    const MainChart = useRef(null);
    const barVisual = useRef(null);
    const pointer = useRef(null);
    const lColor = ThemeHelper.getThemedBrush("buttonLabel");
    const rColor = ThemeHelper.getThemedBrush("buttonLabelGray");
    useEffect(() => {
        props.updateChartRefs(MainChart, BlockType.Pricechart);
    }, []);
    return (
        <>
            <RayChart ref={MainChart}
                height={props.chartHeight}
                width={props.chartWidth}
                scale={props.scale}
                nodeWidth={props.nodeWidth}
                leftScaleWidth={props.leftScaleWidth}
                showEps={props.selectedPerShare}
                TimeLine={props.TimeLine}
                isIntraday={props.isIntraday}
                righscaleWidth={props.rightScaleWidth}
                onChartResize={props.dgDimensionChange}
                showHorizontalGrid={props.videoMode === false}
                showVerticalGrid={props.videoMode === false}
                chartType={"Pricechart"}
                trackpriceReqArgs={{ chartType: BlockType.Pricechart, barVisual }}
            >
                {PriceChartVisual()}
                <Pointer
                    ref={pointer}
                    hasBox={props.videoMode === false}
                    height={20}
                    width={115}
                    fill={ThemeHelper.getThemedBrush("pointerColor")}
                    lcolor={lColor}
                    rcolor={rColor}
                    contentL={props.CPrice}
                    contentR={props.currencyStr}
                    content={props.CPrice}
                    y={props.yValue}
                    yValue={props.yValue}
                    boxL={props.priceChangeStr}
                    boxR={props.pricePercentChangeStr}
                    numL={props.priceChange}
                />
                <FactorResearch />
                <PriceMenuLines />
                
            </RayChart>
        </>
    );
};

const mapStateToProps = (state) => {
    const { chartHeight, chartWidth, HiLowPoints, isLineChart, scale, showEps, ShowVolume, externalDataSettings, ExdEventSeriesData, isHoLoPriceVisible,
        isHoLoPctVisible, isPriceLabelsDisabled, isUpDaysVisible } = state.DatagraphReducers.PriceChartReducer;
    const { padding, isIntraday, periodicity, majorPeriodicity, leftScaleWidth, nodeWidth, rightScaleWidth, videoMode, CPrice, currencyStr, yValue, priceChangeStr,
        pricePercentChangeStr, priceChange } = state.DatagraphReducers.DataGraphReducer
    const TimeLine = state.DatagraphReducers.TimeLineReducer.TimeLineData.dates;
    const { selectedPerShare } = state.DatagraphReducers.FundamentalLineReducer;
    return ({
        chartHeight, chartWidth, HiLowPoints, leftScaleWidth, isIntraday, isLineChart, nodeWidth, rightScaleWidth,
        scale, showEps, TimeLine, videoMode, ShowVolume, selectedPerShare,
        periodicity, majorPeriodicity, externalDataSettings, ExdEventSeriesData, padding, isHoLoPriceVisible, isHoLoPctVisible,
        isUpDaysVisible, isPriceLabelsDisabled, CPrice, currencyStr, yValue, priceChangeStr, pricePercentChangeStr, priceChange
    });
}

const mapDispatchToProps = (dispatch) => ({
    dgDimensionChange: (dimension) => dispatch(dgDimensionChange(dimension)),
    updateChartRefs: (chart, chartType) => dispatch(updateChartRefs(chart, chartType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceAction);