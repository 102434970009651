import BlockType from "../../../../../../Constants/BlockType";
import ColorPicker from "../../../../../../RayCustomControls/ColorPicker/ColorPicker";
import PropTypes from 'prop-types';
import { Shortcuts } from 'react-shortcuts';
import ThemeHelper from "ThemeHelper";
import { AppearanceDialogTranslateHelper, IndicatorCommonTranslateHelper, IndicatorLabelTranslateHelper, ORatingTranslateHelper, TranslateHelper } from '../../../../../../Utils/TranslateHelper';
import { CustomTiDialogConstant, customTIModalDialogConstants } from '../../../../../../Constants/CustomTiDialogConstants';
import { map, find } from "underscore";
import { Modal, Radio } from "react-bootstrap";
import React, { PureComponent } from 'react';


class AppearanceDialog extends PureComponent {

    handleShortcuts = (action) => {
        if (action === 'ENTER_APPLY') {
            this.props.handleClick(true);
        }
    }

    handleOnCancel = () => {
        this.props.handleClick(false, false);
    }

    handleSave = () => {
        this.props.handleClick(true, false);
    }

    onCheckBoxSelect = (e, item) => {
        this.props.updateItemStatus(e.target.checked, item);
    }

    handleChangeColor = (activeMenu, color) => {
        this.props.handleChangeAppearance(activeMenu.isActive, activeMenu, color, activeMenu.weight);
    }

    onWeightChanged = (activeMenu, weight) => {
        this.props.handleChangeAppearance(activeMenu.isActive, activeMenu, activeMenu.color, weight);
    }

    restoreDefaults = () => {
        this.props.restoreDefaults();
    }

    renderName(name) {
        switch (name) {
            case customTIModalDialogConstants.CurrentFiscalYear:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.CurrentFiscalYear];
                break;
            case customTIModalDialogConstants.NextFiscalYear:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.NextFiscalYear];
                break;
            case customTIModalDialogConstants.CurrentFiscalQuarter:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.CurrentFiscalQuarter];
                break;
            case customTIModalDialogConstants.PTOEIndexLine:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOEIndexLine];
                break;
            case customTIModalDialogConstants.PTOEHLEstimates:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOEHLEstimates];
                break;
            case customTIModalDialogConstants.MACDLine:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.MACDLineColor];
                break;
            case customTIModalDialogConstants.MovingAverage:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.MovingAverage];
                break;
            case customTIModalDialogConstants.RSILine:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.RSILineColor];
                break;
            case CustomTiDialogConstant.Overbought:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Overbought];
                break;
            case CustomTiDialogConstant.Oversold:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Oversold];
                break;
            case customTIModalDialogConstants.StochasticsLine:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.StochasticsColor];
                break;
            case customTIModalDialogConstants.MovingAverageLine:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.StochasticsMovingAverageColor];
                break;
            case customTIModalDialogConstants.Rs3Months:
                name = ORatingTranslateHelper[CustomTiDialogConstant.Rs3Months];
                break;
            case customTIModalDialogConstants.Rs6Months:
                name = ORatingTranslateHelper[CustomTiDialogConstant.Rs6Months];
                break;
            case customTIModalDialogConstants.Rs12Months:
                name = ORatingTranslateHelper[CustomTiDialogConstant.Rs12Months];
                break;
            case customTIModalDialogConstants.GrpRs3Months:
                name = ORatingTranslateHelper[CustomTiDialogConstant.GrpRs3Months];
                break;
            case customTIModalDialogConstants.GrpRs6Months:
                name = ORatingTranslateHelper[CustomTiDialogConstant.GrpRs6Months];
                break;
            case customTIModalDialogConstants.Composite:
                name = ORatingTranslateHelper[CustomTiDialogConstant.Composite];
                break;
            case customTIModalDialogConstants.DataGraph:
                name = ORatingTranslateHelper[CustomTiDialogConstant.DataGraph];
                break;
            case customTIModalDialogConstants.EpsRank:
                name = ORatingTranslateHelper[CustomTiDialogConstant.EpsRank];
                break;
            case customTIModalDialogConstants.SMR:
                name = ORatingTranslateHelper[CustomTiDialogConstant.SMR];
                break;
            case customTIModalDialogConstants.Sponsorship:
                name = ORatingTranslateHelper[CustomTiDialogConstant.Sponsorship];
                break;
            case customTIModalDialogConstants.InstSupDem:
                name = ORatingTranslateHelper[CustomTiDialogConstant.InstSupDem];
                break;
            case customTIModalDialogConstants.AccDistLine:
                name = IndicatorCommonTranslateHelper[CustomTiDialogConstant.SignalColor];
                break;
            default:
                name = name
        }
        return name;
    }

    render() {
        const { isShowModal, title, className, activePeriodicityTab, menu } = this.props;
        const activePeriodicityTabMenu = find(menu, (periodicityTab) => periodicityTab.TabType === activePeriodicityTab.TabType);
        const activeMenu = find(activePeriodicityTabMenu.menuObj, (item) => { if (item.isActive){ return item } });
        const colorPickerWidth = 100;
        const thickness = [
            { weight: "thin-line", value: 1 },
            { weight: "normal-line", value: 2 },
            { weight: "weight-line", value: 3 }
        ];
        let header = "";
        switch (title) {
            case IndicatorLabelTranslateHelper[BlockType.EPSR]:
                header = AppearanceDialogTranslateHelper[BlockType.EPSR];
                break;
            case IndicatorLabelTranslateHelper[BlockType.MACD]:
                header = AppearanceDialogTranslateHelper[BlockType.MACD];
                break;
            case IndicatorLabelTranslateHelper[BlockType.RSI]:
                header = AppearanceDialogTranslateHelper[BlockType.RSI];
                break;
            case IndicatorLabelTranslateHelper[BlockType.Extended]:
                header = AppearanceDialogTranslateHelper[BlockType.Extended];
                break;
            case IndicatorLabelTranslateHelper[BlockType.Stochastics]:
                header = AppearanceDialogTranslateHelper[BlockType.Stochastics];
                break;
            case IndicatorLabelTranslateHelper[BlockType.WonStochastics]:
                header = AppearanceDialogTranslateHelper[BlockType.WonStochastics];
                break;
            case IndicatorLabelTranslateHelper[BlockType.PTOE]:
                header = AppearanceDialogTranslateHelper[BlockType.PTOE];
                break;
            case IndicatorLabelTranslateHelper[BlockType.PTOS]:
                header = AppearanceDialogTranslateHelper[BlockType.PTOS];
                break;
            case IndicatorLabelTranslateHelper[BlockType.TechIndicator]:
                header = AppearanceDialogTranslateHelper[BlockType.TechIndicator];
                break;
            case IndicatorLabelTranslateHelper[BlockType.AccDist]:
                header = AppearanceDialogTranslateHelper[BlockType.AccDist];
                break;
            default:
                header = `${title} Appearance`
        }
        const openDialogClass = isShowModal ? " dialog-open" : "";
        return (
            <Shortcuts
                name='NAV_LIST_MANAGER'
                handler={this.handleShortcuts}
                targetNodeSelector=".customIndicatorModalOpened"
            >
                <div>
                    <Modal className={`modal-popup ti-indicators ${className}${openDialogClass}`} show={isShowModal}>
                        <Modal.Header>
                            <Modal.Title><span className="cap-header">{header}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="oneil-rating-box rating-appear-box">
                                <div className="row">
                                    <div className="col-sm-7 appearance-lines">
                                        <h4 className="">{AppearanceDialogTranslateHelper[customTIModalDialogConstants.Lines]}</h4>
                                        <div className="oneil-rate-check">
                                            <ul>
                                                {map(activePeriodicityTabMenu.menuObj, (item, index) =>
                                                    <li key={index}>
                                                        <Radio id="month3" className="custom-check" type="radio" checked={item.value || item.isActive} onChange={(e) => this.onCheckBoxSelect(e, item)}>
                                                            <label className="oneil-rate-label"></label>
                                                            <span className="oneil-rate-txt"><span style={{ backgroundColor: ThemeHelper.getThemedBrush(item.color) }} className="rating-color-indicator"></span>{this.renderName(item.header)}</span>
                                                        </Radio>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 appearance-color-weight">
                                        <div className="pull-left">
                                            <div className="appearance-color">
                                                <h5>{TranslateHelper["Color"]}</h5>
                                                <div className="appearance-color-hold">
                                                    <ColorPicker width={colorPickerWidth} color={activeMenu.color} onChangeComplete={(color) => this.handleChangeColor(activeMenu, color)} />
                                                </div>
                                            </div>
                                            <div className="appearance-weight">
                                                <h6>{TranslateHelper["Weight"]}</h6>
                                                <div className="appearance-weight-hold lining-box">
                                                    {thickness.map((item, index) =>
                                                        (<a data-disable-track-price="true" key={index}
                                                            className={item.value === activeMenu.weight ? `${item.weight} active` : item.weight}                                                            
                                                            onClick={() => this.onWeightChanged(activeMenu, item.value)}
                                                        >
                                                            <div>
                                                                <hr />
                                                            </div>
                                                        </a>)
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                                <div className="rating-default appearance-restore">
                                    <a className="link-restore" onClick={this.restoreDefaults} >{TranslateHelper["Restore_Defaults"]}</a>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <span className="btn-box righter">
                                <button type="button" className="btn btn-xs btn-default btn-secondary cancel" onClick={this.handleOnCancel}>{TranslateHelper["CANCEL"]}</button>
                                <button type="button" className="btn btn-xs btn-default btn-secondary ok" onClick={this.handleSave}>{TranslateHelper["OK"]}</button>
                            </span>
                        </Modal.Footer>
                    </Modal>
                </div>
            </Shortcuts>
        )
    }
}

AppearanceDialog.propTypes = {
    isShowModal: PropTypes.bool.isRequired,
    activePeriodicityTab: PropTypes.object.isRequired,
    menu: PropTypes.array.isRequired,
    handleClick: PropTypes.func.isRequired,
    updateItemStatus: PropTypes.func.isRequired,
    handleChangeAppearance: PropTypes.func.isRequired
}


export default AppearanceDialog;