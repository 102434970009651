import TechnicalIndicatorSettings from "./TechnicalIndicatorSettings.js";
import CorporateEventsSettings from "./CorporateEventsSettings.js";
import PatternRecognitionSettings from "./PatternRecognitionSettings.js";
import HiLoPriceSettings from "./HiLoPriceSettings.js";
import HiLoPctSettings from "./HiLoPctSettings.js";
import UpDaySettings from "./UpDaySettings.js";
import MultiplierSettings from "./MultiplierSettings.js";
import KpiSettings from "./KpiSettings.js";
import MovingAverageSettings from "./MovingAverageSettings.js";
import VolumeBlockSettings from "./VolumeBlockSettings.js";
import SummaryBlockSettings from "./SummaryBlockSettings.js";
import FinancialBlockSettings from "./FinancialBlockSettings.js";
import OwnershipBlockSettings from "./OwnershipBlockSettings.js";
import InsiderBlockSettings from "./InsiderBlockSettings.js";
import RelativeStrenghtLineSettings from "./RelativeStrengthLineSettings.js";
import EpsRpsQuartersSettings from "./EpsRpsQuartersSettings.js";
import IndexLineSettings from "./IndexLineSettings.js";
import LLRSettings from "./LLRSettings.js";
import GraphType from "GraphType";
import PeriodicityHelper from "../../../../../Utils/PeriodicityHelper.js";
import Serializable from "Serializable";
import SmartViewType from 'SmartViewType';
import EPSRChartSettings from "./EPSRChartSettings.js";
import RSiChartSettings from "./RSiChartSettings.js";
import StreetTargetPriceSettings from "./StreetTargetPriceSettings";
// import TiMenuSettings from "./TiMenuSettings.js";

import MacdChartSettings from "./MacdChartSettings.js";
import StochasticsChartSettings from "./StochasticsChartSettings.js";
import WonStochasticsChartSettings from "./WonStochasticsChartSettings.js";
import WonStochasticsUsageSettings from "./WonStochasticsUsageSetting.js";
import OneilRatingsChartSettings from "./OneilRatingsChartSettings.js";
import PTOEChartSettings from "./PTOEChartSettings.js";
import PTOSChartSettings from "./PTOSChartSettings.js";
import AccDistChartSetting from "./AccDistChartSetting";
import ExtendedChartSettings from "./ExtendedChartSettings";
import SettingsStore from "SettingsStore";
import FactorResearchSettings from "./FactorResearchSettings"
import OpenInterestSettings from './OpenInterestSettings';
import EPSAnimationSettings from "./EPSAnimationSettings.js";
import ExternalDataSettings from "./ExternalDataSettings";

class DefaultDataGraphSetting {
  isVideoMode() {
    if (!SettingsStore) {
        return false;
    }
    const consoleSettings = SettingsStore.getConsoleSettings();
    if (!consoleSettings) {
      return false;
    }
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    return tabDataGraphSettings.videoMode;
  }

  getScaleLabelSettings(currency = false, isFuturesOrCashspots = false) {
    const scaleLabelSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      const videoMode = this.isVideoMode();
        if (!scaleLabelSettingObject.hasOwnProperty(majorPeriodicity)) {
            if (videoMode) {
                scaleLabelSettingObject[majorPeriodicity] = "LIN";
            } else {
                switch (majorPeriodicity) {
                case GraphType.Daily:
                    scaleLabelSettingObject[majorPeriodicity] = (currency || isFuturesOrCashspots) ? "LIN" : "LOG (Fixed)";
                    break;
                case GraphType.Weekly:
                case GraphType.Monthly:
                case GraphType.Quarterly:
                case GraphType.Annual:
                    scaleLabelSettingObject[majorPeriodicity] = currency ? "LIN" : "LOG (Fixed)";
                    break;
                default:
                    scaleLabelSettingObject[majorPeriodicity] = "LOG (Auto)";
                    break;
                }
            }
        }
    }
    return scaleLabelSettingObject;
  }
  getEPSLabelSettings() {
    let epsLabelSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!epsLabelSettingObject.hasOwnProperty(majorPeriodicity)) {
        epsLabelSettingObject[majorPeriodicity] = "EPS";
      }
    }
    return epsLabelSettingObject;
  }
  getCurrencyDefaultScaleLabelSettings() {
    let scaleSettingObject = {};
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!scaleSettingObject.hasOwnProperty(majorPeriodicity)) {
        scaleSettingObject[majorPeriodicity] = "LIN";
      }
    }
    return scaleSettingObject;
  }
  getEstimateSettings(isEPS = true, t4Q = -1) {
    let estimateSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!estimateSetting.hasOwnProperty(majorPeriodicity)) {
        estimateSetting[majorPeriodicity] = new EpsRpsQuartersSettings(majorPeriodicity, isEPS, t4Q);
        switch (majorPeriodicity) {
          case GraphType.Intraday:
            estimateSetting[majorPeriodicity].IsVisible = false;
            break;
          case GraphType.Daily:
            estimateSetting[majorPeriodicity].NumOfQuarters = 1;
            break;
          case GraphType.Weekly:
            estimateSetting[majorPeriodicity].NumOfQuarters = 4;
            break;
          default:
            estimateSetting[majorPeriodicity].NumOfQuarters = 8;
            break;
        }
        const videoMode = this.isVideoMode();
        if (videoMode) {
            estimateSetting[majorPeriodicity].IsVisible = false;
        }
      }
    }
    return estimateSetting;
  }
  getDefaultFinancialBlockSetting() {
    let financialBlockSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!financialBlockSettingObject.hasOwnProperty(majorPeriodicity)) {
        financialBlockSettingObject[majorPeriodicity] = new FinancialBlockSettings(majorPeriodicity);
      }
    }
    return financialBlockSettingObject;
  }
  getDefaultOwnershipBlockSettings() {
    let ownershipBlockSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!ownershipBlockSettingObject.hasOwnProperty(majorPeriodicity)) {
        ownershipBlockSettingObject[majorPeriodicity] = new OwnershipBlockSettings(majorPeriodicity);
      }
    }
    return ownershipBlockSettingObject;
  }
  getDefaultInsiderBlockSettings() {
    let insiderBlockSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!insiderBlockSettingObject.hasOwnProperty(majorPeriodicity)) {
        insiderBlockSettingObject[majorPeriodicity] = new InsiderBlockSettings(majorPeriodicity);
      }
    }
    return insiderBlockSettingObject;
  }
  getDefaultVolumeBlockSettings(isFutures) {
    let volumeBlockSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!volumeBlockSettingObject.hasOwnProperty(majorPeriodicity)) {
        volumeBlockSettingObject[majorPeriodicity] = new VolumeBlockSettings(majorPeriodicity,isFutures);
      }
    }
    return volumeBlockSettingObject;
  }
  getDefaultOpenInterestSettings(isVisible = true) {
    const OpenInterestSettingObject = new Serializable();
    for (const periodicity in GraphType) {
      if (GraphType.hasOwnProperty(periodicity)) {
        const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
        if (!OpenInterestSettingObject.hasOwnProperty(majorPeriodicity)) {
          OpenInterestSettingObject[majorPeriodicity] = new OpenInterestSettings(isVisible);
        }
      }
    }
    return OpenInterestSettingObject;
  }
  getDefaultIsSummaryBlockOpen() {
    let summaryBlockSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!summaryBlockSettingObject.hasOwnProperty(majorPeriodicity)) {
        summaryBlockSettingObject[majorPeriodicity] = new SummaryBlockSettings();
      }
    }

    return summaryBlockSettingObject;
  }
  getRSiChartSetting(defaultPeriodicity) {
    let rsiChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!rsiChartSetting.hasOwnProperty(majorPeriodicity)) {
        rsiChartSetting[majorPeriodicity] = new RSiChartSettings();
        rsiChartSetting[majorPeriodicity] = rsiChartSetting[majorPeriodicity].getDefaultRSiChartSettings();
        rsiChartSetting[majorPeriodicity].IsVisible = false;
        rsiChartSetting[majorPeriodicity].seqNumber = 3;
      }
    }
    return rsiChartSetting;
  }
  getExtendedChartSetting(defaultPeriodicity) {
    let extendedChartSetting = new Serializable();
    for (let periodicity in GraphType) {
        let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
        if (!extendedChartSetting.hasOwnProperty(majorPeriodicity)) {
            extendedChartSetting[majorPeriodicity] = new ExtendedChartSettings();
            extendedChartSetting[majorPeriodicity] = extendedChartSetting[majorPeriodicity].getDefaultExtendedChartSettings();
            extendedChartSetting[majorPeriodicity].IsVisible = false;
            extendedChartSetting[majorPeriodicity].seqNumber = 1;
        }
        if (majorPeriodicity == GraphType.Daily) {
            extendedChartSetting[majorPeriodicity].IsAvailable = true;
            extendedChartSetting[majorPeriodicity].IsVisible = false;
        }
    }
    return extendedChartSetting;
  }
  getTiMenuSettings(type = '') {
    let tiMenuSettings = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      let fmVisibility = false;
      let orVisibility = false;
      let tiVisibility = false;
      let pmVisibility = false;
      let edVisibility = false;
      let kpiVisibility = false;
      if(type == SmartViewType.STOCK){
        if(majorPeriodicity != GraphType.Intraday)
          kpiVisibility = true;
          if(majorPeriodicity == GraphType.Monthly)
            orVisibility = true;
          if(majorPeriodicity == GraphType.Daily)
            tiVisibility = true;
      }
      if(type == SmartViewType.ETF || type == SmartViewType.CEF){
        if(majorPeriodicity == GraphType.Monthly)
          orVisibility = true;
          pmVisibility = true;
        if(majorPeriodicity == GraphType.Daily || majorPeriodicity == GraphType.Weekly || majorPeriodicity == GraphType.Quarterly || majorPeriodicity == GraphType.Annual)
          pmVisibility = true;
      }
      if(type == SmartViewType.FUND || type == SmartViewType.INDEX){
        if(majorPeriodicity == GraphType.Daily || majorPeriodicity == GraphType.Weekly || majorPeriodicity == GraphType.Monthly || majorPeriodicity == GraphType.Quarterly || majorPeriodicity == GraphType.Annual)
          pmVisibility = true;
      }
      if(type == SmartViewType.INDUSTRYGROUP){
        if(majorPeriodicity == GraphType.Daily || majorPeriodicity == GraphType.Weekly || majorPeriodicity == GraphType.Monthly || majorPeriodicity == GraphType.Quarterly || majorPeriodicity == GraphType.Annual)
          pmVisibility = true;
        if(majorPeriodicity == GraphType.Daily)
          tiVisibility = true;
      }
      if (!tiMenuSettings.hasOwnProperty(majorPeriodicity)) {
        tiMenuSettings[majorPeriodicity] = [];
        const individualPeriodicity = {
          ED:{seqNumber:1,isVisible:edVisibility},
          FM:{seqNumber:2,isVisible:fmVisibility},
          KPI:{seqNumber:3,isVisible:kpiVisibility},
          OR:{seqNumber:4,isVisible:orVisibility},
          PM:{seqNumber:5,isVisible:pmVisibility},
          TI:{seqNumber:6,isVisible:tiVisibility},
        }
        let serialisedSection = new Serializable();
        for (var key in individualPeriodicity) {
          if (individualPeriodicity.hasOwnProperty(key)) {
            serialisedSection[key] = Object.assign(new Serializable(),individualPeriodicity[key]);
          }
        }
        tiMenuSettings[majorPeriodicity].push( Object.assign(new Serializable(), serialisedSection ));
      }
    }
    return tiMenuSettings;
  }

  getSerizableObject(obj) {
    let ret = {};
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        ret.push( Object.assign(new Serializable, { ...obj }));
      }
  }
  console.log(ret);
  }
  getTechChartSetting(viewType = 'STOCK', defaultPeriodicity) {
    let oneilRtgsChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!oneilRtgsChartSetting.hasOwnProperty(majorPeriodicity)) {
        oneilRtgsChartSetting[majorPeriodicity] = new OneilRatingsChartSettings();
        oneilRtgsChartSetting[majorPeriodicity] = oneilRtgsChartSetting[majorPeriodicity].getDefaultOneilRatingsChartSettings();
        if (majorPeriodicity == GraphType.Intraday)
          oneilRtgsChartSetting[majorPeriodicity].IsAvailable = false;
        if (majorPeriodicity != GraphType.Monthly)
          oneilRtgsChartSetting[majorPeriodicity].IsVisible = false;
        if (viewType === SmartViewType.CEF || viewType === SmartViewType.ETF)
          oneilRtgsChartSetting[majorPeriodicity].DataGraph = false;
        else
          oneilRtgsChartSetting[majorPeriodicity].DataGraph = true;
        if (viewType === SmartViewType.CEF || viewType === SmartViewType.ETF)
          oneilRtgsChartSetting[majorPeriodicity].Rs3Months = true;
        else
          oneilRtgsChartSetting[majorPeriodicity].Rs3Months = false;
        oneilRtgsChartSetting[majorPeriodicity].Rs6Months = false;
        if (viewType === SmartViewType.CEF || viewType === SmartViewType.ETF)
          oneilRtgsChartSetting[majorPeriodicity].Rs12Months = true;


        oneilRtgsChartSetting[majorPeriodicity].seqNumber = 1;
        //else
        //   techChartSetting[majorPeriodicity].Rs12Months = false;
        // techChartSetting[majorPeriodicity].GrpRs3Months = false;
        // techChartSetting[majorPeriodicity].GrpRs6Months = false;
        // techChartSetting[majorPeriodicity].InstSupDem = false;
        // techChartSetting[majorPeriodicity].SMR = false;
        // techChartSetting[majorPeriodicity].Sponsorship = false;
        // techChartSetting[majorPeriodicity].Composite = false;
        // techChartSetting[majorPeriodicity].EpsRank = false;
        // techChartSetting[majorPeriodicity].DataGraphColor = "#9866FF";
        // techChartSetting[majorPeriodicity].Rs3MonthsColor = "#fda57d";
        // techChartSetting[majorPeriodicity].Rs6MonthsColor = "#fc6901";
        // techChartSetting[majorPeriodicity].Rs12MonthsColor = "#FF0000";
        // techChartSetting[majorPeriodicity].GrpRs3MonthsColor = "#cbcb01";
        // techChartSetting[majorPeriodicity].GrpRs6MonthsColor = "#990000";
        // techChartSetting[majorPeriodicity].InstSupDemColor = "#34FF33";
        // techChartSetting[majorPeriodicity].SMRColor = "#66CCFF";
        // techChartSetting[majorPeriodicity].SponsorshipColor = "#089800";
        // techChartSetting[majorPeriodicity].CompositeColor = "#660099";
        // techChartSetting[majorPeriodicity].EpsRankColor = "#0066CC";
      }
    }
    return oneilRtgsChartSetting;
  }
  getYTDChartSetting() {
    let ytdChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!ytdChartSetting.hasOwnProperty(majorPeriodicity)) {
        ytdChartSetting[majorPeriodicity] = new TechnicalIndicatorSettings();
        if (majorPeriodicity == GraphType.Intraday) {
          ytdChartSetting[majorPeriodicity].IsAvailable = false;
          ytdChartSetting[majorPeriodicity].IsVisible = false;
        }
        ytdChartSetting[majorPeriodicity].seqNumber = 1;
      }
    }
    return ytdChartSetting;
  }
  getYTD1ChartSetting() {
    let ytdChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!ytdChartSetting.hasOwnProperty(majorPeriodicity)) {
        ytdChartSetting[majorPeriodicity] = new TechnicalIndicatorSettings();
        ytdChartSetting[majorPeriodicity].IsVisible = false;
        if (majorPeriodicity == GraphType.Intraday) {
          ytdChartSetting[majorPeriodicity].IsAvailable = false;
        }

        ytdChartSetting[majorPeriodicity].seqNumber = 2;
      }
    }
    return ytdChartSetting;
  }
  getYTD3ChartSetting() {
    let ytdChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!ytdChartSetting.hasOwnProperty(majorPeriodicity)) {
        ytdChartSetting[majorPeriodicity] = new TechnicalIndicatorSettings();
        ytdChartSetting[majorPeriodicity].IsVisible = false;
        if (majorPeriodicity == GraphType.Intraday) {
          ytdChartSetting[majorPeriodicity].IsAvailable = false;
        }
        ytdChartSetting[majorPeriodicity].seqNumber = 3;
      }
    }
    return ytdChartSetting;
  }
  getYTD5ChartSetting() {
    let ytdChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!ytdChartSetting.hasOwnProperty(majorPeriodicity)) {
        ytdChartSetting[majorPeriodicity] = new TechnicalIndicatorSettings();
        ytdChartSetting[majorPeriodicity].IsVisible = false;
        if (majorPeriodicity == GraphType.Intraday) {
          ytdChartSetting[majorPeriodicity].IsAvailable = false;
        }
        ytdChartSetting[majorPeriodicity].seqNumber = 4;
      }
    }
    return ytdChartSetting;
  }
  getYTD10ChartSetting() {
    let ytdChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!ytdChartSetting.hasOwnProperty(majorPeriodicity)) {
        ytdChartSetting[majorPeriodicity] = new TechnicalIndicatorSettings();
        ytdChartSetting[majorPeriodicity].IsVisible = false;
        if (majorPeriodicity == GraphType.Intraday) {
          ytdChartSetting[majorPeriodicity].IsAvailable = false;
        }
        ytdChartSetting[majorPeriodicity].seqNumber = 5;
      }
    }
    return ytdChartSetting;
  }
  getStochasticsChartSetting(defaultPeriodicity) {
    let stochasticsChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!stochasticsChartSetting.hasOwnProperty(majorPeriodicity)) {
        stochasticsChartSetting[majorPeriodicity] = new StochasticsChartSettings();
        stochasticsChartSetting[majorPeriodicity] = stochasticsChartSetting[majorPeriodicity].getDefaultStochasticsChartSettings();
        stochasticsChartSetting[majorPeriodicity].IsVisible = false;
        stochasticsChartSetting[majorPeriodicity].seqNumber = 4;
      }
    }
    return stochasticsChartSetting;
  }
  getWonStochasticsChartSetting(defaultPeriodicity) {
    let stochasticsChartSetting = new Serializable();
    for (let periodicity in GraphType) {
        let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
        if (!stochasticsChartSetting.hasOwnProperty(majorPeriodicity)) {
            stochasticsChartSetting[majorPeriodicity] = new WonStochasticsChartSettings();
            stochasticsChartSetting[majorPeriodicity] = stochasticsChartSetting[majorPeriodicity].getDefaultStochasticsChartSettings();
            stochasticsChartSetting[majorPeriodicity].IsVisible = false;
            stochasticsChartSetting[majorPeriodicity].seqNumber = 5;
        }
    }
    return stochasticsChartSetting;
  }
  getWonStochasticsUsageSetting(defaultPeriodicity) {
    let stochasticsUsageSetting = new Serializable();
    for (let periodicity in GraphType) {
        let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
        if (!stochasticsUsageSetting.hasOwnProperty(majorPeriodicity)) {
            stochasticsUsageSetting[majorPeriodicity] = new WonStochasticsUsageSettings();
            stochasticsUsageSetting[majorPeriodicity] = stochasticsUsageSetting[majorPeriodicity].getDefaultStochasticsUsageSettings();
        }
    }
    return stochasticsUsageSetting;
  }
  getMacdChartSetting(defaultPeriodicity) {
    let macdChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!macdChartSetting.hasOwnProperty(majorPeriodicity)) {
        macdChartSetting[majorPeriodicity] = new MacdChartSettings();
        macdChartSetting[majorPeriodicity] = macdChartSetting[majorPeriodicity].getDefaultMacdChartSettings();
        macdChartSetting[majorPeriodicity].IsVisible = false;
        macdChartSetting[majorPeriodicity].seqNumber = 2;
      }
    }
    return macdChartSetting;
  }
  getAccDistChartSetting(show = true, defaultPeriodicity) {
    let accDistChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!accDistChartSetting.hasOwnProperty(majorPeriodicity)) {
        accDistChartSetting[majorPeriodicity] = new AccDistChartSetting();
        accDistChartSetting[majorPeriodicity] = accDistChartSetting[majorPeriodicity].getDefaultAccDistChartSetting();
        accDistChartSetting[majorPeriodicity].IsVisible = show;
        if (majorPeriodicity != GraphType.Daily) {
          accDistChartSetting[majorPeriodicity].IsAvailable = false;
          accDistChartSetting[majorPeriodicity].IsVisible = false;
        }
        accDistChartSetting[majorPeriodicity].seqNumber = 1;
      }
    }
    return accDistChartSetting;
  }
  getPTOEChartSetting(defaultPeriodicity) {
    let ptoeChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!ptoeChartSetting.hasOwnProperty(majorPeriodicity)) {
        ptoeChartSetting[majorPeriodicity] = new PTOEChartSettings();
        ptoeChartSetting[majorPeriodicity] = ptoeChartSetting[majorPeriodicity].getDefaultPTOEChartSettings();
        if (majorPeriodicity == GraphType.Intraday)
          ptoeChartSetting[majorPeriodicity].IsAvailable = false;
        ptoeChartSetting[majorPeriodicity].IsVisible = false;
        ptoeChartSetting[majorPeriodicity].IsRelative = false;
        ptoeChartSetting[majorPeriodicity].seqNumber = 2;
      }
    }
    return ptoeChartSetting;
  }
  getPTOSChartSetting(defaultPeriodicity) {
    let ptosChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!ptosChartSetting.hasOwnProperty(majorPeriodicity)) {
        ptosChartSetting[majorPeriodicity] = new PTOSChartSettings();
        ptosChartSetting[majorPeriodicity] = ptosChartSetting[majorPeriodicity].getDefaultPTOSChartSettings();
        if (majorPeriodicity == GraphType.Intraday)
          ptosChartSetting[majorPeriodicity].IsAvailable = false;
        ptosChartSetting[majorPeriodicity].IsVisible = false;
        ptosChartSetting[majorPeriodicity].IsRelative = false;
        ptosChartSetting[majorPeriodicity].seqNumber = 3;
      }
    }
    return ptosChartSetting;
  }
  getEPSRChartSetting(defaultPeriodicity) {
    let techChartSetting = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!techChartSetting.hasOwnProperty(majorPeriodicity)) {
        techChartSetting[majorPeriodicity] = new EPSRChartSettings();
        techChartSetting[majorPeriodicity] = techChartSetting[majorPeriodicity].getDefaultEPSRChartSettings();
        if (majorPeriodicity == GraphType.Intraday)
          techChartSetting[majorPeriodicity].IsAvailable = false;
        techChartSetting[majorPeriodicity].IsVisible = false;
        techChartSetting[majorPeriodicity].IsRelative = false;
        techChartSetting[majorPeriodicity].seqNumber = 1;
      }
    }
    return techChartSetting;
  }
  getEPSAnimationSetting(defaultPeriodicity) {
    let epsAnimationSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!epsAnimationSettingObject.hasOwnProperty(majorPeriodicity)) {
        epsAnimationSettingObject[majorPeriodicity] = new EPSAnimationSettings(majorPeriodicity);
      }
    }
    return epsAnimationSettingObject;
  } 

  getCorporateEventsSetting(ETForCEF, IsIndex = false) {
    let corporateEventsSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!corporateEventsSettingObject.hasOwnProperty(majorPeriodicity, ETForCEF)) {
          corporateEventsSettingObject[majorPeriodicity] = new CorporateEventsSettings(majorPeriodicity, ETForCEF);
          const videoMode = this.isVideoMode();
          if (videoMode) {
                corporateEventsSettingObject[majorPeriodicity].IsVisible = false;
          }
          if(IsIndex){
            corporateEventsSettingObject[majorPeriodicity].IsAvailable = false;
          }
      }
    }
    return corporateEventsSettingObject;
  }
  // getCorporateEventsSetting(ETForCEF,IsIndex) {
  //   let corporateEventsSettingObject = new Serializable();
  //   for (let periodicity in GraphType) {
  //     let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
  //     if (!corporateEventsSettingObject.hasOwnProperty(majorPeriodicity, ETForCEF)) {
  //       corporateEventsSettingObject[majorPeriodicity] = new CorporateEventsSettings(majorPeriodicity, ETForCEF);
  //     }
  //   }
  //   return corporateEventsSettingObject;
  // }

  getPatternRecognitionSetting(isVisible = true) {
    const patternRecognitionSettingObject = new Serializable();
    for (let periodicity in GraphType) {
        const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
        const videoMode = this.isVideoMode();
        if (videoMode === true || (majorPeriodicity !== GraphType.Daily && majorPeriodicity !== GraphType.Weekly)) {
            isVisible = false;
        } else {
            isVisible = true;
        }
        if (!patternRecognitionSettingObject.hasOwnProperty(isVisible)) {
            patternRecognitionSettingObject[majorPeriodicity] = new PatternRecognitionSettings(isVisible);
            if (majorPeriodicity !== GraphType.Daily && majorPeriodicity !== GraphType.Weekly) {
                patternRecognitionSettingObject[majorPeriodicity].IsAvailable = false;
            }
        }
    }
    return patternRecognitionSettingObject;
  }
  //getPatternRecognitionSetting(isVisible = true) {
  //  let patternRecognitionSettingObject = new PatternRecognitionSettings(isVisible);
  //  return patternRecognitionSettingObject;
  //}
  getStreetTargetPriceSetting(){
    const streetTargetPriceSettingObject = new Serializable();
    for (let periodicity in GraphType) {
        const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
        if (!streetTargetPriceSettingObject.hasOwnProperty(majorPeriodicity)) {
            streetTargetPriceSettingObject[majorPeriodicity] = new StreetTargetPriceSettings();
            const videoMode = this.isVideoMode();
            if (videoMode) {
                streetTargetPriceSettingObject[majorPeriodicity].IsVisible = false;
            }        }
        if (majorPeriodicity === GraphType.Quarterly || majorPeriodicity === GraphType.Annual) {
          streetTargetPriceSettingObject[majorPeriodicity].IsVisible = false;
        }
    }
    return streetTargetPriceSettingObject;
  }
  getFactorResearchSetting(isVisible = true) {
    let factorResearchSettingObject = new FactorResearchSettings(isVisible);
    return factorResearchSettingObject;
  }

  getRelativeStrenghtLineSetting(rsLineNum, isVisible = true, country = 1) {
      const relativeStrenghtLineSettingObject = new Serializable();
      if (!relativeStrenghtLineSettingObject.hasOwnProperty(country)) {
          relativeStrenghtLineSettingObject[country] = new RelativeStrenghtLineSettings(rsLineNum, isVisible);
          if (rsLineNum > 1) {
              relativeStrenghtLineSettingObject[country].IsVisible = false;
          }
      }
      return relativeStrenghtLineSettingObject;
  }
  getRelativeStrenghtLineSettingByPeriodicity(rsLineNum, isVisible = true) {
    let relativeStrenghtLineSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!relativeStrenghtLineSettingObject.hasOwnProperty(majorPeriodicity)) {
          const videoMode = this.isVideoMode();
          relativeStrenghtLineSettingObject[majorPeriodicity] = new RelativeStrenghtLineSettings(rsLineNum, isVisible);
          if (rsLineNum > 1) {
              relativeStrenghtLineSettingObject[majorPeriodicity].IsVisible = false;
          }
          if (videoMode && rsLineNum === 1) {
              relativeStrenghtLineSettingObject[majorPeriodicity].lineColor = 'P3399FF0066CC';
              relativeStrenghtLineSettingObject[majorPeriodicity].showRank = false;
              relativeStrenghtLineSettingObject[majorPeriodicity].showGrade = false;
          }
      }
    }
      return relativeStrenghtLineSettingObject;
  }
  getIndexLineSetting(isVisible = true, country = 1) {
      const indexLineSettingObject = new Serializable();
      if (!indexLineSettingObject.hasOwnProperty(country)) {
          const videoMode = this.isVideoMode();
          indexLineSettingObject[country] = new IndexLineSettings(isVisible);
          if (videoMode) {
              indexLineSettingObject[country].IsVisible = false;
          }
      }
      return indexLineSettingObject;
  }
  getIndexLineSettingByPeriodicity(isVisible = true) {
    const indexLineSettingObject = new Serializable();
    for (let periodicity in GraphType) {
        const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
        if (!indexLineSettingObject.hasOwnProperty(majorPeriodicity)) {
            indexLineSettingObject[majorPeriodicity] = new IndexLineSettings(isVisible);
            const videoMode = this.isVideoMode();
            if (videoMode) {
                indexLineSettingObject[majorPeriodicity].IsVisible = false;
            }
        }
    }
    return indexLineSettingObject;
  }
  getLLRSetting(isVisible = true) {
    let llrSettingObject = new LLRSettings(isVisible);
    return llrSettingObject;
  }
  getHiLoPriceSetting(isCryptoCurrency = false) {
    let hiLoPriceSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!hiLoPriceSettingObject.hasOwnProperty(majorPeriodicity)) {
        hiLoPriceSettingObject[majorPeriodicity] = new HiLoPriceSettings(majorPeriodicity);
      }
      if (isCryptoCurrency && majorPeriodicity === GraphType.Daily) {
        hiLoPriceSettingObject[majorPeriodicity].IsAvailable = false;
        hiLoPriceSettingObject[majorPeriodicity].IsVisible = false;
      }
      const videoMode = this.isVideoMode();
      if (videoMode) {
        hiLoPriceSettingObject[majorPeriodicity].IsVisible = false;
      }
    }
    return hiLoPriceSettingObject;
  }
  getHiLoPctSetting(isCryptoCurrency = false, isIndexView = false, isIndstryGroup=false) {
    let hiLoPctSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!hiLoPctSettingObject.hasOwnProperty(majorPeriodicity)) {
        hiLoPctSettingObject[majorPeriodicity] = new HiLoPctSettings(majorPeriodicity);
      }
      if (isCryptoCurrency && majorPeriodicity === GraphType.Daily) {
        hiLoPctSettingObject[majorPeriodicity].IsAvailable = false;
        hiLoPctSettingObject[majorPeriodicity].IsVisible = false;
      }
      if (isIndexView || isIndstryGroup) {
          hiLoPctSettingObject[majorPeriodicity].IsVisible = true;
      }
      const videoMode = this.isVideoMode();
      if (videoMode) {
          hiLoPctSettingObject[majorPeriodicity].IsVisible = false;
      }
    }
    return hiLoPctSettingObject;
  }
  getUpDaysSetting(isCryptoCurrency = false, isIndexView = false, isIndstryGroup = false) {
      let upDaysSettingObject = new Serializable();
      for (let periodicity in GraphType) {
          let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
          if (!upDaysSettingObject.hasOwnProperty(majorPeriodicity)) {
              upDaysSettingObject[majorPeriodicity] = new UpDaySettings(majorPeriodicity);
          }
          if (isCryptoCurrency && majorPeriodicity === GraphType.Daily) {
              upDaysSettingObject[majorPeriodicity].IsAvailable = false;
              upDaysSettingObject[majorPeriodicity].IsVisible = false;
          }
          if (isIndexView || isIndstryGroup) {
              upDaysSettingObject[majorPeriodicity].IsVisible = true;
          }
          const videoMode = this.isVideoMode();
          if (videoMode) {
              upDaysSettingObject[majorPeriodicity].IsVisible = false;
          }
      }
      return upDaysSettingObject;
  }
  getMultiplierSetting() {
    let multiplierSettingObject = new Serializable();
    if (!multiplierSettingObject.hasOwnProperty("Auto"))
      multiplierSettingObject = new MultiplierSettings();
    return multiplierSettingObject;
  }
  getKpiSetting(defaultPeriodicity = '') {
    let KpiSettingsObjectReturn = new Serializable();
    let KpiSettingsObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = defaultPeriodicity ? defaultPeriodicity : PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!KpiSettingsObject.hasOwnProperty(majorPeriodicity)) {
        KpiSettingsObject[majorPeriodicity] = new KpiSettings();
        KpiSettingsObject[majorPeriodicity] = KpiSettingsObject[majorPeriodicity].getDefaultKpiChartSettings();
        KpiSettingsObject[majorPeriodicity].IsVisible = false;
      }
    }
    KpiSettingsObjectReturn['default'] = KpiSettingsObject;
    return KpiSettingsObjectReturn;
  }
  getKpiSettingDefault(IsVisible = false,seqNumber = '') {
    let KpiSettingsObjectReturn = new Serializable();
    let KpiSettingsObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (!KpiSettingsObject.hasOwnProperty(majorPeriodicity)) {
        KpiSettingsObject[majorPeriodicity] = new KpiSettings();
        KpiSettingsObject[majorPeriodicity] = KpiSettingsObject[majorPeriodicity].getDefaultKpiChartSettings();
        KpiSettingsObject[majorPeriodicity].IsVisible = IsVisible;
        KpiSettingsObject[majorPeriodicity].seqNumber = seqNumber;
      }
    }KpiSettingsObjectReturn = KpiSettingsObject;
    return KpiSettingsObjectReturn;
  }
  getMovingAverageSetting(isVisible = true) {
    const movingAverageSettingsObject = new Serializable();
    for (let periodicity in GraphType) {
      const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      const videoMode = this.isVideoMode();
      const color1 = videoMode ? 'PFF0000670000' : 'P0066CC3399FF';
      const color2 = videoMode ? 'P9999994C4C4C' : 'PCC0100990000';
      switch (majorPeriodicity) {
        case GraphType.Daily:
          if (!movingAverageSettingsObject.hasOwnProperty(majorPeriodicity)) {
            movingAverageSettingsObject[majorPeriodicity] = [];
            if (isVisible) {
              movingAverageSettingsObject[majorPeriodicity].push(new MovingAverageSettings(50, 'SMA', isVisible, color1, 1));
              movingAverageSettingsObject[majorPeriodicity].push(new MovingAverageSettings(200, 'SMA', isVisible, color2, 1));
            }
          }
          break;
        case GraphType.Weekly:
          if (!movingAverageSettingsObject.hasOwnProperty(majorPeriodicity)) {
            movingAverageSettingsObject[majorPeriodicity] = [];
            if (isVisible) {
              movingAverageSettingsObject[majorPeriodicity].push(new MovingAverageSettings(10, 'SMA', isVisible, color1, 1));
              movingAverageSettingsObject[majorPeriodicity].push(new MovingAverageSettings(40, 'SMA', isVisible, color2, 1));
            }
          }
          break;
        // case GraphType.Monthly:
        //   if (!movingAverageSettingsObject.hasOwnProperty(majorPeriodicity)) {
        //     movingAverageSettingsObject[majorPeriodicity] = [];
        //     movingAverageSettingsObject[majorPeriodicity].push(new MovingAverageSettings(6, 'SMA', isVisible));
        //   }
        //   break;
        default:
          if (!movingAverageSettingsObject.hasOwnProperty(majorPeriodicity)) {
            movingAverageSettingsObject[majorPeriodicity] = [];
          }
          break;
      }
    }
    return movingAverageSettingsObject;
  }

  getMovingAverageSettingObject(ma, maType, isVisible, lineColor, lineThickness) {
    return new MovingAverageSettings(ma, maType, isVisible, lineColor, lineThickness)
  }

  getExternalDataSettings(IsUploaded = false) {
    let externalDataSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
      if (majorPeriodicity !== GraphType.Intraday) {
        if (!externalDataSettingObject.hasOwnProperty(majorPeriodicity)) {
          externalDataSettingObject[majorPeriodicity] = new ExternalDataSettings(IsUploaded);
        }
      }
    }
    externalDataSettingObject = Object.assign(new Serializable(), { default: externalDataSettingObject });
    return externalDataSettingObject;
  }

  getExternalDataRiPanelSettings() {
    const riPanelSettings = new Serializable();
    return riPanelSettings;
  }
}
const defaultDataGraphSetting = new DefaultDataGraphSetting();
export default defaultDataGraphSetting;
