import AnnotationLayers from './AnnotationLayers.jsx'
import AnnotationUtil from '../../../../../../Utils/AnnotationUtil';
import BaseServiceApi from 'BaseServiceApi';
import ContextMenuConstants from "ContextMenuConstants";
import DGContextMenu from "../../../../../../RayCustomControls/ContextMenu/DGContextMenu.jsx";
import EditableTextInput from '../Presentational/EditableTextInput.jsx';
import { getAnnotations } from '../../../../../../Reducers/AnnotationReducers/reselectorsdata';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import PortalContainer from 'PortalContainer';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import store from '../../../../../../Redux/store';
import StringUtil from '../../../../../../Utils/StringUtil';
import TabDataGraphActionClass from "../../../../../../Actions/TabDataGraphAction.js";
import TimeTrackingWindow from "TimeTrackingWindow";
import { connect, Provider } from 'react-redux';
import {
    handleClick, handleMouseMove, handleReWrite, handleToggleContextMenu, handleDeleteAnnotationDrawing, handleDeleteAllAnnotation,
    handleCtrlClick, handleDeleteConfirmPopup, handleAddLineAlert, onEndExtendChange, onCheckBoxChange, handleRemoveLineAlert,
    moveFront, moveBack, handleEscClick, onAnnotationToolChange, updateStageClickCapturedFlag,
    updateRiPanelInfoFromUserSettings, updateSelectedAnnotationId, clearSelectedAnnotation
} from "../../../../../../Actions/AnnotationActions";
import React, { Component } from 'react';
import { Stage, Layer } from 'react-konva';
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

class AnnotationStage extends Component {
    constructor(props) {
        super(props);
        this.onDocumentKeyPress = this.onDocumentKeyPress.bind(this);
    }
    componentDidMount() {
        document.addEventListener("keydown", this.onDocumentKeyPress, false);
        document.addEventListener("keyup", this.onDocumentKeyReleased, false);
    }
    componentDidUpdate() {
        if (this.props.selectedAnnotId.length > 0) {
            if (!document.body.classList.contains('annotation-selected')) {
                document.body.classList.add('annotation-selected');
            }
        } else {
            if (document.body.classList.contains('annotation-selected')) {
                document.body.classList.remove('annotation-selected');
            }
        }
        const element = document.getElementsByClassName('symbol-chart-stack')[0];
        if (this.props.isDrawing || this.props.activeAnchor !== '' || this.props.isDragging) {
            if (!element.classList.contains('annotation-draw-time')) {
                element.classList.add('annotation-draw-time');
            }
        } else {
            if (element.classList.contains('annotation-draw-time')) {
                element.classList.remove('annotation-draw-time');
            }
        }

        if (this.props.summaryReducer.isOpen) {
            const element1 = document.getElementsByClassName('summary-block-symbol')[0];
            if (this.props.isDrawing || this.props.activeAnchor !== '' || this.props.isDragging) {
                if (element1 && !element1.classList.contains('annotation-draw-time')) {
                    element1.classList.add('annotation-draw-time');
                }
            }
            else {
                element1 && element1.classList.remove('annotation-draw-time');
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyPress, false);
        document.removeEventListener("keyup", this.onDocumentKeyReleased, false);
    }
    onDocumentKeyPress(e) {
        const charCode = (e.which) ? e.which : e.keyCode;

        if (document.body.classList.contains("symbol-entry-focused") && charCode === KeyCodes.DELETE) {
            return;
        }

        if ((charCode === KeyCodes.DELETE || charCode === KeyCodes.ESCAPE) && (this.props.isDrawing || this.props.selectedAnnotId.length > 0) && !document.body.classList.contains("annotation-active")) {
            e.preventDefault();
            e.stopPropagation();
            if (this.props.selectedAnnotId.length > 1 && charCode === KeyCodes.DELETE) {
                this.props.confirmDelete(true);
            } else {
                this.props.deleteAnnotDraw(charCode);
            }
            return;
        }
        else if ((charCode === KeyCodes.ESCAPE) && document.body.classList.contains("annotation-active")) {
            this.props.handleEscClick();
        }
        else if (!AnnotationUtil.isCtrlKeyMode && charCode === KeyCodes.CTRL && !document.getElementById("popover-contained") && ((this.props.selectedToolType === 0 && !document.body.classList.contains("annotation-active")) || !AnnotationUtil.isAnnotationsTabActive())) {
            e.stopPropagation();
            AnnotationUtil.isCtrlKeyMode = true;
            this.props.onAnnotationToolChange(RayAnnotationType.LINE_AnnotationType, true);
            this.props.updateRiPanelInfoFromUserSettings(true);
        }
    }

    onDocumentKeyReleased = (e) => {
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode === KeyCodes.CTRL && AnnotationUtil.isCtrlKeyMode) {
            e.stopPropagation();
            AnnotationUtil.isCtrlKeyMode = false;
            if (this.props.selectedAnnotId.length === 1) {
                this.props.deleteAnnotDraw(KeyCodes.ESCAPE);
            }
            this.props.onAnnotationToolChange(this.props.tempToolType, true);
        }
    }

    onContextMenuClick = (item) => {
        this.closeContextMenu();
        switch (item.action) {
            case ContextMenuConstants.DELETE_SELECTED:
                if (this.props.selectedAnnotId.length > 1) {
                    this.props.confirmDelete(true);
                } else {
                    this.props.deleteAnnotDraw(KeyCodes.DELETE);
                }
                break;
            case ContextMenuConstants.DELETE_ALL:
                this.props.deleteAllAnnotation();
                break;
            case ContextMenuConstants.CREATE_LINE_ALERT:
                {
                    // update ri panel previews and ettings for end extend and alert checkboxes
                    // let { endArrowChecked, lineX2, endPolygonPoints, endExtendChecked } = this.props.lineModelInfo
                    // if (!endExtendChecked) {
                    //     endExtendChecked = !endExtendChecked;
                    //     lineX2 = 266;
                    //     if (endArrowChecked) {
                    //         endPolygonPoints = '254,35 254,25 266,30';
                    //     }
                    //     this.props.onEndExtendChange(endExtendChecked, lineX2, endPolygonPoints);
                    // }

                    this.props.addLineAlert();
                }
                break;
            case ContextMenuConstants.REMOVE_LINE_ALERT:
                this.props.removeLineAlert();
                break;
            case ContextMenuConstants.MOVE_TO_FRONT:
                this.props.moveAnnotToFront();
                break;
            case ContextMenuConstants.MOVE_TO_BACK:
                this.props.moveAnnotToBack();
                break;
            default:
                break

        }
    }
    handleClick = (e) => {
        e.evt.stopPropagation();
        if (!this.props.isApiReqResolved) {
            return;
        }

        this.props.updateStageClickCapturedFlag(true);
        if (e.evt.ctrlKey && e.target.attrs.shape) {
            this.props.handleCtrlClick(e);
        } else {
            if ((e.evt.button === 2 && e.evt.ctrlKey) || e.evt.button === 0) { // The value is coming 2, the earlier cond' was 0(which comes normally), hence not drawing
                if (AnnotationUtil.isCtrlKeyMode) {
                    e.currentTarget.getStage().container().style.cursor = 'crosshair';
                }
                this.props.handleClick(e);
            }
        }
        TabDataGraphActionClass.hideOtherMenu();
    }

    changeMouseOnHover = (e) => {

        if (e.currentTarget.targetShape && e.currentTarget.targetShape.attrs.id === 'anchor') {
            if (e.currentTarget.targetShape.attrs.type === RayAnnotationType.TEXT_AnnotationType) {
                e.currentTarget.getStage().container().style.cursor = 'ew-resize';
            }
            else if (e.currentTarget.targetShape.attrs.type === RayAnnotationType.RECT_AnnotationType || e.currentTarget.targetShape.attrs.type === RayAnnotationType.ELLIPSE_AnnotationType || (e.currentTarget.targetShape.attrs.type === RayAnnotationType.TRIANGLE_AnnotationType && (e.currentTarget.targetShape.attrs.direction === 'NW' || e.currentTarget.targetShape.attrs.direction === 'SE'))) {
                if (e.currentTarget.targetShape.attrs.className === 'top-right' || e.currentTarget.targetShape.attrs.className === 'bottom-left') {
                    e.currentTarget.getStage().container().style.cursor = 'nesw-resize';
                }
                else if (e.currentTarget.targetShape.attrs.className === 'top-left' || e.currentTarget.targetShape.attrs.className === 'bottom-right') {
                    e.currentTarget.getStage().container().style.cursor = 'nwse-resize';
                }
                else if (e.currentTarget.targetShape.attrs.className === 'top-middle' || e.currentTarget.targetShape.attrs.className === 'bottom-middle') {
                    e.currentTarget.getStage().container().style.cursor = 'ns-resize';
                }
                else {
                    e.currentTarget.getStage().container().style.cursor = 'ew-resize';
                }
            }
            else if (e.currentTarget.targetShape.attrs.type === RayAnnotationType.TRIANGLE_AnnotationType && (e.currentTarget.targetShape.attrs.direction === 'NE' || e.currentTarget.targetShape.attrs.direction === 'SW')) {
                if (e.currentTarget.targetShape.attrs.className === 'top-right' || e.currentTarget.targetShape.attrs.className === 'bottom-left') {
                    e.currentTarget.getStage().container().style.cursor = 'nwse-resize';
                }
                else if (e.currentTarget.targetShape.attrs.className === 'top-left' || e.currentTarget.targetShape.attrs.className === 'bottom-right') {
                    e.currentTarget.getStage().container().style.cursor = 'nesw-resize';
                }
                else if (e.currentTarget.targetShape.attrs.className === 'top-middle' || e.currentTarget.targetShape.attrs.className === 'bottom-middle') {
                    e.currentTarget.getStage().container().style.cursor = 'ns-resize';
                }
                else {
                    e.currentTarget.getStage().container().style.cursor = 'ew-resize';
                }
            }
            else {
                e.currentTarget.getStage().container().style.cursor = 'all-scroll';
            }
        }
        else {
            e.currentTarget.getStage().container().style.cursor = 'pointer';

        }
    }
    checkInsideBoundary(e) {
        if (e.currentTarget.clickEndShape && e.currentTarget.clickEndShape.attrs.shape === RayAnnotationType.ELLIPSE_AnnotationType &&
            e.evt.layerX > e.currentTarget.clickEndShape.attrs.x && e.evt.layerX < e.currentTarget.clickEndShape.attrs.x + Math.abs(e.currentTarget.clickEndShape.attrs.width)
            && e.evt.layerY > e.currentTarget.clickEndShape.attrs.y && e.evt.layerY < e.currentTarget.clickEndShape.attrs.y + Math.abs(e.currentTarget.clickEndShape.attrs.height)) {
            return true;
        } else if (e.currentTarget.clickEndShape && e.currentTarget.clickEndShape.attrs.shape === RayAnnotationType.TRIANGLE_AnnotationType) {
            switch (e.currentTarget.clickEndShape.attrs.direction) {
                case 'SE':
                    return (e.evt.layerX > e.currentTarget.clickEndShape.attrs.x && e.evt.layerX < e.currentTarget.clickEndShape.attrs.x + Math.abs(e.currentTarget.clickEndShape.attrs.width)
                        && e.evt.layerY > e.currentTarget.clickEndShape.attrs.y && e.evt.layerY < e.currentTarget.clickEndShape.attrs.y + Math.abs(e.currentTarget.clickEndShape.attrs.height)) ? true : false;

                case 'SW':
                    return (e.evt.layerX > e.currentTarget.clickEndShape.attrs.x - Math.abs(e.currentTarget.clickEndShape.attrs.width) && e.evt.layerX < e.currentTarget.clickEndShape.attrs.x
                        && e.evt.layerY > e.currentTarget.clickEndShape.attrs.y && e.evt.layerY < e.currentTarget.clickEndShape.attrs.y + Math.abs(e.currentTarget.clickEndShape.attrs.height)) ? true : false;

                case 'NW':
                    return (e.evt.layerX > e.currentTarget.clickEndShape.attrs.x - Math.abs(e.currentTarget.clickEndShape.attrs.width) && e.evt.layerX < e.currentTarget.clickEndShape.attrs.x
                        && e.evt.layerY > e.currentTarget.clickEndShape.attrs.y - Math.abs(e.currentTarget.clickEndShape.attrs.height) && e.evt.layerY < e.currentTarget.clickEndShape.attrs.y) ? true : false;

                case 'NE':
                    return (e.evt.layerX > e.currentTarget.clickEndShape.attrs.x && e.evt.layerX < e.currentTarget.clickEndShape.attrs.x + Math.abs(e.currentTarget.clickEndShape.attrs.width)
                        && e.evt.layerY > e.currentTarget.clickEndShape.attrs.y - Math.abs(e.currentTarget.clickEndShape.attrs.height) && e.evt.layerY < e.currentTarget.clickEndShape.attrs.y) ? true : false;

                default: break;
            }
        } else {
            return false;
        }
    }
    handleMouseMove = (e) => {
        if (e.currentTarget && e.currentTarget.getStage() && e.currentTarget.getStage().container()) {
            if (AnnotationUtil.isAnnotationsTabActive() && this.props.tempToolType !== 0) {
                if (e.currentTarget.targetShape !== undefined && e.currentTarget.targetShape !== null && !this.props.isDrawing) {
                    this.changeMouseOnHover(e);
                }
                else {
                    e.currentTarget.getStage().container().style.cursor = 'crosshair';
                }
            }
            else if (!AnnotationUtil.isCtrlKeyMode && (!AnnotationUtil.isAnnotationsTabActive() || this.props.tempToolType === 0) && e.currentTarget.targetShape === null) {
                if (this.checkInsideBoundary(e)) {
                    return;
                }
                else {
                    const annotationDiv = document.getElementsByClassName('annotation-graphics')[0];
                    if (!StringUtil.isEmpty(annotationDiv)) {
                        if (!annotationDiv.classList.contains("selected-selectiontool")) {
                            annotationDiv.className += " selected-selectiontool";
                            annotationDiv.style.zIndex = "";
                        }
                        // this.props.clearSelectedAnnotation();
                    }
                }
            }
            else {
                if (e.currentTarget.targetShape !== undefined && e.currentTarget.targetShape !== null && !this.props.isDrawing) {
                    this.changeMouseOnHover(e);
                }
                else if (AnnotationUtil.isCtrlKeyMode) {
                    e.currentTarget.getStage().container().style.cursor = 'crosshair';
                }
                else {
                    e.currentTarget.getStage().container().style.cursor = 'default';
                }
            }
        }

        if (this.props.isDrawing && !document.body.classList.contains('symbol-entry-focused')) {
            this.props.handleMouseMove(e);
        }
    }
    handleReWrite = (e, isSave = false) => {
        this.props.handleReWrite(e, isSave);
    }

    onContextClick = (e, shape) => {
        this.props.closeOtherContextMenu();
        // Restrict user to perform context menu options on other shapes, immediately after creating new shape.
        // which will impact on newly created shape instead of the one which was selected for applying the action.
        if (!this.props.isApiReqResolved) {
            return;
        }

        this.props.updateStageClickCapturedFlag(true);
        if (e.evt.which === 3) {
            // const convaTargt = e.target.getStage().getPointerPosition();
            // let clientX = convaTargt.x;
            // let clientY = convaTargt.y;
            const clientX = e.evt.clientX;
            const clientY = e.evt.clientY;
            const { selectedAnnotId } = this.props;
            if (selectedAnnotId.length > 0) {
                if (!selectedAnnotId.includes(e.target.id())) {
                    this.props.clearSelectedAnnotation();
                    this.props.updateSelectedAnnotationId(e.target.id());
                }
            } else {
                this.props.clearSelectedAnnotation();
                this.props.updateSelectedAnnotationId(e.target.id());
            }
            this.props.toggleContextMenu(true, clientX, clientY, shape);
        }
        e.evt.stopPropagation();
        e.evt.preventDefault();
        return false;
    }
    closeContextMenu() {
        this.props.toggleContextMenu(false, 0, 0, '');
    }

    render() {

        const { width, height, showContextMenu, annotSelected, contextMenuX, contextMenuY, contextMenu, selectedAnnotId, orderedLayersList,
            textReducer, isApiReqResolved, groupedAnnots } = this.props;
        TimeTrackingWindow.trackChartLoadingTime();

        return (
            <React.Fragment>
                {
                    annotSelected && annotSelected.isEditable &&
                    <PortalContainer>
                        {
                            // textReducer.map( (text, idx) => {
                            //     if( annotSelected.id === text.id && idx > arr.length - 1){
                            //         arr.push({
                            //             color: 'red',
                            //             backgroundColor: 'white'
                            //         })
                            //     }else if(annotSelected.id === text.id && idx <= arr.length - 1){
                            //         arr[idx].color = 'blue';
                            //         arr[idx].backgroundColor = 'white'
                            //     }
                            // })

                            // textReducer.map((text, idx) => {
                            //     if (annotSelected.id === text.id && idx <= arr.length - 1) {
                            //         arr[idx].color = this.props.textModelInfo.color;
                            //         arr[idx].backgroundColor = this.props.textModelInfo.bgColor;
                            //         }
                            // })
                        }
                        {
                            textReducer.map((text, idx) => (
                                text.isEditable && !text.isDeleted && <div style={{ position: 'absolute', left: text.txtX, top: text.txtY, width: text.width, height: text.height }}>
                                    <EditableTextInput handleRename={this.handleReWrite} text={text} />
                                </div>
                            ))
                        }

                    </PortalContainer>
                }

                {showContextMenu &&
                    <PortalContainer>
                        <DGContextMenu contextMenu={contextMenu}
                            contextMenuXPos={contextMenuX}
                            contextMenuYPos={contextMenuY}
                            closeContextMenu={() => this.closeContextMenu()}
                            onContextMenuClick={(item) => this.onContextMenuClick(item)}
                        />
                    </PortalContainer>}

                {width !== 0 && height !== 0 && <Stage width={width} height={height}
                    onMouseDown={this.handleClick}
                    onContentMouseMove={this.handleMouseMove}
                    onContextMenu={(e) => e.evt.preventDefault()}
                >
                    <Provider store={store}>
                        {orderedLayersList && orderedLayersList.map((layer, index) =>
                            layer.isVisible && groupedAnnots.hasOwnProperty(layer.layerID) &&
                            <Layer
                                key={index}
                                id={layer.layerID}
                                name={layer.layerName}
                                visible={layer.isVisible}
                            >
                                <AnnotationLayers layer={layer}
                                    onContextMenu={this.onContextClick}
                                    selectedAnnotId={selectedAnnotId}
                                    draggable={layer.shareAccess !== ShareAccessType.SHARE_READONLY}
                                    isApiReqResolved={isApiReqResolved}
                                    groupedAnnots={groupedAnnots} />
                            </Layer>
                        )}
                    </Provider>
                </Stage>}

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const groupedAnnots = getAnnotations(state);
    const { annotationReducers } = state;
    const { annotSelected, isDrawing, showContextMenu, contextMenuX, contextMenuY, contextMenu,
        selectedAnnotId, isApiReqResolved, activeAnchor, isDragging } = annotationReducers.annotationChartModelReducer;
    const { isAlertChecked, selectedToolType, tempToolType } = annotationReducers.annotationMenuReducer;
    const { LayersList, modifiedLayers, isLayerOrderChange } = annotationReducers.annotationLayerManagementReducer;
    const { textReducer } = annotationReducers;

    const textModelInfo = annotationReducers.annotationMenuReducer.TextModelInfo;
    const lineModelInfo = annotationReducers.annotationMenuReducer.LineModelInfo;
    let orderedLayersList = LayersList;

    const { summaryReducer } = state
    // if (selectedToolType == 0) {
    //     orderedLayersList = JSON.parse(JSON.stringify(LayersList)).reverse();
    // }
    if (isLayerOrderChange) {
        orderedLayersList = modifiedLayers;
    }

    return ({
        groupedAnnots, annotSelected, isDrawing, isAlertChecked, selectedToolType, tempToolType, showContextMenu, contextMenuX, contextMenuY, contextMenu, selectedAnnotId,
        orderedLayersList, textReducer, textModelInfo, lineModelInfo, isApiReqResolved, activeAnchor, isDragging, summaryReducer
    });
}

const mapDispatchToProps = (dispatch) => ({
    handleClick: (e) => dispatch(handleClick(e)),
    handleMouseMove: (e) => dispatch(handleMouseMove(e)),
    handleReWrite: (e, isSave) => dispatch(handleReWrite(e, isSave)),
    // addAlert: (e) => dispatch(addAlert(e)),
    toggleContextMenu: (showContext, clientX, clientY, shape) => dispatch(handleToggleContextMenu(showContext, clientX, clientY, shape)),
    deleteAnnotDraw: (charCode) => dispatch(handleDeleteAnnotationDrawing(charCode)),
    deleteAllAnnotation: () => dispatch(handleDeleteAllAnnotation()),
    handleCtrlClick: (e) => dispatch(handleCtrlClick(e)),
    confirmDelete: (showConfirm) => dispatch(handleDeleteConfirmPopup(showConfirm)),
    onEndExtendChange: (endExtendChecked, lineX2, endPolygonPoints) => dispatch(onEndExtendChange(endExtendChecked, lineX2, endPolygonPoints)),
    onCheckBoxChange: (event) => dispatch(onCheckBoxChange(event)),
    addLineAlert: () => dispatch(handleAddLineAlert()),
    removeLineAlert: () => dispatch(handleRemoveLineAlert()),
    moveAnnotToFront: () => dispatch(moveFront()),
    moveAnnotToBack: () => dispatch(moveBack()),
    handleEscClick: () => dispatch(handleEscClick()),
    onAnnotationToolChange: (selectedToolType, isCtrl = false) => dispatch(onAnnotationToolChange(selectedToolType, isCtrl)),
    updateRiPanelInfoFromUserSettings: (isCtrl = false) => dispatch(updateRiPanelInfoFromUserSettings(isCtrl)),
    updateSelectedAnnotationId: (id) => dispatch(updateSelectedAnnotationId(id)),
    clearSelectedAnnotation: () => dispatch(clearSelectedAnnotation()),
    updateStageClickCapturedFlag: (isStageClickCaptured) => dispatch(updateStageClickCapturedFlag(isStageClickCaptured))
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationStage);