import { call, put, takeLatest, select } from "redux-saga/effects";
import moment from "moment"

import { AINewsConstants, AINewsType, TREND } from "../../../Constants/AINewsConstants";
import aiNewsApi from "../../../ServiceApi/Apis/AINewsApi";
import { getDatagraphStates } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import periodicityHelper from "../../../Utils/PeriodicityHelper";
import { symbolSelect } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors";

const {
    ActionTypes
} = AINewsConstants;

function* getCompetitiveEdgeData({ symbol }) {
    try {
        const response = yield call(aiNewsApi.getCompetitiveEdgeRequest, symbol)

        if (response.data) {
            yield put({
                type: ActionTypes.GET_COMPETITIVE_EDGE_SUCCESS,
                data: response.data,
                message: ''
            });
        }
        else {
            yield put({
                type: ActionTypes.GET_COMPETITIVE_EDGE_FAILURE,
                data: '',
                message: 'Data is Not Available'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_COMPETITIVE_EDGE_FAILURE,
            data: '',
            message: 'Something went wrong while fetching the data. Please try again after some time.'
        });
        
        console.log(`%cError occurs in AINewsSaga.js, getCompetitiveEdgeData ${error}`, 'color:#508F4E;background-color:#ffff00');
    }
}

function* getZigzagTrendData({ startDate, endDate, trendType }) {
    try {
        const symbol = yield select(symbolSelect);
        const formattedstartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedendDate = moment(endDate).format('YYYY-MM-DD');
        const response = yield call(aiNewsApi.getZigzagTrendDataRequest, symbol, formattedstartDate, formattedendDate, trendType)

        if (response.data) {
            yield put({
                type: ActionTypes.GET_ZIGZAG_TREND_DATA_SUCCESS,
                data: response.data,
                message: ''
            });
        }
        else {
            yield put({
                type: ActionTypes.GET_ZIGZAG_TREND_DATA_FAILURE,
                data: '',
                message: 'Data is Not Available'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_ZIGZAG_TREND_DATA_FAILURE,
            data: '',
            message: 'Something went wrong while fetching the data. Please try again after some time.'
        });
        
        console.log(`%cError occures in AINewsSaga.js, getCompetitiveEdgeData ${error}`);
    }
}

function* setAITextReactions({ reaction, text, newsType, selectedSegment }) {
    try {
        const { periodicity, SymbolInfo } = yield select(getDatagraphStates)
        let payload = { reaction, text, newsType, OSID: SymbolInfo.Osid, periodicity: periodicityHelper.convertToPeriodicity(periodicity) }
        switch (newsType) {
            case AINewsType.TREND_NEWS: {
                const formattedstartDate = moment(selectedSegment.startDate).format('YYYY-MM-DD');
                const formattedendDate = moment(selectedSegment.endDate).format('YYYY-MM-DD');
                payload.startDate = formattedstartDate;
                payload.endDate = formattedendDate;
                payload.trend = selectedSegment.upTrend ? TREND.UP_TREND : TREND.DOWN_TREND;
                break;
            }
            case AINewsType.COMPETITIVE_NEWS: {
                payload.startDate = null;
                payload.endDate = null;
                payload.trend = null;
                break;
            }
            default: 
        }
        yield call(aiNewsApi.aiTextFeedbackRequest, payload)
    } catch (error) { 
        console.log(`%cError occures in AINewsSaga.js, setAITextReactions ${error}`);
    }
}

export function* watchCompetitiveEdge() {
    yield takeLatest(ActionTypes.GET_COMPETITIVE_EDGE_REQUEST, getCompetitiveEdgeData)
}

export function* watchSetAITextReactions() {
    yield takeLatest(ActionTypes.SET_AI_TEXT_REACTION, setAITextReactions)
}

export function* watchZigzagTrendData() {
    yield takeLatest(ActionTypes.GET_ZIGZAG_TREND_DATA_REQUEST, getZigzagTrendData)
}