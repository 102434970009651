import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import SettingsStore from "SettingsStore";
import { SettingsConstants } from "../../../Constants/SettingsConstants.js";
import { TabDataGraphConstants } from "../../../Constants/TabDataGraphConstants.js";
import DataGraphSettingHelper from "../../ConsoleWindow/Settings/Modules/DataGraph/DataGraphSettingHelper.js";
import SmartViewType from "../../../Constants/SmartViewType";
import ChartApi from "../../../ServiceApi/Apis/ChartApi.js";
import SymbolType from "../../../Constants/SymbolType.js";
import TabType from "../../../Constants/TabType.js";
import PeriodicityHelper from "../../../Utils/PeriodicityHelper.js";
import GraphType from "GraphType";
import DateHelper from "DateHelper";
import StockMarketUtil from "../../../Utils/StockMarketUtil";
import AlertStore from "../../../Stores/Alerts/AlertStore.js";
import ConsoleStore from "../../../Stores/ConsoleWindow/ConsoleStore";
import { OnClearKPIData } from '../../../Actions/KpiGraphActions';
import { toggleDgRiPanel } from '../../../Actions/AnnotationActions';
import AdobeUtil from "../../../Utils/AdobeUtil";
import AdobeConstants from "../../../Constants/AdobeConstants";
import RiSubCategoryType from "../../../Constants/RiSubCategoryType.js"
import BrowserUtil from "../../../Utils/BrowserUtil.js";
//import MiniListStore from "MiniListStore";
import MiniListHelper from "MiniListHelper";
import BaseServiceApi from "BaseServiceApi";
import GraphApi from "../../../ServiceApi/Apis/GraphApi.js";
import BaseServiceApiWCF from "BaseServiceApiWCF";
import { dispatch } from "../../../Redux/dispatch.js";
import TimeTrackingWindow from "../../../RayCustomControls/TimeTrackingWindow.js";
import NavType from "../../../Constants/NavType.js";
import UserInfoUtil from "../../../Utils/UserInfoUtil.js";
import store from "../../../Redux/store.js";
import StringUtil from "../../../Utils/StringUtil.js";
import { setIsCurrencyConversionChecked } from "../../../Actions/CurrencyActions.js";
import SettingsActions from "../../../Actions/SettingsActions.js";
import { updateSymbolEntryFlag } from "../../../Actions/DatagraphActions.js";
import PerformanceWindowStore from "./PerformanceWindowStore.js";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
var IntradaySourceType = BaseServiceApi.rayData.IntradaySourceType;
const CHANGE_EVENT = "change";
const CHART_RESIZE_EVENT = "CHART_RESIZE_EVENT";

//let _consoleSettings = {};
let _state = {
  TabCollection: [],
  isInitial: true,
  SelectedTabKey: undefined,
  SymbolType: SmartViewType.STOCK,
  Symbol: "",
  isOpen: false,
  isSymbolConvertedDialogOpen: false,
  isSymbolConvertedDialogAvailable: false,
  finTargetCurrencyCode: 'USD',
  isCurrencyConversion: false,
  isToolTip: false,
  processJPEG: false,
  VolPct: "",
  volumeRateFundamentals : "",
  isReceivedQuote: false,
  isHistoric: false
};
let _currentAction = null;

let contextMenuHit = null;

class DatagraphStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    this.recentSymbols = [];
    this.stockPriceAndInfoResult = {};
  }

  addChangeListener(callback, event = CHANGE_EVENT) {
    this.on(event, callback);
  }

  removeChangeListener(callback, event = CHANGE_EVENT) {
    this.removeListener(event, callback);
  }

  setState() {

  }
  getSymbolInfo() {
    return _state.SymbolInfo;
  }

  getFinTargetCurrencyCode() {
    return _state.finTargetCurrencyCode;
  }

  getCurrencyConversionFlag() {
    return _state.isCurrencyConversion;
  }

  getCurrentAction() {
    return _currentAction;
  }

  clearCurrentAction() {
    _currentAction = null;
  }

  getHistoricState() {
      return _state.isHistoric;
  }

  setHistoricState(state) {
      _state.isHistoric = state;
  }

  getTooltipState() {
      return _state.isToolTip;
  }
  setTooltipState(state) {
      //setTimeout(() => {
          if (!_state.isToolTip)
              _state.isToolTip = state;
          if (!state)
              _state.isToolTip = state;
          //},
          //0);
  }

  setChartContextHit(val) {
    if (val == null || val.lineID)
      contextMenuHit = val;
    else if (!(!val.lineID && contextMenuHit && contextMenuHit.lineID && (contextMenuHit.lineID.indexOf(" SMA") > 0 || contextMenuHit.lineID.indexOf(" EMA") > 0)))
      contextMenuHit = val;
  }

  getChartContexHit() {
    setTimeout(() => {
      contextMenuHit = null;
    }, 1)
    return contextMenuHit;
  }

  setSymbolPriceData(result) {
    _state.priceData = { ...result };
    if(!_state.symbolPriceData){
      _state.symbolPriceData = { ...result };

      let currency = "USD";
      if (_state.SymbolInfo.DefaultCurrency)
            currency = _state.SymbolInfo.DefaultCurrency.CurrencyCode ? _state.SymbolInfo.DefaultCurrency.CurrencyCode : _state.SymbolInfo.DefaultCurrency.Code;
      if (currency === "GBP" || currency === "ZAR") {
        _state.symbolPriceData.PPrice *= 100;
        _state.symbolPriceData.CPrice *= 100;
      }
      _state.action = SettingsConstants.ActionTypes.GET_SYMBOL_PRICE_DATA;
      this.emit(CHANGE_EVENT);
      _state.action = null;
    }
  }

  getSymbolPriceData() {
    return _state.priceData;
  }

  getIsCryptoCurrencySymbol() {
    return _state && _state.SymbolInfo && _state.SymbolInfo.SymTypeEnum === SymbolType.CRYPTOCURRENCY;
  }

  getNasdaqONStatus(){
    const selectQuoteServiceOption = SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption;
    const isNasdaqOn = selectQuoteServiceOption === IntradaySourceType.IntradaySource_NASDAQ ? true : false;
    return isNasdaqOn;
  }

  getPeriodicityValue(){
   return PeriodicityHelper.getPeriodicityValue(PeriodicityHelper.mapMajorPeriodicities(this.getNavDataGraphSettings().TabDataGraphSettings.Periodicity));
  }

  setStateOnPeriodicityChanged() {
    _state.pricePercentage = undefined;
    if (_state.SymbolInfo) {
      const navDataGraphSetting = this.getNavDataGraphSettings();
      const selectedTab = navDataGraphSetting.SelectedTabSettings[_state.SymbolType];
      const selectQuoteServiceOption = SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption;
      const isNasdaqOn = selectQuoteServiceOption === IntradaySourceType.IntradaySource_NASDAQ ? 1 : 0;

      if ((selectedTab === TabType.Fundamental || selectedTab === TabType.Ownership) && (isNasdaqOn && _state.isReceivedQuote)) {
        let periodicity = PeriodicityHelper.mapMajorPeriodicities(navDataGraphSetting.TabDataGraphSettings.Periodicity);

        if (periodicity === GraphType.Intraday) { // || selectedTab === TabType.Fundamental) {
          periodicity = GraphType.Daily;
        }
        else {
          const lastTradeDate = _state.SymbolInfo.LastTradeDate.getFullYear ? _state.SymbolInfo.LastTradeDate : DateHelper.parseJsonDate(_state.SymbolInfo.LastTradeDate, _state.SymbolInfo.MomentZoneID);
          const endOfMonth = StockMarketUtil.GetMEndDate(lastTradeDate);
          const endOfQuarter = StockMarketUtil.GetQEndDate(lastTradeDate);
          const endOfYear = StockMarketUtil.GetAEndDate(lastTradeDate);

          if ((periodicity === GraphType.Weekly && lastTradeDate.getDay() === 1) || lastTradeDate === endOfMonth || lastTradeDate === endOfQuarter || lastTradeDate === endOfYear)
            periodicity = GraphType.Daily;
        }

        const osid = type === 8 ? _state.SymbolInfo.Osid - 40000000 : _state.SymbolInfo.Osid;
        const type = _state.SymbolInfo.SymTypeEnum;
        const IPODate = DateHelper.parseJsonDate(_state.SymbolInfo.IPODate, _state.SymbolInfo.MomentZoneID);
        const userDate = ConsoleStore.getUserEndDate();
        const data = {
          Symbol: _state.SymbolInfo.Symbol,
          Periodicity: periodicity,
          beginDate: IPODate,
          endDate: userDate
        }

        let isHistoric = true;
        let isStreaming = false;
        if (isNasdaqOn && _state.isReceivedQuote) {
          isHistoric = false;
          isStreaming = true;
          data.endDate = DateHelper.parseJsonDate(_state.SymbolInfo.LastTradeDate, _state.SymbolInfo.MomentZoneID);
        }

        GraphApi.getVolumeChange(data, osid, isStreaming, isHistoric).then((result) => {
          this.updatePricePercentage(result.volumePriceData.priceChange, result.volumePriceData.pricePercentageChange);
          this.updateVolumeData(result.volumePriceData.volumeRate, true);
          this.volumeRateForFundamentals(result.volumePriceData.volumeRate);
        });
      }

      const symbol = _state.SymbolInfo.Symbol;
      const isMiniListPlay = _state.IsMiniListPlay;
      if (MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)) {
        this.emit(CHANGE_EVENT);
        _state.action = null;
      }
    }
  }
  isReceivedQuote(isStreaming){
    _state.isReceivedQuote = isStreaming;
  }
  updatePricePercentage(priceChange, pricePercentageChange){
    _state.priceChange = priceChange;
    _state.pricePercentage = pricePercentageChange;
  }
  updateVolumeData(volRate, isRTReceived = false, hasVolume = true) {
    _state.hasVolume = hasVolume;
    if (volRate !== undefined) {
      if(_state.VolPct !== volRate){
        _state.VolPct = volRate;
        _state.action = SettingsConstants.ActionTypes.GET_RT_PRICE_DATA;
        this.emit(CHANGE_EVENT);
      }
      if (isRTReceived) {
        _state.action = SettingsConstants.ActionTypes.GET_RT_PRICE_DATA;
      }
      //this.emit(CHANGE_EVENT);
      _state.action = null;
    }
  }
  volumeRateForFundamentals(volRate){
    _state.volumeRateFundamentals = volRate;

  }
  setStateOnSymbolChanged(symbolData) {
    const pricePanelData = symbolData.PricePanelDataResp;
    const symbolInfo = symbolData.SymbolInfo;
    const symbolType = symbolInfo.SymTypeEnum;
    let navDataGraphSettings = this.getNavDataGraphSettings();
    switch (symbolType) {
      case SymbolType.USSTOCK:
        _state = this.getStateFromStockType(navDataGraphSettings, symbolInfo);
        break;
      case SymbolType.CURRENCY:
        _state = this.getStateFromCurrencyType(navDataGraphSettings, symbolInfo);
        break;
      case SymbolType.CRYPTOCURRENCY:
        _state = this.getStateFromCryptoCurrencyType(navDataGraphSettings, symbolInfo);
        break;
      case SymbolType.ETF:
        _state = this.getStateFromETFType(navDataGraphSettings, symbolInfo);
        break;
      case SymbolType.FundClosedEnd:
        _state = this.getStateFromCEFType(navDataGraphSettings, symbolInfo);
        break;
      case SymbolType.FUND:
        _state = this.getStateFromFUNDType(navDataGraphSettings, symbolInfo);
        break;
      case SymbolType.INDEXNOINTRADAYVOLDATA:
      case SymbolType.ECONOMICINDICATOR:
      case SymbolType.INDEXNOINTRADAYDATA:
      case SymbolType.INDEXNOINTRADAYVOL:
      case SymbolType.INTERNATIONALINDEX:
      case SymbolType.HISTORICINDEX:
      case SymbolType.INDEX:
        _state = this.getStateFromIndexType(this.getNavDataGraphSettings(), symbolInfo);
        break;
      case SymbolType.FUTURECONTRACTS:
        _state = this.getStateFromFuturesType(this.getNavDataGraphSettings(),symbolInfo);
        break;
      case SymbolType.CASHSPOTS:
        _state = this.getStateFromCashSpotsType(this.getNavDataGraphSettings(), symbolInfo);
        break; 
      case SymbolType.WONINDUSTRYGROUP197:
      case SymbolType.WONMAJORINDUSTRY89:
      case SymbolType.WONSECTOR11:
      case SymbolType.WONSUBSECTOR33:
        _state = this.getStateFromINDUSTRYGROUPType(navDataGraphSettings, symbolInfo);
        break;
      case SymbolType.FUTURECONTRACTS:
        _state = this.getStateFromFuturesType(this.getNavDataGraphSettings(),symbolInfo);
        break;
      case SymbolType.CASHSPOTS:
        _state = this.getStateFromCashSpotsType(this.getNavDataGraphSettings(), symbolInfo);
        break;
      default:
        _state = this.getStateFromStockType(navDataGraphSettings, symbolInfo);
        break;

    }
    TimeTrackingWindow.isSymbolChanged = true;
    this.processBlockOutItems(pricePanelData.PricePanelData.blackOutDatesDict.BlackOutDates);
    this.processEquityEvents(pricePanelData.PricePanelData.EquityEvents.eventDateItems)
    _state.isMiniListOpen = navDataGraphSettings.isMiniListOpen;
    //_state.showOwnershipTab = navDataGraphSettings.showOwnershipTab;
    _state.recentSymbols = this.recentSymbols;
    _state.finTargetCurrencyCode = 'USD';
    _state.isCurrencyConversion = false;
    _state.processJPEG = false;
    _state.isSymbolConvertedDialogAvailable = navDataGraphSettings.isSymbolConvertedDialogAvailable;
    _state.isInitial = symbolInfo.IsMiniListPlay === undefined ? false: symbolInfo.IsMiniListPlay;
    // if(!symbolInfo.IsMiniListPlay){
    //   this.updateDatagraphURL(symbolInfo.Symbol);
    // }
  }
  //processHSFResultsDates(hsfResults) {
  //    return hsfResults.map((hsfitem) => {
  //        hsfitem.Date = DateHelper.returnPacificServerTime(hsfitem.DateTicks);
  //        return hsfitem;
  //    });
  //}
  //processHsfDataDates(hsfData) {
  //    hsfData.StartDate = DateHelper.returnPacificServerTime(hsfData.StartDateTicks);
  //    hsfData.EndDate = DateHelper.returnPacificServerTime(hsfData.EndDateTicks);
  //    hsfData.TrueStartDate = DateHelper.returnPacificServerTime(hsfData.TrueStartDateTicks);
  //    hsfData.CreateTime = DateHelper.returnPacificServerTime(hsfData.CreateTimeTicks);
  //    hsfData.TrueEndDate = DateHelper.returnPacificServerTime(hsfData.TrueEndDateTicks);
  //    hsfData.ServerDate = DateHelper.returnPacificServerTime(hsfData.ServerDateTicks);
  //    hsfData.LastUpdateTime = DateHelper.returnPacificServerTime(hsfData.LastUpdateTimeTicks);
  //}
  //processStockInfoDates(stockInfo) {
  //    stockInfo.LastTradeDate = DateHelper.returnPacificServerTime(stockInfo.LastTradeDateTicks);
  //    stockInfo.ExchangeOpenTime = DateHelper.returnPacificServerTime(stockInfo.ExchangeOpenTimeTicks);
  //    stockInfo.ExchangeCloseTime = DateHelper.returnPacificServerTime(stockInfo.ExchangeCloseTimeTicks);
  //    stockInfo.CreateTime = DateHelper.returnPacificServerTime(stockInfo.CreateTimeTicks);
  //    stockInfo.ScrollToDate = DateHelper.returnPacificServerTime(stockInfo.ScrollToDateTicks);
  //    stockInfo.IPODate = DateHelper.returnPacificServerTime(stockInfo.IPODateTicks);
  //    stockInfo.LastUpdateTime = DateHelper.returnPacificServerTime(stockInfo.LastUpdateTimeTicks);
  //    stockInfo.Earnrptdate = DateHelper.returnPacificServerTime(stockInfo.EarnrptdateTicks);
  //    stockInfo.ExchangeTime = DateHelper.returnPacificServerTime(stockInfo.ExchangeTimeTicks);
  //    stockInfo.LastCompletedTradeDay = DateHelper.returnPacificServerTime(stockInfo.LastCompletedTradeDayTicks);
  //}
  //processCorpEventsDates(corpEvents) {
  //    return corpEvents.map((cpitem) => {
  //        cpitem.CorpActionDate = DateHelper.returnPacificServerTime(cpitem.CorpActionDateTicks);
  //        cpitem.GraphDate = DateHelper.returnPacificServerTime(cpitem.GraphDateTicks);
  //        return cpitem;
  //    });
  //}
  //processAnnualDates(annualDates) {
  //    return annualDates.map((aitem) => {
  //        aitem.FiscalDate = DateHelper.returnPacificServerTime(aitem.FiscalDateTicks);
  //        aitem.ConversionDate = DateHelper.returnPacificServerTime(aitem.ConversionDateTicks);
  //        return aitem;
  //    });
  //}
  //processWeekDates(weekDates) {
  //    return weekDates.map((witem) => {
  //        witem.WeekDate = DateHelper.returnPacificServerTime(witem.WeekDateTicks);
  //        return witem;
  //    });
  //}
  //processFinancialDates(financialDates) {
  //    financialDates.forEach((fitem) => {
  //        fitem.ReportDate = DateHelper.returnPacificServerTime(fitem.ReportDateTicks);
  //        fitem.FiscalDate = DateHelper.returnPacificServerTime(fitem.FiscalDateTicks);
  //        fitem.ConversionDate = DateHelper.returnPacificServerTime(fitem.ConversionDateTicks);
  //    });
  //}
  //processSplitItems(splitItems) {
  //    return splitItems.map((spitem) => {
  //        spitem.SplitEndDate = DateHelper.returnPacificServerTime(spitem.SplitEndDateTicks);
  //        spitem.SplitDate = DateHelper.returnPacificServerTime(spitem.SplitDateTicks);
  //        spitem.SplitCumlative = spitem.SplitCumlativeF;
  //        spitem.SplitRelatve = spitem.SplitRelatveF;
  //        spitem.SplitActual = spitem.SplitActualF;
  //        return spitem;
  //    });
  //}
  //processCloseResultDates(closeResults) {
  //    return closeResults.map((critem) => {
  //        critem.Date = DateHelper.returnPacificServerTime(critem.DateTicks);
  //        return critem;
  //    });
  //}
  processHSFResultsDates(hsfResults) {
    if (!hsfResults || hsfResults.length < 1) return null;
    //const pacificOffset = DateHelper.getPacificOffset(DateHelper.getTicks(hsfResults[0].Date));
    const navDataGraphSetting = this.getNavDataGraphSettings();
    const periodicity = PeriodicityHelper.mapMajorPeriodicities(navDataGraphSetting.TabDataGraphSettings.Periodicity);
    const momentZoneID = BaseServiceApiWCF.getMomentZoneIDFromServer(_state.SymbolInfo.CountryCode, periodicity);
    return hsfResults.map((hsfitem) => {
      hsfitem.Date = DateHelper.getUtcTicksfromTimeStamp(hsfitem.Date, momentZoneID);
      return hsfitem;
    });
  }
  processHsfDataDates(hsfData) {
    //let pacificOffset = DateHelper.getPacificOffset(DateHelper.getTicks(hsfData.EndDate));
    hsfData.StartDate = DateHelper.getUtcTicksfromTimeStamp(hsfData.StartDate);//, pacificOffset);
    hsfData.EndDate = DateHelper.getUtcTicksfromTimeStamp(hsfData.EndDate);//, pacificOffset);
    hsfData.TrueStartDate = DateHelper.getUtcTicksfromTimeStamp(hsfData.TrueStartDate);//, pacificOffset);
    hsfData.CreateTime = DateHelper.getUtcTicksfromTimeStamp(hsfData.CreateTime);//, pacificOffset);
    hsfData.TrueEndDate = DateHelper.getUtcTicksfromTimeStamp(hsfData.TrueEndDate);//, pacificOffset);
    hsfData.ServerDate = DateHelper.getUtcTicksfromTimeStamp(hsfData.ServerDate);//, pacificOffset);
    hsfData.LastUpdateTime = DateHelper.getUtcTicksfromTimeStamp(hsfData.LastUpdateTime);//, pacificOffset);
  }
  processStockInfoDates(stockInfo) {
    //let pacificOffset = DateHelper.getPacificOffset(DateHelper.getTicks(stockInfo.LastTradeDate));
    stockInfo.LastTradeDate = DateHelper.getUtcTicksfromTimeStamp(stockInfo.LastTradeDate);//, pacificOffset);
    stockInfo.ExchangeOpenTime = DateHelper.getUtcTicksfromTimeStamp(stockInfo.ExchangeOpenTime);//, pacificOffset);
    stockInfo.ExchangeCloseTime = DateHelper.getUtcTicksfromTimeStamp(stockInfo.ExchangeCloseTime);//, pacificOffset);
    stockInfo.CreateTime = DateHelper.getUtcTicksfromTimeStamp(stockInfo.CreateTime);//, pacificOffset);
    stockInfo.ScrollToDate = DateHelper.getUtcTicksfromTimeStamp(stockInfo.ScrollToDate);//, pacificOffset);
    stockInfo.IPODate = DateHelper.getUtcTicksfromTimeStamp(stockInfo.IPODate);//, pacificOffset);
    stockInfo.LastUpdateTime = DateHelper.getUtcTicksfromTimeStamp(stockInfo.LastUpdateTime);//, pacificOffset);
    stockInfo.Earnrptdate = DateHelper.getUtcTicksfromTimeStamp(stockInfo.Earnrptdate);//, pacificOffset);
    stockInfo.ExchangeTime = DateHelper.getUtcTicksfromTimeStamp(stockInfo.ExchangeTime);//, pacificOffset);
    stockInfo.LastCompletedTradeDay = DateHelper.getUtcTicksfromTimeStamp(stockInfo.LastCompletedTradeDay);//, pacificOffset);
  }
  processSymbolInfoDates(stockInfo) {
    stockInfo.LastTradeDate = DateHelper.getPSTFromLong(stockInfo.LastTradeDate);//, pacificOffset);
    stockInfo.ExchangeOpenTime = DateHelper.getPSTFromLong(stockInfo.ExchangeOpenTime);//, pacificOffset);
    stockInfo.ExchangeCloseTime = DateHelper.getPSTFromLong(stockInfo.ExchangeCloseTime);//, pacificOffset);
    stockInfo.IPODate = stockInfo.IPODate && DateHelper.getPSTFromLong(stockInfo.IPODate);//, pacificOffset);
    stockInfo.Earnrptdate = DateHelper.getPSTFromLong(stockInfo.Earnrptdate);//, pacificOffset);
  }
  processCorpEventsDates(corpEvents) {
    return corpEvents.map((cpitem) => {
      cpitem.CorpActionDate = DateHelper.getUtcTicksfromTimeStamp(cpitem.CorpActionDate);//, pacificOffset);
      cpitem.GraphDate = DateHelper.getUtcTicksfromTimeStamp(cpitem.GraphDate);//, pacificOffset);
      return cpitem;
    });
  }
  processAnnualDates(annualDates) {
    return annualDates.map((aitem) => {
      aitem.FiscalDate = DateHelper.getUtcTicksfromTimeStamp(aitem.FiscalDate);//, pacificOffset);
      aitem.ConversionDate = DateHelper.getUtcTicksfromTimeStamp(aitem.ConversionDate);//, pacificOffset);
      return aitem;
    });
  }
  processWeekDates(weekDates) {
    return weekDates.map((witem) => {
      witem.WeekDate = DateHelper.getUtcTicksfromTimeStamp(witem.WeekDate);//, pacificOffset);
      return witem;
    });
  }

  processFinancialDates(financialDates) {
    financialDates.forEach((fitem) => {
    fitem.ReportDate = DateHelper.getUtcTicksfromTimeStamp(fitem.ReportDate);//, pacificOffset);
    fitem.FiscalDate = DateHelper.getUtcTicksfromTimeStamp(fitem.FiscalDate);//, pacificOffset);
    fitem.ConversionDate = DateHelper.getUtcTicksfromTimeStamp(fitem.ConversionDate);//, pacificOffset);
    });
  }

  processEarningsAnimationDates(earningsAnimationDates) {
    earningsAnimationDates.forEach((aitem) => {
      aitem.SnapshotDate = aitem.snapshotDt !== null ? new Date(aitem.snapshotDt.toNumber()) : new Date(1970, 1, 1);
      aitem.graphComponent = "EPS";
      aitem.Results.forEach((epsitem) => {
        epsitem.ReportDate = epsitem.reportDt !== null ? new Date(epsitem.reportDt.toNumber()) : new Date(1970, 1, 1);
        epsitem.FiscalDate = epsitem.fiscalDt !== null ? new Date(epsitem.fiscalDt.toNumber()) : new Date(1970, 1, 1);
        epsitem.Eps = epsitem.eps;
        epsitem.EpsHigh = epsitem.epsHigh;
        epsitem.EpsLow = epsitem.epsLow;
        epsitem.Display = epsitem.display;
        epsitem.EpsSurprise = epsitem.epsSurprise;
        epsitem.EpsSuprisePctDiff = epsitem.epsSurprisePctDiff;       
      });      
    });
    return earningsAnimationDates;
  }
  processSplitItems(splitItems) {
    return splitItems.map((spitem) => {
      spitem.SplitEndDate = DateHelper.getUtcTicksfromTimeStamp(spitem.SplitEndDate);//, pacificOffset);
      spitem.SplitDate = DateHelper.getUtcTicksfromTimeStamp(spitem.SplitDate);//, pacificOffset);
      spitem.SplitCumlative = spitem.SplitCumlativeF;
      spitem.SplitRelatve = spitem.SplitRelatveF;
      spitem.SplitActual = spitem.SplitActualF;
      return spitem;
    });
  }
  processBlockOutItems(blockOutItems) {
    _state.blockOutDates = blockOutItems.map((critem) => {
      critem.Date = DateHelper.getUtcTicksfromTimeStamp(critem.Key);//, pacificOffset);
      return critem;
    });
  }
  processEquityEvents(equityEvents) {
    _state.equityEventDates = equityEvents.map((critem) => {
      critem.EventDate = DateHelper.getPSTFromLong(critem.EventDate);
      return critem;
    });
    this.emit(CHANGE_EVENT);
  }
  processCloseResultDates(closeResults) {
    return closeResults.map((critem) => {
      critem.Date = DateHelper.getUtcTicksfromTimeStamp(critem.Date);//, pacificOffset);
      return critem;
    });
  }

  updateLastPrice(lastPrice, prevPrice) {
    if (lastPrice && _state.symbolPriceData) {
      let priceData = _state.symbolPriceData;
      priceData.CPrice = lastPrice;
      if (prevPrice > 0)
        priceData.PPrice = prevPrice;
      _state.action = SettingsConstants.ActionTypes.GET_RT_PRICE_DATA;
      this.emit(CHANGE_EVENT);
      _state.action = null;
    }
  }

  onQuoteRecieved(quote) {
    let priceData = _state.symbolPriceData;
    if (!priceData || _state.Symbol !== quote.Symbol) {
      return;
    }
    //let pricePct = (quote.Last - priceData.PPrice) * 100 / priceData.PPrice;
    //   this.state.Volume = quote.Volume != null ? quote.Volume : 0;
    priceData.isReceivedFromQuote = true;
    if (quote.Last) {
      priceData.CPrice = quote.Last;
    }
    if (quote.VolRateDaily != null && quote.VolRateDaily != -101 && _state.SymbolInfo.SymTypeEnum === 1) {
      priceData.VolPct = quote.VolRateDaily;
    }
    if (quote.VolRateWeekly != null && quote.VolRateWeekly != -101 && _state.SymbolInfo.SymTypeEnum === 2) {
      priceData.VolPct = quote.VolRateWeekly;
    }

    _state.action = SettingsConstants.ActionTypes.GET_RT_PRICE_DATA;
    this.emit(CHANGE_EVENT);
    _state.action = null;

  }

  addToRecentList(symboInfo) {
    if (this.recentSymbols.length >= 100) {
      this.recentSymbols.splice(99, 1);
    }
    if (symboInfo.Symbol && symboInfo.CompanyName) {
      let exchangeCode = !symboInfo.exchange ? " " : symboInfo.exchange;
      let searchSymbol = symboInfo.Symbol.toUpperCase();
      let index = this.recentSymbols.map((item) => item.symbol).indexOf(searchSymbol);
      if (index >= 0) {
        this.recentSymbols.splice(index, 1);
      }
      this.recentSymbols.insert(0, { localSymbol: symboInfo.localSymbol, symbol: symboInfo.Symbol, companyName: symboInfo.CompanyName.trim(), exgCode: exchangeCode.trim() });
    }
  }
  updateIsMiniListopen(isMiniListOpen) {
    PerformanceWindowStore.setShouldUpdate(false);
    _state.isMiniListOpen = isMiniListOpen;
    let navDataGraphSettings = this.getNavDataGraphSettings();
    navDataGraphSettings.isMiniListOpen = isMiniListOpen;
    SettingsStore.saveSettings();
    _currentAction = TabDataGraphConstants.ActionTypes.UPDATE_MINILIST_TOGGLE_STATUS;
    this.emit(CHANGE_EVENT);
  }
  getState() {
    return _state;
  }

  stockTemplateUpdate() {
    return _state.SymbolInfo && (_state.SymbolInfo.SymTypeEnum === SymbolType.ETF ||
      _state.SymbolInfo.SymTypeEnum === SymbolType.FUND ||
      _state.SymbolInfo.SymTypeEnum === SymbolType.CURRENCY ||
      _state.SymbolInfo.SymTypeEnum === SymbolType.FundClosedEnd ||
      _state.SymbolInfo.SymTypeEnum === SymbolType.WONINDUSTRYGROUP197 ||
      _state.SymbolInfo.SymTypeEnum === SymbolType.WONMAJORINDUSTRY89 ||
      _state.SymbolInfo.SymTypeEnum === SymbolType.WONSECTOR11 ||
      _state.SymbolInfo.SymTypeEnum === SymbolType.WONSUBSECTOR33)
  }
  getStateFromStockType(setting, result) {
    let state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.STOCK;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }
  getStateFromCurrencyType(setting, result) {
    let state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.CURRENCY;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }
  getStateFromFuturesType(setting, result) {
    const state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.FUTURES;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }
  getStateFromCryptoCurrencyType(setting, result) {
    let state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.CRYPTOCURRENCY;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }
  getStateFromFuturesType(setting, result) {
    const state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.FUTURES;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }
  getStateFromCashSpotsType(setting, result) {
    const state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.CASHSPOTS;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }
  getStateFromETFType(setting, result) {
    let state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.ETF;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }
  getStateFromCEFType(setting, result) {
    let state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.CEF;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }
  getStateFromFUNDType(setting, result) {
    let state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.FUND;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }
  getStateFromIndexType(setting, result) {
    let state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.INDEX;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }

  getStateFromINDUSTRYGROUPType(setting, result) {
    let state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.INDUSTRYGROUP;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }

  getStateFromCashSpotsType(setting, result) {
    const state = {};
    this.setSymbolInfo(result, state);
    state.TabCollection = this.getTabCollection(setting, result);
    state.SymbolType = SmartViewType.CASHSPOTS;
    state.SelectedTabKey = this.getSelectedTabKey(setting, state.SymbolType,state.TabCollection);
    return state;
  }

  getTabType(tabkey) {
    if (tabkey === undefined) {
      return undefined;
    }
    var tabObject = _state.TabCollection.filter((tabItem) => {
      if (tabItem.Header === tabkey) {
        return true;
      }
      else {
        return false;
      }
    });

    return tabObject.length > 0 ? tabObject[0].TabType : null;

  }

  updateFlag(gdata) {
    if (gdata) {
        _state.action = "UPDATE_FLAG";
        _state.flag = gdata.isFlag !== undefined ? gdata?.isFlag : gdata?.data?.header?.flagged;
        dispatch(updateSymbolEntryFlag(_state.flag))
        this.emit(CHANGE_EVENT);
        _state.action = null;
    }
  }

  setFlag(flagStatus){
    _state.flag = flagStatus;
    dispatch(updateSymbolEntryFlag(_state.flag))
  }
  getTabCollection(setting, result) {
  let tabCollection = [];
    setting.TabCollection.forEach((tabItem) => {
      if (result.SymTypeEnum === SymbolType.FUND ||
        result.SymTypeEnum === SymbolType.CURRENCY ||
        result.SymTypeEnum === SymbolType.CRYPTOCURRENCY ||
        result.SymTypeEnum === SymbolType.ETF ||
        result.SymTypeEnum === SymbolType.FundClosedEnd ||
        result.SymTypeEnum === SymbolType.INDEXNOINTRADAYVOLDATA||
        result.SymTypeEnum === SymbolType.INDEXNOINTRADAYDATA||
        result.SymTypeEnum === SymbolType.INDEXNOINTRADAYVOL||
        result.SymTypeEnum === SymbolType.INTERNATIONALINDEX||
        result.SymTypeEnum === SymbolType.HISTORICINDEX||
        result.SymTypeEnum === SymbolType.INDEX ||
        result.SymTypeEnum === SymbolType.FUTURECONTRACTS ||
        result.SymTypeEnum === SymbolType.CASHSPOTS ||
        result.SymTypeEnum === SymbolType.WONINDUSTRYGROUP197 ||
        result.SymTypeEnum === SymbolType.WONMAJORINDUSTRY89 ||
        result.SymTypeEnum === SymbolType.WONSECTOR11 ||
        result.SymTypeEnum === SymbolType.WONSUBSECTOR33
      ) {
        if (tabItem.TabType == TabType.Datagraph || 
          ((result.SymTypeEnum === SymbolType.ETF || result.SymTypeEnum === SymbolType.FundClosedEnd) && tabItem.TabType == TabType.Ownership) ||
          (result.SymTypeEnum === SymbolType.FUND || result.SymTypeEnum === SymbolType.FundClosedEnd || result.SymTypeEnum === SymbolType.ETF) && tabItem.TabType == TabType.Holdings) {
          tabCollection.push(tabItem);
        }
      }
      else {
        if(tabItem.TabType != TabType.Holdings){
          tabCollection.push(tabItem);
        }
      }
    });

    /* for (let i = 0; i < result.ModelPeriodsList.length; i++) {
       var modelObject = result.ModelPeriodsList[i];
       tabCollection.push({Header: modelObject.Name, TabType: TabType.Model});
     }*/
    return tabCollection;
  }

  getSelectedTabKey(setting, smartGraphkey,tabCollection) {
    var selectedTabKey = setting.SelectedTabSettings[smartGraphkey];
    if(tabCollection){
      let tabCollect = [];
      tabCollection.map((tab)=>{
        tabCollect.push(tab.Header)
      })
      let reqTabKey ;
      if(selectedTabKey && tabCollect.includes(selectedTabKey)){
       reqTabKey  = selectedTabKey;
      }else{
        reqTabKey  = tabCollect[0];  
        }
      return reqTabKey;
    }
    return selectedTabKey;
  }

  getNavDataGraphSettings() {
    return SettingsStore.getConsoleSettings().NavDatagraphSettings;
  }

  // updateShowOwnershipTab() {
  //   _state.showOwnershipTab = !_state.showOwnershipTab;
  //   let navDataGraphSettings = this.getNavDataGraphSettings();
  //   navDataGraphSettings.showOwnershipTab = _state.showOwnershipTab;
  //   SettingsStore.saveSettings();
  //   this.emit(CHANGE_EVENT);
  // }

  updateDatagraphURL(symbol) {
    setTimeout(() => {
      let newUrl = '#/' + ConsoleStore.getSymbolUrl(symbol);
      if (window.location.hash != newUrl) window.history.pushState(null, null, newUrl);
    }, 0);
  }

  etf(info) {
    return (info &&
      info.Industry197Name &&
      info.Industry197Name.includes("ETF")
    );
  }

  commonStock(info) {
    return (info &&
      info.SymTypeEnum &&
      (info.SymTypeEnum === SymbolType.USSTOCK ||
        info.SymTypeEnum === SymbolType.PREIPO ||
        info.SymTypeEnum === SymbolType.ADR ||
        info.SymTypeEnum === SymbolType.HISTORICSTOCK ||
        info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK ||
        info.SymTypeEnum === SymbolType.MODELSTOCK ||
        info.SymTypeEnum === SymbolType.ETF ||
        info.SymTypeEnum === SymbolType.REIT ||
        info.SymTypeEnum === SymbolType.FundClosedEnd ||
        info.SymTypeEnum === SymbolType.HISTORICALMODELSTOCK ||
        info.SymTypeEnum === SymbolType.ECONOMICINDICATOR
      )
    );
  }

  internationalStock(info) {
    return (info &&
      info.SymTypeEnum &&
      info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK
    );
  }

  getRIPanelCategoryType(type) {
    var text = '';
    switch (type) {
      case RiSubCategoryType.News: text = "NEWS"; break;
      case RiSubCategoryType.Ownership: text = "OWNERSHIP"; break;
      case RiSubCategoryType.LlrView: text = "O'NEIL"; break;
      case RiSubCategoryType.Holdings: text = "HOLDINGS"; break;
      case RiSubCategoryType.Alerts: text = "ALERTS"; break;
      case RiSubCategoryType.CheckList: text = "CHECKLIST"; break;
      default: text = ""; break;
    }
    return text;
  }

  getAbbreviatedIndicators(indicator) {
    switch (indicator) {
      case "Estimate Revisions": return "ESTREV";
      case "YTD Performance": return "YTDPERF";
      case "Price-To-Earnings": return "PE";
      case "Price-To-Sales": return "PS";
      case "O'Neil Ratings": return "ONR";
      case "MACD": return "MACD";
      case "RSI": return "RSI";
      case "Extended": return "Extended";
      case "Stochastics": return "STOCH";
      case "WonStochastics": return "WONSTOCH";
      case "Accumulation Distribution": return "ACCDIST";
      case "1-Year Rolling Performance": return "1YROLLPERF";
      case "3-Year Rolling Performance": return "3YROLLPERF";
      case "5-Year Rolling Performance": return "5YROLLPERF";
      case "10-Year Rolling Performance": return "10YROLLPERF";
      default: return ""
    }
  }

  getIndicators(setting, periodicity, commonStock = false, etf = false, international = false) {
    let str = "";
    let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
    let isIntraday = majorPeriodicity === GraphType.Intraday;
    if (!isIntraday) {
      if (setting.YTDChartSettings) {
        let ytdSettings = setting.YTDChartSettings[majorPeriodicity];
        if (ytdSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("YTD Performance");
        }
      }
      if (setting.YTD1ChartSettings) {
        let ytdSettings = setting.YTD1ChartSettings[majorPeriodicity];
        if (ytdSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("1-Year Rolling Performance");
        }
      }
      if (setting.YTD3ChartSettings) {
        let ytdSettings = setting.YTD3ChartSettings[majorPeriodicity];
        if (ytdSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("3-Year Rolling Performance");
        }
      }
      if (setting.YTD5ChartSettings) {
        let ytdSettings = setting.YTD5ChartSettings[majorPeriodicity];
        if (ytdSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("5-Year Rolling Performance");
        }
      }
      if (setting.YTD10ChartSettings) {
        let ytdSettings = setting.YTD10ChartSettings[majorPeriodicity];
        if (ytdSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("10-Year Rolling Performance");
        }
      }
      if (setting.EPSRChartSettings && commonStock && !etf && !international) {
        let epsrSettings = setting.EPSRChartSettings[majorPeriodicity];
        if (majorPeriodicity === GraphType.Quarterly || majorPeriodicity === GraphType.Annual) {
          epsrSettings.IsAvailable = false;
        }
        if (epsrSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("Estimate Revisions");
        }
      }
      if (setting.PTOEChartSettings && commonStock && !etf && !international) {
        let ptoeSettings = setting.PTOEChartSettings[majorPeriodicity];
        if (ptoeSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("Price-To-Earnings");
        }
      }
      if (setting.PTOSChartSettings && commonStock && !etf && !international) {
        let ptosSettings = setting.PTOSChartSettings[majorPeriodicity];
        if (ptosSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("Price-To-Sales");
        }
      }
      if (setting.TechChartSettings && (commonStock || etf) && !international) {
        let techSettings = setting.TechChartSettings[majorPeriodicity];
        if (techSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("O'Neil Ratings");
        }
      }
      if (setting.TechChartSettings && international && majorPeriodicity === GraphType.Daily) {
        let techSettings = setting.TechChartSettings[majorPeriodicity];
        if (techSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("O'Neil Ratings");
        }
      }
    }
    if (setting.MacdChartSettings) {
      let macdSettings = setting.MacdChartSettings[majorPeriodicity];
      if (macdSettings.IsAvailable) {
        str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("MACD");
      }
    }
    if (setting.RsiChartSettings) {
      let rsiSettings = setting.RsiChartSettings[majorPeriodicity];
      if (rsiSettings.IsAvailable) {
        str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("RSI");
      }
    }
    if (setting.StochasticsChartSettings) {
      let stochkSettings = setting.StochasticsChartSettings[majorPeriodicity];
      if (stochkSettings.IsAvailable) {
        str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("Stochastics");
      }
    }
    if (setting.WonStochasticsChartSettings) {
      let stochkSettings = setting.WonStochasticsChartSettings[majorPeriodicity];
      if (stochkSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("WonStochastics");
      }
    }
    if (!isIntraday) {
      if (setting.AccDistChartSettings) {
        let accdiskSettings = setting.AccDistChartSettings[majorPeriodicity];
        if (accdiskSettings.IsAvailable) {
          str += (str == "" ? "" : ",") + this.getAbbreviatedIndicators("Accumulation Distribution");
        }
      }
    }
    return str;
  }

  pushDataToAdobe() {
    // Push data to DTM while loading login page
    if (_state.SelectedTabKey === TabType.Fundamental)
      return;

    let RISettings, SummarySettings, InsiderSettings, VolumeSettings, FinancialSettings, settings;
    const navDataGraphSetting = this.getNavDataGraphSettings();
    const tabDatagraphSetting = navDataGraphSetting.TabDataGraphSettings
    const navListSetting = SettingsStore.getConsoleSettings().NavListManagerSettings;
    const RIPanelSettings = navDataGraphSetting.RelatedInformationSettings;
    const periodicity = PeriodicityHelper.mapMajorPeriodicities(navDataGraphSetting.TabDataGraphSettings.Periodicity);

    switch (_state.SymbolType) {
      case SmartViewType.STOCK:
        settings = tabDatagraphSetting.StockViewSettings;
        RISettings = RIPanelSettings.RIPanelContentSettings;
        SummarySettings = tabDatagraphSetting.StockViewSettings.SummaryBlockSettings[periodicity];
        InsiderSettings = tabDatagraphSetting.StockViewSettings.InsiderBlockSettings[periodicity];
        VolumeSettings = tabDatagraphSetting.StockViewSettings.VolumeBlockSettings[periodicity];
        FinancialSettings = tabDatagraphSetting.StockViewSettings.FinancialBlockSettings[periodicity];
        break;
      case SmartViewType.FUND:
        settings = tabDatagraphSetting.FUNDViewSettings;
        RISettings = RIPanelSettings.FUNDViewSettings;
        SummarySettings = tabDatagraphSetting.FUNDViewSettings.SummaryBlockSettings && tabDatagraphSetting.FUNDViewSettings.SummaryBlockSettings[periodicity];
        InsiderSettings = tabDatagraphSetting.FUNDViewSettings.InsiderBlockSettings && tabDatagraphSetting.FUNDViewSettings.InsiderBlockSettings[periodicity];
        VolumeSettings = tabDatagraphSetting.FUNDViewSettings.VolumeBlockSettings && tabDatagraphSetting.FUNDViewSettings.VolumeBlockSettings[periodicity];
        FinancialSettings = tabDatagraphSetting.FUNDViewSettings.FinancialBlockSettings && tabDatagraphSetting.FUNDViewSettings.FinancialBlockSettings[periodicity];
        break;
      case SmartViewType.CURRENCY:
        RISettings = RIPanelSettings.CurrencyRIPanelContentSettings;
        settings = tabDatagraphSetting.CurrencyViewSettings;
        SummarySettings = tabDatagraphSetting.CurrencyViewSettings.SummaryBlockSettings && tabDatagraphSetting.CurrencyViewSettings.SummaryBlockSettings[periodicity];
        InsiderSettings = tabDatagraphSetting.CurrencyViewSettings.InsiderBlockSettings && tabDatagraphSetting.CurrencyViewSettings.InsiderBlockSettings[periodicity];
        VolumeSettings = tabDatagraphSetting.CurrencyViewSettings.VolumeBlockSettings && tabDatagraphSetting.CurrencyViewSettings.VolumeBlockSettings[periodicity];
        FinancialSettings = tabDatagraphSetting.CurrencyViewSettings.FinancialBlockSettings && tabDatagraphSetting.CurrencyViewSettings.FinancialBlockSettings[periodicity];
        break;
      case SmartViewType.ETF:
        RISettings = RIPanelSettings.ETFRIPanelContentSettings;
        settings = tabDatagraphSetting.ETFViewSettings;
        SummarySettings = tabDatagraphSetting.ETFViewSettings.SummaryBlockSettings && tabDatagraphSetting.ETFViewSettings.SummaryBlockSettings[periodicity];
        InsiderSettings = tabDatagraphSetting.ETFViewSettings.InsiderBlockSettings && tabDatagraphSetting.ETFViewSettings.InsiderBlockSettings[periodicity];
        VolumeSettings = tabDatagraphSetting.ETFViewSettings.VolumeBlockSettings && tabDatagraphSetting.ETFViewSettings.VolumeBlockSettings[periodicity];
        FinancialSettings = tabDatagraphSetting.ETFViewSettings.FinancialBlockSettings && tabDatagraphSetting.ETFViewSettings.FinancialBlockSettings[periodicity];
        break;
      case SmartViewType.CEF:
        RISettings = RIPanelSettings.CEFRIPanelContentSettings;
        settings = tabDatagraphSetting.CEFViewSettings;
        SummarySettings = tabDatagraphSetting.CEFViewSettings.SummaryBlockSettings && tabDatagraphSetting.CEFViewSettings.SummaryBlockSettings[periodicity];
        InsiderSettings = tabDatagraphSetting.CEFViewSettings.InsiderBlockSettings && tabDatagraphSetting.CEFViewSettings.InsiderBlockSettings[periodicity];
        VolumeSettings = tabDatagraphSetting.CEFViewSettings.VolumeBlockSettings && tabDatagraphSetting.CEFViewSettings.VolumeBlockSettings[periodicity];
        FinancialSettings = tabDatagraphSetting.CEFViewSettings.FinancialBlockSettings && tabDatagraphSetting.CEFViewSettings.FinancialBlockSettings[periodicity];
        break;
      case SmartViewType.INDEX:
        RISettings = RIPanelSettings.RIPanelContentSettings;
        settings = tabDatagraphSetting.IndexViewSettings;
        SummarySettings = tabDatagraphSetting.IndexViewSettings.SummaryBlockSettings && tabDatagraphSetting.IndexViewSettings.SummaryBlockSettings[periodicity];
        InsiderSettings = tabDatagraphSetting.IndexViewSettings.InsiderBlockSettings && tabDatagraphSetting.IndexViewSettings.InsiderBlockSettings[periodicity];
        VolumeSettings = tabDatagraphSetting.IndexViewSettings.VolumeBlockSettings && tabDatagraphSetting.IndexViewSettings.VolumeBlockSettings[periodicity];
        FinancialSettings = tabDatagraphSetting.IndexViewSettings.FinancialBlockSettings && tabDatagraphSetting.IndexViewSettings.FinancialBlockSettings[periodicity];
        break;
      default:
        RISettings = RIPanelSettings.RIPanelContentSettings;
        settings = tabDatagraphSetting.StockViewSettings;
        SummarySettings = tabDatagraphSetting.StockViewSettings.SummaryBlockSettings && tabDatagraphSetting.StockViewSettings.SummaryBlockSettings[periodicity];
        InsiderSettings = tabDatagraphSetting.StockViewSettings.InsiderBlockSettings && tabDatagraphSetting.StockViewSettings.InsiderBlockSettings[periodicity];
        VolumeSettings = tabDatagraphSetting.StockViewSettings.VolumeBlockSettings && tabDatagraphSetting.StockViewSettings.VolumeBlockSettings[periodicity];
        FinancialSettings = tabDatagraphSetting.StockViewSettings.FinancialBlockSettings && tabDatagraphSetting.StockViewSettings.FinancialBlockSettings[periodicity];
    }
    const etf = _state.SymbolInfo ? this.etf(_state.SymbolInfo) : false;
    const international = _state.SymbolInfo ? this.internationalStock(_state.SymbolInfo) : false;
    const commonStock = _state.SymbolInfo ? this.commonStock(_state.SymbolInfo) : true;


    AdobeUtil.DTMTrack({
      pageName: "Panaray : Symbol : Datagraph",
      siteSection: "Panaray : Symbol",
      userType: "User",
      userIdRequired: true,
      symbol: navDataGraphSetting.symbol,
      inteval: periodicity,
      chartDate: DateHelper.getDateString(new Date()),
      RIPanelState: RIPanelSettings && RIPanelSettings.isRIPanelOpen ? "Open" : "Closed",
      RIPanelSection: RISettings && this.getRIPanelCategoryType(RISettings.ActiveSelectedSubCategory),
      miniListSection: navListSetting && navListSetting.TabONeilSettings.SelectedListInfo.SelectedListName,
      miniListState: _state.isMiniListOpen ? "Open" : "Closed",
      summaryBlockPanel: SummarySettings && SummarySettings.isOpen ? "Open" : "Closed",
      insidersBlockPanel: InsiderSettings && InsiderSettings.IsVisible ? "Open" : "Closed",
      volumeBlockPanel: VolumeSettings && VolumeSettings.IsVisible ? "Open" : "Closed",
      financialsBlockPanel: FinancialSettings && FinancialSettings.IsVisible ? "Open" : "Closed",
      indicatorsType: _state.SymbolType,
      indicators: this.getIndicators(settings, periodicity, commonStock, etf, international),
    },
      AdobeConstants.TAB_GRAPH_VIEW);
  }


  setSymbolInfo(result, state = _state) {
    state.SymbolInfo = result;
    state.Symbol = result.Symbol;
    state.CompanyName = result.CompanyName;
  }

  updateSymbol(Symbol){
    _state.Symbol = Symbol;
    _state.CompanyName = '';
    _currentAction = SettingsConstants.ActionTypes.ON_MINILIST_SYMBOL_CHANGED;
    this.emit(CHANGE_EVENT);
  }

  getCurrencyFactor(targetCurrencyCode) {
    let curfactor = 1;
    let cur = null;
    const {
      currencyData
    } = store.getState().currency;
    if (currencyData != null && !StringUtil.isEmpty(targetCurrencyCode))
      cur = currencyData.filter((c) => c.currencyCode == targetCurrencyCode)[0];
    if (!StringUtil.isEmpty(cur)) curfactor = cur.currencyFactor;
    return curfactor;
  }

  getESCurrencyFactor(targetCurrencyCode) {
    let curfactor = 1;
    let cur = null;
    const {
      esCurrencyData
    } = store.getState().currency;
    if (esCurrencyData != null && !StringUtil.isEmpty(targetCurrencyCode))
      cur = esCurrencyData.filter((c) => c.currencyCode == targetCurrencyCode)[0];
    if (!StringUtil.isEmpty(cur)) curfactor = cur.currencyFactor;
    return curfactor;
  }

  setFinTargetCurrencyCode(finTargetCurrencyCode) {
    _state.finTargetCurrencyCode = finTargetCurrencyCode;
  }

  getIsShowSelectedCurrency(){
    const { isDualCurrency, isMatchFinancials, defaultCurrency, selectedCurrencyCode } = SettingsStore.getConsoleSettings();
    if (_state.SymbolInfo) {
    const symbolInfo = _state.SymbolInfo;
    let isShowSelectedCurrency = false;
    if (isDualCurrency && isMatchFinancials) {
      isShowSelectedCurrency = defaultCurrency != selectedCurrencyCode;
    } else if (isDualCurrency && !isMatchFinancials) {
      isShowSelectedCurrency = true;
    } else if (!defaultCurrency) {
      isShowSelectedCurrency = false;
    } else if (symbolInfo.SymTypeEnum == SymbolType.FUTURECONTRACTS ||
        symbolInfo.SymTypeEnum == SymbolType.CASHSPOTS) {
        isShowSelectedCurrency = false;
    } else {
      isShowSelectedCurrency = defaultCurrency != selectedCurrencyCode;
    }
    return isShowSelectedCurrency;
  }
}

  isShowPattern() {
    const { isCurrencyConversionChecked } = store.getState().currency;
    const isShowSelectedCurrency = this.getIsShowSelectedCurrency();
    return !(isCurrencyConversionChecked && isShowSelectedCurrency);
  }

  isShowFactor() {
    const { isCurrencyConversionChecked } = store.getState().currency;
    const isShowSelectedCurrency = this.getIsShowSelectedCurrency();
    return !(isCurrencyConversionChecked && isShowSelectedCurrency);
  }

  isHideEpsChng() {
    const {
        isMatchFinancials
    } = store.getState().currency;
    const settings = SettingsStore.getConsoleSettings().NavDatagraphSettings;
    const periodicity = settings.TabDataGraphSettings.Periodicity
    if (periodicity == GraphType.Daily) {
        if (!isMatchFinancials) {
            return true;
        }
    } else {
        if (!isMatchFinancials || !StringUtil.isEmpty(this.targetCurrencyCode())) {
            return true;
        }
    }
    return false;
  }

  getCurrencyCode(defaultCurrencyCode) {
    const targetCurrencyCode = this.targetCurrencyCode();
    return targetCurrencyCode == '' ? defaultCurrencyCode : targetCurrencyCode; 
  }

  isCurrencyEntitled() {
    return UserInfoUtil.IsUserEntitled(EntitlementType.RAY_CURRENCY_CONVERSION_Entitlement);
  }

  targetCurrencyCode() {
    const { isCurrencyConversionChecked, selectedCurrencyCode } = SettingsStore.getConsoleSettings();
    return isCurrencyConversionChecked && this.isCurrencyControlVisible() ? selectedCurrencyCode : '';
  }

  finTargetCurrencyCode(symbolInfo = _state.SymbolInfo) {
    if (this.isCurrencyEntitled()) {
      const { isCurrencyConversionChecked, isMatchFinancials, selectedCurrencyCode } = SettingsStore.getConsoleSettings();
      const isCurrencyControlVisible = this.isCurrencyControlVisible();
      if (isCurrencyConversionChecked && isCurrencyControlVisible) {
        return selectedCurrencyCode;
      }
      const defaultCurrencyCode = symbolInfo && symbolInfo.DefaultCurrency && symbolInfo.DefaultCurrency.Code ? symbolInfo.DefaultCurrency.Code : symbolInfo.DefaultCurrency ? symbolInfo.DefaultCurrency : null;
      if (isMatchFinancials && defaultCurrencyCode == "GBP") {
        return "GBX";
      }
      if (isMatchFinancials && defaultCurrencyCode == "ZAR") {
        return "ZAX";
      }
      if (symbolInfo && symbolInfo.WSCurrency != null) {
        return isMatchFinancials && isCurrencyControlVisible ? defaultCurrencyCode : symbolInfo.WSCurrency;
      }
      return null;
    }
    return null;
  }

  selectCurrencyCode() {
    const settings = SettingsStore.getConsoleSettings();
    const {
      isCurrencyConversionChecked,
      selectedCurrencyCode
    } = settings;
    if (isCurrencyConversionChecked) {
      return selectedCurrencyCode;
    }
    return null;
  }

  isCurrencyControlVisible() {
    let navDatagraphSettings = this.getNavDataGraphSettings();
    if (navDatagraphSettings) {
      return this.isCurrencyControlVisibleForSymbolType(navDatagraphSettings.symbolTypeEnum);      
    }
  }

  isCurrencyControlVisibleForSymbolType(symbolType) {
    if (ConsoleStore.getState().ActiveNav == NavType.NavSymbol && this.isCurrencyEntitled() && !ConsoleStore.getState().isHistoric) { //&& !NavSymbol.States.ActiveChartPanelModel.States.IsModelStock) {
      return this.isStockTypeValidForCurrency(symbolType) && !this.isIndexGroupForFutureContracts();
    }
    return false;
  }

  isIndexGroupForFutureContracts() {
    let flag = false;
    let navDatagraphSettings = this.getNavDataGraphSettings();
    if (navDatagraphSettings && navDatagraphSettings.symbolTypeEnum == SymbolType.FUTURECONTRACTS) {
      const headerStates = store.getState().DatagraphReducers.HeaderReducer
      flag = headerStates.gicsIndustryGroupName === 'Index';
    }
    return flag;
  }

  isStockTypeValidForCurrency(symbolType) {
    switch (symbolType) {
      case SymbolType.INDEX:
      case SymbolType.INDEXNOINTRADAYVOL:
      case SymbolType.INDEXNOINTRADAYDATA:
      case SymbolType.INDEXNOINTRADAYVOLDATA:
      case SymbolType.INTERNATIONALINDEX:
      case SymbolType.HISTORICINDEX:
      case SymbolType.CURRENCY:
      case SymbolType.CRYPTOCURRENCY:
      case SymbolType.EXCHANGERATE:
      case SymbolType.WONINDUSTRYGROUP197:
      case SymbolType.WONMAJORINDUSTRY89:
      case SymbolType.WONSECTOR11:
      case SymbolType.WONSUBSECTOR33:
      case SymbolType.ECONOMICINDICATOR:
      case SymbolType.FUTURECONTRACTS:
      case SymbolType.CASHSPOTS:
        return false;
      default:
        return true;
    }
  }

  dispatcherCallback(payload) {
    AppDispatcher.waitFor([SettingsStore.dispatchToken]);
    const action = payload.action;
    const actionData = action.data;
    const data = {SymbolInfo:action.data,PricePanelDataResp:action.PricePanelDataResp}
    switch (action.actionType) {
      case TabDataGraphConstants.ActionTypes.SET_DEFAULT_STATE:
      case TabDataGraphConstants.ActionTypes.PERIODICITY_CHANGED: {
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        break;
      }
      case SettingsConstants.ActionTypes.GET_SYMBOL_PRICE_DATA: {
        _state.action = action.actionType;
        _state.symbolPriceData = action.data;
        this.emit(CHANGE_EVENT);
        _state.action = null;
        break;
      }
      case SettingsConstants.ActionTypes.DATAGRAPH_UPDATE_TAB: {
        const viewSetting = this.getNavDataGraphSettings();
        // if (viewSetting.showOwnershipTab == false && action.data == 'OWNERSHIP') action.data = 'DATAGRAPH'
        DataGraphSettingHelper.updateTabSelection(viewSetting, _state.SymbolType, action.data);
        _state.SelectedTabKey = this.getSelectedTabKey(viewSetting, _state.SymbolType);
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        _currentAction = SettingsConstants.ActionTypes.DATAGRAPH_UPDATE_TAB;
        this.emit(CHANGE_EVENT);
        break;
      }
      case SettingsConstants.ActionTypes.STOCK_SYMBOL_CHANGED: {
        this.setStateOnSymbolChanged(data);
        const viewSetting = this.getNavDataGraphSettings();
        _currentAction = SettingsConstants.ActionTypes.STOCK_SYMBOL_CHANGED;
        DataGraphSettingHelper.updateSymbol(viewSetting, action.data.Symbol);
        this.addToRecentList(action.data);
        _state.IsMiniListPlay = action.data.IsMiniListPlay;
        if (_state.isInitial) {
          this.emit(CHANGE_EVENT);
          _state.isInitial = false;
        }
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        break;
      }
      case SettingsConstants.ActionTypes.CURRENCY_SYMBOL_CHANGED: {
        const viewSetting = this.getNavDataGraphSettings();
        _currentAction = SettingsConstants.ActionTypes.CURRENCY_SYMBOL_CHANGED;
        DataGraphSettingHelper.updateSymbol(viewSetting, action.data.Symbol);
        _state.IsMiniListPlay = action.data.IsMiniListPlay;

        this.addToRecentList(action.data);
        this.setStateOnSymbolChanged(data);
        this.emit(CHANGE_EVENT);
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        // if (action.data.IsMiniListPlay) {
        //   MiniListStore.setMiniListNextInterval();
        // }
        break;
      }
      case SettingsConstants.ActionTypes.CRYPTO_CURRENCY_SYMBOL_CHANGED: {
        const viewSetting = this.getNavDataGraphSettings();
        _currentAction = SettingsConstants.ActionTypes.CRYPTO_CURRENCY_SYMBOL_CHANGED;
        DataGraphSettingHelper.updateSymbol(viewSetting, action.data.Symbol);
        _state.IsMiniListPlay = action.data.IsMiniListPlay;

        this.addToRecentList(action.data);
        this.setStateOnSymbolChanged(data);
        this.emit(CHANGE_EVENT);
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        // if (action.data.IsMiniListPlay) {
        //   MiniListStore.setMiniListNextInterval();
        // }
        break;
      }
      case SettingsConstants.ActionTypes.ETF_SYMBOL_CHANGED: {
        this.setStateOnSymbolChanged(data);
        const viewSetting = this.getNavDataGraphSettings();
        _currentAction = SettingsConstants.ActionTypes.ETF_SYMBOL_CHANGED;
        DataGraphSettingHelper.updateSymbol(viewSetting, action.data.Symbol);
        _state.IsMiniListPlay = action.data.IsMiniListPlay;

        this.addToRecentList(action.data);
        this.emit(CHANGE_EVENT);
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        // if (action.data.IsMiniListPlay) {
        //   MiniListStore.setMiniListNextInterval();
        // }
        break;
      }
      case SettingsConstants.ActionTypes.CEF_SYMBOL_CHANGED: {
        this.setStateOnSymbolChanged(data);
        const viewSetting = this.getNavDataGraphSettings();
        _currentAction = SettingsConstants.ActionTypes.CEF_SYMBOL_CHANGED;
        DataGraphSettingHelper.updateSymbol(viewSetting, action.data.Symbol);
        _state.IsMiniListPlay = action.data.IsMiniListPlay;

        this.addToRecentList(action.data);
        this.emit(CHANGE_EVENT);
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        // if (action.data.IsMiniListPlay) {
        //   MiniListStore.setMiniListNextInterval();
        // }
        break;
      }
      case SettingsConstants.ActionTypes.INDEX_SYMBOL_CHANGED: {
        this.setStateOnSymbolChanged(data);
        const viewSetting = this.getNavDataGraphSettings();
        _currentAction = SettingsConstants.ActionTypes.INDEX_SYMBOL_CHANGED;
        DataGraphSettingHelper.updateSymbol(viewSetting, action.data.Symbol);
        _state.IsMiniListPlay = action.data.IsMiniListPlay;

        this.addToRecentList(action.data);
        this.emit(CHANGE_EVENT);
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        // if (action.data.IsMiniListPlay) {
        //   MiniListStore.setMiniListNextInterval();
        // }
        break;
      }
      case SettingsConstants.ActionTypes.FUND_SYMBOL_CHANGED: {
        this.setStateOnSymbolChanged(data);
        const viewSetting = this.getNavDataGraphSettings();
        _currentAction = SettingsConstants.ActionTypes.FUND_SYMBOL_CHANGED;
        DataGraphSettingHelper.updateSymbol(viewSetting, action.data.Symbol);
        _state.IsMiniListPlay = action.data.IsMiniListPlay;

        this.addToRecentList(action.data);
        this.emit(CHANGE_EVENT);
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        // if (action.data.IsMiniListPlay) {
        //   MiniListStore.setMiniListNextInterval();
        // }
        break;
      }
      case SettingsConstants.ActionTypes.CASHSPOTS_SYMBOL_CHANGED: {
        this.setStateOnSymbolChanged(data);
        const viewSetting = this.getNavDataGraphSettings();
        _currentAction = SettingsConstants.ActionTypes.CASHSPOTS_SYMBOL_CHANGED;
        DataGraphSettingHelper.updateSymbol(viewSetting, action.data.Symbol);
        _state.IsMiniListPlay = action.data.IsMiniListPlay;

        this.addToRecentList(action.data);
        this.emit(CHANGE_EVENT);
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        // if (action.data.IsMiniListPlay) {
        //   MiniListStore.setMiniListNextInterval();
        // }
        break;
      }
      case SettingsConstants.ActionTypes.FUTURES_SYMBOL_CHANGED: {
        this.setStateOnSymbolChanged(data);
        const viewSetting = this.getNavDataGraphSettings();
        _currentAction = SettingsConstants.ActionTypes.FUTURES_SYMBOL_CHANGED;
        DataGraphSettingHelper.updateSymbol(viewSetting, action.data.Symbol);
        _state.IsMiniListPlay = action.data.IsMiniListPlay;

        this.addToRecentList(action.data);
        this.emit(CHANGE_EVENT);
        this.setStateOnPeriodicityChanged();
        SettingsStore.saveSettings();
        this.pushDataToAdobe();
        // if (action.data.IsMiniListPlay) {
        //   MiniListStore.setMiniListNextInterval();
        // }
        break;
      }
      case SettingsConstants.ActionTypes.INDUSTRYGROUP_SYMBOL_CHANGED: {
        this.setStateOnSymbolChanged(data);
         const viewSetting = this.getNavDataGraphSettings();
         _currentAction = SettingsConstants.ActionTypes.INDUSTRYGROUP_SYMBOL_CHANGED;
         DataGraphSettingHelper.updateSymbol(viewSetting, action.data.Symbol)
         _state.IsMiniListPlay = action.data.IsMiniListPlay;

         this.addToRecentList(action.data);
         this.emit(CHANGE_EVENT);
         this.setStateOnPeriodicityChanged();
         SettingsStore.saveSettings();
        //  if (action.data.IsMiniListPlay) {
        //   MiniListStore.setMiniListNextInterval();
        //  }
         break;
       }
      case TabDataGraphConstants.ActionTypes.CHART_RESIZE:
        _currentAction = TabDataGraphConstants.ActionTypes.CHART_RESIZE;
        this.emit(CHART_RESIZE_EVENT);
        break;
      case SettingsConstants.ActionTypes.DATAGRAPH_STOCK_CURRENCY_CHANGED:
        _currentAction = SettingsConstants.ActionTypes.DATAGRAPH_STOCK_CURRENCY_CHANGED;
        _state.finTargetCurrencyCode = action.data;
        this.emit(CHANGE_EVENT);
        break;
      case SettingsConstants.ActionTypes.ENABLE_CURRENCY_CONVERSION:
        _state.isCurrencyConversion = action.data;
        this.emit(CHANGE_EVENT);
        break;
      // case SettingsConstants.ActionTypes.TOGGLE_OWNERSHIP_TAB:
      //   _currentAction = SettingsConstants.ActionTypes.TOGGLE_OWNERSHIP_TAB;
      //   this.updateShowOwnershipTab();
      //   break;
      case TabDataGraphConstants.ActionTypes.PROCESS_JPEG:
        _state.processJPEG = true;
        this.emit(CHANGE_EVENT);
        break;
      case SettingsConstants.ActionTypes.SYMBOL_CONVERTED_DIALOG_AVAILABLE_CHANGE:
        _state.isSymbolConvertedDialogAvailable = action.data;
        let navDataGraphSetting = this.getNavDataGraphSettings();
        navDataGraphSetting.isSymbolConvertedDialogAvailable = action.data;
        SettingsStore.saveSettings();
        break;
      case TabDataGraphConstants.ActionTypes.UPDATE_GLOBAL_FLAG:
        const flagStatus = actionData.isDelete ? 0 : 1;
        this.setFlag(flagStatus);
        break;
      default:
        break;
    }
  }
}

const datagraphStore = new DatagraphStore();
export default datagraphStore;
